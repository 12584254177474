import React, { useEffect, useState } from 'react';
import commonFunction from '@/lib/utils/common';

export default function HotelAutoComplete(props) {
    const { theme } = props;

    return (
        <div className={props.styles.mob_hotel_search_wid_inputfield}>
            <label>{props.label}</label>
            <div className={props.styles.mob_hotel_search_wid_inputfield_main}>
                {/* <img src={props.leftIcon} className={props.styles.hotel_search_left_icon} /> */}
                <span className={props.styles.mob_hotel_search_left_icon}
                    onClick={() => { props.isAutoCompleteExpand(!props.expandAutoCompleteBool); props.onChangeHotelName(""); props.onClickHotelInput(); }}
                >
                    {/* <img src={props.leftIcon} className={props.styles.mob_hotel_search_left_icon} alt=""/> */}

                    {props.leftIcon}
                </span>
                <input type='text' autoComplete={'off'} id="myTextField"
                    placeholder={!commonFunction.isValueEmpty(props.placholder) ? props.placholder : ""}
                    className={props.styles.search_widget_input_new}
                    value={!commonFunction.isValueEmpty(props.value) ? props.value : ""}
                    onChange={(event) => { props.onChangeHotelName(event.target.value) }}
                    onClick={() => { props.isAutoCompleteExpand(!props.expandAutoCompleteBool); props.onChangeHotelName(""); props.onClickHotelInput(); }}
                />
                {!commonFunction.isValueEmpty(props.isError) && props.isError === true ? (
                    <div className={props.styles.mob_hotel_search_error_message}><span>{!commonFunction.isValueEmpty(props.errorMessage) ? props.errorMessage : 'Invalid field'}</span></div>
                ) : null}
                {props.expandAutoCompleteBool === true ? (
                    <div className={props.styles.mob_hotel_autocomplete_value}>
                        {!commonFunction.isValueEmpty(props.autoCompleteData) && props.autoCompleteData.length > 0 &&
                            props.autoCompleteData.map(element => {
                                return (
                                    <div className={props.styles.mob_hotel_autocomplete_element} onClick={() => {
                                        props.onClickHotelName(element); props.isAutoCompleteExpand(false)
                                    }}>
                                        <img src={element.property_count === 0 ? "/images/hotels.svg" : "/images/location.svg"} alt=""/>
                                        <div>
                                            <div className={props.styles.hotel_autocomplete_name}>{element.search_text}</div>
                                            {element.property_count === 0 ? "" : <div className={props.styles.hotel_autocomplete_tag}>{element.property_count} Properties</div>}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                ) : null
                }
            </div>
        </div>
    )
}