import React, { useEffect, useState, useRef, Component } from 'react';
import moment from 'moment';
import {
    format, startOfMonth, endOfMonth, differenceInDays, addDays, getMonth, getYear,
    endOfToday, differenceInCalendarDays, subDays, isMonday, isTuesday, isWednesday, isThursday,
    isFriday, isSaturday, isSunday, isBefore, startOfToday, isAfter, isSameDay
} from 'date-fns';
import commonFunction from '../../../../lib/utils/common';
// import styles from "./calendar.module.css";

const MonthList = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const weekList = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export default class MobileCalender extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateFormat: !commonFunction.isValueEmpty(this.props.dateFormat) ? this.props.dateFormat : "dd/MM/yyyy",
            isCalendarVisible: false,
            numberOfClicks: 0,
            twoCalendarsArray: [],
            isDoubleSelection: !commonFunction.isValueEmpty(this.props.doubleSelection) ? this.props.doubleSelection : false,
            dateLimitArr: this.props.startDateLimit,
            currentMonths: [getMonth(startOfMonth(new Date()))],
            selectedMonthStartDate: startOfMonth(startOfToday()),
            selectedMonthEndDate: endOfMonth(startOfMonth(new Date())),
            // startDate: this.props.startDate,
            endDate: this.props.endDate,
            hoverEndDate: this.props.endDate,
            totalDays: "",
            pageX: "",
            pageY: "",
            initPageX: "",
            initPageY: "",
            totalMonths: !commonFunction.isValueEmpty(this.props.totalMonths) ? this.props.totalMonths : 12,
            serviceType: this.props.serviceType ? this.props.serviceType : ""
        };
    }
    componentDidMount() {
        this.setState({
            currentMonths: this.props.type === "double" ? !commonFunction.isValueEmpty(this.state.dateLimitArr) ?
                [getMonth(startOfMonth(new Date(this.specDateEle(this.state.dateLimitArr, 'y'), this.specDateEle(this.state.dateLimitArr, 'm'), this.specDateEle(this.state.dateLimitArr, 'd')))),
                getMonth(startOfMonth(new Date(this.specDateEle(this.state.dateLimitArr, 'y'), this.specDateEle(this.state.dateLimitArr, 'm'), this.specDateEle(this.state.dateLimitArr, 'd')))) + 1] :
                [getMonth(startOfMonth(new Date())), getMonth(startOfMonth(new Date())) + 1] : !commonFunction.isValueEmpty(this.state.dateLimitArr) ?
                [getMonth(startOfMonth(new Date(this.specDateEle(this.state.dateLimitArr, 'y'), this.specDateEle(this.state.dateLimitArr, 'm'), this.specDateEle(this.state.dateLimitArr, 'd'))))] :
                [getMonth(startOfMonth(new Date()))],
            selectedMonthStartDate: !commonFunction.isValueEmpty(this.state.dateLimitArr) ? startOfMonth(new Date(this.specDateEle(this.state.dateLimitArr, 'y'), this.specDateEle(this.state.dateLimitArr, 'm'), this.specDateEle(this.state.dateLimitArr, 'd'))) : startOfMonth(startOfToday()),
        })
    }
    componentDidUpdate() {
        if (this.props.endDate !== this.state.endDate) {
            this.setState({ endDate: this.props.endDate });
        }
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    handleClickOutside = () => {
        if (this.state.isCalendarVisible === true) this.setState({ isCalendarVisible: false });
    };
    specDateEle(date, type) {
        try {
            let toDefaultFormat = moment(date, this.state.dateFormat);
            let getDate = toDefaultFormat.format(this.state.dateFormat);
            let getYear = typeof date === 'string' ? new Date(getDate).getFullYear() : date.getFullYear();
            let getMonth = typeof date === 'string' ? new Date(getDate).getMonth() : date.getMonth();
            let getDay = typeof date === 'string' ? new Date(getDate).getDate() : date.getDate();
            switch (type) {
                case 'y':
                    return getYear
                case 'm':
                    return getMonth
                case 'd':
                    return getDay
                case 'a':
                    return [getYear, getMonth, getDay];
                default:
            }
        } catch (err) {
            console.log('catch yyyyy', err)
        }
    }
    onClickChangeVisibility = () => {
        this.props.changeVisibility(!this.props.isVisible)
    }
    onClickDateCell = (day, month, year, bool) => {
        if (!bool) { //new added
            if (this.state.isDoubleSelection === true) {
                let date = new Date(year, month, day);
                if (this.state.numberOfClicks === 0) {
                    this.setState({ numberOfClicks: 1, totalDays: '' }, () => {
                        this.props.onChangeDate(date, 'startDate');
                        this.props.onChangeDate('', 'endDate');
                    });
                }
                if (this.state.numberOfClicks === 1) {
                    let differenceInDays = differenceInCalendarDays(new Date(date), new Date(this.props.startDate))
                    this.setState({ numberOfClicks: 0 }, () => {
                        if (differenceInDays < 0) {
                            this.props.onChangeDate(date, 'startDate');
                            this.props.onChangeDate('', 'endDate');
                        } else {
                            this.setState({ numberOfClicks: 0 }, () => {
                                this.props.onChangeDate(date, 'endDate');
                                // this.props.changeVisibility(!this.props.isVisible)
                            });
                        }
                    });
                }
            } else {
                if (bool === false) {
                    let date = moment(new Date(year, month, day)).format(this.state.dateFormat);
                    this.props.onChangeDate(date);
                }
            }
        }
    }
    renderCalendar() {
        let temp = this.state.selectedMonthStartDate;
        let twoCalendarsArray = [];
        for (let i = 0; i < this.state.totalMonths; i++) {
            let StartDateOfMonth = temp;
            let monthHead = addDays(StartDateOfMonth, i === 0 ? 0 : 1);
            let endDateOfMonth = endOfMonth(monthHead);
            let dayDifference = differenceInCalendarDays(endDateOfMonth, monthHead);
            let days = [];
            let month = getMonth(monthHead)
            let year = getYear(monthHead)
            let startOfMonthDay = isMonday(monthHead) ? 0 : isTuesday(monthHead) ? 1 : isWednesday(monthHead) ? 2 : isThursday(monthHead) ? 3 : isFriday(monthHead) ? 4 : isSaturday(monthHead) ? 5 : 6;
            let startDateLimit = this.props.startDateLimit;
            let isHoliday = this.props.isHoliday ? this.props.isHoliday : false;
            let holidayStartDate = this.props.holidayStartDate ? this.props.holidayStartDate : '';
            let holidayEndDate = this.props.holidayEndDate ? this.props.holidayEndDate : '';
            let startDate = this.props.startDate;
            let endDate = this.state.endDate;
            let hoverDate = this.state.hoverEndDate;
            for (let k = 0; k < startOfMonthDay; k++) {
                days = [...days, { day: "", month: month, year: year, isDisable: true, selectedDate: false, highlight: false }];
            }
            for (let j = 0; j <= dayDifference; j++) {
                let isBeforeDate = isBefore(new Date(year, month, j + 1), new Date(this.specDateEle(startDateLimit, 'y'), this.specDateEle(startDateLimit, 'm'), this.specDateEle(startDateLimit, 'd')));
                let disableDate = false;

                if (isHoliday) {
                    let isHLDBeforeDate = isBefore(new Date(year, month, j + 1), new Date(this.specDateEle(holidayStartDate, 'y'), this.specDateEle(holidayStartDate, 'm'), this.specDateEle(holidayStartDate, 'd')));
                    let isHLDAfterDate = isAfter(new Date(year, month, j + 1), new Date(this.specDateEle(holidayEndDate, 'y'), this.specDateEle(holidayEndDate, 'm'), this.specDateEle(holidayEndDate, 'd')));
                    disableDate = isHLDBeforeDate == true || isHLDAfterDate == true ? true : false;
                }
                if (isBeforeDate === true || disableDate == true) {
                    days = [...days, { day: j + 1, month: month, year: year, isDisable: true, selectedDate: false, highlight: false }];
                } else {
                    days = [...days, { day: j + 1, month: month, year: year, isDisable: false, selectedDate: false, highlight: false }];
                }

                //     console.log("day aarti", days , j+1)
                if (this.state.isDoubleSelection === true) {
                    let isBeforeDate = isBefore(new Date(year, month, j + 1), new Date(this.specDateEle(endDate, 'y'), this.specDateEle(endDate, 'm'), this.specDateEle(endDate, 'd')));
                    let isAfterDate = isAfter(new Date(year, month, j + 1), new Date(this.specDateEle(startDate, 'y'), this.specDateEle(startDate, 'm'), this.specDateEle(startDate, 'd')));
                    // console.log('UUUUUU==== ' + new Date(year, month, j + 1) + ' ==== ' + endDate + ' ==== ' + startDate)
                    //    console.log("isAfterDate",isAfterDate);
                    //    console.log("isBeforeDate",isBeforeDate);
                    if (isAfterDate && isBeforeDate) {
                        //    days[j + 1].highlight = true;
                        days[j].highlight = true;
                    }
                }
            }
            if (this.state.isDoubleSelection === true) {
                let startDateIndex = days.findIndex((items) => new Date(items.year, items.month, items.day).toString() === new Date(this.specDateEle(startDate, 'y'), this.specDateEle(startDate, 'm'), this.specDateEle(startDate, 'd')).toString());
                let endDateIndex = days.findIndex((items) => new Date(items.year, items.month, items.day).toString() === new Date(this.specDateEle(endDate, 'y'), this.specDateEle(endDate, 'm'), this.specDateEle(endDate, 'd')).toString());
                if (startDateIndex !== -1) {
                    days[startDateIndex].selectedDate = true;
                }
                if (endDateIndex !== -1) {
                    days[endDateIndex].selectedDate = true;
                }
            } else {
                let SelectedDate = this.props.value;
                let selectedDateIndex = days.findIndex((items) => new Date(items.year, items.month, items.day).toString() === new Date(this.specDateEle(SelectedDate, 'y'), this.specDateEle(SelectedDate, 'm'), this.specDateEle(SelectedDate, 'd')).toString())
                if (selectedDateIndex !== -1) {
                    days[selectedDateIndex].selectedDate = true;
                }
            }
            temp = endDateOfMonth;
            twoCalendarsArray.push(
                <div className={this.props.styles.calendar_whole_body}>
                    <div className={this.props.styles.calendar_head}>
                        <div className={this.props.styles.calendar_head_center_side}>
                            <h3>{format(monthHead, 'MMMM yyyy')}</h3>
                        </div>
                    </div>
                    <div className={this.props.styles.calendar_body}>
                        <div className={this.props.styles.calendar_week_heads}>
                            {weekList.map((ele, ix) => {
                                return (
                                    <div className={this.props.styles.coln_center_week} key={i + "th_month_" + ix + "week"}>
                                        {ele}
                                    </div>
                                )
                            })}
                        </div>
                        <div className={this.props.styles.calendar_day_list}>
                            {days.map((ele, idx) => {
                                let isHighLight = false;
                                let isHoverDate = false;
                                if (this.state.isDoubleSelection === true) {
                                    let isBeforeDate = isBefore(new Date(ele.year, ele.month, ele.day), new Date(this.specDateEle(hoverDate, 'y'), this.specDateEle(hoverDate, 'm'), this.specDateEle(hoverDate, 'd')));
                                    let isBeforeEndDate = isBefore(new Date(ele.year, ele.month, ele.day), new Date(this.specDateEle(endDate, 'y'), this.specDateEle(endDate, 'm'), this.specDateEle(endDate, 'd')));
                                    let isAfterDate = isAfter(new Date(ele.year, ele.month, ele.day), new Date(this.specDateEle(startDate, 'y'), this.specDateEle(startDate, 'm'), this.specDateEle(startDate, 'd')));
                                    isHoverDate = isSameDay(new Date(ele.year, ele.month, ele.day), new Date(this.specDateEle(hoverDate, 'y'), this.specDateEle(hoverDate, 'm'), this.specDateEle(hoverDate, 'd')));
                                    if ((isAfterDate && isBeforeDate && commonFunction.isValueEmpty(endDate)) || (isAfterDate && isBeforeEndDate && (!commonFunction.isValueEmpty(endDate)))) {
                                        isHighLight = true;
                                    }
                                }
                                return (
                                    <div className={(ele.selectedDate === true && ele.day) ? this.props.styles.day_cell_center + ' ' + this.props.styles.selected_calendar_date : isHighLight === true && ele.day != "" ? this.props.styles.day_cell_center_highlight : this.props.styles.day_cell_center} key={i}
                                        onClick={(e) => { e.stopPropagation(); this.onClickDateCell(ele.day, ele.month, ele.year, ele.isDisable) }} onMouseEnter={(e) => this.onHoverDayCell(ele, e)} >
                                        {this.state.isDoubleSelection === true && !commonFunction.isValueEmpty(ele.day) && ele.isDisable === false && !commonFunction.isValueEmpty(hoverDate) && isHoverDate && !commonFunction.isValueEmpty(this.state.totalDays) && <div class={this.props.styles.container_tooltip}>{this.state.totalDays} Night</div>}
                                        <span className={ele.isDisable === true ? this.props.styles.calendar_day_disable : ''}>{ele.day}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            )
        }
        return twoCalendarsArray
    }
    onClickDoneBtn = () => {
        this.props.changeVisibility(false)
    }
    onHoverDayCell = (currentDate, e) => {
        let date = moment(new Date(currentDate.year, currentDate.month, currentDate.day)).format(this.props.dateFormat ? this.props.dateFormat : "DD/MMM/yyyy");
        this.setState({
            hoverEndDate: date
        })
        if (!commonFunction.isValueEmpty(this.props.startDate) && commonFunction.isValueEmpty(this.state.endDate)) {
            this.setState({
                hoverEndDate: date, pageX: Number(e.clientX) - Number(this.state.initPageX), pageY: Number(e.clientY) - Number(this.state.initPageY)
            }, () => {
                let difference = differenceInCalendarDays(new Date(currentDate.year, currentDate.month, currentDate.day), new Date(this.props.startDate))
                if (difference > 0) this.setState({ totalDays: difference })
                else this.setState({ totalDays: '' })
            })
        }
    }
    onMouseMoveCalendar = (e, eventUsed) => {
        switch (eventUsed) {
            case 'onMouseEnter':
                this.setState({ initPageX: e.clientX, initPageY: e.clientY })
                break;
            default:
                break;
        }
        e.preventDefault()
    }
    selectDateLabelType() {
        let dateLabel = { calaHeadTitle1: "DEPARTURE DATE", calaHeadTitle2: "RETURN DATE" };
        if (this.state.serviceType == "hotel") {
            dateLabel.calaHeadTitle1 = "Check In";
            dateLabel.calaHeadTitle2 = "Check Out";
        } else if (this.state.serviceType == "holiday") {
            dateLabel.calaHeadTitle1 = "START DATE";
            dateLabel.calaHeadTitle2 = "END DATE";
        }
        return dateLabel;
    }
    render() {
        // console.log('calendar_wid_container', this.props)
        // console.log('calendar_wid_container11111', this.state)
        return (
            <div className={this.props.styles.calendar_wid_container}>
                <label>{this.props.label}</label>
                <div className={this.props.styles.calendar_wid_main}>
                    {!commonFunction.isValueEmpty(this.props.iconImage) ? (
                        <img src={this.props.iconImage} className={this.props.styles.flight_search_wid_inputfield_img} alt=""/>
                    ) : null}
                    {this.state.isDoubleSelection === true ? (
                        <input type='text' autoComplete={'off'} id="myTextField" className="search_widget_input_new" readOnly
                            placeholder={!commonFunction.isValueEmpty(this.props.placholder) ? this.props.placholder : ""}
                            value={!commonFunction.isValueEmpty(this.props.value) ? this.props.value : ""}
                            onClick={(e) => { e.stopPropagation(); this.onClickChangeVisibility() }}
                        />
                    ) : (
                        <input type='text' autoComplete={'off'} id="myTextField" className="search_widget_input_new" readOnly
                            placeholder={!commonFunction.isValueEmpty(this.props.placholder) ? this.props.placholder : ""}
                            value={!commonFunction.isValueEmpty(this.props.value) ? this.props.value : ""}
                            onClick={(e) => { e.stopPropagation(); this.onClickChangeVisibility() }}
                        />
                    )}
                </div>
                {this.props.isVisible === true ? (
                    <div className={this.props.styles.calendar_wid_container_popup}>
                        {/* <img src="/images/download.png" onClick={(e) => { e.stopPropagation(); this.onClickChangeVisibility() }} className={this.props.styles.flight_search_wid_inputfield_img} /> */}
                        <div className={this.props.styles.calendar_modal_close} onClick={(e) => { e.stopPropagation(); this.onClickChangeVisibility() }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                        </div>
                        <div className={this.props.styles.calendar_head_container}>
                            <div className={this.props.styles.calendar_head_main}>
                                <div className={this.props.styles.calendar_head_com + " " + this.props.styles.calendar_head_left}>
                                    {/* <span className={this.props.styles.calendar_head_title}>{this.props.isHoliday ? "START DATE" : "DEPARTURE DATE"}</span> */}
                                    <span className={this.props.styles.calendar_head_title}>{this.selectDateLabelType()?.calaHeadTitle1}</span>
                                    <h5>{!commonFunction.isValueEmpty(this.props.startDate) ? format(new Date(this.props.startDate), 'dd MMMM yyyy') : 'Select from date'}</h5>
                                </div>
                                <div className={this.props.styles.calendar_head_com + " " + this.props.styles.calendar_head_right}>
                                    {/* <span className={this.props.styles.calendar_head_title}>{this.props.isHoliday ? "END DATE" : "RETURN DATE"}</span> */}
                                    <span className={this.props.styles.calendar_head_title}>{this.selectDateLabelType()?.calaHeadTitle2}</span>
                                    <h5>{!commonFunction.isValueEmpty(this.props.endDate) ? format(new Date(this.props.endDate), 'dd MMMM yyyy') : 'Select to date'}</h5>
                                </div>
                            </div>
                        </div>
                        <div className={this.props.styles.calendar_main_body_popup}>
                            {this.renderCalendar()}
                        </div>
                        <div className={this.props.styles.calendar_footer}>
                            <button onClick={(e) => { e.stopPropagation(); this.onClickDoneBtn() }}>Done</button>
                        </div>
                    </div>
                ) : null}
            </div>
        )
    }
}

// export default function MobileCalender(props) {
//     const wrapperRef = useRef(null);
//     const dateLimitArr = props.dateLimit.split('/');
//     const [dateFormat, setDateFormat] = useState(!commonFunction.isValueEmpty(props.dateFormat) ? props.dateFormat : "dd/MM/yyyy");
//     const [isCalendarVisible, setIsCalendarVisible] = useState(false);
//     const [isDoubleSelection, setIsDoubleSelection] = useState(!commonFunction.isValueEmpty(props.doubleSelection) ? props.doubleSelection : false);
//     const [numberOfClicks, setNumberOfClicks] = useState(0)
//     const [selectedMonthStartDate, setSelectedMonthStartDate] = useState(!commonFunction.isValueEmpty(dateLimitArr) ?
//         startOfMonth(new Date(dateLimitArr[2], dateLimitArr[1] - 1, dateLimitArr[0])) : startOfMonth(startOfToday()));
//     const [selectedMonthEndDate, setSelectedMonthEndDate] = useState(endOfMonth(startOfMonth(new Date())));
//     const [currentMonths, setCurrentMonths] = useState(props.type === "double" ? !commonFunction.isValueEmpty(dateLimitArr) ?
//         [getMonth(startOfMonth(new Date(dateLimitArr[2], dateLimitArr[1] - 1, dateLimitArr[0]))), getMonth(startOfMonth(new Date(dateLimitArr[2], dateLimitArr[1] - 1, dateLimitArr[0]))) + 1] :
//         [getMonth(startOfMonth(new Date())), getMonth(startOfMonth(new Date())) + 1] : !commonFunction.isValueEmpty(dateLimitArr) ?
//         [getMonth(startOfMonth(new Date(dateLimitArr[2], dateLimitArr[1] - 1, dateLimitArr[0])))] :
//         [getMonth(startOfMonth(new Date()))]);
//     const twoCalendarsArray = [];
//     let temp = selectedMonthStartDate;
//     let endDateVar = props.endDate.split('/');
//     let startDateVar = props.startDate.split('/');
//     useEffect(() => {
//         document.addEventListener("click", handleClickOutside, false);
//         return () => {
//             document.removeEventListener("click", handleClickOutside, false);
//         };
//     }, [])
//     const handleClickOutside = event => {
//         if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
//             if (isCalendarVisible === true) setIsCalendarVisible(false)
//         }
//     };
//     for (let i = 0; i < 12; i++) {
//         let StartDateOfMonth = temp;
//         let monthHead = addDays(StartDateOfMonth, 1);
//         let endDateOfMonth = endOfMonth(monthHead);
//         let startMonth = startOfMonth(monthHead)
//         let dayDifference = differenceInCalendarDays(endDateOfMonth, startMonth);
//         let days = [];
//         let month = getMonth(monthHead)
//         let year = getYear(monthHead)
//         let startOfMonthDay = isMonday(monthHead) ? 0 : isTuesday(monthHead) ? 1 : isWednesday(monthHead) ? 2 : isThursday(monthHead) ? 3 : isFriday(monthHead) ? 4 : isSaturday(monthHead) ? 5 : 6;
//         let dateLimit = props.dateLimit.split("/");
//         let startDate = props.startDate.split("/");
//         let endDate = props.endDate.split("/");
//         console.log("////////", monthHead);
//         console.log("======", endDateOfMonth);
//         console.log("******", dayDifference);
//         for (let k = 0; k < startOfMonthDay; k++) {
//             days = [...days, { day: "", month: month, year: year, isDisable: true, selectedDate: false, highlight: false }];
//         }
//         for (let j = 0; j <= dayDifference; j++) {
//             let isBeforeDate = isBefore(new Date(year, month, j + 1), new Date(dateLimit[2], dateLimit[1] - 1, dateLimit[0]));
//             if (isBeforeDate === true) {
//                 days = [...days, { day: j + 1, month: month, year: year, isDisable: true, selectedDate: false, highlight: false }];
//             } else {
//                 days = [...days, { day: j + 1, month: month, year: year, isDisable: false, selectedDate: false, highlight: false }];
//             }

//             if (isDoubleSelection === true) {
//                 let isBeforeDate = isBefore(new Date(year, month, j + 1), new Date(endDate[2], endDate[1] - 1, endDate[0]));
//                 let isAfterDate = isAfter(new Date(year, month, j + 1), new Date(startDate[2], startDate[1] - 1, startDate[0]));
//                 if (isAfterDate && isBeforeDate) {
//                     console.log('text1111111', days[j])
//                     days[j + 1].highlight = true;
//                 }
//             }
//         }
//         if (isDoubleSelection === true) {
//             let startDateIndex = days.findIndex((items) => new Date(items.year, items.month, items.day).toString() === new Date(startDate[2], startDate[1] - 1, startDate[0]).toString())
//             let endDateIndex = days.findIndex((items) => new Date(items.year, items.month, items.day).toString() === new Date(endDate[2], endDate[1] - 1, endDate[0]).toString())
//             if (startDateIndex !== -1) {
//                 days[startDateIndex].selectedDate = true;
//             }
//             if (endDateIndex !== -1) {
//                 days[endDateIndex].selectedDate = true;
//             }
//         } else {
//             let SelectedDate = props.value.split("/");
//             let selectedDateIndex = days.findIndex((items) => new Date(items.year, items.month, items.day).toString() === new Date(SelectedDate[2], SelectedDate[1] - 1, SelectedDate[0]).toString())
//             if (selectedDateIndex !== -1) {
//                 days[selectedDateIndex].selectedDate = true;
//             }
//         }
//         temp = endDateOfMonth;
//         twoCalendarsArray.push(
//             <div className={styles.calendar_whole_body}>
//                 <div className={styles.calendar_head}>
//                     <div className={styles.calendar_head_center_side}>
//                         <h3>{format(monthHead, 'MMMM yyyy')}</h3>
//                     </div>
//                 </div>
//                 <div className={styles.calendar_body}>
//                     <div className={styles.calendar_week_heads}>
//                         {weekList.map((ele, i) => {
//                             return (
//                                 <div className={styles.coln_center_week} key={i}>
//                                     {ele}
//                                 </div>
//                             )
//                         })}
//                     </div>
//                     <div className={styles.calendar_day_list}>
//                         {days.map((ele, i) => {
//                             return (
//                                 <div className={ele.selectedDate === true ? styles.day_cell_center + ' ' + styles.selected_calendar_date : ele.highlight === true ? styles.day_cell_center_highlight : styles.day_cell_center} key={i}
//                                     onClick={() => onClickDateCell(ele.day, ele.month, ele.year, ele.isDisable)}>
//                                     <span className={ele.isDisable === true ? styles.calendar_day_disable : ''}>{ele.day}</span>
//                                 </div>
//                             )
//                         })}
//                     </div>
//                 </div>
//             </div>
//         )
//     }
//     const onClickChangeVisibility = () => {
//         props.changeVisibility(!props.isVisible)
//     }
//     const onClickDateCell = (day, month, year, bool) => {
//         if (isDoubleSelection === true) {
//             let date = format(new Date(year, month, day), dateFormat);
//             if (numberOfClicks === 0) {
//                 setNumberOfClicks(1)
//                 props.onChangeDate('startDate', date);
//                 props.onChangeDate('endDate', '');
//             }
//             if (numberOfClicks === 1) {
//                 props.onChangeDate('endDate', date);
//                 setNumberOfClicks(0);
//             }
//         } else {
//             if (bool === false) {
//                 let date = format(new Date(year, month, day), dateFormat);
//                 props.onChangeDate(date);
//             }
//         }
//     }

//     console.log(".download.png", currentMonths)
//     return (
//         <div className={styles.calendar_wid_container} ref={wrapperRef}>
//             <label>{props.label}</label>
//             <div className={styles.calendar_wid_main}>
//                 {!commonFunction.isValueEmpty(props.iconImage) ? (
//                     <img src={props.iconImage} className={styles.flight_search_wid_inputfield_img} />
//                 ) : null}
//                 {isDoubleSelection === true ? (
//                     <input type='text' autoComplete={'off'} id="myTextField" className="search_widget_input_new" readOnly
//                         placeholder={!commonFunction.isValueEmpty(props.placholder) ? props.placholder : ""}
//                         value={!commonFunction.isValueEmpty(props.value) ? props.value : ""}
//                         onClick={() => onClickChangeVisibility()}
//                     />
//                 ) : (
//                     <input type='text' autoComplete={'off'} id="myTextField" className="search_widget_input_new" readOnly
//                         placeholder={!commonFunction.isValueEmpty(props.placholder) ? props.placholder : ""}
//                         value={!commonFunction.isValueEmpty(props.value) ? props.value : ""}
//                         onClick={() => onClickChangeVisibility()}
//                     />
//                 )}
//             </div>
//             {props.isVisible === true ? (
//                 <div className={styles.calendar_wid_container_popup} ref={wrapperRef}>
//                     <img src="/images/download.png" onClick={() => onClickChangeVisibility()} className={styles.flight_search_wid_inputfield_img} />
//                     <div className={styles.calendar_head_container}>
//                         <div className={styles.calendar_head_main}>
//                             <div className={styles.calendar_head_com + " " + styles.calendar_head_left}>
//                                 <span className={styles.calendar_head_title}>DEPARTURE DATE</span>
//                                 <h5>{!commonFunction.isValueEmpty(props.startDate) ? format(new Date(startDateVar[2], startDateVar[1], startDateVar[0]), 'dd MMMM yyyy') : 'Select from date'}</h5>
//                             </div>
//                             <div className={styles.calendar_head_com + " " + styles.calendar_head_right}>
//                                 <span className={styles.calendar_head_title}>RETURN DATE</span>
//                                 <h5>{!commonFunction.isValueEmpty(props.endDate) ? format(new Date(endDateVar[2], endDateVar[1], endDateVar[0]), 'dd MMMM yyyy') : 'Select to date'}</h5>
//                             </div>
//                         </div>
//                     </div>
//                     <div className={styles.calendar_main_body_popup}>
//                         {twoCalendarsArray}
//                     </div>
//                     <div className={styles.calendar_footer}>
//                         <button>Done</button>
//                     </div>
//                 </div>
//             ) : null}
//             {/* <div className={styles.calendar_wid_main}>
//                 {isDoubleSelection === true ? (
//                     <input type='text' autoComplete={'off'} id="myTextField" className="search_widget_input_new" readOnly
//                         placeholder={!commonFunction.isValueEmpty(props.placholder) ? props.placholder : ""}
//                         value={!commonFunction.isValueEmpty(props.value) ? props.value : ""}
//                         onClick={() => onClickChangeVisibility()}
//                     />
//                 ) : (
//                     <input type='text' autoComplete={'off'} id="myTextField" className="search_widget_input_new" readOnly
//                         placeholder={!commonFunction.isValueEmpty(props.placholder) ? props.placholder : ""}
//                         value={!commonFunction.isValueEmpty(props.value) ? props.value : ""}
//                         onClick={() => onClickChangeVisibility()}
//                     />
//                 )}
//             </div>
//             {props.isVisible === true ? (
//                 <div className={props.type === 'double' ? styles.calendar_container + " " + styles.calendar_container_double : styles.calendar_container + " " + styles.calendar_container_single}>
//                     <div className={styles.calendar_main}>
//                         {getMonth(selectedMonthStartDate) !== getMonth(new Date()) ? (
//                             <div className={styles.calendar_head_left_side} onClick={() => onClickPrevNextArrow('prev')}>
//                                 <span className={styles.calendar_head_icon}>  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
//   <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
// </svg> </span>
//                             </div>
//                         ) : null}
//                         <div className={styles.calendar_head_right_side} onClick={() => onClickPrevNextArrow('next')}>
//                             <span className={styles.calendar_head_icon}> <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
//   <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
// </svg> </span>
//                         </div>
//                         {twoCalendarsArray}
//                     </div>
//                 </div>
//             ) : null} */}

//         </div>

//     )
// }
