import React, { useEffect, useState } from 'react';
import commonFunction from '../../../../lib/utils/common';

export default function PaxConfig(props) {
    const { theme } = props;
    return (
        <div className={props.styles.mob_flight_search_wid_inputfield}>
            <div 
            // className={props.value === props.minLimit ? props.styles.disable_pax_btn : ""} 
            onClick={() => {
                if (!commonFunction.isValueEmpty(props.value)) {
                    if (props.value > props.minLimit) {
                        props.onChangePaxValue(props.value - 1, "minus");
                    }
                }
            }}>
                <div className={props.styles.mob_flight_search_flex_adjust}>
                    {/* <img src="/images/Minus.svg" className={props.styles.minus_pax} /> */}
                    <svg className={props.styles.minus_pax} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="minus"><path fill="#FFFFFF" d="M19,11H5a1,1,0,0,0,0,2H19a1,1,0,0,0,0-2Z"></path></svg>
                </div>
            </div>
            <span>{props.value}</span>
            <div 
            // className={props.totalPax === props.maxLimit ? props.styles.disable_pax_btn : ""} 
            onClick={() => {
                console.log("theme333333", props.value);
                if (!commonFunction.isValueEmpty(props.value)) {
                    if (
                        props.totalPax >= props.minLimit &&
                        props.totalPax <= props.maxLimit
                    ) {
                        props.onChangePaxValue(props.value + 1, "add");
                    }
                }
            }}>
                {/* <img src="/images/Add.svg" className={props.styles.add_pax} /> */}
                <svg className={props.styles.minus_pax} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="plus"><path fill="#fff" d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"></path></svg>
            </div>
        </div>
    )
}