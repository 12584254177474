
import Theme5 from "./theme5";

export default function SearchWidget(props) {
    const theme = props.pageTheme;
 
    switch (theme) {
        case 'theme5':
            return <Theme5 {...props} />         
        default:
            return <Theme5  {...props}/>
    }
}