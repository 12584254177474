import React, { Component } from 'react';
import { format, addDays, differenceInCalendarDays } from 'date-fns';
import HotelAutoComplete from './HotelAutoComplete';
import MobileCalender from '../../../calendar/hotelCalender/theme1/MobileCalender';
// import commonFunction from '@/lib/utils/common';
import commonFunction from '../../../../lib/utils/common';

const childAgeList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
// let regions = ""
export default class Theme1 extends Component {
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
        this.state = {
            hotelName: "",
            expandHotelNameBoolean: false,
            checkInOutValue: "",
            checkInValue: commonFunction.getDateWithFormat(new Date(), this?.props?.dateFormat),
            checkOutValue: commonFunction.getDateWithFormat(addDays(new Date(), 2), this?.props?.dateFormat),
            dateLimit: commonFunction.getDateWithFormat(new Date(), this?.props?.dateFormat),
            openRoomDetailList: false,
            expandCheckInOutDateBoolean: false,
            selectedRoomDetails: { rooms: 1, guest: 2 },
            getAutoSuggestList: this.props.getAutoSuggestList,
            isError: { hotelName: false, checkInOutDate: false, roomGuest: false },
            getDestinationId: '',
            getSearchType: '',
            roomList: [{
                id: 1, adults: 2, children: 0, adultAddCountDisable: false, adultMinusCountDisable: false,
                childrenAddCountDisable: false, childrenMinusCountDisable: true, childDetails: []
            }],
        }
    }
    componentDidMount() {
        // regions = new URLSearchParams(window.location.search).get("region")
        if (!commonFunction.isValueEmpty(this.props) && this.props.isSRP === true && !commonFunction.isValueEmpty(this.props.query)) {
            let checkInArr = this.props.query.checkin.split('-'); let checkOutArr = this.props.query.checkout.split('-');
            let checkInValue = commonFunction.getDateWithFormat(new Date(checkInArr[0], Number(checkInArr[1]) - 1, checkInArr[2]), this?.props?.dateFormat);
            let checkOutValue = commonFunction.getDateWithFormat(new Date(checkOutArr[0], Number(checkOutArr[1]) - 1, checkOutArr[2]), this?.props?.dateFormat);
            let checkInOut = commonFunction.getDateWithFormat(new Date(checkInArr[0], Number(checkInArr[1]) - 1, checkInArr[2]), this?.props?.dateFormat) + ' - ' + commonFunction.getDateWithFormat(new Date(checkOutArr[0], Number(checkOutArr[1]) - 1, checkOutArr[2]), this?.props?.dateFormat);
            let roomsdetail = this.props.query.rooms.split('_');
            let totalRooms = roomsdetail.length; let totalAdult = 0; let totalChildren = 0; let roomListArr = [];
            roomsdetail.map((ele, index) => {
                let split = ele.split('-').filter(item => item);
                totalAdult = Number(split[0].charAt(0)) + totalAdult;
                totalChildren = Number(split[1].charAt(0)) + totalChildren;
                roomListArr = [...roomListArr, {
                    id: index + 1, adults: Number(split[0].charAt(0)), children: Number(split[1].charAt(0)), adultAddCountDisable: false, adultMinusCountDisable: true,
                    childrenAddCountDisable: false, childrenMinusCountDisable: Number(split[1].charAt(0)) == 1 ? false : true, childDetails: []
                }]
                split.map((element, id) => {
                    if (id > 1) roomListArr[index].childDetails = [...roomListArr[index].childDetails, { age: element, isOpen: false, isError: false }]
                })
            });
            this.setState({
                checkInOutValue: checkInOut, checkInValue: checkInValue, checkOutValue: checkOutValue, getDestinationId: this.props.query.destid,
                getSearchType: this.props.query.search_type, hotelName: this.props.query.region + ", " + this.props.query.country,
                selectedRoomDetails: { rooms: totalRooms, guest: totalAdult + totalChildren }, roomList: roomListArr
            }, () => {
                this.checkRoomDetails()
            })
        }
        else {
            // if (regions != null && regions != "" && regions != undefined) {
            //     this.renderInfo()
            // }
            this.setState({
                checkInOutValue: this.getCheckInOutValue(this.state.checkInValue, this.state.checkOutValue,),
            })
        }
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    checkRoomDetails() {
        let { roomList } = this.state;
        roomList.map((ele, index) => {
            let totalAccomodation = ele.adults + ele.children;
            if (totalAccomodation <= 12) {
                if (totalAccomodation === 12) {
                    roomList[index].adultAddCountDisable = true;
                    roomList[index].childrenAddCountDisable = true;
                } else if (totalAccomodation < 12) {
                    if (roomList[index].adultAddCountDisable === true && roomList[index].childrenAddCountDisable === true) {
                        roomList[index].adultAddCountDisable = false;
                        roomList[index].childrenAddCountDisable = false;
                    }
                    if (roomList[index].adults > 1) {
                        roomList[index].adultMinusCountDisable = false;
                    } else if (roomList[index].adults === 1) {
                        roomList[index].adultMinusCountDisable = true;
                        roomList[index].adultAddCountDisable = false;
                    }
                    if (roomList[index].children > 1) {
                        roomList[index].childrenMinusCountDisable = false;
                    } else if (roomList[index].children > 0) {
                        roomList[index].childrenMinusCountDisable = false;
                        roomList[index].childrenAddCountDisable = false;
                    }
                }
            }
        })
        this.setState({ roomList: [...roomList] });
    }
    handleClickOutside = e => {
        if (this.dropdownWrapperRef && !this.dropdownWrapperRef.contains(e.target)) {
            if (this.props.expandHotelNameBoolean === true) this.props.expandHotelNameBooleanfunc(false);
            if (this.state.expandCheckInOutDateBoolean === true || this.state.openRoomDetailList === true) {
                this.setState({ expandCheckInOutDateBoolean: false, openRoomDetailList: false });
            }
        }
    };
    setDropdownWrapperRef = (node) => {
        this.dropdownWrapperRef = node;
    }
    onClickDoneButton = () => {
        let totalGuest = 0;
        let count = 0;
        let Validation = true;
        let roomList = this.state.roomList;
        while (count <= roomList.length) {
            if (!commonFunction.isValueEmpty(roomList[count]) && !commonFunction.isValueEmpty(roomList[count].childDetails)
                && roomList[count].childDetails.length > 0) {
                roomList[count].childDetails.map((element, index) => {
                    if (commonFunction.isValueEmpty(element.age)) {
                        roomList[count].childDetails[index].isError = true;
                    }
                })
                Validation = roomList[count].childDetails.every(item => !commonFunction.isValueEmpty(item.age));
            }
            count++;
        }
        // this.setState({ roomList: [...roomList] })
        this.setState({ roomList: [...roomList] }, () => {
            if (Validation === true) {
                this.state.roomList.length > 0 && this.state.roomList.map(element => {
                    totalGuest = totalGuest + element.adults + element.children;
                })
                this.setState({ selectedRoomDetails: { rooms: this.state.roomList.length, guest: totalGuest } })
                if (this.state.openRoomDetailList === true) {
                    this.setState({ openRoomDetailList: false });
                }
            }
        });
    }

    removeRoomItem = (id) => {
        const filteredRoom = this.state.roomList.filter((item) => item.id !== id);
        this.setState({ roomList: [...filteredRoom] });
    }
    addAccomodationCount = async (id, index, type) => {
        let { roomList } = this.state;
        if (type === "adult") {
            if (roomList[index].id === id) {
                roomList[index].adults = roomList[index].adults + 1;
            }
        } else {
            if (roomList[index].id === id) {
                roomList[index].children = roomList[index].children + 1;
                roomList[index].childDetails = [...roomList[index].childDetails, { age: '', isOpen: false, isError: false }]
            }
        }
        let totalAccomodation = roomList[index].adults + roomList[index].children;
        if (totalAccomodation <= 7) {
            if (totalAccomodation === 7) {
                roomList[index].adultAddCountDisable = true;
                roomList[index].childrenAddCountDisable = true;
            } else if (totalAccomodation < 7) {
                if (roomList[index].adultAddCountDisable === true && roomList[index].childrenAddCountDisable === true) {
                    roomList[index].adultAddCountDisable = false;
                    roomList[index].childrenAddCountDisable = false;
                }
            }
            if (roomList[index].adults > 1) {
                roomList[index].adultMinusCountDisable = false;
            }
            if (roomList[index].children > 0) {
                roomList[index].childrenMinusCountDisable = false;
            }
            await this.setState({ roomList: [...roomList] });
        }
    }
    minusAccomodationCount = async (id, index, type) => {
        let { roomList } = this.state;
        if (type === "adult") {
            if (roomList[index].id === id) {
                if (roomList[index].adults >= 2) {
                    roomList[index].adults = roomList[index].adults - 1;
                    roomList[index].adultMinusCountDisable = false;
                    if (roomList[index].adultAddCountDisable === true) {
                        roomList[index].adultAddCountDisable = false;
                    }
                    if (roomList[index].childrenAddCountDisable === true) {
                        roomList[index].childrenAddCountDisable = false;
                    }
                } else if (roomList[index].adults === 1) {
                    roomList[index].adultMinusCountDisable = true;
                }
            }
        } else {
            if (roomList[index].id === id) {
                roomList[index].childDetails.pop();
                if (roomList[index].children >= 1) {
                    roomList[index].children = roomList[index].children - 1;
                    roomList[index].childrenMinusCountDisable = false;
                    if (roomList[index].childrenAddCountDisable === true) {
                        roomList[index].childrenAddCountDisable = false;
                    }
                    if (roomList[index].adultAddCountDisable === true) {
                        roomList[index].adultAddCountDisable = false;
                    }
                } else if (roomList[index].children === 0) {
                    roomList[index].childrenMinusCountDisable = true;
                }
            }
        }
        await this.setState({ roomList: [...roomList] });
    }
    isChildrenAge = (index, ind, type, age) => {
        let { roomList } = this.state;
        if (type === "isOpen") {
            roomList[index].childDetails[ind].isOpen = !roomList[index].childDetails[ind].isOpen;
        } else {
            roomList[index].childDetails[ind].age = age;
            roomList[index].childDetails[ind].isOpen = false;
            if (roomList[index].childDetails[ind].isError === true) {
                roomList[index].childDetails[ind].isError = false;
            }
        }
        this.setState({ roomList: [...roomList] })
    }
    onChangeCheckInOutValue = (value, type) => {
        if (type === "startDate") {
            this.setState({ checkInValue: value });
        } else if (type === "endDate") {
            let differenceInDays = differenceInCalendarDays(new Date(value), new Date(this.state.checkInValue));
            if (differenceInDays === 0) {
                this.setState({ checkOutValue: addDays(new Date(value), 1) }, () => {
                    if (!commonFunction.isValueEmpty(value)) {
                        let checkInOut = commonFunction.getDateWithFormat(this?.state?.checkInValue, this?.props?.dateFormat) + ' - ' + commonFunction.getDateWithFormat(this?.state?.checkOutValue, this?.props?.dateFormat);
                        this.setState({ checkInOutValue: checkInOut });
                    }
                })
            } else {
                this.setState({ checkOutValue: value }, () => {
                    if (!commonFunction.isValueEmpty(value)) {
                        let checkInOut = commonFunction.getDateWithFormat(this?.state?.checkInValue, this?.props?.dateFormat) + ' - ' + commonFunction.getDateWithFormat(value, this?.props?.dateFormat);
                        this.setState({ checkInOutValue: checkInOut });
                    }
                })
            }
        }
    }
    onChangeSetHotelName = (value) => {
        this.setState({ hotelName: value }, () => {
            if (this.state.isError.hotelName === true) {
                this.validateErrorMsg();
            }
            this.props.getAutoSuggestValue(value)
        })
    }
    validateErrorMsg = () => {
        const mapDispatch = {
            hotelName: commonFunction.isValueEmpty(this.state.hotelName) ? true : false,
            checkInOutDate: commonFunction.isValueEmpty(this.state.checkInOutValue) ? true : false,
            roomGuest: commonFunction.isValueEmpty(this.state.selectedRoomDetails) ? true : false,
        }
        this.setState({ isError: mapDispatch })
    }
    onClickSearchHotel = () => {
        const mapDispatch = {
            hotelName: commonFunction.isValueEmpty(this.state.hotelName)||""  ? true : false,
            checkInOutDate: commonFunction.isValueEmpty(this.state.checkInOutValue) ? true : false,
            roomGuest: commonFunction.isValueEmpty(this.state.selectedRoomDetails) ? true : false,
        }
        // mapDispatch.hotelName =  this.state.getDestinationId ? false : true;
        this.setState({ isError: mapDispatch }, () => {
            let Validate = true;
            for (const [key, value] of Object.entries(this.state.isError)) {
                if (value === true) {
                    Validate = false;
                    break;
                }
            }
            if (Validate === true) {
                let hotelNameArry = this.state.hotelName.split(',');
                let roomListArr = [];
                this.state.roomList.map((res, id) => {
                    roomListArr = [...roomListArr, res.adults + 'a-' + res.children + 'c'];
                    res.childDetails.map(ele => {
                        roomListArr[id] = roomListArr[id].concat('-', ele.age)
                    })
                });
                //IF CHECKOUT DATE IS EMPTY
                if (!this.state.checkOutValue) {
                    this.state.checkOutValue = commonFunction.getDateWithFormat(addDays(new Date(this.state.checkInValue), 2), this?.props?.dateFormat);
                }
                let urlQuery = {
                    region: hotelNameArry[0]?.trim(), country: (this.props.isSRP === true) ? hotelNameArry[1]?.trim() : hotelNameArry[hotelNameArry.length - 1]?.trim(), checkin: format(new Date(this.state.checkInValue), 'yyyy-MM-dd'),
                    checkout: format(new Date(this.state.checkOutValue), 'yyyy-MM-dd'), rooms: roomListArr.join('_'), type: 'location', roomtype: 'single', page: 1,
                    orderby: 'desc', mop: 'accrual', destid: this.state.getDestinationId, search_type: this.state.getSearchType, ratings: '', qc: '',
                    nationallity: undefined, guests: undefined, is_web_app: false
                };
                commonFunction.redirectTo("/hotel/search", urlQuery);
            }
        })
    }
    onClickSetHotelName = (element) => {
        this.setState({
            hotelName: element.search_text, getDestinationId: element.destination_id,
            getSearchType: element.search_type ? element.search_type : 6
        })
    }
    getCheckInOutValue = (checkIn, checkOut) => {
        let checkInArr = checkIn.split('/');
        let checkOutArr = checkOut.split('/');
        return checkIn + ' - ' + checkOut;
    }
    onClickVisibilityStatus = (type, value) => {
        switch (type) {
            case "hotel":
                this.props.expandHotelNameBooleanfunc(value)
                if (this.state.expandCheckInOutDateBoolean === true || this.state.openRoomDetailList === true) {
                    this.setState({ expandCheckInOutDateBoolean: false, openRoomDetailList: false });
                }
                break;
            case "calendar":
                this.setState({ expandCheckInOutDateBoolean: value }, () => {
                    if (this.props.expandHotelNameBoolean === true || this.state.openRoomDetailList === true) {
                        this.setState({ openRoomDetailList: false });
                        this.props.expandHotelNameBooleanfunc(false);
                    }
                });
                break;
            case "rooms":
                if (this.state.openRoomDetailList === false) {
                    this.setState({ openRoomDetailList: true }, () => {
                        if (this.props.expandHotelNameBoolean === true || this.state.expandCheckInOutDateBoolean === true) {
                            this.setState({ expandCheckInOutDateBoolean: false });
                            this.props.expandHotelNameBooleanfunc(false);
                        }
                    });
                } else {
                    this.onClickDoneButton();
                }
                break;
            default:
                break;
        }
    }
    onClickHotelInput = () => {
        this.props.getAutoSuggestValue('')
    }
    // renderInfo = () => {
    //     this.props.getAutoSuggestValue(regions)
    // }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if (regions != null && regions != "" && regions != undefined && !this.props.isSRP) {
    //         if (prevProps.getAutoSuggestList.length < this.props.getAutoSuggestList.length) {
    //             prevProps.getAutoSuggestList = this.props.getAutoSuggestList
    //             this.setState({
    //                 hotelName: prevProps.getAutoSuggestList[0].search_text, getDestinationId: prevProps.getAutoSuggestList[0].destination_id,
    //                 getSearchType: prevProps.getAutoSuggestList[0].search_type,
    //             }, () => {
    //                 this.props.expandHotelNameBooleanfunc(false)
    //             })
    //         }
    //     }
    // }
    render() {
        return (
            <div className={this.props.styles.mob_hotel_search_wid_container}>
                <div className={this.props.styles.mob_hotel_search_wid_main}>
                    <div id={this.props.styles.tab_submenu_content}>
                        <div class={this.props.styles.landing_tab_submenu_new + " " + this.props.styles.sub_tab_new}>
                            <div class={this.props.styles.mobile_visible}>
                                <div class={this.props.styles.mobo_tab_header + " " + this.props.styles.flight_header_mobo}>
                                    {this.props.isSRP ? "" : <img src="/images/back-arrow.png" alt="" className={this.props.styles.arrow_image} onClick={() => this.props.onClosePopup(false)} />}
                                    <h3 className={this.props.styles.search_filter_header}> {this.props.isSRP ? 'Modify search' : 'Hotels & Accomodation'}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={this.props.styles.mob_hotel_search_details_container}>
                        <div className={this.props.styles.mob_hotel_search_details_main}>
                            <div className={this.props.styles.mob_hotel_search_details_row}>
                                <div className={this.props.styles.mob_hotel_search_column + " " + this.props.styles.mob_hotel_search_name}>
                                    <HotelAutoComplete
                                        isDisable={this.props.isDisable === true}
                                        label="City / Area / Hotel Name"
                                        styles={this.props.styles}
                                        value={this.state.hotelName}
                                        placholder="City / Area / Hotel Name"
                                        onClickHotelInput={() => this.onClickHotelInput()}
                                        onClickHotelName={(value) => this.onClickSetHotelName(value)}
                                        onChangeHotelName={(value) => this.onChangeSetHotelName(value)}
                                        isAutoCompleteExpand={(value) => this.onClickVisibilityStatus("hotel", value)}
                                        autoCompleteData={!commonFunction.isValueEmpty(this.props.getAutoSuggestList) ? this.props.getAutoSuggestList : []}
                                        expandAutoCompleteBool={!commonFunction.isValueEmpty(this.props.expandHotelNameBoolean) ? this.props.expandHotelNameBoolean : false}
                                        // leftIcon={'/images/et-location.png'}
                                        leftIcon={
                                            <svg version="1.1" id="Layer_1" width="200px" height="200px" viewBox="0 0 200 200">
                                                <g>
                                                    <path fill="#005A95" d="M104.961,19.873c-37.566-4.212-69.525,25.084-69.525,61.716c0,14.338,4.666,27.075,15.075,41.234   l40.387,58.382c1.594,2.297,4.212,3.674,7.015,3.674l0,0c2.824,0,5.459-1.396,7.03-3.693l26.707-38.361l15.004-22.836   c10.592-13.402,15.113-30.198,12.727-47.289C155.54,45.214,132.652,22.997,104.961,19.873z M141.464,116.248l-15.118,22.991   l-26.678,38.314c-0.57,0.835-1.421,0.928-1.755,0.928c-0.333,0-1.183-0.088-1.754-0.913l-40.44-58.459   c-9.602-13.064-13.883-24.642-13.883-37.52c0-30.723,25.073-55.718,55.886-55.718c2.145,0,4.32,0.123,6.516,0.372   c24.836,2.797,45.355,22.709,48.801,47.346C155.181,88.932,151.128,103.998,141.464,116.248z"></path>
                                                    <path fill="#005A95" d="M97.395,49.585c-17.646,0-32.004,14.357-32.004,32.004c0,17.648,14.357,32.004,32.004,32.004   s32.004-14.356,32.004-32.004C129.398,63.942,115.036,49.585,97.395,49.585z M97.395,107.191c-14.12,0-25.603-11.482-25.603-25.604   c0-14.119,11.483-25.603,25.603-25.603c14.119,0,25.602,11.484,25.602,25.603C122.998,95.709,111.509,107.191,97.395,107.191z"></path>
                                                </g>
                                            </svg>
                                        }
                                        errorMessage={"Please select your Destination"}
                                        isError={this.state.isError.hotelName}
                                    />
                                </div>
                                <div className={this.props.styles.mob_hotel_search_column + " " + this.props.styles.mob_hotel_search_date}>
                                    <MobileCalender
                                        label="Check In & Check Out Date"
                                        styles={this.props.styles}
                                        value={!commonFunction.isValueEmpty(this.state.checkInOutValue) ? this.state.checkInOutValue : ""}
                                        placholder="Select the date"
                                        onChangeDate={(type, value) => { this.onChangeCheckInOutValue(type, value) }}
                                        // iconImage="/images/fgt-dt.png"
                                        iconImage={
                                            <svg version="1.1" id="Layer_1" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 30 30">
                                            <g>
                                                <path fill="currentColor" d="M7.258,17.91H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.445,1.002,1.002,1.002h0.083 c0.558,0,1.003-0.445,1.003-1.002v-0.111C8.262,18.355,7.816,17.91,7.258,17.91z"></path>
                                                <path fill="currentColor" d="M11.322,17.91h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.002,1.001,1.002h0.084 c0.556,0,1.002-0.445,1.002-1.002v-0.111C12.324,18.355,11.878,17.91,11.322,17.91z"></path>
                                                <path fill="currentColor" d="M15.414,17.91h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.446,1.002,1.002,1.002h0.112 c0.556,0,1.001-0.445,1.001-1.002v-0.111C16.415,18.355,15.97,17.91,15.414,17.91L15.414,17.91z"></path>
                                                <path fill="currentColor" d="M7.258,13.792H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.445,1.001,1.002,1.001h0.083 c0.558,0,1.003-0.445,1.003-1.001v-0.111C8.262,14.237,7.816,13.792,7.258,13.792z"></path>
                                                <path fill="currentColor" d="M11.322,13.792h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.001,1.001,1.001h0.084 c0.556,0,1.002-0.445,1.002-1.001v-0.111C12.324,14.237,11.878,13.792,11.322,13.792z"></path>
                                                <path fill="currentColor" d="M15.414,13.792h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.111c0,0.557,0.446,1.001,1.002,1.001h0.112 c0.556,0,1.001-0.445,1.001-1.001v-0.111C16.415,14.237,15.97,13.792,15.414,13.792z"></path>
                                                <path fill="currentColor" d="M19.478,17.91h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.111c0,0.557,0.445,1.002,1.003,1.002h0.111 c0.556,0,1.001-0.445,1.001-1.002v-0.111C20.506,18.355,20.033,17.91,19.478,17.91z"></path>
                                                <path fill="currentColor" d="M23.568,17.91h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.002,1.001,1.002h0.112 c0.557,0,1.002-0.445,1.002-1.002v-0.111C24.57,18.355,24.125,17.91,23.568,17.91z"></path>
                                                <path fill="currentColor" d="M19.478,13.792h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.111c0,0.557,0.445,1.001,1.003,1.001h0.111 c0.556,0,1.001-0.445,1.001-1.001v-0.111C20.506,14.237,20.033,13.792,19.478,13.792z"></path>
                                                <path fill="currentColor" d="M23.568,13.792h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.111c0,0.557,0.445,1.001,1.001,1.001h0.112 c0.557,0,1.002-0.445,1.002-1.001v-0.111C24.57,14.237,24.125,13.792,23.568,13.792z"></path>
                                                <path fill="currentColor" d="M7.258,22.002H7.176c-0.557,0-1.002,0.445-1.002,1.002v0.109c0,0.559,0.445,1.004,1.002,1.004h0.083 c0.558,0,1.003-0.445,1.003-1.004v-0.109C8.262,22.473,7.816,22.002,7.258,22.002z"></path>
                                                <path fill="currentColor" d="M11.322,22.002h-0.084c-0.556,0-1.001,0.445-1.001,1.002v0.109c0,0.559,0.445,1.004,1.001,1.004h0.084 c0.556,0,1.002-0.445,1.002-1.004v-0.109C12.324,22.473,11.878,22.002,11.322,22.002z"></path>
                                                <path fill="currentColor" d="M15.414,22.002h-0.112c-0.556,0-1.002,0.445-1.002,1.002v0.109c0,0.559,0.446,1.004,1.002,1.004h0.112 c0.556,0,1.001-0.445,1.001-1.004v-0.109C16.415,22.473,15.97,22.002,15.414,22.002z"></path>
                                                <path fill="currentColor" d="M19.478,22.002h-0.111c-0.558,0-1.003,0.445-1.003,1.002v0.109c0,0.559,0.445,1.004,1.003,1.004h0.111 c0.556,0,1.001-0.445,1.001-1.004v-0.109C20.506,22.473,20.033,22.002,19.478,22.002z"></path>
                                                <path fill="currentColor" d="M23.568,22.002h-0.112c-0.556,0-1.001,0.445-1.001,1.002v0.109c0,0.559,0.445,1.004,1.001,1.004h0.112 c0.557,0,1.002-0.445,1.002-1.004v-0.109C24.57,22.473,24.125,22.002,23.568,22.002z"></path>
                                                <path fill="currentColor" d="M28.577,10.535L28.577,10.535V8.281c0-1.726-1.392-3.118-3.089-3.118h-1.643V4.189 c0-1.196-0.973-2.171-2.171-2.171c-1.196,0-2.17,0.975-2.17,2.171v0.974l-8.238,0V4.19c0-1.197-0.974-2.171-2.17-2.171 c-1.197,0-2.17,0.974-2.17,2.171v0.974H5.283c-1.725,0-3.117,1.392-3.117,3.118v16.643c0,1.697,1.392,3.088,3.117,3.088h20.232 c1.698,0,3.09-1.391,3.09-3.088L28.577,10.535L28.577,10.535L28.577,10.535z M20.785,4.189c0-0.473,0.39-0.863,0.861-0.863 c0.474,0,0.863,0.39,0.863,0.863v2.06c0,0.473-0.39,0.863-0.863,0.863c-0.472,0-0.861-0.39-0.861-0.863V4.189z M8.206,4.189 c0-0.473,0.389-0.863,0.862-0.863c0.474,0,0.863,0.39,0.863,0.863v2.06c0,0.473-0.39,0.863-0.863,0.863 c-0.473,0-0.862-0.39-0.862-0.863V4.189z M5.254,6.472h1.67c0.112,1.085,1.03,1.948,2.17,1.948c1.113,0,2.031-0.863,2.143-1.948 h8.266c0.111,1.085,1.03,1.948,2.143,1.948c1.113,0,2.031-0.863,2.144-1.948h1.67c0.974,0,1.781,0.807,1.781,1.809v1.615H3.446 V8.28C3.446,7.279,4.253,6.472,5.254,6.472L5.254,6.472z M25.488,26.703H5.254c-1.002,0-1.808-0.807-1.808-1.779V11.175H27.27 v13.749C27.27,25.896,26.462,26.703,25.488,26.703z"></path>
                                            </g>
                                            </svg>
                                        }
                                        startDate={!commonFunction.isValueEmpty(this.state.checkInValue) ? this.state.checkInValue : ""}
                                        endDate={!commonFunction.isValueEmpty(this.state.checkOutValue) ? this.state.checkOutValue : ""}
                                        startDateLimit={!commonFunction.isValueEmpty(this.state.dateLimit) ? this.state.dateLimit : ""}
                                        dateFormat={"DD-MMM-YYYY"}
                                        changeVisibility={(bool) => this.onClickVisibilityStatus("calendar", bool)}
                                        isVisible={this.state.expandCheckInOutDateBoolean}
                                        type={"double"}
                                        doubleSelection={true}
                                        totalMonths={12}
                                        serviceType="hotel"
                                    />
                                </div>
                                <div className={this.props.styles.mob_hotel_search_column + " " + this.props.styles.mob_hotel_search_accomodation}>
                                    <div className={this.props.styles.mob_hotel_cabin_class_cont} onClick={() => this.onClickVisibilityStatus("rooms")}>
                                        <div className={this.props.styles.mob_hotel_cabin_class_icon}>
                                            {/* <img src="/images/psgr.png" className={this.props.styles.mob_hotel_cabin_class_icon_img} alt=""/> */}
                                            <svg version="1.1" id="Layer_1" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 30 30">
                                                <rect x="3.833" y="1.033" fill="none" width="22.334" height="27.964"></rect>
                                                <g>
                                                    <path fill="currentColor" d="M9.683,7.213c0-2.827,2.366-5.146,5.317-5.146c2.952,0,5.316,2.318,5.316,5.146 c0,2.828-2.364,5.146-5.316,5.146C12.048,12.359,9.683,10.042,9.683,7.213L9.683,7.213z M15,1.038c-3.491,0-6.346,2.75-6.346,6.175 c0,3.425,2.855,6.175,6.346,6.175c3.49,0,6.346-2.75,6.346-6.175C21.346,3.789,18.49,1.038,15,1.038z M7.674,17.063 c4.801-2.187,10.323-2.137,15.083,0.138c1.444,0.689,2.363,2.146,2.363,3.746v6.355l-5.047,0.428 c-3.208,0.271-6.435,0.277-9.644,0.018L4.88,27.3V21.11c0-1.691,0.987-3.224,2.525-3.924L7.674,17.063z M23.202,16.272 c-5.035-2.405-10.877-2.458-15.955-0.146l-0.269,0.122c-1.905,0.868-3.127,2.768-3.127,4.862v7.138l6.496,0.526 c3.266,0.264,6.549,0.256,9.814-0.021l5.989-0.507v-7.301C26.149,18.952,25.002,17.134,23.202,16.272L23.202,16.272z"></path>
                                                </g>
                                            </svg>
                                        </div>
                                        <label>Rooms & Guests</label>
                                        <div className={this.props.styles.mob_hotel_cabin_class_name}>
                                            {!commonFunction.isValueEmpty(this.state.selectedRoomDetails) ? this.state.selectedRoomDetails.rooms + " Room, " + this.state.selectedRoomDetails.guest + " Guest" : "1 Room, 2 Guest"}
                                        </div>
                                    </div>
                                    {this.state.openRoomDetailList === true ? (
                                        <div className={this.props.styles.mob_hotel_room_list}>
                                            <div className={this.props.styles.mob_hotel_room_list_main}>
                                                {this.state.roomList.map((element, index) => {
                                                    return (
                                                        <div className={this.props.styles.mob_hotel_room_detail_ele} key={index}>
                                                            <div className={this.props.styles.mob_hotel_room_head}>
                                                                <div className={this.props.styles.mob_hotel_room_title}>
                                                                    Room {index + 1}
                                                                </div>
                                                                {index > 0 ? (
                                                                    <div className={this.props.styles.mob_hotel_room_remove} onClick={() => this.removeRoomItem(element.id)}>Remove</div>
                                                                ) : null
                                                                }
                                                            </div>
                                                            <div className={this.props.styles.mob_hotel_accomodation_main}>
                                                                <div className={this.props.styles.mob_hotel_adult_accomodation}>
                                                                    <div className="mob_hotel_adult_accomodation_tag">
                                                                        <p>Adult</p>
                                                                        <span>(12 yrs & above)</span>
                                                                    </div>
                                                                    <div className={this.props.styles.mob_hotel_accomodation_conf}>
                                                                        <span className={element.adultMinusCountDisable ? this.props.styles.disbale_traveller : ''} onClick={() => this.minusAccomodationCount(element.id, index, "adult")}>
                                                                            <img src="/images/minusimage.png" className={this.props.styles.mob_hotel_accomodation_icon} alt=""/>
                                                                        </span>
                                                                        <div className={this.props.styles.ac_count}>{element.adults}</div>
                                                                        <span className={element.adultAddCountDisable ? this.props.styles.disbale_traveller : ''} onClick={() => this.addAccomodationCount(element.id, index, "adult")}>
                                                                            <img src="/images/plus.png" className={this.props.styles.mob_hotel_accomodation_icon} alt=""/>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="mob_hotel_children_accomodation">
                                                                    <div className="mob_hotel_children_accomodation_tag">
                                                                        <p>Children</p>
                                                                        <span>(0 - 12 yrs)</span>
                                                                    </div>
                                                                    <div className={this.props.styles.mob_hotel_accomodation_conf}>
                                                                        <span className={element.childrenMinusCountDisable ? this.props.styles.disbale_traveller : ''} onClick={() => this.minusAccomodationCount(element.id, index, "children")}>
                                                                            <img src="/images/minusimage.png" className={this.props.styles.mob_hotel_accomodation_icon} alt=""/>
                                                                        </span>
                                                                        <div className={this.props.styles.ac_count}>{element.children}</div>
                                                                        <span className={element.childrenAddCountDisable ? this.props.styles.disbale_traveller : ''} onClick={() => this.addAccomodationCount(element.id, index, "children")}>
                                                                            <img src="/images/plus.png" className={this.props.styles.mob_hotel_accomodation_icon} alt=""/>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {element.childDetails.length > 0 && element.childDetails.map((item, ind) => {
                                                                return (
                                                                    <div className={this.props.styles.child_row_container} key={ind}>
                                                                        <div className={this.props.styles.child_row_main}>
                                                                            <label>Age of Child {ind + 1}</label>
                                                                            <input type="text" className={this.props.styles.selected_age} placeholder="Select Age" value={item.age}
                                                                                onClick={() => this.isChildrenAge(index, ind, 'isOpen')} readOnly />
                                                                            {item.isError === true ? (
                                                                                <span className={this.props.styles.err_message_text}>Please enter valid age</span>
                                                                            ) : null}
                                                                            {item.isOpen === true ? (
                                                                                <div className={this.props.styles.age_list_cont} >
                                                                                    <div className={this.props.styles.age_list_main}>
                                                                                        {childAgeList.map((ele, i) => {
                                                                                            return (
                                                                                                <div key={i} className={this.props.styles.age_list_ele} onClick={() => this.isChildrenAge(index, ind, 'clickAge', ele)}>
                                                                                                    <span>{ele}</span>
                                                                                                </div>)
                                                                                        })}
                                                                                    </div>
                                                                                </div>) : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    )
                                                })}
                                                {this.state.roomList.length < 4 ? (
                                                    <div className={this.props.styles.mob_hotel_add_rooms} onClick={() => this.setState({
                                                        roomList: [...this.state.roomList, {
                                                            id: this.state.roomList.length + 1, adults: 1, children: 0,
                                                            adultAddCountDisable: false, adultMinusCountDisable: true, childrenAddCountDisable: false, childrenMinusCountDisable: true, childDetails: []
                                                        }]
                                                    })}>
                                                        <span>+</span>
                                                        <p>ADD ROOM</p>
                                                    </div>
                                                ) : null
                                                }
                                                <div className={this.props.styles.done_button_main}>                                                   <button className={this.props.styles.done_button} onClick={() => this.onClickDoneButton()}>Done</button>                                           </div>
                                            </div>
                                        </div>
                                    ) : null
                                    }
                                </div>
                            </div>
                            <div className={this.props.styles.mob_hotel_search_details_row}>
                                <div className={this.props.styles.mob_hotel_search_class}>
                                    <button className={this.props.styles.mob_hotel_search_widget_btn} onClick={() => this.onClickSearchHotel()}>{this.props.isSRP ? 'Modify' : 'Search'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}