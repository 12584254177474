
// export  function myGreetingWhyzenith() {
//   var swiper = new Swiper('.why_holidayCont', {
//     autoplay: {
//       slidesPerView: 4,
//       spaceBetween: 30,
//       disableOnInteraction: false,
//     },
//     pagination: {
//         el: ".swiper-pagination",
//         clickable: true
//       },
//     breakpoints: {
//       640: {
//         slidesPerView: 3,
//         spaceBetween: 0,
//       },
//       1024: {
//         slidesPerView: 4,
//         spaceBetween: 30,
//       },
//     },
//   }
// )
// }

export  function myGreetingMySwiper() {
    // console.log(">>>>>>>>>>>>>>",Swiper)
    var swiper = new Swiper('.mySwiper', {
      // slidesPerView: 4,
      // spaceBetween: 30,
      // slidesPerGroup: 4,
      // loop: true,
      // autoplay: {
      //     delay: 2000,
      //     disableOnInteraction: false,
      // },
      autoplay: {
        slidesPerView: 4,
        spaceBetween: 30,
        disableOnInteraction: false,
      },
      // pagination: {
      //     el: ".pagination",
      //     dynamicBullets: false,
      //     clickable: true
      //   },
      breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      },
    })
  }

 



export function myGreetingMySwiper2() {
    // console.log(">>>>>>>>>>>>>>",Swiper)
    var swiper = new Swiper('.mySwiper2', {
      slidesPerView: 1,
      spaceBetween: 0,
      slidesPerGroup: 1,
      loop: true,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
    })
  }

  export  function offerSwiper() {
    var swiper = new Swiper('.why_holidayCont', {
      loop: true,
      pagination: {
        el: '.swiper-pagination', 
        clickable: true, 
    },
      autoplay: {
        slidesPerView: 4,
        spaceBetween: 30,
        delay: 5000,
        disableOnInteraction: false,
      },
      speed: 600,
      breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      },
    })
  }


export function myGreetingElevatenew() {
    // console.log(">>>>>>>>>>>>>>",Swiper)
    var swiper = new Swiper('.elevate-new', {
      // slidesPerView: 3,
      // spaceBetween: 30,
      // slidesPerGroup: 3,
      // loop: true,
      // autoplay: {
      //     delay: 2000,
      //     disableOnInteraction: false,
      // },
      autoplay: {
        slidesPerView: 3,
        spaceBetween: 30,
        disableOnInteraction: false,
      },
      breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
    })
  }

// theme2 courosal js start//

export function myGreetingCruiseHolidays() {
    // console.log(">>>>>>>>>>>>>>",Swiper)
    var swiper = new Swiper('.CruiseHolidays', {
      autoplay: {
        slidesPerView: 4,
        spaceBetween: 10,
        disableOnInteraction: false,
      },
      breakpoints: {
        360: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 10,
        },
      },
    })
  }



export function myGreetingChild_featureSwiper() {
    // console.log(">>>>>>>>>>>>>>",Swiper)
    var swiper = new Swiper('.child_featureSwiper', {
      autoplay: {
        slidesPerView: 4,
        spaceBetween: 10,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
      },
      breakpoints: {
        360: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 10,
        },
      },
    })
  }


export function myGreetingTestimonial_data() {
    // console.log(">>>>>>>>>>>>>>",Swiper)
    var swiper = new Swiper('.testimonial_data', {
      slidesPerView: 1,
      spaceBetween: 0,
      slidesPerGroup: 1,
      loop: true,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
    })
  }


export function myGreetingMyclientSwiper() {
    // console.log(">>>>>>>>>>>>>>",Swiper)
    var swiper = new Swiper('.myclientSwiper', {
      autoplay: {
        slidesPerView: 6,
        spaceBetween: 10,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-pagination',
      },
      breakpoints: {
        360: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 6,
          spaceBetween: 10,
        },
      },
    })
  }

// Theme5 slider

export function myGreetingOffermain() {
    // console.log(">>>>>>>>>>>>>>",Swiper)
    var swiper = new Swiper('.offermain', {
      autoplay: {
        // delay: 3000,
        // slidesPerView: 4,
        // spaceBetween: 20,
        disableOnInteraction: false,
      },
      breakpoints: {
        360: {
          slidesPerView: 1.3,
          spaceBetween: 10,
        },
        640: {
          slidesPerView: 1.3,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 10,
        },
      },
    })
  }


export function myGreetingNewlyadded() {
    // console.log(">>>>>>>>>>>>>>",Swiper)
    var swiper = new Swiper('.newlyadded', {
      autoplay: {
        // slidesPerView: 5,
        // spaceBetween: 10,
        disableOnInteraction: false,
      },
      breakpoints: {
        360: {
          slidesPerView: 1.4,
          spaceBetween: 10,
        },
        640: {
          slidesPerView: 1.4,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 10,
        },
      },
    })
  }


export function myGreetingMostbooked() {
    // console.log(">>>>>>>>>>>>>>",Swiper)
    var swiper = new Swiper('.mostbooked', {
      autoplay: {
        // slidesPerView: 5,
        // spaceBetween: 10,
        disableOnInteraction: false,
      },
      breakpoints: {
        360: {
          slidesPerView: 1.6,
          spaceBetween: 10,
        },
        640: {
          slidesPerView: 1.6,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 10,
        },
      },
    })
  }


export function myGreetingHoneymoonpkg() {
    // console.log(">>>>>>>>>>>>>>",Swiper)
    var swiper = new Swiper('.honeymoonpkg', {
      autoplay: {
        // slidesPerView: 7,
        // spaceBetween: 10,
        disableOnInteraction: false,
      },
      breakpoints: {
        360: {
          slidesPerView: 3.7,
          spaceBetween: 10,
        },
        640: {
          slidesPerView: 3.7,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 7,
          spaceBetween: 10,
        },
      },
    })
  }


// Explore holiday mobile

export function myGreetingExploremain() {
    // console.log(">>>>>>>>>>>>>>",Swiper)
    var swiper = new Swiper('.exploremain', {
      // slidesPerView: 4,
      // spaceBetween: 10,
      autoplay: {
        disableOnInteraction: false,
      },
      breakpoints: {
        360: {
          slidesPerView: 1.3,
          spaceBetween: 10,
        },
        640: {
          slidesPerView: 1.3,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 10,
        },
      },
    })
  }


//budget package

export function myGreetingBudgetSwiper() {
    // console.log(">>>>>>>>>>>>>>",Swiper)
    var swiper = new Swiper('.budgetSwiper', {
      slidesPerView: 3,
      spaceBetween: 20,
      // slidesPerGroup: 4,
      // loop: true,
      // autoplay: {
      //     delay: 2000,
      //     disableOnInteraction: false,
      // },
      autoplay: {
        // slidesPerView: 4,
        // spaceBetween: 30,
        disableOnInteraction: false,
      },
      breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      },
    })
  }
  

export function copyCodeClipBoard(id) {
  // Get the text field
  var copyText = document.getElementById(id).innerHTML
  console.log(copyText)
  // Select the text field
  // For mobile devices
  // Copy the text inside the text field
  navigator.clipboard.writeText(copyText)
  // Alert the copied text
  // alert("Copied the text: " + copyText);
}
