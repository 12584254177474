import React, { useEffect, useState } from 'react';
import commonFunction from '../../../../lib/utils/common';

export default function PaxConfig(props) {
    const { theme } = props;
    return (
        <div className={props.styles.mob_flight_search_wid_inputfield}>
            <div className={props.value === props.minLimit ? props.styles.disable_pax_btn : ""} onClick={() => {
                if (!commonFunction.isValueEmpty(props.value)) {
                    if (props.value > props.minLimit) {
                        props.onChangePaxValue(props.value - 1, "minus");
                    }
                }
            }}>
                <div className={props.styles.mob_flight_search_flex_adjust}>
                    {/* <img src="/images/Minus.svg" className={props.styles.minus_pax} /> */}
                    <svg
                        version="1.1"
                        id="Layer_1"
                        x="0px"
                        y="0px"
                        className={props.styles.minus_pax}
                        width="45px"
                        height="45px"
                        viewBox="0 0 45 45"
                        enable-background="new 0 0 45 45"
                    >
                        <defs>
    <clipPath id="clip-path">
      <rect width="20" height="49" fill="none"/>
    </clipPath>
  </defs>
  <g id="Group_1398" data-name="Group 1398" transform="translate(-242.648 -575.141)">
    <ellipse id="Ellipse_37" data-name="Ellipse 37" cx="20.534" cy="19.678" rx="20.534" ry="19.678" transform="translate(242.648 578.978)" fill="#3479c5"/>
    <g id="Repeat_Grid_1" data-name="Repeat Grid 1" transform="translate(253.858 575.141)" clip-path="url(#clip-path)">
      <g transform="translate(-253.858 -575.141)">
        <text id="_-" data-name="-" transform="translate(253.858 612.141)" fill="#fff" font-size="35" font-family="Poppins-Regular, Poppins" letter-spacing="0.021em"><tspan x="0" y="0">-</tspan></text>
      </g>
    </g>
  </g>
                    </svg>
                </div>
            </div>
            <span>{props.value}</span>
            <div className={props.totalPax === props.maxLimit ? props.styles.disable_pax_btn : ""} onClick={() => {
                // console.log("theme333333", props.value);
                if (!commonFunction.isValueEmpty(props.value)) {
                    if (
                        props.totalPax >= props.minLimit &&
                        props.totalPax <= props.maxLimit
                    ) {
                        props.onChangePaxValue(props.value + 1, "add");
                    }
                }
            }}>
                {/* <img src="/images/Add.svg" className={props.styles.add_pax} /> */}
                <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          className={props.styles.add_pax}
          width="45px"
          height="45px"
          viewBox="0 0 45 45"
          enable-background="new 0 0 45 45"
        >
          <g id="Group_1399" data-name="Group 1399" transform="translate(-321.361 -574.156)">
    <ellipse id="Ellipse_39" data-name="Ellipse 39" cx="20.534" cy="19.678" rx="20.534" ry="19.678" transform="translate(321.361 578.978)" fill="#3479c5"/>
    <text id="_" data-name="+" transform="translate(330.395 612.156)" fill="#fff" font-size="36" font-family="Poppins-Regular, Poppins" letter-spacing="0.021em"><tspan x="0" y="0">+</tspan></text>
  </g>
        </svg>
            </div>
        </div>
    )
}