import 'react-multi-carousel/lib/styles.css'
import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  SearchActivityHomeWidget,
  SearchHotelHomeWidget,
  autoSuggestHomeWidget,
} from '@/lib/searchHomeWidget'
import AuthContext from 'store/auth-context'
import Carousel from 'react-multi-carousel'
import Countrystyles from '../../../../../common/components/CountryDropdown/theme1/index.module.css'
import CustomLink from '@/components/Link'
import DesktopCalenderThemeOneCss from '../../../../../common/components/widgets/flightSearchWidget/theme3/index.module.css'
import DesktopHotelStylesTheme1 from '../../../../../common/components/widgets/hotelSearchWidget/theme3/index.module.css'
import FlightSearchThemeOneCSS from '../../../../../common/components/widgets/flightSearchWidget/theme3/index.module.css'
import FlightSearchWidget from '../../../../../common/components/widgets/flightSearchWidget'
import HotelSearchWidget from '../../../../../common/components/widgets/hotelSearchWidget'
import Image from 'next/image'
import b2bWidgetStyle from '../../../../../common/components/b2bwidgets/theme1/index.module.css'
import cf from '@/lib/utils/common'
import configUrl from '../../../../../config/config.json'
import parse from 'html-react-parser'
import styles from './index.module.css'

const availableSearchTypes = [
  'flight_search',
  'hotel_search',
  'activity_search',
  'experience_search',
  'holiday_search',
  'others_search',
]

export default function Theme3Flow(props) {
  const [initFlightData, setInitFlightData] = useState([])
  const [getDepartureSuggestionList, setDepartureSuggestionList] = useState([])
  const [getGoingToSuggestionList, setGoingToSuggestionList] = useState([])
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const wrapperRef = useRef(null)
  const [expandHotelNameBoolean, setExpandHotelName] = useState(false)
  const [expandActivityName, setExpandActivityName] = useState(false)
  const [getHotelAutoSuggestList, setHotelAutoSuggestList] = useState([])
  const [getActivityAutoSuggestList, setActivityAutoSuggestList] = useState([])
  const [initHotelData, setInitHotelData] = useState([])
  const [homeBodyDesktop, setHomeBodyDesktop] = useState([])
  const [homeBodyMobile, setHomeBodyMobile] = useState([])
  const [hotelDefultDays, setHotelDefultDays] = useState(0)
  const [userProfile, setUserProfile] = useState('')
  const [indexOfWidgetToshow, setIndexOfWidget] = useState(1)
  const [widClicked, setWidClicked] = useState(false)
  const ctx = useContext(AuthContext)
  const flightWidRef = useRef(null)
  const hotelWidRef = useRef(null)

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      // slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  }

  useEffect(() => {
    let userToken = cf.getCookieTocken('userToken')
    cf.getUserProfile(userToken, props.site_id).then((res) => {
      if (res.status === true) {
        setUserProfile(ctx?.user)
      } else {
        setUserProfile(res)
      }
    })

    if (props?.home?.data?.data?.body?.desktop) {
      setHomeBodyDesktop(props.home.data.data.body.desktop)

      /**FIND FIRST AVILLABLE WIDGET**/
      let indexOfFirstWidghts = props.home.data.data.body.desktop?.findIndex((x) =>
        availableSearchTypes.includes(x.type)
      )
      setIndexOfWidget(indexOfFirstWidghts)

      let hotelIndex = props.home.data.data.body.desktop.findIndex(
        (items) => items.type === 'hotel_search'
      )
      if (hotelIndex > -1) {
        setHotelDefultDays(props.home.data.data.body.desktop[hotelIndex].default_days)
      }
    }
    if (props?.home?.data?.data?.body?.mobile) setHomeBodyMobile(props.home.data.data.body.mobile)
    const style = document.createElement('style')
    if (
      !cf.isValueEmpty(props) &&
      !cf.isValueEmpty(props.home) &&
      !cf.isValueEmpty(props.home.data)
    ) {
      style.innerHTML = props.home.data.data.css
      document.head.appendChild(style)
    }
    setActiveItemIndex(activeItemIndex)
    if (!cf.isValueEmpty(props) && !cf.isValueEmpty(props.home) && props.home.status === true) {
      if (
        !cf.isValueEmpty(props.home.data.data) &&
        !cf.isValueEmpty(props.home.data.data.body) &&
        !cf.isValueEmpty(props.home.data.data.body.desktop) &&
        props.home.data.data.body.desktop.length > 0
      ) {
        let index = props.home.data.data.body.desktop.findIndex(
          (items) => items.type === 'flight_search'
        )
        let hotelIndex = props.home.data.data.body.desktop.findIndex(
          (items) => items.type === 'hotel_search'
        )
        if (index !== -1) {
          if (
            !cf.isValueEmpty(props.home.data.data.body.desktop[index].airports) &&
            props.home.data.data.body.desktop[index].airports.length > 0
          ) {
            // let sortedDefaultAirports = props?.home?.data?.data?.body?.desktop &&
            let sortedDefaultAirports = []
            if (
              props?.home?.data?.data?.body?.desktop &&
              props?.home?.data?.data?.body?.desktop[index] &&
              props?.home?.data?.data?.body?.desktop[index].airports &&
              props?.home?.data?.data?.body?.desktop[index].airports.length
            ) {
              sortedDefaultAirports = props?.home?.data?.data?.body?.desktop[index].airports.sort(
                (a, b) => {
                  return a.ORDER - b.ORDER
                }
              )
            }
            setInitFlightData([...sortedDefaultAirports])
            setDepartureSuggestionList([...sortedDefaultAirports])
            setGoingToSuggestionList([...sortedDefaultAirports])
          }
        }
        if (hotelIndex !== -1) {
          if (
            !cf.isValueEmpty(props.home.data.data.body.desktop[hotelIndex].locations) &&
            props.home.data.data.body.desktop[hotelIndex].locations.length > 0
          ) {
            setInitHotelData([...props.home.data.data.body.desktop[hotelIndex].locations])
            setHotelAutoSuggestList([...props.home.data.data.body.desktop[hotelIndex].locations])
          }
        }
      }
    }
    // showMore()
    // <script>
    //    {showMore()}
    // </script>
    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [])

  const getAutoSuggestDataFunc = (value, section, type, event) => {
    switch (section) {
      case 'flight':
        if (event === 'click') {
          if (value.length === 0) {
            if (type === 'departure') {
              setDepartureSuggestionList([...initFlightData])
            } else {
              setGoingToSuggestionList([...initFlightData])
            }
          }
        } else {
          autoSuggestHomeWidget({ autoSuggest: value }).then((res) => {
            if (!cf.isValueEmpty(res) && res.status === true && !cf.isValueEmpty(res.data)) {
              if (type === 'departure') {
                setDepartureSuggestionList([...res.data])
              } else {
                setGoingToSuggestionList([...res.data])
              }
            }
          })
        }
        break
      case 'hotel':
        if (value.length > 0) {
          SearchHotelHomeWidget({ autoSuggest: value }).then((res) => {
            if (!cf.isValueEmpty(res) && res.status === true && !cf.isValueEmpty(res.data)) {
              if (res.data.status === 'success' && !cf.isValueEmpty(res.data.result)) {
                setHotelAutoSuggestList([...res.data.result.records])
                setExpandHotelName(true)
              }
            }
          })
        } else {
          setHotelAutoSuggestList([...initHotelData])
          setExpandHotelName(true)
        }
        break
      case 'activity':
        SearchActivityHomeWidget({ autoSuggest: value }).then((res) => {
          if (!cf.isValueEmpty(res) && !cf.isValueEmpty(res.data) && res.data.status === true) {
            if (
              !cf.isValueEmpty(res.data.searchBycity) &&
              res.data.searchBycity !== 'Not Found' &&
              res.data.searchBycity.status === 'success'
            ) {
              if (
                !cf.isValueEmpty(res.data.searchBycity.result) &&
                res.data.searchBycity.result.length > 0
              ) {
                getActivityAutoSuggestList.cityList = [...res.data.searchBycity.result]
                //     setActivityAutoSuggestList({ ...getActivityAutoSuggestList })
                setActivityAutoSuggestList([...res.data.searchBycity.result])
                if (expandActivityName === false) {
                  setExpandActivityName(true)
                }
              }
              if (
                !cf.isValueEmpty(res.data.searchBycountry) &&
                res.data.searchBycountry !== 'Not Found' &&
                res.data.searchBycountry.status === 'success'
              ) {
                if (
                  !cf.isValueEmpty(res.data.searchBycountry.result) &&
                  res.data.searchBycountry.result.length > 0
                ) {
                  getActivityAutoSuggestList.countryList = [...res.data.searchBycountry.result]
                  //      setActivityAutoSuggestList({ ...getActivityAutoSuggestList })
                  setActivityAutoSuggestList([
                    ...getActivityAutoSuggestList.cityList,
                    ...getActivityAutoSuggestList.countryList,
                  ])

                  if (expandActivityName === false) {
                    setExpandActivityName(true)
                  }
                }
              }
            }
          }
        })
        break
      default:
        break
    }
  }

  const handleClickOutside = (event) => {
    console.log('eventevent', event)
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      if (expandHotelNameBoolean === true) setExpandHotelName(false)
    }
    if (flightWidRef.current && !flightWidRef.current.contains(event.target)) {
      setWidClicked(false)
    }
    if (hotelWidRef.current && !hotelWidRef.current.contains(event.target)) {
      setWidClicked(false)
    }
  }

  const imageLoader = ({ src }) => {
    return `${'/shared/api/media/' + props.site_id + '/' + src}`
  }

  const onClickWidgets = () => {
    setWidClicked(true)
  }

  return (
    <>
      <>
        {/* <TimeoutWrap theme="theme2" type="flight"/> */}
        <div className={styles.home_container}>
          <div className="home-page-offer">
            <div className="main-banner">
              <div>
                {homeBodyDesktop.map((res, i) => {
                  return (
                    <div key={'homeBodyDsktp' + i}>
                      {/* {!cf.isValueEmpty(res.type) && res.type == 'image_banner' && (
                                 <div className="img_banner_home">
                                   <Image loader={imageLoader} src={res.banners} layout="fill" width={500} />
                                 </div>
                               )} */}
                      {indexOfWidgetToshow == i &&
                        !cf.isValueEmpty(res.type) &&
                        res.type == 'flight_search' && (
                          <div
                            onClick={onClickWidgets}
                            className={widClicked ? styles.parentWid : ''}
                          >
                            <div
                              ref={flightWidRef}
                              onClick={onClickWidgets}
                              className={widClicked ? styles.childWid : ''}
                            >
                              {!props.isMobile && (
                                <FlightSearchWidget
                                  pageTheme={props?.home?.data?.site?.site_theme}
                                  isSRP={false}
                                  isOffline={false}
                                  homeData={!cf.isValueEmpty(initFlightData) ? initFlightData : []}
                                  styles={FlightSearchThemeOneCSS}
                                  b2bStyles={b2bWidgetStyle}
                                  calendarStyles={DesktopCalenderThemeOneCss}
                                  getAutoSuggestData={(value, type, event) =>
                                    getAutoSuggestDataFunc(value, 'flight', type, event)
                                  }
                                  getGoingToSuggestionList={getGoingToSuggestionList}
                                  getDepartureSuggestionList={getDepartureSuggestionList}
                                  dateFormat={props?.home?.data?.site?.date_format}
                                  userProfile={userProfile}
                                />
                              )}
                            </div>{' '}
                            {/* Flight Desktop */}
                          </div>
                        )}

                      {indexOfWidgetToshow == i &&
                        !cf.isValueEmpty(res.type) &&
                        res.type == 'hotel_search' && (
                          <div
                            onClick={onClickWidgets}
                            className={widClicked ? styles.parentWid : ''}
                          >
                            <div
                              ref={flightWidRef}
                              onClick={onClickWidgets}
                              className={widClicked ? styles.childWid : ''}
                            >
                              <HotelSearchWidget
                                pageTheme={props?.home?.data?.site?.site_theme}
                                isSRP={false}
                                isOffline={false}
                                styles={DesktopHotelStylesTheme1}
                                b2bStyles={b2bWidgetStyle}
                                getAutoSuggestValue={(value) =>
                                  getAutoSuggestDataFunc(value, 'hotel')
                                }
                                expandHotelNameBoolean={expandHotelNameBoolean}
                                getDefaultDays={hotelDefultDays}
                                getAutoSuggestList={getHotelAutoSuggestList}
                                expandHotelNameBooleanfunc={(value) => setExpandHotelName(value)}
                                homeData={!cf.isValueEmpty(initHotelData) ? initHotelData : []}
                                dateFormat={props?.home?.data?.site?.date_format}
                                userProfile={userProfile}
                                Countrystyles={Countrystyles}
                              />
                            </div>
                          </div>
                        )}
                      {/* {!cf.isValueEmpty(res.type) && res.type == 'activity_search' && (
                                 <SearchWidgetActivity
                                   {...props}
                                   cssfile={cssfile}
                                   pageTheme={props?.home?.data?.site?.site_theme}
                                   calendarStyles={DesktopCalenderThemeOneCss}
                                   dateFormat={props?.home?.data?.site?.date_format}
                                   b2bStyles={b2bWidgetStyle}
                                 />
                               )}
                               {!cf.isValueEmpty(res.type) && res.type == 'experience_search' && (
                                 <SearchWidgetExperience
                                   {...props}
                                   cssfile={cssfile}
                                   pageTheme={props?.home?.data?.site?.site_theme}
                                   calendarStyles={DesktopCalenderThemeOneCss}
                                   dateFormat={props?.home?.data?.site?.date_format}
                                   b2bStyles={b2bWidgetStyle}
                                   userProfile={userProfile}
                                 />
                               )} */}

                      <div>
                        {!cf.isValueEmpty(res.html)
                          ? parse(res.html)
                          : // <Interweave content={res.html} />
                            ''}
                      </div>
                      {!cf.isValueEmpty(res.type) && res.type == 'image_carousal' && (
                        <div>
                          <Carousel
                            swipeable={false}
                            draggable={false}
                            showDots={true}
                            responsive={responsive}
                            ssr={false} // means to render carousel on server-side.
                            infinite={true}
                            autoPlay={props.isMobile ? true : true}
                            autoPlaySpeed={3000}
                            keyBoardControl={true}
                            customTransition="all .5"
                            transitionDuration={500}
                            containerClass="carousel-container"
                            // deviceType={this.props.deviceType}
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px"
                          >
                            {!cf.isValueEmpty(res) &&
                              !cf.isValueEmpty(res.banners) &&
                              res.banners.length > 0 &&
                              res.banners.map((imagedata, index) => {
                                return (
                                  // <div>index====={index}</div>
                                  // <img style={{ height: 300, background: '#EEE' }} src={`${ '/shared/api/media/'+props.site_id+'/' + imagedata.image}`} />
                                  <div key={'banner' + index} className="img_banner_home_3">
                                    <CustomLink href={imagedata.banner_url}>
                                      <Image
                                        loader={imageLoader}
                                        src={imagedata.image}
                                        width={500}
                                        layout="fill"
                                      />
                                    </CustomLink>
                                    {/* <img src={`${ '/shared/api/media/' + props.site_id + '/' + imagedata.image}`} /> */}
                                  </div>
                                  // <div className="testimonial_carousel">
                                  //    <div className='container mx-auto xl:px-8 flex items-center justify-center'>
                                  //       <div className="testimonial_left">
                                  //          <img src={`${configUrl.BASE_URL + '/shared/api/media/' + props.site_id + '/' + imagedata.image}`} />
                                  //       </div>
                                  //       <div className="testimonial_right">
                                  //          <div className="testimonial_header">Test</div>
                                  //          <div className="testimonial_text">
                                  //             Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                  //             Minima reiciendis, aliquid ipsam doloribus dignissimos
                                  //             ipsa. Quidem, architecto laudantium! Aut quas dolor nam
                                  //             eaque ullam vero voluptatibus non, molestiae quae beatae.
                                  //          </div>
                                  //          <div className='testimonial_name'>Test User</div>
                                  //          <div class="testimonial_star">
                                  //             <img src="https://uat.elevatetrips.com/image/star-rating-4.0.svg" alt="" />
                                  //          </div>
                                  //       </div>
                                  //    </div>
                                  // </div>
                                )
                              })}
                          </Carousel>
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
            {/* widgets components starts */}
            {/* cards & other data starts */}
            {/* <Theme3Cards 
                props={props}
                /> */}
          </div>
        </div>
      </>
    </>
  )
}
