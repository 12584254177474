import React, { Component } from 'react';

class PickupTime extends Component {
  constructor(props) {
    super(props);
    const [hourMinute, period] = (props.time || '01:00 AM').split(' ');
    const [hour, minute] = hourMinute.split(':');
    this.state = {
      time: props.time || '01:00 AM',
      show: false,
      tempTime: props.time || '01:00 AM',
      selectedHour: hour,
      selectedMinute: minute,
      selectedPeriod: period
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ time: this.props.time, tempTime: this.props.time })
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      const [hourMinute, period] = (this.state.time || '01:00 AM').split(' ');
      const [hour, minute] = hourMinute.split(':');
      this.setState({
        show: false,
        tempTime: this.state.time,
        selectedHour: hour,
        selectedMinute: minute,
        selectedPeriod: period
      });
    }
  };

  handleTimeChange = (part, value) => {
    const [hour, minutePeriod] = this.state.tempTime.split(':');
    const [minute, period] = minutePeriod.split(' ');

    let newTime;
    if (part === 'hour') {
      newTime = `${value}:${minute} ${period}`;
      this.setState({ selectedHour: value });
    } else if (part === 'minute') {
      newTime = `${hour}:${value} ${period}`;
      this.setState({ selectedMinute: value });
    } else if (part === 'period') {
      newTime = `${hour}:${minute} ${value}`;
      this.setState({ selectedPeriod: value });
    }

    this.setState({
      tempTime: newTime,
    });
  };

  handleClick = () => {
    this.setState((prevState) => ({
      show: !prevState.show,
      tempTime: prevState.time,  // Copy current time to tempTime when toggling
    }));
  };

  onApply = () => {
    const { type } = this.props;
    this.setState({
      time: this.state.tempTime,  // Apply changes to the actual time
      show: false,
    }, () => {
      this.props.apply(type, this.state.time);
    });
  };

  render() {
    const hours = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'));
    const minutes = Array.from({ length: 12 }, (_, i) => (i * 5).toString().padStart(2, '0'));
    const periods = ['AM', 'PM'];

    // Extract hour, minute, and period for display
    const [hour, minutePeriod] = this.state.time.split(':');
    const [minute, period] = minutePeriod.split(' ');

    return (
      <div ref={this.wrapperRef} className={this.props.styles.pickup_time}>
        <div onClick={this.handleClick} className={this.props.styles.pickup_time_wrap}>
          <div className={this.props.styles.pickup_time__display}>
            <h3 className={this.props.styles.pickup_time__title}>
              <span className={this.props.styles.pickup_time__label}>{this.props.label}</span>
              <span className={this.props.styles.pickup_time__value}>
                {/* {hour}:{minute} {period} */}
                {this.props.time}
                {/* {this.state.tempTime} */}
              </span>
            </h3>
          </div>
          <span className="">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12" fill="none">
              <path
                d="M17.1827 1.27698C16.6377 0.727984 15.7538 0.727984 15.2078 1.27698L8.79475 7.74198L2.38375 1.27798C1.83875 0.727985 0.95475 0.727985 0.40875 1.27798C-0.13625 1.82798 -0.13625 2.71898 0.40875 3.26898L7.80375 10.722C8.21275 11.135 8.81375 11.239 9.31775 11.032C9.48975 10.963 9.65075 10.86 9.78975 10.72L17.1838 3.26698C17.7288 2.71698 17.7287 1.82698 17.1827 1.27698Z"
                fill="#266AAC"
              />
            </svg>
          </span>
        </div>

        {this.state.show && (
          <div className={this.props.styles.pickup_time__selection}>
            <div className={this.props.styles.pickup_time__preview}>
              <span className={this.props.styles.pickup_time__temp_value}>
                {this.state.tempTime}
              </span>
              <button className={this.props.styles.pickup_time__apply_button} onClick={this.onApply}>
                Apply
              </button>
            </div>

            <div className={this.props.styles.pickup_time__preview_wrap}>
              <div className={`${this.props.styles.pickup_time__options} ${this.props.styles.pickup_time__options__hours}`}>
                {hours.map((hourOption) => (
                  <div
                    key={hourOption}
                    onClick={() => this.handleTimeChange('hour', hourOption)}
                    className={`${this.props.styles.pickup_time__option} ${this.props.styles.pickup_time__option__hour} ${this.state.selectedHour === hourOption ? this.props.styles.active : ''}`}
                  >
                    {hourOption} {' hrs'}
                  </div>
                ))}
              </div>

              <div className={`${this.props.styles.pickup_time__options} ${this.props.styles.pickup_time__options__minutes}`}>
                {minutes.map((minuteOption) => (
                  <div
                    key={minuteOption}
                    onClick={() => this.handleTimeChange('minute', minuteOption)}
                    className={`${this.props.styles.pickup_time__option} ${this.props.styles.pickup_time__option__minute} ${this.state.selectedMinute === minuteOption ? this.props.styles.active : ''}`}
                  >
                    {minuteOption} {' mins'}
                  </div>
                ))}
              </div>

              <div className={`${this.props.styles.pickup_time__options} ${this.props.styles.pickup_time__options__periods}`}>
                {periods.map((periodOption) => (
                  <div
                    key={periodOption}
                    onClick={() => this.handleTimeChange('period', periodOption)}
                    className={`${this.props.styles.pickup_time__option} ${this.props.styles.pickup_time__option__period} ${this.state.selectedPeriod === periodOption ? this.props.styles.active : ''}`}
                  >
                    {periodOption}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default PickupTime;
