
import Theme5 from "./theme5";
import Theme4 from "./theme4";


export default function SearchWidget(props) {
    const theme = props.pageTheme;
    switch (theme) {
        case 'theme5':
            return <Theme5 {...props} />
        case 'theme4':
            return <Theme4 {...props} />         
        default:
            return <Theme5  {...props}/>
    }
}