import React, { useEffect, useState, useRef, Component } from 'react';
import moment from 'moment';
import {
    format, startOfMonth, endOfMonth, differenceInDays, addDays, getMonth, getYear,
    endOfToday, differenceInCalendarDays, subDays, isMonday, isTuesday, isWednesday, isThursday,
    isFriday, isSaturday, isSunday, isBefore, startOfToday, isAfter, isSameDay
} from 'date-fns';
import commonFunction from '../../../../lib/utils/common';
// import styles from "./calendar.module.css";

const MonthList = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const weekList = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export default class MobileCalender extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateFormat: !commonFunction.isValueEmpty(this.props.dateFormat) ? this.props.dateFormat : "dd/MM/yyyy",
            isCalendarVisible: false,
            numberOfClicks: 0,
            twoCalendarsArray: [],
            isDoubleSelection: !commonFunction.isValueEmpty(this.props.doubleSelection) ? this.props.doubleSelection : false,
            dateLimitArr: this.props.startDateLimit,
            currentMonths: [getMonth(startOfMonth(new Date()))],
            selectedMonthStartDate: startOfMonth(startOfToday()),
            selectedMonthEndDate: endOfMonth(startOfMonth(new Date())),
            isActive: false,
            // startDate: this.props.startDate,
            endDate: this.props.endDate,
            hoverEndDate: this.props.endDate,
            totalDays: "",
            pageX: "",
            pageY: "",
            initPageX: "",
            initPageY: "",
            totalMonths: !commonFunction.isValueEmpty(this.props.totalMonths) ? this.props.totalMonths : 12,
            serviceType: this.props.serviceType ? this.props.serviceType : ""
        };
    }
    componentDidMount() {
        console.log("propsnm,fvdfgdhrb", this.props)
        let { getYear: year, getMonth: month, getDay: day } = this.getSpecificDateElement(this.state.dateLimitArr, "a", "didmount datelimit")

        console.log("year, month, day", year, month, day)
        this.setState({
            currentMonths: this.props.type === "double" ? !commonFunction.isValueEmpty(this.state.dateLimitArr) ?
                [getMonth(startOfMonth(new Date(year, month, day))),
                getMonth(startOfMonth(new Date(year, month, day))) + 1] :
                [getMonth(startOfMonth(new Date())), getMonth(startOfMonth(new Date())) + 1]
                : !commonFunction.isValueEmpty(this.state.dateLimitArr) ?
                    [getMonth(startOfMonth(new Date(new Date(year, month, day))))] :
                    [getMonth(startOfMonth(new Date()))],
            selectedMonthStartDate: !commonFunction.isValueEmpty(this.state.dateLimitArr) ? startOfMonth(new Date(year, month, day)) : startOfMonth(startOfToday()),
            selectedMonthEndDate: !commonFunction.isValueEmpty(this.state.dateLimitArr) ? endOfMonth(new Date(year, month, day)) : endOfMonth(startOfMonth(new Date())),

        })


    }
    componentDidUpdate() {
        if (this.props.endDate !== this.state.endDate) {
            this.setState({ endDate: this.props.endDate });
        }

        if (document.getElementById("selectedstartcal")) {
            document.getElementById("selectedstartcal").focus()

        }
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    handleClickOutside = () => {
        if (this.state.isCalendarVisible === true) this.setState({ isCalendarVisible: false });
    };

    componentWillReceiveProps(nextProps) {
        console.log("this.props.isVisible", nextProps.isVisible)
        // this.setState({
        //     isDoubleSelection: nextProps.doubleSelection
        // })
        if (nextProps.startDateLimit) {
            this.setState({
                dateLimitArr: nextProps.startDateLimit,
                startDate: nextProps.startDate
            }, () => {
                let { getYear: year, getMonth: month, getDay: day } = this.getSpecificDateElement(this.state.dateLimitArr, "a", "dateLimit")
                // console.log("🚀 ~ file: DesktopCalenderNew.js:74 ~ DesktopCalender ~ componentWillReceiveProps ~ year, month, day:", year, month, day)

                const { getYear: startDateYear, getMonth: startDateMonth, getDay: startDateDay } = this.getSpecificDateElement(this.state.startDate, "a", "startdate")
                console.log("🚀 ~ file: DesktopCalenderNew.js:75 ~ DesktopCalender ~ componentWillReceiveProps ~ this.state.startDate:", this.state.startDate, startDateYear, startDateMonth, startDateDay)

                if (!this.state.isActive) {
                    this.setState({
                        currentMonths: this.props.type === "double" ?
                            !commonFunction.isValueEmpty(this.state.dateLimitArr) ?
                                [getMonth(startOfMonth(new Date(year, month, day))),
                                getMonth(startOfMonth(new Date(year, month, day))) + 1] :
                                [getMonth(startOfMonth(new Date())), getMonth(startOfMonth(new Date())) + 1] : !commonFunction.isValueEmpty(this.state.dateLimitArr) ?
                                [getMonth(startOfMonth(new Date(this.specDateEle(new Date(year, month, day)))))] :
                                [getMonth(startOfMonth(new Date()))],
                        selectedMonthStartDate: !commonFunction.isValueEmpty(this.state.dateLimitArr) ? startOfMonth(new Date(year, month, day)) : startOfMonth(startOfToday()),
                        selectedMonthEndDate: !commonFunction.isValueEmpty(this.state.dateLimitArr) ? endOfMonth(new Date(year, month, day)) : endOfMonth(startOfMonth(new Date())),

                    }, () => {
                        // console.log("selectedMonthStartDate",this.state.selectedMonthStartDate)
                        // console.log("selectedMonthEndDate",this.state.selectedMonthEndDate)

                    })
                }
            })
        }

    }


    getSpecificDateElement(date, type, temp) {
        try {
            let getDate = commonFunction.isValueEmpty(this.state.dateFormat) ? moment(new Date(date)).format('DD/MMM/yyyy') : moment(date).format(this.state.dateFormat);
            let getYear = typeof date === 'string' ? new Date(getDate).getFullYear() : date.getFullYear();
            let getMonth = typeof date === 'string' ? new Date(getDate).getMonth() : date.getMonth();
            let getDay = typeof date === 'string' ? new Date(getDate).getDate() : date.getDate();
            switch (type) {
                case 'y':
                    return getYear
                case 'm':
                    return getMonth
                case 'd':
                    return getDay
                case 'a':
                    return { getYear, getMonth, getDay };
                default:
            }
        } catch (err) {
            console.log('getSpecificDate error:', err)
        }
    }

    onClickChangeVisibility = (type) => {
        this.setState({ isActive: false })
        // this.props.changeVisibility(!this.props.isVisible)
        this.setState({
            selectingForDate: type
        }, () => {
            this.props.changeVisibility(!this.props.isVisible, type)

        })


    }

    onClickDateCell = (day, month, year, isDisable, journeyType, selectionType) => {
        this.setState({ isActive: true })
        // if (!bool) { //new added
        console.log("journeyType,selectionType", day, month, year, isDisable, journeyType, selectionType)
        if (isDisable === false) {
            if (journeyType === "roundTrip" && selectionType === "depart") {
                let date = new Date(year, month, day);
                if (this.state.numberOfClicks === 0) {
                    this.setState({ numberOfClicks: 1, totalDays: '' }, () => {
                        this.props.onChangeDate(date, 'startDate');
                        // this.props.onChangeDate('', 'endDate');
                    });
                }
                if (this.state.numberOfClicks === 1) {
                    let differenceInDays = differenceInCalendarDays(new Date(date), new Date(this.props.startDate))
                    this.setState({ numberOfClicks: 0 }, () => {
                        if (differenceInDays < 0) {
                            this.props.onChangeDate(date, 'startDate');
                            // this.props.onChangeDate('', 'endDate');
                        } else {
                            this.setState({ numberOfClicks: 0 }, () => {
                                this.props.onChangeDate(date, 'endDate');
                                this.props.changeVisibility(!this.props.isVisible, selectionType)
                            });
                        }
                    });
                }
            } else {
                if (isDisable === false) {
                    let date = moment(new Date(year, month, day)).format(this.state.dateFormat);
                    this.props.onChangeDate(date, selectionType === "return" ? "endDate" : "startDate");
                    this.props.changeVisibility(!this.props.isVisible, selectionType)

                }
            }
        }
        // }
    }
    renderCalendar(journeyType, selectionType) {
        console.log("journeyType", journeyType, "selectionType", selectionType)

        let temp = this.state.selectedMonthStartDate;
        console.log("🚀 ~ file: DesktopCalenderNew.js:213 ~ DesktopCalender ~ renderCalendar ~ StartDateOfMonth:", temp)

        let twoCalendarsArray = [];
        for (let i = 0; i < this.state.totalMonths; i++) {
            let StartDateOfMonth = temp;

            let monthHead = addDays(StartDateOfMonth, i === 0 ? 0 : 1);
            let endDateOfMonth = endOfMonth(monthHead);
            let dayDifference = differenceInCalendarDays(endDateOfMonth, monthHead);
            let days = [];
            let month = getMonth(monthHead)
            let year = getYear(monthHead)
            let startOfMonthDay = isMonday(monthHead) ? 0 : isTuesday(monthHead) ? 1 : isWednesday(monthHead) ? 2 : isThursday(monthHead) ? 3 : isFriday(monthHead) ? 4 : isSaturday(monthHead) ? 5 : 6;
            let startDateLimit = this.props.startDateLimit;
            let isHoliday = this.props.isHoliday ? this.props.isHoliday : false;
            let holidayStartDate = this.props.holidayStartDate ? this.props.holidayStartDate : '';
            let holidayEndDate = this.props.holidayEndDate ? this.props.holidayEndDate : '';
            let startDate = this.props.startDate;
            let endDate = this.state.endDate;
            let hoverDate = this.state.hoverEndDate;
            let { getYear: startDateyear, getMonth: startDatemonth, getDay: startDateday } = this.getSpecificDateElement(startDate, "a", "props startdate")
            let { getYear: endDateyear, getMonth: endDatemonth, getDay: endDateday } = this.getSpecificDateElement(endDate, "a", "props enddate")
            let { getYear: hoverDateyear, getMonth: hoverDatemonth, getDay: hoverDateday } = this.getSpecificDateElement(hoverDate, "a", "hoverdate")

            for (let k = 0; k < startOfMonthDay; k++) {
                days = [...days, { day: "", month: month, year: year, isDisable: true, selectedDate: false, highlight: false }];
            }
            let { getYear: startDateyearLimit, getMonth: startDatemonthLimit, getDay: startDatedayLimit } = this.getSpecificDateElement(startDateLimit, "a", "startdatelimit")

            for (let j = 0; j <= dayDifference; j++) {
                let isBeforeDate = isBefore(new Date(year, month, j + 1), new Date(startDateyearLimit, startDatemonthLimit, startDatedayLimit));
                let disableDate = false;

                if (isHoliday) {
                    let { getYear: holidayStartDateyear, getMonth: holidayStartDatemonth, getDay: holidayStartDateday } = this.getSpecificDateElement(holidayStartDate, "a", "holidayStartDate")
                    let { getYear: holidayEndDateyear, getMonth: holidayEndDatemonth, getDay: holidayEndDateday } = this.getSpecificDateElement(holidayEndDate, "a", "holidayEndDate")

                    let isHLDBeforeDate = isBefore(new Date(year, month, j + 1), new Date(holidayStartDateyear, holidayStartDatemonth, holidayStartDateday));
                    let isHLDAfterDate = isAfter(new Date(year, month, j + 1), new Date(holidayEndDateyear, holidayEndDatemonth, holidayEndDateday));
                    disableDate = isHLDBeforeDate == true || isHLDAfterDate == true ? true : false;
                }
                if (isBeforeDate === true || disableDate == true) {
                    days = [...days, { day: j + 1, month: month, year: year, isDisable: true, selectedDate: false, highlight: false }];
                } else {
                    days = [...days, { day: j + 1, month: month, year: year, isDisable: false, selectedDate: false, highlight: false }];
                }

                //     console.log("day aarti", days , j+1)
                if (journeyType === "roundTrip") {
                    let { getYear: startDateyearLimit, getMonth: startDatemonthLimit, getDay: startDatedayLimit } = this.getSpecificDateElement(startDateLimit, "a", "startdatelimit")

                    let isBeforeDate = isBefore(new Date(year, month, j + 1), new Date(startDateyearLimit, startDatemonthLimit, startDatedayLimit));
                    let isAfterDate = isAfter(new Date(year, month, j + 1), new Date(startDateyear, startDatemonth, startDateday));
                    // console.log('UUUUUU==== ' + new Date(year, month, j + 1) + ' ==== ' + endDate + ' ==== ' + startDate)
                    //    console.log("isAfterDate",isAfterDate);
                    //    console.log("isBeforeDate",isBeforeDate);
                    if (isAfterDate && isBeforeDate) {
                        //    days[j + 1].highlight = true;
                        days[j].highlight = true;
                    }
                }
            }
            if (journeyType === "roundTrip") {
                let startDateIndex = days.findIndex((items) => new Date(items.year, items.month, items.day).toString() === new Date(startDateyear, startDatemonth, startDateday).toString());
                let endDateIndex = days.findIndex((items) => new Date(items.year, items.month, items.day).toString() === new Date(endDateyear, endDatemonth, endDateday).toString());
                if (startDateIndex !== -1) {
                    days[startDateIndex].selectedDate = true;
                }
                if (endDateIndex !== -1) {
                    days[endDateIndex].selectedDate = true;
                }
            } else {
                let SelectedDate = this.props.value;
                let { getYear: selectedDateyear, getMonth: selectedDatemonth, getDay: selectedDateday } = this.getSpecificDateElement(SelectedDate, "a", "SelectedDate")
                let selectedDateIndex = days.findIndex((items) => new Date(items.year, items.month, items.day).toString() === new Date(selectedDateyear, selectedDatemonth, selectedDateday).toString())

                // let selectedDateIndex = days.findIndex((items) => new Date(items.year, items.month, items.day).toString() === new Date(this.specDateEle(SelectedDate, 'y'), this.specDateEle(SelectedDate, 'm'), this.specDateEle(SelectedDate, 'd')).toString())
                if (selectedDateIndex !== -1) {
                    days[selectedDateIndex].selectedDate = true;
                }
            }
            temp = endDateOfMonth;
            twoCalendarsArray.push(
                <div className={this.props.styles.calendar_whole_body}>
                    <div className={this.props.styles.calendar_head}>
                        <div className={this.props.styles.calendar_head_center_side}>
                            <h3>{format(monthHead, 'MMMM yyyy')}</h3>
                        </div>
                    </div>
                    <div className={this.props.styles.calendar_body}>
                        <div className={this.props.styles.calendar_week_heads}>
                            {weekList.map((ele, ix) => {
                                return (
                                    <div className={this.props.styles.coln_center_week} key={i + "th_month_" + ix + "week"}>
                                        {ele}
                                    </div>
                                )
                            })}
                        </div>
                        <div className={this.props.styles.calendar_day_list}>
                            {days.map((ele, idx) => {
                                let isHighLight = false;
                                let isHoverDate = false;
                                if (journeyType === "roundTrip") {
                                    let isBeforeDate = isBefore(new Date(ele.year, ele.month, ele.day), new Date(hoverDateyear, hoverDatemonth, hoverDateday));
                                    let isBeforeEndDate = isBefore(new Date(ele.year, ele.month, ele.day), new Date(endDateyear, endDatemonth, endDateday));
                                    let isAfterDate = isAfter(new Date(ele.year, ele.month, ele.day), new Date(startDateyear, startDatemonth, startDateday));
                                    isHoverDate = isSameDay(new Date(ele.year, ele.month, ele.day), new Date(hoverDateyear, hoverDatemonth, hoverDateday));
                                    if ((isAfterDate && isBeforeDate && commonFunction.isValueEmpty(endDate)) || (isAfterDate && isBeforeEndDate && (!commonFunction.isValueEmpty(endDate)))) {
                                        isHighLight = true;
                                    }
                                }
                                return (
                                    <div id={ele.selectedDate ? "selectedstartcal" : ""} className={(ele.selectedDate === true && ele.day) ? this.props.styles.day_cell_center + ' ' + this.props.styles.selected_calendar_date : isHighLight === true && ele.day != "" ? this.props.styles.day_cell_center_highlight : this.props.styles.day_cell_center} key={i}
                                        onClick={(e) => { e.stopPropagation(); this.onClickDateCell(ele.day, ele.month, ele.year, ele.isDisable, journeyType, selectionType) }} onMouseEnter={(e) => this.onHoverDayCell(ele, e)} >
                                        {/* {this.state.isDoubleSelection === true && !commonFunction.isValueEmpty(ele.day) && ele.isDisable === false && !commonFunction.isValueEmpty(hoverDate) && isHoverDate && !commonFunction.isValueEmpty(this.state.totalDays) && <div class={this.props.styles.container_tooltip}>{this.state.totalDays} Night</div>} */}
                                        <span className={ele.isDisable === true ? this.props.styles.calendar_day_disable : ''}>{ele.day}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            )
        }
        return twoCalendarsArray
    }
    onClickDoneBtn = () => {
        this.props.changeVisibility(false)
    }
    onHoverDayCell = (currentDate, e) => {
        let date = moment(new Date(currentDate.year, currentDate.month, currentDate.day)).format(this.props.dateFormat ? this.props.dateFormat : "DD/MMM/yyyy");
        this.setState({
            hoverEndDate: date
        })
        if (!commonFunction.isValueEmpty(this.props.startDate) && commonFunction.isValueEmpty(this.state.endDate)) {
            this.setState({
                hoverEndDate: date, pageX: Number(e.clientX) - Number(this.state.initPageX), pageY: Number(e.clientY) - Number(this.state.initPageY)
            }, () => {
                let difference = differenceInCalendarDays(new Date(currentDate.year, currentDate.month, currentDate.day), new Date(this.props.startDate))
                if (difference > 0) this.setState({ totalDays: difference })
                else this.setState({ totalDays: '' })
            })
        }
    }
    onMouseMoveCalendar = (e, eventUsed) => {
        switch (eventUsed) {
            case 'onMouseEnter':
                this.setState({ initPageX: e.clientX, initPageY: e.clientY })
                break;
            default:
                break;
        }
        e.preventDefault()
    }
    selectDateLabelType() {
        let dateLabel = { calaHeadTitle1: "DEPARTURE DATE", calaHeadTitle2: "RETURN DATE" };
        if (this.state.serviceType == "hotel") {
            dateLabel.calaHeadTitle1 = "Check In";
            dateLabel.calaHeadTitle2 = "Check Out";
        } else if (this.state.serviceType == "holiday") {
            dateLabel.calaHeadTitle1 = "START DATE";
            dateLabel.calaHeadTitle2 = "END DATE";
        }
        return dateLabel;
    }


    departureAdditionDiv() {
        return (
            <>
                <div>
                    {/* select cabin class */}
                    <div
                        className={
                            this.props.styles.mob_flight_cabin_class_cont
                        }
                        onClick={() => this.props.onClickOpenCabinClassList()}
                    >
                        {/* <img src="/images/eco.png" className={this.props.styles.mob_flight_cabin_class_icon_img} alt=""/> */}
                        <div
                            className={
                                this.props.styles.mob_flight_cabin_class_name
                            }
                        >
                            {!commonFunction.isValueEmpty(
                                this.props.SelectedClassValue
                            )
                                ? this.props.SelectedClassValue.label
                                : "Economy"}
                        </div>
                        <div
                            className={
                                this.props.styles.mob_flight_cabin_class_arrow
                            }
                        >
                            <svg
                                version="1.1"
                                id="Layer_1"
                                x="0px"
                                y="0px"
                                width="30px"
                                height="30px"
                                viewBox="0 0 30 30"
                                enable-background="new 0 0 30 30"
                            >
                                <path
                                    id="Path_1101"
                                    data-name="Path 1101"
                                    d="M339.293,85.293l1.414,1.414L334,93.414l-6.707-6.707,1.414-1.414L334,90.586Z"
                                    transform="translate(-327.293 -85.293)"
                                    fill-rule="evenodd"
                                ></path>
                            </svg>
                        </div>
                    </div>

                    {/* select cabin class  end*/}

                </div>

            </>
        )
    }

    returnAddtionalDiv() {

        return (
            <>
                {/* select cabin class round trip */}

                <div
                    id="cabinclassmain"
                    className={
                        this.props.styles.mob_flight_search_column +
                        " " +
                        this.props.styles.mob_flight_search_class
                    }
                >
                    {/* display value */}
                    <div
                        className={
                            this.props.styles.mob_flight_cabin_class_cont
                        }
                    >
                        {/* <img src="/images/eco.png" className={this.props.styles.mob_flight_cabin_class_icon_img} alt=""/> */}
                        <div
                            className={
                                this.props.styles.mob_flight_cabin_class_name
                            }
                        >
                            {!commonFunction.isValueEmpty(
                                this.props.SelectedClassValue
                            )
                                ? this.props.SelectedClassValue.label
                                : "Economy"}
                        </div>

                    </div>

                </div>

                {/* select cabin class round trip end */}

            </>

        )
    }

    render() {

        return (
            <div
                // onClick={(e) => { e.stopPropagation(); this.onClickChangeVisibility("depart") }}
                className={this.props.styles.calendar_wid_container}>
                {this.props.value === '' && <label>{this.props.label}</label>}
                <div className={this.props.styles.calendar_wid_main}>
                    {!commonFunction.isValueEmpty(this.props.iconImage) ? (
                        <img src={this.props.iconImage} className={this.props.styles.flight_search_wid_inputfield_img_cal} alt="" />
                    ) : null}
                    {this.props.returnValue == 'Tap to add return' || this.props.searchType == "oneWay" ? (
                        <>


                        <div className={this.props.styles.aaa}>
                        <div className={this.props.styles.new_main_flight_sec_1}>
                                <div className={this.props.styles.new_cal_main} onClick={(e) => { e.stopPropagation(); this.onClickChangeVisibility("depart") }}>
                                    {!commonFunction.isValueEmpty(this.props.iconImage) ? (
                                        <span className={this.props.styles.flight_search_wid_inputfield_img_cal} >
                                            {this.props.iconImage}
                                        </span>
                                    ) : null}
                                    <div className={this.props.styles.flex_cont_cal}>
                                        <div className={this.props.styles.new_cal_digit}>
                                            <h1 className={this.props.styles.new_cal_digit_text}>{this.props.departureValue ? this.props.departureValue.split('-')[0] : ''}</h1>
                                        </div>
                                        <div className={this.props.styles.new_cal_text}>
                                            <p className={this.props.styles.new_cal_month}>{this.props.departureValue ? this.props.departureValue.split('-')[1] : ''}</p>
                                            <p className={this.props.styles.new_cal_year}>{this.props.departureValue ? this.props.departureValue.split('-')[2] : ''}</p>
                                        </div>
                                    </div>



                                </div>
                                <div>
                                    {this.departureAdditionDiv()}
                                </div>
                            </div>
                            


                            <div className={this.props.styles.new_main_flight_sec_2}>
                            <div className={this.props.styles.new_cal_main}
                                onClick={() => {
                                    this.props.oneWayReturnClick();
                                }}>

                                    <div className={this.props.styles.flex_tap_pos}>
                                {!commonFunction.isValueEmpty(this.props.iconImage) ? (
                                    <span className={this.props.styles.flight_search_wid_inputfield_img_cal} >
                                        {this.props.iconImage}
                                    </span>
                                ) : null}

                                <div className={this.props.styles.flex_cont_cal}
                                >
                                    <div className={this.props.styles.new_cal_digit}>
                                        <h1 className={this.props.styles.new_tap_text}>{'Tap to add return'}</h1>
                                    </div>
                                </div>
                                </div>

                            </div>
                            </div>
                            </div>

                        </>
                    ) : (
                            <>
                            <div className={this.props.styles.new_main_flight_sec_1}>
                                    <div className={this.props.styles.new_cal_main} onClick={(e) => { e.stopPropagation(); this.onClickChangeVisibility("depart") }}>
                                        {!commonFunction.isValueEmpty(this.props.iconImage) ? (
                                            <span className={this.props.styles.flight_search_wid_inputfield_img_cal} >
                                                {this.props.iconImage}
                                            </span>
                                        ) : null}
                                        <div className={this.props.styles.flex_cont_cal}>
                                            <div className={this.props.styles.new_cal_digit}>
                                                <h1 className={this.props.styles.new_cal_digit_text}>{this.props.departureValue ? this.props.departureValue.split('-')[0] : ''}</h1>
                                            </div>
                                            <div className={this.props.styles.new_cal_text}>
                                                <p className={this.props.styles.new_cal_month}>{this.props.departureValue ? this.props.departureValue.split('-')[1] : ''}</p>
                                                <p className={this.props.styles.new_cal_year}>{this.props.departureValue ? this.props.departureValue.split('-')[2] : ''}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {this.departureAdditionDiv()}
                                    </div>

                                </div>

                               
                                <div className={`${this.props.styles.new_main_flight_sec_2} ${this.props.styles.new_main_flight_sec_22}`}>
                                  
                                  
                                    <div className={this.props.styles.new_cal_main} onClick={(e) => { e.stopPropagation(); this.onClickChangeVisibility("return") }}>
                                        {!commonFunction.isValueEmpty(this.props.iconImage) ? (
                                            <span className={this.props.styles.flight_search_wid_inputfield_img_cal} >
                                                {this.props.iconImage}
                                            </span>
                                        ) : null}

                                           
                                        <div className={this.props.styles.flex_cont_cal} style={{ marginLeft : "-10px"}}>
                                            <div className={this.props.styles.new_cal_digit}>
                                                <h1 className={this.props.styles.new_cal_digit_text}>{this.props.returnValue ? this.props.returnValue.split('-')[0] : ''}</h1>
                                            </div>
                                            <div className={this.props.styles.new_cal_text}>
                                                <p className={this.props.styles.new_cal_month}>{this.props.returnValue ? this.props.returnValue.split('-')[1] : ''}</p>
                                                <p className={this.props.styles.new_cal_year}>{this.props.returnValue ? this.props.returnValue.split('-')[2] : ''}</p>
                                            </div>
                                        </div>

                                    </div>
                                    { this.props.showCrossIcon ?
                                            <div  className={this.props.styles.showCrossIconrounday} 
                                            onClick={() => { this.props.roundWayReturnClick()}}
                                                                >
                                                    {
                                                        <img src='/images/close-red.svg' tabIndex="2" style={{ width: "15px", height: "15px", right: "0" ,  cursor: "pointer"  }} alt='close-icon' />
                                                    }
                                               </div> : null}
                                    <div className={this.props.styles.return_economy}>
                                        {this.returnAddtionalDiv()}
                                    </div>
                                </div>
                            </>
                        )}
                </div>
                {this.props.isVisible === true ? (
                    <div className={this.props.styles.calendar_wid_container_popup}>
                        {/* <img src="/images/download.png" onClick={(e) => { e.stopPropagation(); this.onClickChangeVisibility() }} className={this.props.styles.flight_search_wid_inputfield_img} /> */}
                        <div className={this.props.styles.calendar_modal_close} onClick={(e) => { e.stopPropagation(); this.onClickChangeVisibility("depart") }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                        </div>
                        <div className={this.props.styles.calendar_head_container}>
                            <div className={this.props.styles.calendar_head_main}>
                                <div className={this.props.styles.calendar_head_com + " " + this.props.styles.calendar_head_left}>
                                    {/* <span className={this.props.styles.calendar_head_title}>{this.props.isHoliday ? "START DATE" : "DEPARTURE DATE"}</span> */}
                                    <span className={this.props.styles.calendar_head_title}>{this.selectDateLabelType()?.calaHeadTitle1}</span>
                                    <h5>{!commonFunction.isValueEmpty(this.props.departureValue) ? format(new Date(this.props.departureValue), 'dd MMMM yyyy') : 'Select from date'}</h5>
                                </div>
                                {this.props.searchType === "roundTrip" ? <div className={this.props.styles.calendar_head_com + " " + this.props.styles.calendar_head_right}>
                                    {/* <span className={this.props.styles.calendar_head_title}>{this.props.isHoliday ? "END DATE" : "RETURN DATE"}</span> */}
                                    <span className={this.props.styles.calendar_head_title}>{this.selectDateLabelType()?.calaHeadTitle2}</span>
                                    <h5>{!commonFunction.isValueEmpty(this.props.returnValue) ? format(new Date(this.props.returnValue), 'dd MMMM yyyy') : 'Select to date'}</h5>
                                </div> : null}
                            </div>
                        </div>
                        <div className={this.props.styles.calendar_main_body_popup}>
                            {this.renderCalendar(this.props.searchType, this.state.selectingForDate)}
                        </div>
                        <div className={this.props.styles.calendar_footer}>
                            <button onClick={(e) => { e.stopPropagation(); this.onClickDoneBtn() }}>Done</button>
                        </div>
                    </div>
                ) : null}
            </div>
        )
    }
}

