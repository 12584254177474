import React, { useEffect, useState } from 'react';
import commonFunction from '../../../../lib/utils/common';

export default function PaxConfig(props) {
    const { theme } = props;
    return (
        <div className={props.styles.mob_flight_search_wid_inputfield}>
            <div className={props.value === props.minLimit ? props.styles.disable_pax_btn : ""} onClick={() => {
                if (!commonFunction.isValueEmpty(props.value)) {
                    if (props.value > props.minLimit) {
                        props.onChangePaxValue(props.value - 1, "minus");
                    }
                }
            }}>
                <div className={props.styles.mob_flight_search_flex_adjust}>
                    {/* <img src="/images/Minus.svg" className={props.styles.minus_pax} /> */}
                    <svg
                        version="1.1"
                        id="Layer_1"
                        x="0px"
                        y="0px"
                        className={props.styles.minus_pax}
                        width="30px"
                        height="30px"
                        viewBox="0 0 30 30"
                        enable-background="new 0 0 30 30"
                    >
                        <g>
                            <path
                                fill="currentColor"
                                d="M15.401,29.146c-7.616,0-13.813-6.196-13.813-13.813S7.785,1.521,15.401,1.521
		c7.617,0,13.813,6.196,13.813,13.813S23.018,29.146,15.401,29.146z M15.401,2.521c-7.065,0-12.813,5.747-12.813,12.813
		c0,7.064,5.748,12.813,12.813,12.813s12.813-5.748,12.813-12.813C28.214,8.268,22.466,2.521,15.401,2.521z"
                            />
                        </g>
                        <path
                            fill="currentColor"
                            d="M24.65,15.333c0,0.368-0.299,0.666-0.667,0.666H6.818c-0.368,0-0.667-0.298-0.667-0.666l0,0
	c0-0.368,0.298-0.667,0.667-0.667h17.166C24.352,14.666,24.65,14.965,24.65,15.333L24.65,15.333z"
                        />
                    </svg>
                </div>
            </div>
            <span>0{props.value}</span>
            <div className={props.totalPax === props.maxLimit ? props.styles.disable_pax_btn : ""} onClick={() => {
                console.log("theme333333", props.value);
                if (!commonFunction.isValueEmpty(props.value)) {
                    if (
                        props.totalPax >= props.minLimit &&
                        props.totalPax <= props.maxLimit
                    ) {
                        props.onChangePaxValue(props.value + 1, "add");
                    }
                }
            }}>
                {/* <img src="/images/Add.svg" className={props.styles.add_pax} /> */}
                <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          className={props.styles.add_pax}
          width="30px"
          height="30px"
          viewBox="0 0 30 30"
          enable-background="new 0 0 30 30"
        >
          <g>
            <path
              fill="currentColor"
              d="M14.813,28.979C7.196,28.979,1,22.783,1,15.167S7.196,1.354,14.813,1.354
		c7.617,0,13.813,6.196,13.813,13.813S22.43,28.979,14.813,28.979z M14.813,2.354C7.748,2.354,2,8.102,2,15.167
		c0,7.064,5.748,12.813,12.813,12.813c7.064,0,12.813-5.748,12.813-12.813C27.625,8.102,21.877,2.354,14.813,2.354z"
            />
          </g>
          <g>
            <path
              fill="currentColor"
              d="M24.063,15.499c0,0.368-0.299,0.666-0.668,0.666H6.229c-0.368,0-0.667-0.298-0.667-0.666l0,0
		c0-0.368,0.298-0.667,0.667-0.667h17.165C23.764,14.832,24.063,15.131,24.063,15.499L24.063,15.499z"
            />
            <path
              fill="currentColor"
              d="M14.813,24.416c-0.368,0-0.666-0.299-0.666-0.667V6.584c0-0.368,0.298-0.667,0.666-0.667l0,0
		c0.368,0,0.667,0.299,0.667,0.667v17.165C15.48,24.117,15.181,24.416,14.813,24.416L14.813,24.416z"
            />
          </g>
        </svg>
            </div>
        </div>
    )
}