import React, { useEffect, useState } from 'react';
import commonFunction from '@/lib/utils/common';

export default function HotelAutoComplete(props) {
    const { theme } = props;
    let hotelNameArry = props?.value?.split(",");
    const region = hotelNameArry[0]?.trim()
    return (
        <div className={props.styles.mob_hotel_search_wid_inputfield}>
            <label>{props.label}</label>
            <div className={props.styles.mob_hotel_search_wid_inputfield_main}>
                {/* <img src={props.leftIcon} className={props.styles.hotel_search_left_icon} /> */}
                <span className={props.styles.mob_hotel_search_left_icon}
                    onClick={() => { props.isAutoCompleteExpand(!props.expandAutoCompleteBool); props.onChangeHotelName(""); props.onClickHotelInput(); }}
                >
                    <img src={props.leftIcon} className={props.styles.mob_hotel_search_left_icon} alt=""/>

                    {/* {props.leftIcon} */}
                </span>
                {!props.isSrp &&
  <h1  onClick={() => {
            props.isAutoCompleteExpand(!props.expandAutoCompleteBool);
            props.onChangeHotelName("");
            props.onClickHotelInput();
          }}> {region} </h1>}
                <input type='text' autoComplete={'off'} id="myTextField"
                    placeholder={!commonFunction.isValueEmpty(props.placholder) ? props.placholder : ""}
                    className={props.styles.search_widget_input_new}
                    value={!commonFunction.isValueEmpty(props.value) ? props.value : ""}
                    onChange={(event) => { props.onChangeHotelName(event.target.value) }}
                    onClick={() => { props.isAutoCompleteExpand(!props.expandAutoCompleteBool); props.onChangeHotelName(""); props.onClickHotelInput(); }}
                />
                {/* <span className={props.styles.mob_cityname_sec}>Dubai, United Arab Emirates</span> */}
               
                {!commonFunction.isValueEmpty(props.isError) && props.isError === true ? (
                    <div className={props.styles.mob_hotel_search_error_message}><span>{!commonFunction.isValueEmpty(props.errorMessage) ? props.errorMessage : 'Invalid field'}</span></div>
                ) : null}
                
                {props.expandAutoCompleteBool === true ? (
               
<>
                    <svg className={props.styles.hotel_arrow_pax}  width="151" height="101" viewBox="0 0 151 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M75.5 1.3811L140.885 76.0953H10.1151L75.5 1.3811Z" fill="white"></path><line y1="-1.5" x2="100.715" y2="-1.5" transform="matrix(0.666347 -0.745642 0.939264 0.343196 9.5873 76.4784)" stroke="#2FC7C9" stroke-width="3"></line><path d="M76.0269 1.19861L140.214 76.0953" stroke="#2FC7C9" stroke-width="3"></path></svg>
                   

                    <div className={props.styles.mob_hotel_autocomplete_value}>
                    
                        {!commonFunction.isValueEmpty(props.autoCompleteData) && props.autoCompleteData.length > 0 &&
                            props.autoCompleteData.map(element => {
                                return (
                                    <div className={props.styles.mob_hotel_autocomplete_element} onClick={() => {
                                        props.onClickHotelName(element); props.isAutoCompleteExpand(false)
                                    }}>
                                        <img src={element.property_count === 0 ? "/images/hotels.svg" : "/images/location-01.svg"} alt=""/>
                                        <div className={props.styles.city_name_mob}>
                                            <div className={props.styles.hotel_autocomplete_name}>{element.search_text}</div>
                                            {element.property_count === 0 ? "" : <div className={props.styles.hotel_autocomplete_tag}>{element.property_count} Properties</div>}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    </>
                ) : null
                }
            </div>
        </div>
    )
}