import React, { Component } from 'react';
import moment from 'moment';


class TimePicker extends Component {
 constructor(props) {
  super(props);
  const [hourMinute, period] = (props.time || '01:00 AM').split(' ');
  const [hour, minute] = hourMinute.split(':');
  this.state = {
    isAM: true,
    selectedDay: null,
    selectedTempDay: null,
    selectedHour: hour,
    selectedMinute: minute,
    selectedPeriod: period,
    time: props.time || '1:00 AM',
    tempTime: props.time || '1:00 AM'
  };
 }

 componentDidMount() {
  const formattedDate = moment(this.props.value, 'DD-MMM-YYYY').format('ddd MMM DD YYYY');
  const [hourMinute, period] = (this.props.time || '01:00 AM').split(' ');
    const [hour, minute] = hourMinute.split(':');
  this.setState({ tempTime: this.props.time, time: this.props.time, selectedDay: formattedDate, selectedTempDay: formattedDate, selectedHour: hour, selectedMinute: minute, selectedPeriod: period, isAM: period === 'AM' ? true : false })
}

componentWillReceiveProps(nextProps) {
  if(this.props.value != nextProps.value || this.state.selectedDay != nextProps.value) {
    const formattedDate = moment(nextProps.value, 'DD-MMM-YYYY').format('ddd MMM DD YYYY');
    this.setState({ selectedDay: formattedDate, selectedTempDay: formattedDate })
  }
  if(this.props.time != nextProps.time || this.state.tempTime != nextProps.time) {
    const [hourMinute, period] = (nextProps.time || '01:00 AM').split(' ');
    const [hour, minute] = hourMinute.split(':');
    this.setState({ tempTime: nextProps.time, time: nextProps.time, selectedHour: hour, selectedMinute: minute, selectedPeriod: period, isAM: period === 'AM' ? true : false })
  }  
}

 toggleAMPM = () => {
   this.setState(prevState => ({
     isAM: !prevState.isAM,
   }), () => {
    this.handleTimeChange('period', this.state.isAM ? 'AM' : 'PM')
   });
 };

 handleTimeChange = (part, value) => {
  const [hour, minutePeriod] = this.state.tempTime.split(':');
  const [minute, period] = minutePeriod.split(' ');

  let newTime;
  if (part === 'hour') {
    newTime = `${value}:${minute} ${period}`;
    this.setState({ selectedHour: value });
  } else if (part === 'minute') {
    newTime = `${hour}:${value} ${period}`;
    this.setState({ selectedMinute: value });
  } else if (part === 'period') {
    newTime = `${hour}:${minute} ${value}`;
    this.setState({ selectedPeriod: value });
  }

  this.setState({
    tempTime: newTime,
  });
};


 onApply = () => {
  const { onChangeDate, timeType, apply, popupClosed } = this.props;
  const { selectedDay } = this.state;
  const dateInMoment = moment(selectedDay, 'ddd MMM DD YYYY');
  const formattedDate = dateInMoment.format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)')
  onChangeDate(formattedDate);
  apply(timeType, this.state.tempTime);
  popupClosed();
 };

generateDaysOfYear = () => {
  const daysOfYear = [];
  const currentDate = moment();
  const currentYear = currentDate.year();
  const daysInYear = currentDate.isLeapYear() ? 366 : 365;
  for (let i = 0; i < daysInYear; i++) {
    const date = currentDate.clone().add(i, 'days');
    daysOfYear.push(date.format('ddd MMM DD YYYY'));
  }
  
  return daysOfYear;
};

 generateHours = () => {
   const hours = [];
   for (let i = 1; i <= 12; i++) {
     hours.push(i.toString().padStart(2, '0'));
   }
   return hours;
 };


 generateMinutes = () => {
   const minutes = [];
   for (let i = 0; i < 60; i++) {
     minutes.push(i.toString().padStart(2, '0'));
   }
   return minutes;
 };

 handleSelect = (day) => {
  this.setState({
    selectedDay: day
  })
};

 render() {
   const { isAM } = this.state;
   const { expandAutoCompleteBool, styles, label, isAutoCompleteExpand, popupClosed, Header, subTitle } = this.props;

   const daysOfYear = this.generateDaysOfYear();
   const hours = this.generateHours();
   const minutes = this.generateMinutes();


   return (
     <div className={styles.timepicker_main_container}>
       <div className={styles.timepicker_main_wrap} onClick={isAutoCompleteExpand}>
         <label className={styles.timepicker_main_label}>{label}</label>
         <div className={styles.timepick_main_flex}>
         <div className={styles.timepick_main_date_box}>
           <span className={styles.timepicker_value}>{moment(this.state.selectedTempDay, 'ddd MMM DD YYYY').format('DD MMM YYYY')}{', '}{this.state.time}</span>
           <div className={styles.timepicker_weekday}>{moment(this.state.selectedTempDay, 'ddd MMM DD YYYY').format('dddd')}</div>
         </div>
         <span className={styles.timepicker_icon}>
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="12" viewBox="0 0 21 12" fill="none">
              <path d="M19 1.5L10.5 10.5L2 1.5" stroke="#266AAC" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
         </span>
         </div>
       </div>


       {expandAutoCompleteBool && (
         <div className={styles.timepicker_popup}>
           <div className={styles.headerAuto}>
             <span
               onClick={() => { popupClosed(); }}
             >
               <svg xmlns="http://www.w3.org/2000/svg" width="14" height="24">
                 <path
                   fillRule="evenodd"
                   fill="#FFF"
                   d="m13.444 3.241-8.815 8.747 8.817 8.749c.75.744.75 1.95 0 2.694a1.93 1.93 0 0 1-2.715 0L.566 13.343a1.89 1.89 0 0 1-.528-.99 1.891 1.891 0 0 1 .525-1.718L10.729.546a1.932 1.932 0 0 1 2.715 0 1.897 1.897 0 0 1 0 2.695z"
                 />
               </svg>
             </span>
             <div className={styles.header_title}>{Header}</div>
           </div>
          
           <div className={styles.pickup_drop_subtitle}>
             <div className={`${styles.calendar_head_com} ${styles.cal_active}`}>
               <label className={styles.calendar_label}>{subTitle}</label>
               <p class={styles.calendar_head_date}>{moment(this.state.selectedDay, 'ddd MMM DD YYYY').format('ddd, DD MMM')}{' '}{this.state.tempTime}</p>
             </div>
           </div>

           <div className={styles.selection_main_container}>
             <div className={styles.calendar_main_div_top}>
               <div className={styles.calendar_sub_div}>
                 <h3 className={styles.startTime_label}>Set Start Time</h3>
                 <div className={styles.meridiem}>
                   <button
                   className={`${styles.toggle_AmPm} ${
                       this.state.isAM ? styles.toggle_active : ''
                   }`}
                   onClick={this.toggleAMPM}
                   >
                    {this.state.isAM &&
                      <span className={styles.active_icon_ampm}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                          <path d="M8.5 0C3.80557 0 0 3.80557 0 8.5C0 13.1944 3.80557 17 8.5 17C13.1944 17 17 13.1944 17 8.5C17 3.80557 13.1944 0 8.5 0ZM13.3942 6.17829L7.46179 12.1101C7.29118 12.2807 7.05925 12.3766 6.81761 12.3766H6.817C6.57536 12.3766 6.34343 12.2801 6.17221 12.1089L3.60461 9.53214C3.24943 9.17575 3.25064 8.59957 3.60704 8.24439C3.96404 7.88921 4.54021 7.89043 4.89479 8.24682L6.81882 10.1775L12.1058 4.89114C12.4616 4.53536 13.0378 4.53536 13.3936 4.89114C13.7494 5.24693 13.7494 5.8225 13.3942 6.17829Z" fill="white"/>
                        </svg>
                      </span>
                    }
                    <p>AM</p>
                   </button>
                   <button
                   className={`${styles.toggle_AmPm} ${
                       !this.state.isAM ? styles.toggle_active : ''
                   }`}
                   onClick={this.toggleAMPM}
                   >
                    {!this.state.isAM &&
                      <span className={styles.active_icon_ampm}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                          <path d="M8.5 0C3.80557 0 0 3.80557 0 8.5C0 13.1944 3.80557 17 8.5 17C13.1944 17 17 13.1944 17 8.5C17 3.80557 13.1944 0 8.5 0ZM13.3942 6.17829L7.46179 12.1101C7.29118 12.2807 7.05925 12.3766 6.81761 12.3766H6.817C6.57536 12.3766 6.34343 12.2801 6.17221 12.1089L3.60461 9.53214C3.24943 9.17575 3.25064 8.59957 3.60704 8.24439C3.96404 7.88921 4.54021 7.89043 4.89479 8.24682L6.81882 10.1775L12.1058 4.89114C12.4616 4.53536 13.0378 4.53536 13.3936 4.89114C13.7494 5.24693 13.7494 5.8225 13.3942 6.17829Z" fill="white"/>
                        </svg>
                      </span>
                    }
                   <p>PM</p>
                   </button>
               </div>
               </div>
             </div>

             <div className={styles.calendar_main_Container}>
             <div className={styles.calendar_main_wrap}>
               <h3 className={styles.calendra_label}>Date</h3>
               <div className={styles.calendar_main_div}>
                 {daysOfYear.map((day, index) => {
                  const displayDate = moment(day, 'ddd MMM DD YYYY').format('ddd MMM DD');
                  return(
                   <div className={`${styles.calendra_value} ${this.state.selectedDay === day ? styles.active : ''}`} key={index} onClick={() => this.handleSelect(day)}>{displayDate}</div>
                 )})}
               </div>
             </div>

             <div className={styles.calendar_main_wrap}>
               <h3 className={styles.calendra_label}>Hours</h3>
               <div className={styles.hours_main_div}>
                 {hours.map((hour, index) => (
                   <div className={`${styles.calendra_value} ${this.state.selectedHour === hour ? styles.active : ''}`} key={index} onClick={() => this.handleTimeChange('hour', hour)}>{hour}</div>
                 ))}
               </div>
             </div>

             <div className={styles.calendar_main_wrap}>
               <h3 className={styles.calendra_label}>Minutes</h3>
               <div className={styles.minutes_main_div}>
                 {minutes.map((minute, index) => (
                   <div className={`${styles.calendra_value} ${this.state.selectedMinute === minute ? styles.active : ''}`} key={index} onClick={() => this.handleTimeChange('minute', minute)}>{minute}</div>
                 ))}
               </div>
             </div>
             </div>
           </div>

           <div className={styles.pickup_button_wrap}>
             <button className={styles.pickup_time__apply_button} onClick={this.onApply}>
               Apply
             </button>
           </div>
         </div>
       )}
     </div>
   );
 }
}


export default TimePicker;



