const categoryList = (router) => {
    try {
      return fetch(`/holiday/api/categoryList`).then((response) =>
        response.json()
      );
    } catch (error) {
      console.log("Error occurred during fetch:", error);
      throw error; // Rethrow the error or handle it as needed
    }
  };
  
  export default categoryList;