import React, { Component } from "react";
import { format, parseISO, add } from "date-fns";
import commonFunction from "../../../../lib/utils/common";
import autoSuggest from "../../../../lib/autoSuggest";
import MobileCalender from "@/commonComponents/calendar/MobileCalender";

const popularCities = [
  {
    destination_id: "11663",
    search_text: "Mumbai",
    location_type: 1,
  },
  {
    destination_id: "11667",
    search_text: "New Delhi",
    location_type: 1,
  },
  {
    destination_id: "11634",
    search_text: "Chennai",
    location_type: 1,
  },
  {
    destination_id: "12633",
    search_text: "Dubai",
    location_type: 1,
  },
  {
    destination_id: "10516",
    search_text: "Paris",
    location_type: 1,
  },
];

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
};
const fetchData = async (query, callBackFunc) => {
  if (query != "") {
    const res = await autoSuggest({ searchText: query });
    callBackFunc(res);
  } else {
    callBackFunc(null);
  }
};
const debouncedFetchData = debounce((query, cb) => {
  fetchData(query, cb);
}, 400);

export default class MobileTheme2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activityName: "",
      isAutoSuggestVisible: false,
      checkInOutValue: "",
      checkInValue: null,
      checkOutValue: null,
      isCalendarVisible: false,
      autoSuggestCountryData: [],
      autoSuggestCityData: popularCities,
      selectedAutoSuggestData: null,
      isError: false,
      isDateError: false,
    };
  }

  componentDidMount = () => {
    this.onChangeCheckInOutValue(new Date());
  }

  onChangeCheckInOutValue = (dateValue) => {
    // console.log("date=value", dateValue);
    this.setState({
      checkInValue: commonFunction.getDateWithFormat(
        // new Date(dateValue),
        add(new Date(dateValue), { days: 7 }),
        this?.props?.dateFormat
      ),
      checkOutValue: commonFunction.getDateWithFormat(
        add(new Date(dateValue), { days: 8 }),
        this?.props?.dateFormat
      ),
      checkInOutValue: commonFunction.getDateWithFormat(
        dateValue,
        this?.props?.dateFormat
      ),
      isDateError: false,
    });
  };

  onChanegeSetActivityName = (value) => {
    this.setState({ activityName: value });
    if (value.trim() == "") {
      this.setState({
        autoSuggestCityData: popularCities,
        isAutoSuggestVisible: false,
        selectedAutoSuggestData: null,
        isError: false,
      });
    } else {
      debouncedFetchData(value, (data) => {
        if (
          data &&
          data.data &&
          data.data.searchBycity &&
          typeof data.data.searchBycity === "object"
        ) {
          this.setState({
            autoSuggestCityData: [...data.data.searchBycity.result],
            isAutoSuggestVisible: true,
          });
        } else if (data && data.data && data.data.status == false) {
          this.setState({
            autoSuggestCityData: popularCities,
            isAutoSuggestVisible: true,
          });
        }
        //   else if(data && data.data && data.data.searchBycountry && typeof (data.data.searchBycountry) === 'object'){
        //     this.setState({ autoSuggestCountryData: [...data.data.searchBycity.result] , isAutoSuggestVisible:true});
        // }
      });
    }
  };

  onClickSearchActivity = () => {
    if (
      !(
        this.state.selectedAutoSuggestData &&
        this.state.selectedAutoSuggestData.search_text
      )
    ) {
      this.setState({ isError: true });
    }
    if (this.state.checkInOutValue == "") {
      this.setState({ isDateError: true });
    }
    if (
      this.state.selectedAutoSuggestData &&
      this.state.selectedAutoSuggestData.search_text &&
      this.state.checkInOutValue
    ) {
      let urlQuery = {
        location: this.state.selectedAutoSuggestData.search_text,
        checkin: format(new Date(this.state.checkInValue), "yyyy-MM-dd"),
        checkout: format(new Date(this.state.checkOutValue), "yyyy-MM-dd"),
        from: 1,
        to: 30,
        // search_type: this.state.selectedAutoSuggestData.location_type,
        search_type: 1,
        search_text: this.state.selectedAutoSuggestData.destination_id,
        currency: "AED",
      };
      commonFunction.redirectTo("/activity/search", urlQuery);
    }
  };
  onClickSetActivityName = (element) => {
    this.setState({
      activityName: element.search_text,
      selectedAutoSuggestData: element,
      isError: false,
    });
  };
  render() {
    return (
      <div
        className=""
        onClick={(e) => {
          e.stopPropagation();
          if (this.state.isAutoSuggestVisible) {
            this.setState({
              isAutoSuggestVisible: false,
            });
          }
          if (this.state.isCalendarVisible) {
            this.setState({
              isCalendarVisible: false,
            });
          }
        }}
      >
       <div class={this.props?.cssfileMobile?.mobile_visible}>
        <div className={this.props?.cssfileMobile?.activity_way_title}>
          <img
            src="/images/back-arrow.png"
            alt=""
            class="arrow_image"
            onClick={(e) => {
              e.stopPropagation();
              this.props.onClosePopup();
            }}
          />
          <h2>Activity Search</h2>
        </div>
        </div>
        <div className={this.props?.cssfileMobile?.activity_search_wid_container}>
          <div className="activity_search_wid_main">
            {/* <div className={this.props.cssfileMobile.activity_way_title}>
                          <h2>Activities</h2>
                      </div> */}
            <div
              className={
                this.props?.cssfileMobile?.activity_search_details_container
              }
            >
              <div
                className={
                  this.props?.cssfileMobile?.activity_search_details_main
                }
              >
                <div
                  className={
                    this.props?.cssfileMobile?.activity_search_details_row
                  }
                >
                  <div
                    className={
                      this.props?.cssfileMobile?.activity_search_column +
                      " " +
                      this.props.cssfileMobile?.activity_search_name
                    }
                  >
                    <small>
                    <svg version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          width="200px"
                          height="200px"
                          viewBox="0 0 200 200"
                          enableBackground="new 0 0 200 200"
                          >
<path id="Path_164" fill="#E72643" d="M11.045,0.004C4.943,0.008-0.001,4.958,0.003,11.06c0.004,6.102,4.954,11.045,11.057,11.042
	c2.33-0.001,4.601-0.739,6.485-2.108l5.5,5.5c0.668,0.687,1.766,0.702,2.452,0.035s0.702-1.766,0.035-2.452
	c-0.012-0.012-0.023-0.023-0.035-0.035l-5.5-5.5c3.587-4.931,2.499-11.836-2.432-15.424C15.671,0.739,13.388-0.001,11.045,0.004z
	 M11.045,18.637c-4.193,0.006-7.596-3.389-7.602-7.582c-0.005-4.193,3.389-7.596,7.582-7.602c4.193-0.005,7.597,3.389,7.602,7.582
	c0.003,2.017-0.797,3.951-2.223,5.378c-1.422,1.421-3.349,2.22-5.359,2.22V18.637z"/>
</svg>
                    </small>
                    <div
                      id="search-widget-main"
                      className={
                        this.props.cssfileMobile?.activity_search_wid_inputfield
                      }
                    >
                      {/* <label>{`Enter Destination`}</label> */}
                      <div
                        className={
                          this.props.cssfileMobile
                            .activity_search_wid_inputfield_main
                        }
                      >
                        <span>
                        <svg version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          width="200px"
                          height="200px"
                          viewBox="0 0 200 200"
                          enableBackground="new 0 0 200 200"
                          >
<g>
	<path fill="currentColor" d="M101,184.511c-0.031,0-0.062,0-0.094-0.001c-1.113-0.026-2.164-0.515-2.902-1.349
		c-1.537-1.736-3.586-4.031-6.147-6.885c-2.659-2.961-7.256-8.648-14.055-17.387c-6.738-8.66-12.719-17.192-17.779-25.359
		c-5.106-8.243-9.814-17.68-13.991-28.049c-4.284-10.633-6.457-20.179-6.457-28.372c0-16.481,5.985-30.635,17.79-42.068
		C69.39,23.395,84.07,17.489,101,17.489s31.546,5.842,43.442,17.365c11.932,11.556,17.982,25.772,17.982,42.255
		c0,8.19-2.106,17.607-6.26,27.989c-4.062,10.15-9.045,19.803-14.812,28.688c-5.665,8.729-11.346,17.015-16.885,24.627
		c-5.553,7.63-10.354,13.807-14.27,18.359c-0.053,0.062-0.107,0.121-0.164,0.18l-6.167,6.346
		C103.114,184.074,102.079,184.511,101,184.511z M101,25.489c-14.988,0-27.441,5.004-38.069,15.298
		C52.599,50.795,47.576,62.676,47.576,77.109c0,7.164,1.977,15.704,5.876,25.382c4.007,9.945,8.506,18.972,13.372,26.826
		c4.91,7.927,10.729,16.224,17.292,24.66c6.589,8.469,11.196,14.174,13.693,16.955c1.222,1.361,2.329,2.597,3.319,3.706l3.084-3.174
		c3.762-4.381,8.399-10.354,13.787-17.758c5.456-7.498,11.056-15.665,16.643-24.275c5.483-8.45,10.226-17.637,14.095-27.305
		c3.774-9.433,5.688-17.85,5.688-25.018c0-14.434-5.086-26.376-15.549-36.508C128.378,30.432,115.988,25.489,101,25.489z"/>
</g>
<g>
	<path fill="currentColor" d="M101,91.748c-7.077,0-12.834-5.757-12.834-12.834S93.923,66.079,101,66.079s12.835,5.757,12.835,12.834
		S108.077,91.748,101,91.748z M101,74.079c-2.666,0-4.834,2.169-4.834,4.834s2.169,4.834,4.834,4.834
		c2.666,0,4.835-2.169,4.835-4.834S103.666,74.079,101,74.079z"/>
</g>
</svg>
                        </span>
                        <input
                          type="text"
                          autoComplete={"off"}
                          id="myTextField"
                          placeholder={`City / Destination / Experiences `}
                          readOnly
                          className={
                            this.props.cssfileMobile.search_widget_input_new
                          }
                          value={
                            !commonFunction.isValueEmpty(
                              this.state.activityName
                            ) && !this.state.isAutoSuggestVisible
                              ? this.state.activityName
                              : ""
                          }
                          onChange={(event) => {
                            this.onChanegeSetActivityName(event.target.value);
                          }}
                          onClick={() => {
                            this.setState((prevState) => {
                              return {
                                isAutoSuggestVisible:
                                  !prevState.isAutoSuggestVisible,
                                isCalendarVisible: false,
                                activityName: '',
                              };
                            });
                          }}
                        />
                        {this.state.isError && (
                          <div className={this.props.cssfileMobile.error}>
                            {"Please select your Destination"}
                          </div>
                        )}

                        {this.state.isAutoSuggestVisible === true ? (
                          <div
                            className={
                              this.props.cssfileMobile
                                .activity_autocomplete_value
                            }
                          >
                            <div className={this.props.cssfileMobile.input_search_destination}>
                             <svg width="50px" height="50px" viewBox="0,0,256,256">
                                  <g fill="#3479c5" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none">
                                <g transform="scale(5.12,5.12)"><path d="M21,3c-9.39844,0 -17,7.60156 -17,17c0,9.39844 7.60156,17 17,17c3.35547,0 6.46094,-0.98437 9.09375,-2.65625l12.28125,12.28125l4.25,-4.25l-12.125,-12.09375c2.17969,-2.85937 3.5,-6.40234 3.5,-10.28125c0,-9.39844 -7.60156,-17 -17,-17zM21,7c7.19922,0 13,5.80078 13,13c0,7.19922 -5.80078,13 -13,13c-7.19922,0 -13,-5.80078 -13,-13c0,-7.19922 5.80078,-13 13,-13z"></path></g></g>
                             </svg>  
                          <input
                          type="text"
                          autoComplete={"off"}
                          id="myTextField"
                          placeholder={`City / Destination / Experience `}
                          autoFocus
                          className={
                            this.props.cssfileMobile.search_widget_input_new
                          }
                          value={
                            !commonFunction.isValueEmpty(
                              this.state.activityName
                            )
                              ? this.state.activityName
                              : ""
                          }
                          onChange={(event) => {
                            this.onChanegeSetActivityName(event.target.value);
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            this.setState((prevState) => {
                              return {...prevState,
                                isAutoSuggestVisible: true,
                                isCalendarVisible: false,
                                activityName: '',
                              };
                            });
                          }}
                        />
                                                </div>
                            {!commonFunction.isValueEmpty(
                              this.state.autoSuggestCountryData
                            ) &&
                              this.state.autoSuggestCountryData.length > 0 &&
                              this.state.countryData.map((element) => {
                                return (
                                  <div
                                    className={
                                      this.props.cssfileMobile
                                        .activity_autocomplete_element
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      this.setState({
                                        activityName: element.search_text,
                                        isAutoSuggestVisible: false,
                                      });
                                      this.onClickSetActivityName(element);
                                    }}
                                  >
                                    <img src="images/location-01.svg" alt=""/>
                                    <div>
                                      <div
                                        className={
                                          this.props.cssfileMobile
                                            .activity_autocomplete_name
                                        }
                                      >
                                        {element.search_text}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            {!commonFunction.isValueEmpty(
                              this.state.autoSuggestCityData
                            ) && this.state.autoSuggestCityData.length > 0 ? (
                              this.state.autoSuggestCityData.map((element) => {
                                return (
                                  <div
                                    key={`city-${element.search_text}`}
                                    className={
                                      this.props.cssfileMobile
                                        .activity_autocomplete_element
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      this.setState({
                                        activityName: element.search_text,
                                        isAutoSuggestVisible: false,
                                      });
                                      this.onClickSetActivityName(element);
                                    }}
                                  >
                                    <img src="images/location-01.svg" alt=""/>
                                    <div>
                                      <div
                                        className={
                                          this.props.cssfileMobile
                                            .activity_autocomplete_name
                                        }
                                      >
                                        {element.search_text}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div
                                key={`noresults`}
                                className={
                                  this.props.cssfileMobile
                                    .activity_autocomplete_element
                                }
                              >
                                <img src="images/location-01.svg" alt=""/>
                                <div>
                                  <div
                                    className={
                                      this.props.cssfileMobile
                                        .activity_autocomplete_name
                                    }
                                  >
                                    No Results Found
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : null}
                        <span className={this.props.cssfileMobile.searchredicon}><img src="images/search-red.svg"/></span>
                      </div>
                    </div>
                  </div>

                  {/* <div
                    className={`${this.props.cssfileMobile.activity_search_column} ${this.props.cssfileMobile.activity_search_date}`}
                  >
                    <MobileCalender
                      label="Date"
                      styles={this.props.cssfileMobile}
                      //  styles={styles}
                      value={
                        !commonFunction.isValueEmpty(this.state.checkInOutValue)
                          ? this.state.checkInOutValue
                          : ""
                      }
                      placholder="Select the date"
                      onChangeDate={(dateVal) => {
                        this.onChangeCheckInOutValue(dateVal);
                      }}
                      iconImage={"images/calendar-01.svg"}
                      startDate={commonFunction.getDateWithFormat(
                        new Date(),
                        this?.props?.dateFormat
                      )}
                      startDateLimit={commonFunction.getDateWithFormat(
                        new Date(),
                        this?.props?.dateFormat
                      )}
                      dateFormat={this?.props?.dateFormat}
                      changeVisibility={(bool) => {
                        console.log("calander visibile", bool);
                        this.setState({
                          isCalendarVisible: bool,
                          isAutoSuggestVisible: false,
                          isDateError: false,
                        });
                      }}
                      isVisible={this.state.isCalendarVisible}
                      doubleSelection={false}
                    />
                    {this.state.isDateError && (
                      <div className={this.props.cssfileMobile.errordate}>
                        {"Please select Date"}
                      </div>
                    )}
                  </div> */}
                  <div
                    className={`${this.props.cssfileMobile.activity_search_details_row} ${this.props.cssfileMobile.activity_nw}`}
                  >
                    <div
                      className={this.props.cssfileMobile.activity_search_class}
                    >
                      <button
                        className={
                          this.props.cssfileMobile.activity_search_widget_btn
                        }
                       
                        onClick={() => this.onClickSearchActivity()}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
