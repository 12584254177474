import React, { useEffect } from "react";

import commonFunction from "../../../../lib/utils/common";

export default function AutoComplete(props) {
  const onClickHandler = (e) => {
    document.querySelector("body").style.overflow = props?.isMobile
      ? "hidden"
      : "auto";
    props.onclickFlightAutoComplete();
  };

  const selectListClickHandler = (element) => {
    document.querySelector("body").style.overflow =
      !props.expandAutoCompleteBool && props?.isMobile ? "hidden" : "auto";
    props.onClickSourceAndDestination(element);
  };

  return (
    <div
      className={`${props.styles.flight_search_wid_inputfield} autosuggest_container`}
      ref={props.listWrapper}
      id={`car_${props.label}_${props.id}`}
    >
      <label>{props.label}</label>
      <div className={`${props.styles.autocomplet_wrap}`}>
        <input
          type="text"
          autoComplete={"off"}
          id="myTextField"
          tabindex="1"
          placeholder={
            !commonFunction.isValueEmpty(props.placholder)
              ? props.placholder
              : ""
          }
          className={props.styles.search_widget_input_new}
          value={!commonFunction.isValueEmpty(props.value) ? props.value : ""}
          onChange={(event) => {
            props.onChangeSourceAndDestination(
              event.target.value.replace(/[^a-zA-Z0-9_&,\s-]/g, "")
            );
          }}
          onClick={onClickHandler}
          onFocusCapture={onClickHandler}
        />
      </div>
      {/* <div className={props.styles.flight_airlineName}>
        {!commonFunction.isValueEmpty(props.subTitle) ? props.subTitle : ""}
      </div> */}
      {!commonFunction.isValueEmpty(props.isError) && props.isError === true ? (
        <div className={`${props.styles.error_srp} ${props.styles.error_desk}`}>
          <span>
            {!commonFunction.isValueEmpty(props.errorMessage)
              ? props.errorMessage
              : "Invalid field"}
          </span>
        </div>
      ) : null}
      {props.expandAutoCompleteBool &&
        !props.isMobile &&
        props.text == "src" && (
          <div
            ref={props.listWrapper}
            tabIndex="2"
            className={`${props.styles.flight_autocomplete_value} autosuggest_value`}
            id={`Flight_${props.label}_${props.id}`}
          >
            <div className={props.styles.flight_InputVal}>
              <span className={props.styles.searchAutoIcon}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14">
                  <defs>
                    <filter id="6waepprpsa">
                      <feFlood
                        flood-color="#979797"
                        flood-opacity="1"
                        result="floodOut"
                      ></feFlood>
                      <feComposite
                        operator="atop"
                        in="floodOut"
                        in2="SourceGraphic"
                        result="compOut"
                      ></feComposite>
                      <feBlend in="compOut" in2="SourceGraphic"></feBlend>
                    </filter>
                  </defs>
                  <g filter="url(#6waepprpsa)">
                    <path
                      fill-rule="evenodd"
                      fill="#97144D"
                      d="M10.928 9.513A6.022 6.022 0 0 0 1.116 2.53C-.812 5.24-.179 8.1 2.531 10.928a6.021 6.021 0 0 0 6.982 0l2.83 2.83a1.004 1.004 0 0 0 1.415-1.415l-2.83-2.83zM2.006 6.03a4.015 4.015 0 1 1 6.855 2.841A4.015 4.015 0 0 1 2.006 6.03z"
                    ></path>
                  </g>
                </svg>
              </span>
              <input
                type="text"
                autoComplete={"off"}
                id="myTextField"
                tabindex="1"
                placeholder={
                  !commonFunction.isValueEmpty(props.placholder)
                    ? props.placholder
                    : ""
                }
                className={props.styles.search_widget_input_highlight}
                value={
                  !commonFunction.isValueEmpty(props.value) ? props.value : ""
                }
                onChange={(event) => {
                  props.onChangeSourceAndDestination(
                    event.target.value.replace(/[^a-zA-Z0-9_&,\s-]/g, "")
                  );
                }}
                onClick={onClickHandler}
                onFocusCapture={onClickHandler}
              />
            </div>
            {!props.isMobile && props.text == "dest" && (
              <div className={props.styles.flight_InputVal}>
                <span className={props.styles.searchAutoIcon}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="14"
                  >
                    <defs>
                      <filter id="6waepprpsa">
                        <feFlood
                          flood-color="#979797"
                          flood-opacity="1"
                          result="floodOut"
                        ></feFlood>
                        <feComposite
                          operator="atop"
                          in="floodOut"
                          in2="SourceGraphic"
                          result="compOut"
                        ></feComposite>
                        <feBlend in="compOut" in2="SourceGraphic"></feBlend>
                      </filter>
                    </defs>
                    <g filter="url(#6waepprpsa)">
                      <path
                        fill-rule="evenodd"
                        fill="#97144D"
                        d="M10.928 9.513A6.022 6.022 0 0 0 1.116 2.53C-.812 5.24-.179 8.1 2.531 10.928a6.021 6.021 0 0 0 6.982 0l2.83 2.83a1.004 1.004 0 0 0 1.415-1.415l-2.83-2.83zM2.006 6.03a4.015 4.015 0 1 1 6.855 2.841A4.015 4.015 0 0 1 2.006 6.03z"
                      ></path>
                    </g>
                  </svg>
                </span>
                <input
                  type="text"
                  autoComplete={"off"}
                  id="myTextField"
                  tabindex="1"
                  placeholder={
                    !commonFunction.isValueEmpty(props.placholder)
                      ? props.placholder
                      : ""
                  }
                  className={props.styles.search_widget_input_highlight}
                  value={
                    !commonFunction.isValueEmpty(props.value) ? props.value : ""
                  }
                  onChange={(event) => {
                    props.onChangeSourceAndDestination(
                      event.target.value.replace(/[^a-zA-Z0-9_&,\s-]/g, "")
                    );
                  }}
                  onClick={onClickHandler}
                  onFocusCapture={onClickHandler}
                />
              </div>
            )}
          </div>
        )}
      {props.expandAutoCompleteBool &&
        !props.isMobile &&
        props.text == "dest" && (
          <div
            ref={props.listWrapper}
            tabIndex="2"
            className={`${props.styles.flight_autocomplete_value} autosuggest_value`}
            id={`Flight_${props.label}_${props.id}`}
          >
            <div className={props.styles.flight_InputVal}>
              <span className={props.styles.searchAutoIcon}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14">
                  <defs>
                    <filter id="6waepprpsa">
                      <feFlood
                        flood-color="#979797"
                        flood-opacity="1"
                        result="floodOut"
                      ></feFlood>
                      <feComposite
                        operator="atop"
                        in="floodOut"
                        in2="SourceGraphic"
                        result="compOut"
                      ></feComposite>
                      <feBlend in="compOut" in2="SourceGraphic"></feBlend>
                    </filter>
                  </defs>
                  <g filter="url(#6waepprpsa)">
                    <path
                      fill-rule="evenodd"
                      fill="#97144D"
                      d="M10.928 9.513A6.022 6.022 0 0 0 1.116 2.53C-.812 5.24-.179 8.1 2.531 10.928a6.021 6.021 0 0 0 6.982 0l2.83 2.83a1.004 1.004 0 0 0 1.415-1.415l-2.83-2.83zM2.006 6.03a4.015 4.015 0 1 1 6.855 2.841A4.015 4.015 0 0 1 2.006 6.03z"
                    ></path>
                  </g>
                </svg>
              </span>
              <input
                type="text"
                autoComplete={"off"}
                id="myTextField"
                tabindex="1"
                placeholder={
                  !commonFunction.isValueEmpty(props.placholder)
                    ? props.placholder
                    : ""
                }
                className={props.styles.search_widget_input_highlight}
                value={
                  !commonFunction.isValueEmpty(props.value) ? props.value : ""
                }
                onChange={(event) => {
                  props.onChangeSourceAndDestination(
                    event.target.value.replace(/[^a-zA-Z0-9_&,\s-]/g, "")
                  );
                }}
                onClick={onClickHandler}
                onFocusCapture={onClickHandler}
              />
            </div>
          </div>
        )}
      {props.expandAutoCompleteBool && (
        <div
          ref={props.listWrapper}
          tabIndex="2"
          className={`${props.styles.flight_autocomplete_value} autosuggest_value`}
          id={`Flight_${props.label}_${props.id}`}
        >
          {!props.isMobile && props.text == "src" && (
            <div className={props.styles.flight_InputVal}>
              <span className={props.styles.searchAutoIcon}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14">
                  <defs>
                    <filter id="6waepprpsa">
                      <feFlood
                        flood-color="#979797"
                        flood-opacity="1"
                        result="floodOut"
                      ></feFlood>
                      <feComposite
                        operator="atop"
                        in="floodOut"
                        in2="SourceGraphic"
                        result="compOut"
                      ></feComposite>
                      <feBlend in="compOut" in2="SourceGraphic"></feBlend>
                    </filter>
                  </defs>
                  <g filter="url(#6waepprpsa)">
                    <path
                      fill-rule="evenodd"
                      fill="#97144D"
                      d="M10.928 9.513A6.022 6.022 0 0 0 1.116 2.53C-.812 5.24-.179 8.1 2.531 10.928a6.021 6.021 0 0 0 6.982 0l2.83 2.83a1.004 1.004 0 0 0 1.415-1.415l-2.83-2.83zM2.006 6.03a4.015 4.015 0 1 1 6.855 2.841A4.015 4.015 0 0 1 2.006 6.03z"
                    ></path>
                  </g>
                </svg>
              </span>
              <input
                type="text"
                autoComplete={"off"}
                id="myTextField"
                tabindex="1"
                placeholder={
                  !commonFunction.isValueEmpty(props.placholder)
                    ? props.placholder
                    : ""
                }
                className={props.styles.search_widget_input_highlight}
                value={
                  !commonFunction.isValueEmpty(props.value) ? props.value : ""
                }
                onChange={(event) => {
                  props.onChangeSourceAndDestination(
                    event.target.value.replace(/[^a-zA-Z0-9_&,\s-]/g, "")
                  );
                }}
                onClick={onClickHandler}
                onFocusCapture={onClickHandler}
              />
            </div>
          )}
          {!props.isMobile && props.text == "dest" && (
            <div className={props.styles.flight_InputVal}>
              <span className={props.styles.searchAutoIcon}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14">
                  <defs>
                    <filter id="6waepprpsa">
                      <feFlood
                        flood-color="#979797"
                        flood-opacity="1"
                        result="floodOut"
                      ></feFlood>
                      <feComposite
                        operator="atop"
                        in="floodOut"
                        in2="SourceGraphic"
                        result="compOut"
                      ></feComposite>
                      <feBlend in="compOut" in2="SourceGraphic"></feBlend>
                    </filter>
                  </defs>
                  <g filter="url(#6waepprpsa)">
                    <path
                      fill-rule="evenodd"
                      fill="#97144D"
                      d="M10.928 9.513A6.022 6.022 0 0 0 1.116 2.53C-.812 5.24-.179 8.1 2.531 10.928a6.021 6.021 0 0 0 6.982 0l2.83 2.83a1.004 1.004 0 0 0 1.415-1.415l-2.83-2.83zM2.006 6.03a4.015 4.015 0 1 1 6.855 2.841A4.015 4.015 0 0 1 2.006 6.03z"
                    ></path>
                  </g>
                </svg>
              </span>
              <input
                type="text"
                autoComplete={"off"}
                id="myTextField"
                tabindex="1"
                placeholder={
                  !commonFunction.isValueEmpty(props.placholder)
                    ? props.placholder
                    : ""
                }
                className={props.styles.search_widget_input_highlight}
                value={
                  !commonFunction.isValueEmpty(props.value) ? props.value : ""
                }
                onChange={(event) => {
                  props.onChangeSourceAndDestination(
                    event.target.value.replace(/[^a-zA-Z0-9_&,\s-]/g, "")
                  );
                }}
                onClick={onClickHandler}
                onFocusCapture={onClickHandler}
              />
            </div>
          )}

          {props?.isMobile ? (
            <div className={props.styles.headerAuto}>
              <span
                onClick={() => {
                  document.querySelector("body").style.overflow =
                    !props.expandAutoCompleteBool && props?.isMobile
                      ? "hidden"
                      : "auto";
                  props.popupClosed();
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="24">
                  <path
                    fill-rule="evenodd"
                    fill="#FFF"
                    d="m13.444 3.241-8.815 8.747 8.817 8.749c.75.744.75 1.95 0 2.694a1.93 1.93 0 0 1-2.715 0L.566 13.343a1.89 1.89 0 0 1-.528-.99 1.891 1.891 0 0 1 .525-1.718L10.729.546a1.932 1.932 0 0 1 2.715 0 1.897 1.897 0 0 1 0 2.695z"
                  />
                </svg>
              </span>
              <div className={props.styles.header_title}>{props?.Header}</div>
            </div>
          ) : null}

          {props?.isMobile && (
            <div className={props.styles.flight_InputVal}>
              <div className={props.styles.flight_main_box}>
                <span className={props.styles.search_Autoicon}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="14"
                  >
                    <defs>
                      <filter id="6waepprpsa">
                        <feFlood
                          flood-color="#979797"
                          flood-opacity="1"
                          result="floodOut"
                        ></feFlood>
                        <feComposite
                          operator="atop"
                          in="floodOut"
                          in2="SourceGraphic"
                          result="compOut"
                        ></feComposite>
                        <feBlend in="compOut" in2="SourceGraphic"></feBlend>
                      </filter>
                    </defs>
                    <g filter="url(#6waepprpsa)">
                      <path
                        fill-rule="evenodd"
                        fill="#97144D"
                        d="M10.928 9.513A6.022 6.022 0 0 0 1.116 2.53C-.812 5.24-.179 8.1 2.531 10.928a6.021 6.021 0 0 0 6.982 0l2.83 2.83a1.004 1.004 0 0 0 1.415-1.415l-2.83-2.83zM2.006 6.03a4.015 4.015 0 1 1 6.855 2.841A4.015 4.015 0 0 1 2.006 6.03z"
                      ></path>
                    </g>
                  </svg>
                </span>
                <input
                  type="text"
                  autoComplete={"off"}
                  id="myTextField"
                  tabindex="1"
                  placeholder={
                    !commonFunction.isValueEmpty(props.placholder)
                      ? props.placholder
                      : ""
                  }
                  className={props.styles.search_widget_input_new}
                  value={
                    !commonFunction.isValueEmpty(props.value) ? props.value : ""
                  }
                  onChange={(event) => {
                    props.onChangeSourceAndDestination(
                      event.target.value.replace(/[^a-zA-Z0-9_&,\s-]/g, "")
                    );
                  }}
                  onClick={onClickHandler}
                  onFocusCapture={onClickHandler}
                  autoFocus
                />
              </div>
            </div>
          )}
          <div className={props.styles.suggestion_main_container}>
            <p className={props.styles.suggestion_label}>Suggestions</p>
            {!commonFunction.isValueEmpty(props.autoCompleteData) &&
            props.autoCompleteData.length > 0 ? (
              props.autoCompleteData.map((element, i) => {
                return (
                  <div
                    id="flightData"
                    key={"autoCompleteData" + (i + 1)}
                    className={props.styles.flight_autocomplete_element}
                    onClick={() => {
                      selectListClickHandler(element);
                    }}
                    tabIndex="2"
                  >
                    <div className={props.styles.fltg_icon}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 17 22"
                        fill="none"
                      >
                        <path
                          d="M8.4691 0C3.80026 0 0 3.88692 0 8.66285V8.97548C0 11.1165 0.695502 13.1953 1.97673 14.8881L6.67603 21.0946C7.58969 22.3018 9.41305 22.3018 10.3267 21.0946L15.0233 14.8909C16.3045 13.1979 17 11.1162 17 8.97548V8.66285C17 3.88692 13.1997 0 8.53226 0H8.4691ZM8.4691 1.38583H8.53389C12.4716 1.38583 15.65 4.63524 15.65 8.66147V8.97409C15.65 10.8079 15.0537 12.5904 13.9562 14.0397L9.25559 20.2434C8.86287 20.7617 8.13961 20.7617 7.74692 20.2434L3.04898 14.0369C1.95152 12.5874 1.35519 10.8078 1.35519 8.97409V8.66147C1.35519 4.63388 4.53134 1.38445 8.4691 1.38583ZM8.50083 5.33235C6.51218 5.33235 4.88437 6.99698 4.88437 9.03252C4.88437 11.0681 6.51073 12.7341 8.50083 12.7327C10.4895 12.7327 12.1186 11.0681 12.1186 9.03252C12.1186 6.99698 10.4896 5.33096 8.50083 5.33235ZM8.50083 6.71818C9.75826 6.71818 10.7645 7.74675 10.7645 9.03377C10.7645 10.3194 9.75826 11.348 8.50083 11.348C7.2434 11.348 6.2398 10.3194 6.2398 9.03377C6.2398 7.7481 7.24336 6.71818 8.50083 6.71818Z"
                          fill="black"
                        ></path>
                      </svg>
                    </div>
                    <div className={props.styles.flight_autocomplete_name}>
                      {element.name}
                    </div>
                  </div>
                );
              })
            ) : (
              <div class={props.styles.no_result}>
                <p className={props.styles.no_resMsg}>No Result Found</p>
                {/* {props.isMobile && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30px"
                      height="22px"
                    >
                      <path
                        fill-rule="evenodd"
                        stroke-width="1px"
                        stroke="rgb(0, 0, 0)"
                        fill="rgb(255, 255, 255)"
                        d="M23.029,3.739 L17.573,6.184 L12.172,4.850 C11.930,4.794 11.688,4.822 11.446,4.933 L10.183,5.572 C9.807,5.767 9.565,6.156 9.592,6.573 C9.592,7.018 9.834,7.379 10.210,7.573 L12.790,8.796 L8.678,10.963 L5.265,9.741 C4.942,9.630 4.566,9.657 4.271,9.796 C3.867,9.991 3.572,10.408 3.518,10.853 C3.464,11.325 3.626,11.769 3.975,12.075 L7.307,14.965 C7.603,15.216 8.006,15.298 8.382,15.160 L14.832,12.714 L13.650,16.688 C13.489,17.216 13.650,17.800 14.053,18.161 C14.295,18.383 14.617,18.494 14.913,18.494 C15.101,18.494 15.316,18.438 15.504,18.356 L16.552,17.828 C16.821,17.689 17.036,17.466 17.170,17.188 L20.180,10.658 L25.125,8.268 C25.717,7.962 26.147,7.461 26.361,6.823 C26.576,6.184 26.523,5.517 26.254,4.906 C25.663,3.655 24.211,3.155 23.029,3.739 Z"
                      />
                    </svg>
                  )} */}
              </div>
            )}
            {props.recentData.length && props.recentData ? (
              <>
                <p className={props.styles.suggestion_label}>Recent Search</p>
                {props.recentData.map((element, i) => {
                  if (element != "") {
                    return (
                      <div
                        id="flightData"
                        key={"autoCompleteData" + (i + 1)}
                        className={props.styles.flight_autocomplete_element}
                        onClick={() => {
                          selectListClickHandler(element);
                        }}
                        tabIndex="2"
                      >
                        <div className={props.styles.fltg_icon}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19"
                            height="18"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M9.969 3.402V8.83c0 .124.05.243.138.33l3.863 3.836a.465.465 0 0 1-.33.795.466.466 0 0 1-.333-.137L9.445 9.818a1.393 1.393 0 0 1-.412-.988V3.402c0-.256.21-.464.468-.464.259 0 .468.208.468.464zm.747 14.331a8.652 8.652 0 0 1-4.864-.716.463.463 0 0 1-.2-.626.468.468 0 0 1 .63-.2 7.749 7.749 0 0 0 8.368-1.303 7.62 7.62 0 0 0 .525-10.816C12.314.941 7.439.708 4.287 3.55a7.622 7.622 0 0 0-.526 10.816v-1.009c0-.257.21-.465.469-.465.258 0 .468.208.468.465v2.092c0 .258-.21.466-.468.466H2.123a.466.466 0 1 1 0-.931h.953a8.582 8.582 0 0 1-2.15-4.567C.26 5.712 3.559 1.361 8.296.699c4.735-.66 9.112 2.616 9.779 7.319.665 4.701-2.628 9.048-7.359 9.715z"
                            />
                          </svg>
                        </div>
                        <div className={props.styles.flight_autocomplete_name}>
                          {element.name}
                        </div>
                      </div>
                    );
                  }
                
                })}
              </>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
}
