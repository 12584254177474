import React, { useEffect, useState } from "react";

import DesktopCalender from "../../../calendar/DesktopCalender";
import FlightAutoComplete from "./FlightAutoComplete";
import MulticityForm from './MulticityForm'
import PaxConfig from "./PaxConfig";
import cf from "../../../../lib/utils/common";

export default function Multicity(props) {
  return (
    <>
      <div className={props.styles.container_widget} >
        {/* SELECTION BOXES SECTION  */}
        <div className={props.styles.selectors_boxes}>
          <div className={props.styles.flight_search_details_container}>
            <div className={props.styles.flight_search_details_main}>
              <div className={props.styles.flight_search_details_row_multicity}>
                <div className={props.styles.conditional_comp}>
                  {props?.state?.multicityFlightData.map((data, i) => {
                    // console.log(i,">>>>");
                    return (
                      <div className={props.styles.returns_div}>
                        <MulticityForm props={props}
                          data={data}
                          index={i} />
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className={props.styles.add_rem_btns}>
              {props?.state?.multicityFlightData.length <= 5 &&
                <div class={props.styles.search_button_section}>
                  <button
                    onClick={props.AddFlightButton}
                  >
                    {/* Add More flight(4 Max) + */}
                    Add a City
                  </button>

                </div>
              }
                  {/* <div class={props.styles.search_button_section}>
                  <button>
                  Remove
                  </button>

                </div> */}
              </div>
              <div
                className={
                  props.styles.flight_search_column_multicity_trav +
                  " " +
                  props.styles.flight_search_classMulticity
                }
              >

                <svg width="15px" height="15px" viewBox="0 0 200 200" >
                  <g>
                    <path fill="#30C8CA" d="M100,188.716c-48.918,0-88.716-39.798-88.716-88.716S51.082,11.284,100,11.284S188.716,51.082,188.716,100   S148.918,188.716,100,188.716z M45.883,162.507c14.516,12.586,33.442,20.209,54.117,20.209c20.675,0,39.602-7.624,54.118-20.209   c-1.134-27.888-38.089-30.935-54.118-30.935S47.016,134.62,45.883,162.507z M100,17.284c-45.61,0-82.716,37.106-82.716,82.716   c0,22.237,8.82,42.452,23.146,57.331c1.983-10.823,9.079-19.279,20.815-24.682c12.708-5.85,28.09-7.077,38.755-7.077   c10.666,0,26.048,1.228,38.756,7.077c11.736,5.402,18.832,13.858,20.814,24.682c14.326-14.88,23.146-35.094,23.146-57.331   C182.716,54.39,145.609,17.284,100,17.284z M100,103c-17.409,0-31.572-14.163-31.572-31.572c0-17.409,14.163-31.572,31.572-31.572   c17.409,0,31.572,14.163,31.572,31.572C131.572,88.837,117.409,103,100,103z M100,45.856c-14.1,0-25.572,11.472-25.572,25.572   C74.428,85.528,85.9,97,100,97c14.101,0,25.572-11.472,25.572-25.572C125.572,57.328,114.101,45.856,100,45.856z" />
                  </g>
                </svg>


                <div
                  className={props.styles.flight_cabin_class_cont}
                  onClick={() => props.onClickOpenPassangerList()}
                >
                  <div className={props.styles.flight_trav_title}> TRAVELLER & CLASS</div>
                  <div
                    className={props.styles.flight_cabin_class_name}
                  >
                    {`${props.state.paxAdultValue + props.state.paxChildrenValue + props.state.paxInfantValue} Passenger`}
                  </div>
                  <div className={props.styles.flt_trav_label}>{props.state.SelectedClassValue.label}</div>
                  <div
                    className={
                      props.styles.flight_cabin_class_arrow_pass
                    }
                  > </div>
                </div>
                {props.state.openPaxList === true ? (
                  <div
                    className={
                      props.styles.flighttraveller_drop
                    }
                    ref={props.passengerDropdownWrapper}
                  >
                    <svg className={props.styles.arrow_pass} width="151" height="101" viewBox="0 0 151 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M75.5 1.3811L140.885 76.0953H10.1151L75.5 1.3811Z" fill="white" />
                      <line y1="-1.5" x2="100.715" y2="-1.5" transform="matrix(0.666347 -0.745642 0.939264 0.343196 9.5873 76.4784)" stroke="#2FC7C9" stroke-width="3" />
                      <path d="M76.0269 1.19861L140.214 76.0953" stroke="#2FC7C9" stroke-width="3" />
                    </svg>

                    {/* <div className={props.styles.flight_pax_title}>Traveler</div> */}
                    <div
                      className={
                        // this.props.styles.flight_search_column +
                        // " " +
                        props.styles.flighttraveller_drop_flex
                      }
                    >
                      <div className={props.styles.flighttraveller_drop_adult}>
                        <label>Adult(s)</label>
                        <span>(12 yrs & above)</span>
                      </div>
                      <PaxConfig
                        value={
                          !cf.isValueEmpty(props.state.paxAdultValue)
                            ? props.state.paxAdultValue
                            : 0
                        }
                        onChangePaxValue={(value, type) =>
                          props.onClickChangePaxNumber("adult", value, type)
                        }
                        maxLimit={9}
                        minLimit={1}
                        totalPax={
                          +props.state.paxChildrenValue +
                          +props.state.paxAdultValue
                        }
                        styles={props.styles}
                      />

                    </div>

                    <div
                      className={
                        props.styles.flighttraveller_drop_flex
                      }
                    >
                      <div className={props.styles.flighttraveller_drop_adult}>
                        <label>Children(s)</label>
                        <span>(2-12 yrs)</span>
                      </div>
                      <PaxConfig
                        value={
                          !cf.isValueEmpty(props.state.paxChildrenValue)
                            ? props.state.paxChildrenValue
                            : 0
                        }
                        onChangePaxValue={(value, type) =>
                          props.onClickChangePaxNumber(
                            "children",
                            value,
                            type
                          )
                        }
                        maxLimit={9}
                        minLimit={0}
                        totalPax={
                          +props.state.paxChildrenValue +
                          +props.state.paxAdultValue
                        }
                        styles={props.styles}
                      />
                    </div>

                    <div
                      className={
                        props.styles.flighttraveller_drop_flex
                      }
                    >
                      <div className={props.styles.flighttraveller_drop_adult}>
                        <label>Infant(s)</label>
                        <span>(below 2 yrs)</span>
                      </div>
                      <PaxConfig
                        value={
                          !cf.isValueEmpty(props.state.paxInfantValue)
                            ? props.state.paxInfantValue
                            : 0
                        }
                        onChangePaxValue={(value, type) =>
                          props.onClickChangePaxNumber("infant", value, type)
                        }
                        maxLimit={+props.state.paxAdultValue}
                        minLimit={0}
                        totalPax={props.state.paxInfantValue}
                        styles={props.styles}
                      />
                    </div>

                    <div className={`${props.styles.flight_pax_title} ${props.styles.cabin_class_sec}`}>Cabin Class</div>
                    {
                      <div
                        ref={props.economyPopUpWrapper}
                        className={props?.styles?.flight_cabin_class_list_them}
                      >


                        {props.cabinClass.map((element, index) => {
                          return (

                            <div className={props?.styles?.flight_cabin_bxd}>
                              <input type="radio"
                                className={props?.styles?.pax_input}
                                checked={element.label === props.state.SelectedClassValue.label}

                                onClick={() => {
                                  props.setState({
                                    SelectedClassValue: {
                                      label: element.label,
                                      id: element.id,
                                    },
                                    // openCabinClassListOne: false,
                                  }, () => {

                                  });
                                }}

                                id={element.label}
                                value={element.label}
                              />
                              <label  >{element.label} </label>

                            </div>
                          );
                        })}
                      </div>
                    }

                    <div className={props.styles.pax_btn_thme}>

                      <button
                        onClick={() => {
                          props.setState({
                            openPaxList: false,
                          });
                        }}>  Done</button>
                    </div>
                  </div>

                ) : null}
              </div>


              <div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}


