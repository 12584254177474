import React, { useEffect, useState } from 'react';
import commonFunction from '@/lib/utils/common';

export default function HotelAutoComplete(props) {
    const { theme } = props;

    return (
        <div className={props.styles.mob_hotel_search_wid_inputfield}>
            <label>{props.label}</label>
            <div className={props.styles.mob_hotel_search_wid_inputfield_main}>
                {/* <img src={props.leftIcon} className={props.styles.hotel_search_left_icon} /> */}
                <span className={props.styles.mob_hotel_search_left_icon}
                    onClick={() => { props.isAutoCompleteExpand(!props.expandAutoCompleteBool); props.onChangeHotelName(""); props.onClickHotelInput(); }}
                >
                    {/* <img src={props.leftIcon} className={props.styles.mob_hotel_search_left_icon} alt=""/> */}
                    <svg version="1.1" id="Layer_1" x="0px" y="0px" width="200px" height="200px" viewBox="0 0 200 200" enable-background="new 0 0 200 200"><g><path fill="currentColor" d="M101,184.511c-0.031,0-0.062,0-0.094-0.001c-1.113-0.026-2.164-0.515-2.902-1.349 c-1.537-1.736-3.586-4.031-6.147-6.885c-2.659-2.961-7.256-8.648-14.055-17.387c-6.738-8.66-12.719-17.192-17.779-25.359 c-5.106-8.243-9.814-17.68-13.991-28.049c-4.284-10.633-6.457-20.179-6.457-28.372c0-16.481,5.985-30.635,17.79-42.068 C69.39,23.395,84.07,17.489,101,17.489s31.546,5.842,43.442,17.365c11.932,11.556,17.982,25.772,17.982,42.255 c0,8.19-2.106,17.607-6.26,27.989c-4.062,10.15-9.045,19.803-14.812,28.688c-5.665,8.729-11.346,17.015-16.885,24.627 c-5.553,7.63-10.354,13.807-14.27,18.359c-0.053,0.062-0.107,0.121-0.164,0.18l-6.167,6.346 C103.114,184.074,102.079,184.511,101,184.511z M101,25.489c-14.988,0-27.441,5.004-38.069,15.298 C52.599,50.795,47.576,62.676,47.576,77.109c0,7.164,1.977,15.704,5.876,25.382c4.007,9.945,8.506,18.972,13.372,26.826 c4.91,7.927,10.729,16.224,17.292,24.66c6.589,8.469,11.196,14.174,13.693,16.955c1.222,1.361,2.329,2.597,3.319,3.706l3.084-3.174 c3.762-4.381,8.399-10.354,13.787-17.758c5.456-7.498,11.056-15.665,16.643-24.275c5.483-8.45,10.226-17.637,14.095-27.305 c3.774-9.433,5.688-17.85,5.688-25.018c0-14.434-5.086-26.376-15.549-36.508C128.378,30.432,115.988,25.489,101,25.489z"></path></g><g><path fill="currentColor" d="M101,91.748c-7.077,0-12.834-5.757-12.834-12.834S93.923,66.079,101,66.079s12.835,5.757,12.835,12.834 S108.077,91.748,101,91.748z M101,74.079c-2.666,0-4.834,2.169-4.834,4.834s2.169,4.834,4.834,4.834 c2.666,0,4.835-2.169,4.835-4.834S103.666,74.079,101,74.079z"></path></g></svg>

                    {/* {props.leftIcon} */}
                </span>
                <input type='text' autoComplete={'off'} id="myTextField"
                    placeholder={!commonFunction.isValueEmpty(props.placholder) ? props.placholder : ""}
                    className={props.styles.search_widget_input_new}
                    readOnly
                    value={!commonFunction.isValueEmpty(props.value) && !props.expandAutoCompleteBool ? props.value : ""}
                    // onChange={(event) => { props.onChangeHotelName(event.target.value) }}
                    onClick={() => { props.isAutoCompleteExpand(!props.expandAutoCompleteBool); props.onChangeHotelName(""); props.onClickHotelInput(); }}
                />




            <div className={props.styles.mob_div_section}>
                {!commonFunction.isValueEmpty(props.isError) && props.isError === true ? (
                    <div className={props.styles.mob_hotel_search_error_message}><span>{!commonFunction.isValueEmpty(props.errorMessage)  ? props.errorMessage : ''}</span></div>
                ) : null}
                {props.expandAutoCompleteBool === true ? (
                    <div className={props.styles.mob_hotel_autocomplete_value}>
                    <div className={props.styles?.input_search_destination}>
                                            <svg width="50px" height="50px" viewBox="0,0,256,256"><g fill="#3479c5" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none"><g transform="scale(5.12,5.12)"><path d="M21,3c-9.39844,0 -17,7.60156 -17,17c0,9.39844 7.60156,17 17,17c3.35547,0 6.46094,-0.98437 9.09375,-2.65625l12.28125,12.28125l4.25,-4.25l-12.125,-12.09375c2.17969,-2.85937 3.5,-6.40234 3.5,-10.28125c0,-9.39844 -7.60156,-17 -17,-17zM21,7c7.19922,0 13,5.80078 13,13c0,7.19922 -5.80078,13 -13,13c-7.19922,0 -13,-5.80078 -13,-13c0,-7.19922 5.80078,-13 13,-13z"></path></g></g></svg>  
                                            <input type='text' autoComplete={'off'} id="myTextField"
                    placeholder={!commonFunction.isValueEmpty(props.placholder) ? props.placholder : ""}
                    className={props.styles.search_widget_input_new}
                    autoFocus
                    value={!commonFunction.isValueEmpty(props.value) ? props.value : ""}
                    onChange={(event) => { props.onChangeHotelName(event.target.value) }}
                    onClick={() => { props.isAutoCompleteExpand(!props.expandAutoCompleteBool); props.onChangeHotelName(""); props.onClickHotelInput(); }}
                />
                                                </div>
                        {!commonFunction.isValueEmpty(props.autoCompleteData) && props.autoCompleteData.length > 0 &&
                            props.autoCompleteData.map(element => {
                                return (
                                    <div className={props.styles.mob_hotel_autocomplete_element} onClick={() => {
                                        props.onClickHotelName(element); props.isAutoCompleteExpand(false)
                                    }}>
                                        <img src={element.property_count === 0 ? "/images/hotels.svg" : "/images/dark-location.svg"} alt=""/>
                                        <div>
                                            <div className={props.styles.hotel_autocomplete_name}>{element.search_text}</div>
                                            {element.property_count === 0 ? "" : <div className={props.styles.hotel_autocomplete_tag}>{element.property_count} Properties</div>}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                ) : null
                }
                </div>
            </div>
        </div>
    )
}