import React, { Component } from 'react';
import { addDays, differenceInCalendarDays, format } from 'date-fns';

import FlightAutoComplete from './FlightAutoComplete';
import MobileCalender from '../../../calendar/flightCalender/theme3/MobileCalender';
import Multicity from './Multicity'
import PaxConfig from './PaxConfig';
import cf from "../../../../lib/utils/common";
import commonFunction from '../../../../lib/utils/common';

const cabinClass = [
    { id: 1, value: 'Economy', label: 'Economy' },
    { id: 2, value: 'Business', label: 'Business' },
    { id: 3, value: 'First Class', label: 'First Class' },
    { id: 4, value: 'Premium Economy', label: 'Premium Economy' }
];

const todaysDate = format(new Date(), 'dd-LLL-yyyy');
export default class Theme1 extends Component {
    constructor(props) {
        super(props);
        this.setState = this.setState.bind(this);
        this.state = {
            departureAirPortName:"",
            goingToAirPortName:"",
            isOnewaySearch: true,
            departureValue: "",
            goingToValue: "",
            expandDepartureBoolean: false,
            expandGoingToBoolean: false,
            departureDateValue: format(new Date(), 'dd-LLL-yyyy'),
            goingToDateValue: format(addDays(new Date(), 7), 'dd-LLL-yyyy'),
            expandDepartureDateBoolean: false,
            expandGoingToDateBoolean: false,
            paxAdultValue: 1,
            paxChildrenValue: 0,
            paxInfantValue: 0,
            SelectedClassValue: { label: "Economy", id: 1 },
            openCabinClassList: false,
            getDepartureSuggestionList: [],
            getGoingToSuggestionList: [],
            getDepartureAirportCode: "",
            getGoingToAirportCode: "",
            isError: { departure: false, goingTo: false, departureDate: false, goingToDate: false, },
            returnCity: "",
            departureCity: "",
            isMultiCitySearch:false,
            openPaxList:false,
            multicityFlightData:[ {
                org: "",
                dest: "",
                odt: cf.getDateWithFormat(
                  new Date(),
                  this?.props?.dateFormat
                ),
            },
            {
              org: "",
              dest: "",
              odt: cf.getDateWithFormat(
                new Date(),
                this?.props?.dateFormat
              ),
          },]
        }
    }
    componentDidMount() {
        this.setState({
            getDepartureSuggestionList: this.props.airports,
            getGoingToSuggestionList: this.props.airports,
            goingToValue: new URLSearchParams(window.location.search).get('to') && new URLSearchParams(window.location.search).get('toCity') ? new URLSearchParams(window.location.search).get('toCity') + " - (" + new URLSearchParams(window.location.search).get('to') + ")" : '',
            getGoingToAirportCode: new URLSearchParams(window.location.search).get('to') ? new URLSearchParams(window.location.search).get('to') : "",
            returnCity: new URLSearchParams(window.location.search).get('toCity') ? new URLSearchParams(window.location.search).get('toCity') : ''
        })
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    handleClickOutside = () => {
        if (this.state.openCabinClassList === true) this.setState({ openCabinClassList: false });
        if (this.state.expandDepartureBoolean === true) this.setState({ expandDepartureBoolean: false });
        if (this.state.expandGoingToBoolean === true) this.setState({ expandGoingToBoolean: false });
    }
    onClickOpenCabinClassList = () => {
        // document.getElementById("modal_container-popup").scrollIntoView({ behavior: 'smooth', block: 'start' })
        if (this.state.openCabinClassList === false) {
            this.setState({ openCabinClassList: true });
        } else if (this.state.openCabinClassList === true) {
            this.setState({ openCabinClassList: false });
        }
    }

    checkValidation = (item)=>{
      let isValid = true
      const { multicityFlightData = [] } = item
      multicityFlightData.map((data,index)=>{
      let errorObj = {}
      if ( data['org'] === '') {
        isValid = false
        errorObj['org'] = 'Enter Origin City'
      }
      if ( data['dest'] === '') {
        isValid = false
        errorObj['dest'] = 'Enter Destination City'
      }
  
      if (data['org'] === data['dest'] &&  data['dest'] !== "" ) {
        isValid = false
        errorObj['dest'] = 'Origin and destination cannot be same'
        
      }
      data.error = errorObj
      })
      return {isValid, data: multicityFlightData }
    }

    onClickSearchFlight = () => {

      if (this.state.isMultiCitySearch) {
        let {isValid ,data} = this.checkValidation(this.state)
      
          this.setState((prevState) => {
            return {
            ...prevState,
            multicityFlightData : data,
            isValid
         
           
          }})
      }
        const mapDispatch = {
            departure: commonFunction.isValueEmpty(this.state.departureValue) ? true : false,
            goingTo: commonFunction.isValueEmpty(this.state.goingToValue) ? true : (this.state.departureValue === this.state.goingToValue) ? true : false,
            departureDate: commonFunction.isValueEmpty(this.state.departureDateValue) ? true : false,
            goingToDate: commonFunction.isValueEmpty(this.state.goingToDateValue) ? true : false,
        }
        this.setState({ isError: mapDispatch }, () => {
            let Validate = true;
            for (const [key, value] of Object.entries(this.state.isError)) {
                if (value === true) {
                    Validate = false;
                    break;
                }
            }

            let multicityData = this.state.multicityFlightData
            multicityData.forEach((element) => {
              element.odt = format(new Date(element.odt), "yyyy-MM-dd")
            });
            let multicity_data = multicityData
            let fromValue = this.state.departureValue.split("-");
            let toValue = this.state.goingToValue.split("-");
            let arrivalDate = ''
            let departureDateArr = !commonFunction.isValueEmpty(this.state.departureDateValue) ? this.state.departureDateValue : '';
            let departureDate = !commonFunction.isValueEmpty(departureDateArr) ? format(new Date(departureDateArr), 'yyyy-MM-dd') : '';
            if (this.state.isOnewaySearch === false) {
                let arrivalDateArr = !commonFunction.isValueEmpty(this.state.goingToDateValue) ? this.state.goingToDateValue : '';
                arrivalDate = !commonFunction.isValueEmpty(arrivalDateArr) ? format(new Date(arrivalDateArr), 'yyyy-MM-dd') : '';
            }
            let urlQuery = {
                from: this.state.getDepartureAirportCode, to: this.state.getGoingToAirportCode, type: this.state.isOnewaySearch ? 1 : this.state.isMultiCitySearch ? 3 :  2, class: this.state.SelectedClassValue.id, adults: this.state.paxAdultValue, child: this.state.paxChildrenValue, infants: this.state.paxInfantValue,
                departure: departureDate, arrival: arrivalDate , fromCity: this.state.departureCity, toCity: this.state.returnCity,departureAirportName: this.state.departureAirPortName,
                goingToAirPortName:this.state.goingToAirPortName, departure_time: this.state.dtym, departureValue: this.props.isOffline ? fromValue[0] : "",
                goingToValue: this.props.isOffline ? toValue[0] : "",  multicity: this.state.isMultiCitySearch ? JSON.stringify( multicity_data ) : [],
            }
                
     if (this.state.isValid) {
      cf.redirectTo("flight/search", urlQuery);
     } else {
      if (Validate === true) commonFunction.redirectTo("flight/search", urlQuery)
     }
          
        });
    }


    onchangeVisibility = (bool, type,index) => {
      // this.setDropdownWrapperRef.current.scrollIntoView({
      //   behavior: "smooth",
      //   block: "center",
      // });
      if (type === "goingTo") {
        this.setState(
          { expandGoingToDateBoolean: bool, expandDepartureDateBoolean: false },
          () => {
            if (this.state.isOnewaySearch === true) {
              this.setState({ isOnewaySearch: false });
            }
          }
        );
      } else {
        if (index !== undefined) {
        
          const multicityFlightData = JSON.parse(JSON.stringify(this.state.multicityFlightData))
          multicityFlightData[index]["expandDepartureDateBoolean"] = bool
          this.setState((prevState) => {
            return {
            ...prevState,
            multicityFlightData,
            expandGoingToDateBoolean: false,
          }})
       
        
        } else{
          this.setState({
            expandGoingToDateBoolean: false,
            expandDepartureDateBoolean: bool,
          });
        }
     
      }
    };

    onChangeSetDepartureGoingToValue = (value, type,index) => {
      if (type === "departure") {
        if (index !== undefined) {
          const multicityFlightData = JSON.parse(JSON.stringify(this.state.multicityFlightData))
          multicityFlightData[index]["org"]= value
          multicityFlightData[index]["departureValue"]= value
          this.setState((prevState)=>{
            return {
              ...prevState,
              multicityFlightData
            }
          })
          
        } else {
          this.setState(
            { departureValue: value, getDepartureAirportCode: "" },
            () => {
              if (this.state.isError.departure === true) {
                this.validateErrorMsg();
              }
              if (value.length === 0) {
                this.setState({ getDepartureSuggestionList: [] });
              }
            }
          );
        }
      
      } else {
        if (index !== undefined) {
          const multicityFlightData = JSON.parse(JSON.stringify(this.state.multicityFlightData))
          multicityFlightData[index]["dest"]= value
          multicityFlightData[index]["goingToValue"]= value
          this.setState((prevState)=>{
            return {
              ...prevState,
              multicityFlightData
            }
          })
          
        } else {
          this.setState({ goingToValue: value, getGoingToAirportCode: "" }, () => {
            if (this.state.isError.goingTo === true) {
              this.validateErrorMsg();
              if (value.length === 0)
                this.setState({ getGoingToSuggestionList: [] });
            }
          });
        }
     
      }
      if (type === "departure") {
        this.props.getAutoSuggestData(value, type, "change");
        this.setState({ expandDepartureBoolean: true });
      } else {
        this.props.getAutoSuggestData(value, type, "change");
        this.setState({ expandGoingToBoolean: true });
      }
    };
  
    onClickSetDepartureGoingToValue = (city, airPortCode,airPortName,type,index) => {
      console.log(type,"type");
      if (type === "departure") {
        if (this?.state?.isMultiCitySearch) {
          const multicityFlightData = JSON.parse(JSON.stringify(this.state.multicityFlightData))
          multicityFlightData[index]["org"] = airPortCode
          multicityFlightData[index]["orgCity"] = city
          multicityFlightData[index]["departureValue"] = city + " - (" + airPortCode + ")"
          multicityFlightData[index]["departureAirPortName"] = airPortName
            this.setState((prevState) => {
          return {
          ...prevState,
          multicityFlightData,
          selectedDepartureIndex: null,
        }
        }, () => {
          if (multicityFlightData.isValid === true) {
            this.checkValidation(this.state);
          }
        })
        } else {
          this.setState(
            {
              departureValue: city + " - (" + airPortCode + ")",
              departureAirPortName:airPortName,
              getDepartureAirportCode: airPortCode,
              departureCity: city,
            },
            () => {
              if (this.state.isError.departure === true) {
                this.validateErrorMsg();
              }
            }
          );
        }
      
      } else {
       
          if (this?.state?.isMultiCitySearch) {
            const multicityFlightData = JSON.parse(JSON.stringify(this.state.multicityFlightData))
            multicityFlightData[index]["dest"] = airPortCode
            multicityFlightData[index + 1] ?  multicityFlightData[index + 1]["org"]= airPortCode : "",
            multicityFlightData[index]["destCity"] = city
            multicityFlightData[index + 1] ?  multicityFlightData[index + 1]["orgCity"]= city : "",
            multicityFlightData[index]["goingToValue"] = city + " - (" + airPortCode + ")",
            multicityFlightData[index + 1] ?  multicityFlightData[index + 1]["departureValue"]= city + " - (" + airPortCode + ")" : "",
            multicityFlightData[index]["goingToAirPortName"] = airPortName
            multicityFlightData[index + 1] ?  multicityFlightData[index + 1]["departureAirPortName"]= airPortName : "",
            this.setState((prevState) => {
              return {
                ...prevState,
                multicityFlightData,
                selectedGoingToIndex: null,
              }
            })
            
          }
         else {
          this.setState(
            {
              goingToValue: city + " - (" + airPortCode + ")",
              getGoingToAirportCode: airPortCode,
              goingToAirPortName:airPortName,
              returnCity: city,
            },
            () => {
              if (this.state.isError.goingTo === true) {
                this.validateErrorMsg();
              }
            }
          );
        }
       
      }
    };
   
    onclickFlightAutoComplete(type,key,i) {
        if (type === "departure") {
          if (i !== undefined && key !== undefined) {
            const multicityFlightData = JSON.parse(JSON.stringify(this.state.multicityFlightData))
            multicityFlightData[i][key] = true
            multicityFlightData[i]["org"] = ""
            multicityFlightData[i]["orgCity"] = ""
            this.setState((prevState) => {
              return {
                ...prevState,
                multicityFlightData,
                selectedDepartureIndex: i,
                selectedGoingToIndex:null
              }
            })
            
          } 
          this.setState({ departureValue: "",departureAirPortName:"" }, () => {
            this.props?.getAutoSuggestData(this.state.departureValue, type, "click");
          });
        } else {
          if (i !== undefined && key !== undefined) {
            const multicityFlightData = JSON.parse(JSON.stringify(this.state.multicityFlightData))
            multicityFlightData[i][key] = true
            multicityFlightData[i]["dest"] = ""
            multicityFlightData[i]["destCity"] = ""

            this.setState((prevState) => {
              return {
                ...prevState,
                multicityFlightData,
                selectedGoingToIndex: i,
                selectedDepartureIndex:null
              }
            })
            
          } 
          this.setState({ goingToValue: "",goingToAirPortName:"" }, () => {
            this.props?.getAutoSuggestData(this.state.goingToValue, type, "click");
          });
        }
   
    }
    onClickChangePaxNumber = (pax, value, type) => {
        if (pax === "adult") {
            console.log("mob_flight_search_wid_container111", type)
            if ((this.state.paxChildrenValue + value) <= 9 && (this.state.paxChildrenValue + value) > 0) {
                this.setState({ paxAdultValue: value }, () => {
                    if (type === "minus") {
                        if (this.state.paxInfantValue > value) {
                            this.setState({ paxInfantValue: --this.state.paxInfantValue });
                        }
                    }
                });
            }
        } else if (pax === "children") {
            if ((value + this.state.paxAdultValue) <= 9 && (value + this.state.paxAdultValue) > 0) {
                this.setState({ paxChildrenValue: value });
            }
        } else if (pax === "infant") {
            console.log("flight_search_wid_main6666", value)
            if (value > this.state.paxAdultValue) {
                this.setState({ paxInfantValue: --value });
            } else {
                this.setState({ paxInfantValue: value });
            }
        }
    }
    validateErrorMsg = () => {
        const mapDispatch = {
            departure: commonFunction.isValueEmpty(this.state.departureValue) ? true : false,
            goingTo: commonFunction.isValueEmpty(this.state.goingToValue) ? true : false,
            departureDate: commonFunction.isValueEmpty(this.state.departureDateValue) ? true : false,
            goingToDate: commonFunction.isValueEmpty(this.state.goingToDateValue) ? true : false,
        }
        this.setState({ isError: mapDispatch });
    }
    onClickSwap = () => {
        if (this.state.departureValue || this.state.goingToValue) {
            let departureValue = this.state.departureValue
            let departureAirportCode = this.state.getDepartureAirportCode
            let departureCity = this.state.departureCity
            let departureAirPortName = this.state.departureAirPortName
            this.setState({
                departureValue: this.state.goingToValue, goingToValue: departureValue,
                getDepartureAirportCode: this.state.getGoingToAirportCode, getGoingToAirportCode: departureAirportCode,
                departureCity: this.state.returnCity, returnCity: departureCity,
                departureAirPortName:this.state.goingToAirPortName,
                goingToAirPortName:departureAirPortName
            });
        }
    }

    onChangeDepartureGoingDateValue = (value, type,index) => {
      console.log(value, type,index,"LLLLLLLLLL");
      if (type === "goingTo") {
        let dayDifference = differenceInCalendarDays(
          new Date(value),
          new Date(this.state.departureDateValue)
        );
        if (dayDifference < 0) {
          this.setState({
            isOnewaySearch: true,
            goingToDateValue: cf.getDateWithFormat(
              addDays(new Date(), 7),
              this?.props?.dateFormat
            ),
          });
        } else {
          this.setState({
            goingToDateValue: cf.getDateWithFormat(
              value,
              this?.props?.dateFormat
            ),
          });
        }
      } else {
        if (index !== undefined) {
          const multicityFlightData = JSON.parse(JSON.stringify(this.state.multicityFlightData))
          
          let goingToDateValue = this.state.goingToDateValue;
         
          let dayDifference = differenceInCalendarDays(
            new Date(this.state.goingToDateValue),
            new Date(value)
          );
          if (dayDifference < 0) {
            goingToDateValue = cf.getDateWithFormat(
              addDays(new Date(value), 7),
              this?.props?.dateFormat
            );
          }
           multicityFlightData[index]["odt"] =  cf.getDateWithFormat(value,this?.props?.dateFormat)
           multicityFlightData[index]["expandDepartureDateBoolean"] = false
          setTimeout(() => {
             this.setState((prevState) => {
              return {
              ...prevState,
              multicityFlightData,
              goingToDateValue
            }})
          }, 10);
        } else {
          let goingToDateValue = this.state.goingToDateValue;
          let dayDifference = differenceInCalendarDays(
            new Date(this.state.goingToDateValue),
            new Date(value)
          );
          if (dayDifference < 0) {
            goingToDateValue = cf.getDateWithFormat(
              addDays(new Date(value), 7),
              this?.props?.dateFormat
            );
          }
          this.setState({
            departureDateValue: cf.getDateWithFormat(
              value,
              this?.props?.dateFormat
            ),
            goingToDateValue,
          });
        }
       
      }
    };

    multicityTab = () => {
        this.setState({ 
          isOnewaySearch: false,
        isMultiCitySearch:true,
        });
      };

      onClickOpenPassangerList=()=>{
        if (this.state.openPaxList === false) {
          this.setState({ openPaxList: true });
        } else if (this.state.openPaxList === true) {
          this.setState({ openPaxList: false });
        }
      }

      AddFlightButton = ()=>{
    
        const multicityFlightData = JSON.parse(JSON.stringify(this.state.multicityFlightData))
        let index = multicityFlightData.length - 1
        let org = multicityFlightData[index].dest
        let departureValue = multicityFlightData[index].goingToValue
          this.setState(prevState => ({
            multicityFlightData: [...prevState.multicityFlightData,
              {
                org: org ? org : "" ,
                dest: "",
                departureValue:departureValue ? departureValue : "",
                odt: cf.getDateWithFormat(
                  new Date(),
                  this?.props?.dateFormat
                ),
              }
            ]
            }))
      }
    
      RemoveFlightObject = (index)=>{
        const items = this.state.multicityFlightData.filter((item,i) => i !== index);
        this.setState((prevState) => {
          return {
            ...prevState,
            multicityFlightData: items
          };
        });
    
    
      }

    render() {
        // console.log("styles.mob_flight_search_wid_container", this.state)
        return (
            <div className={this.props.styles.mob_flight_search_wid_container}>
                <div className={this.props.styles.mob_flight_search_wid_main}>
                    <div id={this.props.styles.tab_submenu_content}>
                        <div className={this.props.styles.landing_tab_submenu_new + " " + this.props.styles.sub_tab_new}>
                            <div className={this.props.styles.mobile_visible}>
                                <div className={this.props.styles.mobo_tab_header + " " + this.props.styles.flight_header_mobo}>
                                    {/* <img src="/images/back-arrow.png" alt="" className={this.props.styles.arrow_image} onClick={() => this.props.onClosePopup(false)} /> */}
                                    <span className={this.props.styles.arrow_image} onClick={() => this.props.onClosePopup(false)}>
                                        {/* <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                                        </svg> */}
                                        <svg version="1.1" id="Layer_1" x="0px" y="0px" width="200px" height="200px" viewBox="0 0 200 200" stroke="currentColor">
                                            <g>
                                              <g>
                                                <path fill="currentColor" d="M94.277,181.169c-3.199,0-6.398-1.22-8.839-3.661L16.77,108.839c-4.881-4.882-4.881-12.796,0-17.678    l68.668-68.668c4.882-4.882,12.797-4.882,17.678,0c4.882,4.881,4.882,12.796,0,17.678L43.287,100l59.83,59.83    c4.882,4.882,4.882,12.796,0,17.678C100.676,179.948,97.476,181.169,94.277,181.169z"></path>
                                              </g>
                                              <g>
                                                <path fill="currentColor" d="M174.391,112.5H35.146c-6.903,0-12.5-5.597-12.5-12.5s5.597-12.5,12.5-12.5h139.245    c6.903,0,12.5,5.597,12.5,12.5S181.294,112.5,174.391,112.5z"></path>
                                              </g>
                                            </g>
                                          </svg>
                                    </span>
                                    <h3 className={this.props.styles.search_filter_header}>Flights</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={this.props.styles.search_popup}>
                        <div className={this.props.styles.mob_flight_way_tab}>
                            <div className={this.props.styles.mob_flight_way_btngrp}>
                                <button className={ (this.state.isOnewaySearch &&  this?.state?.isMultiCitySearch === false) ? this.props.styles.active_way : this.props.styles.button_style} onClick={() => { this.setState({ isOnewaySearch: true,  isMultiCitySearch:false, expandGoingToDateBoolean: false, expandDepartureDateBoolean: false }) }}>One Way <svg width="12px" height="12px" viewBox="0 0 200 200"><polygon fill="#30C8CA" points="19.2,158.5 180.8,158.5 99.798,32.25 "></polygon></svg></button>
                                <button className={  (this.state.isOnewaySearch === false && this?.state?.isMultiCitySearch === false) ? this.props.styles.active_way : this.props.styles.button_style} onClick={() => { this.setState({ isOnewaySearch: false,isMultiCitySearch:false }) }}>Round-Trip <svg width="12px" height="12px" viewBox="0 0 200 200"><polygon fill="#30C8CA" points="19.2,158.5 180.8,158.5 99.798,32.25 "></polygon></svg></button>
                                <button className={ (this.state.isOnewaySearch === false && this?.state?.isMultiCitySearch === true) ? this.props.styles.active_way
                                    : this.props.styles.button_style } onClick={() => {this.multicityTab()}}>Multicity <svg width="12px" height="12px" viewBox="0 0 200 200"><polygon fill="#30C8CA" points="19.2,158.5 180.8,158.5 99.798,32.25 "></polygon></svg></button>
                            </div>
                        </div>
                        <div id="modal_container-popup" className={this.props.styles.mob_flight_search_details_container}>
                        {this.state.isMultiCitySearch   ? 
                          <>
             
                          <Multicity styles={this.props.styles} 
                          cabinClass={cabinClass}
                          state={this.state}
                          onClickSetDepartureGoingToValue={this.onClickSetDepartureGoingToValue}
                          onclickFlightAutoComplete={this.onclickFlightAutoComplete}
                          setDropdownWrapperRef={this.setDropdownWrapperRef}
                          AddFlightButton={this.AddFlightButton}
                          onChangeSetDepartureGoingToValue={this.onChangeSetDepartureGoingToValue}
                          departureListWrapper={this.departureListWrapper}
                          onKeyDownArrow={this.onKeyDownArrow}
                          getDepartureSuggestionList={this.props.getDepartureSuggestionList}
                          setState={this.setState}
                          onSwapKeyDownHandler={this.onSwapKeyDownHandler}
                          onClickSwap={this.onClickSwap}
                          returnListWrapper={this.returnListWrapper}
                          getGoingToSuggestionList={this.props.getGoingToSuggestionList}
                          onChangeDepartureGoingDateValue={this.onChangeDepartureGoingDateValue}
                          onchangeVisibility={this.onchangeVisibility}
                          onClickChangePaxNumber={this.onClickChangePaxNumber}
                          onClickOpenCabinClassList={this.onClickOpenCabinClassList}
                          economyPopUpWrapper={this.economyPopUpWrapper}
                          passengerDropdownWrapper={this.passengerDropdownWrapper}
                          multicityReturnWrapper={this.multicityReturnWrapper}
                          multicityDepartureWrapper ={this. multicityDepartureWrapper}
                          selectedRbdClass = {this.props.selectedRbdClass}
                          RemoveFlightObject={this.RemoveFlightObject}
                          onChangeClassValue={this.props.onChangeClassValue}
                          SelectedClassValue={this.props.SelectedClassValue}
                          onClickOpenPassangerList={this.onClickOpenPassangerList}
                          todaysDate={todaysDate}
              
                          />
                         
                          </>
                         :
                            <div className={this.props.styles.mob_flight_search_details_main}>
                                <div className={this.props.styles.mob_flight_search_details_row}>
                                    <div className={this.props.styles.mob_flight_search_column + " " + this.props.styles.mob_flight_search_depart}>
                                        <FlightAutoComplete
                                            label="From"
                                            value={this.state.departureValue}
                                            onclickFlightAutoComplete={() => this.onclickFlightAutoComplete("departure")}
                                            styles={this.props.styles}
                                            state={this.state}
                                            placholder="City or Airport"
                                            onClickSourceAndDestination={(
                                                city,
                                                airPortCode,
                                                airPortName
                                              ) =>
                                                this.onClickSetDepartureGoingToValue(
                                                  city,
                                                  airPortCode,
                                                  airPortName,
                                                  "departure"
                                                )
                                              }
                                            onChangeSourceAndDestination={(value) => this.onChangeSetDepartureGoingToValue(value, "departure")}
                                            isAutoCompleteExpand={(bool) => this.setState({ expandDepartureBoolean: bool })}
                                            autoCompleteData={!commonFunction.isValueEmpty(this.props.getDepartureSuggestionList) ? this.props.getDepartureSuggestionList : []}
                                            expandAutoCompleteBool={!commonFunction.isValueEmpty(this.state.expandDepartureBoolean) ? this.state.expandDepartureBoolean : false}
                                            // leftIcon={'/images/flgt.png'}
                                            errorMessage={"Please enter origin city"}
                                            isError={this.state.isError.departure}
                                            airPortName={this.state.departureAirPortName}

                                        />
                                    </div>
                                    <div className={this.props.styles.mob_flight_swap_main} onClick={() => { this.onClickSwap() }}>
                                        <img src="/images/home-fgt.png" className={this.props.styles.mob_flight_swap} alt="flight arrow" value="Swap"></img>
                                    </div>
                                    <div className={this.props.styles.mob_flight_search_column + " " + this.props.styles.mob_flight_search_depart}>
                                        <FlightAutoComplete
                                            label="To"
                                            value={this.state.goingToValue}
                                            onclickFlightAutoComplete={() => this.onclickFlightAutoComplete("goingTo")}
                                            styles={this.props.styles}
                                            state={this.state}
                                            placholder="City or Airport"
                                            onClickSourceAndDestination={(
                                                city,
                                                airPortCode,
                                                airPortName
                                              ) =>
                                                this.onClickSetDepartureGoingToValue(
                                                  city,
                                                  airPortCode,
                                                  airPortName,
                                                  "goingTo"
                                                )
                                              }
                                            onChangeSourceAndDestination={(value) => this.onChangeSetDepartureGoingToValue(value, "goingTo")}
                                            isAutoCompleteExpand={(bool) => this.setState({ expandGoingToBoolean: bool })}
                                            autoCompleteData={!commonFunction.isValueEmpty(this.props.getGoingToSuggestionList) ? this.props.getGoingToSuggestionList : []}
                                            expandAutoCompleteBool={!commonFunction.isValueEmpty(this.state.expandGoingToBoolean) ? this.state.expandGoingToBoolean : false}
                                            // leftIcon={'/images/dwn-flgt.png'}
                                            errorMessage={((this.state.departureValue.length && this.state.goingToValue.length) && (this.state.departureValue === this.state.goingToValue)) ? "Origin and destination cannot be same" : "Please enter destination city"}
                                            isError={this.state.isError.goingTo}
                                            airPortName={this.state.goingToAirPortName}                                          
                                        />
                                    </div>
                                    <div className={this.props.styles.mob_flight_search_column + " " + this.props.styles.mob_flight_search_depart_going_date}>
                                        <MobileCalender
                                            label="Departure"
                                            styles={this.props.styles}
                                            value={!commonFunction.isValueEmpty(this.state.departureDateValue) ? this.state.departureDateValue : ""}
                                            placholder="Departure Date"
                                            onChangeDate={(value) => { this.onChangeDepartureGoingDateValue(value, "departure") }}
                                            iconImage="/images/fgt-dt.png"
                                            startDate={!commonFunction.isValueEmpty(todaysDate) ? todaysDate: ""}
                                            endDate={!commonFunction.isValueEmpty(this.state.goingToDateValue) ? this.state.goingToDateValue : ""}
                                            startDateLimit={!commonFunction.isValueEmpty(todaysDate) ? todaysDate : ""}
                                            dateFormat={"DD-MMM-YYYY"}
                                            changeVisibility={(bool) => this.onchangeVisibility(bool, "departure")}
                                            isVisible={this.state.expandDepartureDateBoolean}
                                            type={"double"}
                                            doubleSelection={false}
                                            totalMonths={12}
                                        />
                                        <svg className={this.props.styles.modify_icons} xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" id="calendar-alt"><path fill="#30C8CA" d="M12,19a1,1,0,1,0-1-1A1,1,0,0,0,12,19Zm5,0a1,1,0,1,0-1-1A1,1,0,0,0,17,19Zm0-4a1,1,0,1,0-1-1A1,1,0,0,0,17,15Zm-5,0a1,1,0,1,0-1-1A1,1,0,0,0,12,15ZM19,3H18V2a1,1,0,0,0-2,0V3H8V2A1,1,0,0,0,6,2V3H5A3,3,0,0,0,2,6V20a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V6A3,3,0,0,0,19,3Zm1,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V11H20ZM20,9H4V6A1,1,0,0,1,5,5H6V6A1,1,0,0,0,8,6V5h8V6a1,1,0,0,0,2,0V5h1a1,1,0,0,1,1,1ZM7,15a1,1,0,1,0-1-1A1,1,0,0,0,7,15Zm0,4a1,1,0,1,0-1-1A1,1,0,0,0,7,19Z"></path></svg>
                                    </div>
                                    <div className={this.props.styles.mob_flight_search_column + " " + this.props.styles.mob_flight_search_depart_going_date}>
                                        <MobileCalender
                                            label="Return"
                                            styles={this.props.styles}
                                            value={ !commonFunction.isValueEmpty(this.state.goingToDateValue) ? this.state.goingToDateValue : ""}
                                            placholder="Arrival Date"
                                            onChangeDate={(value) => { this.onChangeDepartureGoingDateValue(value, "goingTo") }}
                                            iconImage="/images/fgt-dt.png"
                                            startDate={!commonFunction.isValueEmpty(todaysDate) ? todaysDate: ""}
                                            endDate={!commonFunction.isValueEmpty(this.state.goingToDateValue) ? this.state.goingToDateValue : ""}
                                            startDateLimit={this.state.departureDateValue ? this.state.departureDateValue : todaysDate ? todaysDate : ""}
                                            dateFormat={"DD-MMM-YYYY"}
                                            changeVisibility={(bool) => this.onchangeVisibility(bool, "goingTo")}
                                            isVisible={this.state.expandGoingToDateBoolean}
                                            type={"double"}
                                            doubleSelection={false}
                                            totalMonths={12}
                                            DisableReturnDate={this.state.isOnewaySearch}

                                        />
                                        <svg className={this.props.styles.modify_icons} xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" id="calendar-alt"><path fill="#30C8CA" d="M12,19a1,1,0,1,0-1-1A1,1,0,0,0,12,19Zm5,0a1,1,0,1,0-1-1A1,1,0,0,0,17,19Zm0-4a1,1,0,1,0-1-1A1,1,0,0,0,17,15Zm-5,0a1,1,0,1,0-1-1A1,1,0,0,0,12,15ZM19,3H18V2a1,1,0,0,0-2,0V3H8V2A1,1,0,0,0,6,2V3H5A3,3,0,0,0,2,6V20a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V6A3,3,0,0,0,19,3Zm1,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V11H20ZM20,9H4V6A1,1,0,0,1,5,5H6V6A1,1,0,0,0,8,6V5h8V6a1,1,0,0,0,2,0V5h1a1,1,0,0,1,1,1ZM7,15a1,1,0,1,0-1-1A1,1,0,0,0,7,15Zm0,4a1,1,0,1,0-1-1A1,1,0,0,0,7,19Z"></path></svg>
                                    </div>

                                    <div className={this.props.styles.mob_flight_search_column + " " + this.props.styles.mob_flight_search_pax}
                                     onClick={() => this.onClickOpenPassangerList()}
                                    >
                             <div className={this.props.styles.flight_trav_title}> TRAVELLER & CLASS</div>
                      <div
                        className={this.props.styles.flight_cabin_class_name}
                      >
                        {`${this.state.paxAdultValue+this.state.paxChildrenValue+this.state.paxInfantValue} Passenger`}
                      </div>
                      <div className={this.props.styles.flt_trav_label}>{this.state?.SelectedClassValue?.label}</div>
                      {/* <div
                        className={
                          this.props.styles.flight_cabin_class_arrow_pass
                        }
                      >
                      </div> */}
                        <div
                        className={
                          this.props.styles.flight_cabin_class_arrow_pass
                        }
                      >
                          <svg className={this.props.styles.modify_icons} version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 200 200">
                            <g id="User__x2F__User_x5F_Circle">
                              <g id="Vector">
                                <path fill="#30C8CA" d="M102,190.5c-18.541,0-35.737-5.862-49.833-15.833c-0.208-0.117-0.407-0.258-0.592-0.423    C29.741,158.526,15.5,132.896,15.5,104c0-47.696,38.803-86.5,86.5-86.5c47.696,0,86.5,38.804,86.5,86.5    c0,28.901-14.247,54.538-36.089,70.254c-0.178,0.158-0.368,0.292-0.566,0.405C137.747,184.632,120.544,190.5,102,190.5z     M58.958,171.183C71.384,179.171,86.161,183.81,102,183.81s30.613-4.638,43.04-12.626c-11.435-11.255-26.912-17.642-43.04-17.642    C85.871,153.542,70.394,159.928,58.958,171.183z M102,146.852c18.293,0,35.831,7.407,48.59,20.422    c18.972-14.604,31.22-37.537,31.22-63.273c0-44.007-35.803-79.81-79.81-79.81S22.19,59.993,22.19,104    c0,25.736,12.247,48.668,31.218,63.272C66.168,154.258,83.707,146.852,102,146.852z M102,125.824    c-17.129,0-31.063-13.936-31.063-31.063c0-17.128,13.935-31.063,31.063-31.063s31.063,13.935,31.063,31.063    C133.063,111.889,119.129,125.824,102,125.824z M102,70.388c-13.439,0-24.373,10.934-24.373,24.373    c0,13.438,10.934,24.372,24.373,24.372c13.439,0,24.373-10.934,24.373-24.372C126.373,81.322,115.439,70.388,102,70.388z"/>
                              </g>
                            </g>
                          </svg>
                      </div>
                                    </div>
                                    {this.state.openPaxList === true ? (
                      <div
                        className={
                          this.props.styles.flighttraveller_drop
                        }
                        ref={this.passengerDropdownWrapper}
                      >
                        <svg className={this.props.styles.arrow_pass} width="151" height="101" viewBox="0 0 151 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M75.5 1.3811L140.885 76.0953H10.1151L75.5 1.3811Z" fill="white"/>
                        <line y1="-1.5" x2="100.715" y2="-1.5" transform="matrix(0.666347 -0.745642 0.939264 0.343196 9.5873 76.4784)" stroke="#2FC7C9" stroke-width="3"/>
                        <path d="M76.0269 1.19861L140.214 76.0953" stroke="#2FC7C9" stroke-width="3"/>
                        </svg>

                        {/* <div className={this.props.styles.flight_pax_title}>Traveler</div> */}
                        <div
                          className={
                            this.props.styles.flighttraveller_drop_flex
                          }
                        >
                          <div className={this.props.styles.flighttraveller_drop_adult}>
                          <label>Adult(s)</label>
                          <span>(12 yrs & above)</span>
                          </div>
                          <PaxConfig
                            value={
                              !cf.isValueEmpty(this.state.paxAdultValue)
                                ? this.state.paxAdultValue
                                : 0
                            }
                            onChangePaxValue={(value, type) =>
                              this.onClickChangePaxNumber("adult", value, type)
                            }
                            maxLimit={9}
                            minLimit={1}
                            totalPax={
                              +this.state.paxChildrenValue +
                              +this.state.paxAdultValue
                            }
                            styles={this.props.styles}
                          />
                          
                        </div>

                        <div
                          className={
                            this.props.styles.flighttraveller_drop_flex
                          }
                        >
                          <div className={this.props.styles.flighttraveller_drop_adult}>
                          <label>Children(s)</label>
                          <span>(2-12 yrs)</span>
                          </div>
                          <PaxConfig
                            value={
                              !cf.isValueEmpty(this.state.paxChildrenValue)
                                ? this.state.paxChildrenValue
                                : 0
                            }
                            onChangePaxValue={(value, type) =>
                              this.onClickChangePaxNumber(
                                "children",
                                value,
                                type
                              )
                            }
                            maxLimit={9}
                            minLimit={0}
                            totalPax={
                              +this.state.paxChildrenValue +
                              +this.state.paxAdultValue
                            }
                            styles={this.props.styles}
                          />
                        </div>

                        <div
                          className={
                            this.props.styles.flighttraveller_drop_flex
                          }
                        >
                          <div className={this.props.styles.flighttraveller_drop_adult}>
                          <label>Infant(s)</label>
                          <span>(below 2 yrs)</span>
                          </div>
                          <PaxConfig
                            value={
                              !cf.isValueEmpty(this.state.paxInfantValue)
                                ? this.state.paxInfantValue
                                : 0
                            }
                            onChangePaxValue={(value, type) =>
                              this.onClickChangePaxNumber("infant", value, type)
                            }
                            maxLimit={+this.state.paxAdultValue}
                            minLimit={0}
                            totalPax={this.state.paxInfantValue}
                            styles={this.props.styles}
                          />
                        </div>
    
                          <div className={`${this.props?.styles?.flight_pax_title} ${this.props?.styles?.cabin_class_sec}`}>Cabin Class</div>
                        {
                        <div 
                          ref={this.economyPopUpWrapper}
                       className={this.props?.styles?.flight_cabin_class_list_them}
                        >


                          {cabinClass.map((element, index) => {
                            return (
                            
                            <div className={this.props?.styles?.flight_cabin_bxd}> 
                            <input type="radio" 
                            className={this.props?.styles?.pax_input}
                               checked={element.label === this.state.SelectedClassValue.label}

                               onClick={() => {
                                this.setState({
                                  SelectedClassValue: {
                                    label: element.label,
                                    id: element.id,
                                  },
                                  // openCabinClassListOne: false,
                                },()=>{
                                  console.log("SelectedClassValue",this.state.SelectedClassValue.label)
                                });
                              }} 
                              
                              id={element.label} 
                             value={element.label} 
                           />
                            <label  >{element.label} </label>
                          
                            </div>
                            );
                          })}
                        </div>
                      }
                        
                        <div className={this.props.styles.pax_btn_thme}>

                      <button    
                        onClick={() => {
                          this.setState({
                            openPaxList: false,
                          });
                        }}>  Done</button>
                    </div>
                      </div>
                      
                    ) : null}
                                </div>
                            </div>
    }
                                     <div className={this.props.styles.mob_flight_search_details_main}>
                                <div className={this.props.styles.mob_flight_search_details_row}>
                                   <div className={this.props.styles.btn_search}>
                                        <button className={this.props.styles.mob_flight_search_widget_btn}
                                            onClick={() => this.onClickSearchFlight()}>Search Flights 
                                            <svg version="1.1" id="Layer_1" x="0px" y="0px" width="200px" height="200px" viewBox="0 0 200 200" enableBackground="new 0 0 200 200">
                                                <g>
                                                  <path fill="#FFFFFF" d="M174.948,108.5H19.5c-4.142,0-7.5-3.357-7.5-7.5c0-4.142,3.358-7.5,7.5-7.5h155.448   c4.143,0,7.5,3.358,7.5,7.5C182.448,105.143,179.091,108.5,174.948,108.5z"></path>
                                                </g>
                                                <g>
                                                  <path fill="#FFFFFF" d="M130.534,158.466c-1.919,0-3.839-0.732-5.304-2.196c-2.929-2.93-2.929-7.678,0-10.607L169.894,101   L125.23,56.338c-2.929-2.929-2.929-7.678,0-10.606c2.93-2.929,7.678-2.929,10.607,0l49.966,49.965   C187.21,97.103,188,99.011,188,101s-0.79,3.896-2.196,5.304l-49.966,49.966C134.373,157.733,132.453,158.466,130.534,158.466z"></path>
                                                </g>
                                            </svg>
                                       </button>
                                    </div>
                                    </div>
                                    </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
