import 'react-multi-carousel/lib/styles.css'
import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  SearchActivityHomeWidget,
  SearchHotelHomeWidget,
  autoSuggestHomeWidget,
} from '@/lib/searchHomeWidget'
import ActivityMobileSearchWidget from '../../../../../common/components/widgets/activityWidgetMobile'
import ActivitySearchMobileThemeOneCSS from '../../../../../common/components/widgets/activityWidgetMobile/theme1/index.module.css'
import AuthContext from 'store/auth-context'
import Carousel from 'react-multi-carousel'
import CustomLink from '@/components/Link'
import DesktopCalenderThemeOneCss from '../../../../../common/components/widgets/flightSearchWidget/theme1/index.module.css'
import DesktopHotelStylesTheme1 from '../../../../../common/components/widgets/hotelSearchWidget/theme1/index.module.css'
import ExperienceMobileSearchWidget from '../../../../../common/components/widgets/experienceWidgetMobile'
import FlightMobileSearchWidget from '../../../../../common/components/widgets/flightMobileSearchWidget'
import FlightSearchMobileThemeOneCSS from '../../../../../common/components/widgets/flightMobileSearchWidget/theme1/index.module.css'
import FlightSearchThemeOneCSS from '../../../../../common/components/widgets/flightSearchWidget/theme1/index.module.css'
import FlightSearchWidget from '../../../../../common/components/widgets/flightSearchWidget'
import HotelMobileSearchWidget from '../../../../../common/components/widgets/hotelMobileSearchWidget'
import HotelSearchMobileThemeOneCSS from '../../../../../common/components/widgets/hotelMobileSearchWidget/theme1/index.module.css'
import HotelSearchWidget from '../../../../../common/components/widgets/hotelSearchWidget'
import SearchWidgetActivity from '../../../../../common/components/widgets/activityWidget/index'
import cssfile from '../../../../../common/components/widgets/activityWidget/theme1/index.module.css'
import SearchWidgetExperience from '../../../../../common/components/widgets/experienceWidget'
import Image from 'next/image'
import ModalPopupComponent from '../../../../../common/components/ModalPopupComponent/ModalPopupComponent'
import Multiselect from 'multiselect-react-dropdown'
import b2bWidgetStyle from '../../../../../common/components/b2bwidgets/theme1/index.module.css'
import cf from '@/lib/utils/common'
import configUrl from '../../../../../config/config.json'
import { getAirlines } from '../../../../../common/lib/getAirlines'
import { getSupplierList } from '../../../../../common/lib/getSupplierList'
import modalCss from '../../../../../common/components/ModalPopupComponent/index.module.css'
import parse from 'html-react-parser'
import swPopupCss from '../../../../../common/components/widgets/commonCss/swPopupCss.module.css'
import { useRouter } from 'next/router'
import Countrystyles from '../../../../../common/components/CountryDropdown/theme4/index.module.css'
import getFareType from '../../../../../common/lib/getFareType'
import MultiSuppilerDropDownoption from '../../../../../common/components/multiDropdown/theme1'
import styleDerpt from '../../../../../common/components/multiDropdown/theme1/index.module.css'
import styles from './index.module.css'

const availableSearchTypes = [
  'flight_search',
  'hotel_search',
  'activity_search',
  'experience_search',
  'holiday_search',
  'others_search',
]
const times = ['Before 6 AM', '6 AM - 12 PM', '12 PM - 6 PM', 'After 6 PM']
const rbdClass = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
]
export default function Home(props) {
  const [isFlightSearchWidgetPopup, setFlightSearchWidgetPopup] = useState(false)
  const [initFlightData, setInitFlightData] = useState([])
  const [getDepartureSuggestionList, setDepartureSuggestionList] = useState([])
  const [getGoingToSuggestionList, setGoingToSuggestionList] = useState([])
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const wrapperRef = useRef(null)
  const [isHotelSearchWidgetPopup, setHotelSearchWidgetPopup] = useState(false)
  const [expandHotelNameBoolean, setExpandHotelName] = useState(false)
  const [expandActivityName, setExpandActivityName] = useState(false)
  const [getHotelAutoSuggestList, setHotelAutoSuggestList] = useState([])
  const [getActivityAutoSuggestList, setActivityAutoSuggestList] = useState([])
  const [menuData, setMenuData] = useState('')
  const [initHotelData, setInitHotelData] = useState([])
  const [isPopup, setPopup] = useState(false)
  const [homeBodyDesktop, setHomeBodyDesktop] = useState([])
  const [homeBodyMobile, setHomeBodyMobile] = useState([])
  const [userProfile, setUserProfile] = useState('')
  const [airlineList, setAirlineList] = useState([])
  const [supplierList, setSupplierList] = useState([])
  const [selectedAirlines, setSelectedAirlines] = useState([])
  const [selectedSupplier, setSelectedSupplier] = useState([])
  const [SelectFareType, setSelectFareType] = useState([])
  const [selectedDepartureTime, setSelectedDepartureTime] = useState([])
  const [selectedRbdClass, setSelectedRbdClass] = useState([])
  const [SelectedClassValue, setSelectedClassValue] = useState({ label: 'Economy', id: 1 })
  const [indexOfWidgetToshow, setIndexOfWidget] = useState(1)
  const ctx = useContext(AuthContext)
  const box = useRef(null)
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      // slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  }

  const router = useRouter()
  //console.log("===========All index page router=========",router);
  const query = router.query.index
  //console.log("===========All index page query=========",query);

  useEffect(() => {
    getFareType().then((res) => {
      console.log('getFareType', res)
      if (res && res.data && res.data.length > 0) {
        let fareType = res.data.map((ele, id) => {
          return {
            label: ele.pax_type,
            value: ele.pax_code,
            isChecked: false,
            //  displayValue: ele
          }
        })
        setSelectFareType(fareType)
      }
    })
    getSupplierList().then((res) => {
      if (res && res.status && res.data && res.data.data && res.data.data.length > 0) {
        setSupplierList(res.data.data)
      }
    })
    getAirlines().then((res) => {
      if (res && res.status && res.data && res.data.data && res.data.data.length > 0) {
        setAirlineList(res.data.data)
      }
    })
    let userToken = cf.getCookieTocken('userToken')
    cf.getUserProfile(userToken, props.site_id).then((res) => {
      if (res.status === true) {
        setUserProfile(ctx?.user)
      } else {
        setUserProfile(res)
      }
    })
    if (props?.home?.data?.data?.body?.desktop) {
      setHomeBodyDesktop(props.home.data.data.body.desktop)
      /**FIND FIRST AVILLABLE WIDGET**/
      let indexOfFirstWidghts = props.home.data.data.body.desktop?.findIndex((x) =>
        availableSearchTypes.includes(x.type)
      )
      setIndexOfWidget(indexOfFirstWidghts)
    }
    if (props?.home?.data?.data?.body?.mobile) setHomeBodyMobile(props.home.data.data.body.mobile)
    const style = document.createElement('style')
    if (
      !cf.isValueEmpty(props) &&
      !cf.isValueEmpty(props.home) &&
      !cf.isValueEmpty(props.home.data)
    ) {
      style.innerHTML = props.home.data.data.css
      document.head.appendChild(style)
    }
    setActiveItemIndex(activeItemIndex)
    if (!cf.isValueEmpty(props) && !cf.isValueEmpty(props.home) && props.home.status === true) {
      if (
        !cf.isValueEmpty(props.home.data.data) &&
        !cf.isValueEmpty(props.home.data.data.body) &&
        !cf.isValueEmpty(props.home.data.data.body.desktop) &&
        props.home.data.data.body.desktop.length > 0
      ) {
        let index = props.home.data.data.body.desktop.findIndex(
          (items) => items.type === 'flight_search'
        )
        let hotelIndex = props.home.data.data.body.desktop.findIndex(
          (items) => items.type === 'hotel_search'
        )
        if (index !== -1) {
          if (
            !cf.isValueEmpty(props.home.data.data.body.desktop[index].airports) &&
            props.home.data.data.body.desktop[index].airports.length > 0
          ) {
            // let sortedDefaultAirports = props?.home?.data?.data?.body?.desktop &&
            let sortedDefaultAirports = []
            if (
              props?.home?.data?.data?.body?.desktop &&
              props?.home?.data?.data?.body?.desktop[index] &&
              props?.home?.data?.data?.body?.desktop[index].airports &&
              props?.home?.data?.data?.body?.desktop[index].airports.length
            ) {
              sortedDefaultAirports = props?.home?.data?.data?.body?.desktop[index].airports.sort(
                (a, b) => {
                  return a.ORDER - b.ORDER
                }
              )
            }
            setInitFlightData([...sortedDefaultAirports])
            setDepartureSuggestionList([...sortedDefaultAirports])
            setGoingToSuggestionList([...sortedDefaultAirports])
          }
        }
        if (hotelIndex !== -1) {
          if (
            !cf.isValueEmpty(props.home.data.data.body.desktop[hotelIndex].locations) &&
            props.home.data.data.body.desktop[hotelIndex].locations.length > 0
          ) {
            setInitHotelData([...props.home.data.data.body.desktop[hotelIndex].locations])
            setHotelAutoSuggestList([...props.home.data.data.body.desktop[hotelIndex].locations])
          }
        }
      }
    }
    // showMore()
    // <script>
    //    {showMore()}
    // </script>
    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [query])

  // useEffect(() => {
  //    <Script id="show-banner" strategy="lazyOnload">
  //       {alert('readMore')}
  //       {showMore()}
  //       {/* {`document.getElementById('banner').classList.remove('hidden')`} */}
  //    </Script>
  // }, [])
  // function showMore(id) {
  //    if (id) {
  //       alert(890);
  //       document.getElementById(id + 'Overflow').className = '';
  //       document.getElementById(id + 'MoreLink').className = 'hidden';
  //       document.getElementById(id + 'LessLink').className = '';
  //    }
  // }
  // function showLess(id) {
  //    if (id) {
  //       document.getElementById(id + 'Overflow').className = 'hidden';
  //       document.getElementById(id + 'MoreLink').className = '';
  //       document.getElementById(id + 'LessLink').className = 'hidden';
  //    }
  // }
  // var len = 255;
  // var shrinkables = document.getElementsByClassName('shrinkable');
  // if (shrinkables.length > 0) {
  //    for (var i = 0; i < shrinkables.length; i++) {
  //       var fullText = shrinkables[i].innerHTML;
  //       if (fullText.length > len) {
  //          var trunc = fullText.substring(0, len).replace(/\w+$/, '');
  //          var remainder = "";
  //          var id = shrinkables[i].id;
  //          remainder = fullText.substring(len, fullText.length);
  //          shrinkables[i].innerHTML = '<span>' + trunc + '<span class="hidden" id="' + id + 'Overflow">' + remainder + '</span></span>&nbsp;<a id="' + id + 'MoreLink" style="color: var(--primary_color); font-size: 15px; font-weight: bold;cursor: pointer;" onclick="showMore(\'' + id + '\');">...Read More</a><a class="hidden" id="' + id + 'LessLink" style="color: var(--primary_color);font-size: 15px; font-weight: bold;cursor: pointer;" onclick="showLess(\'' + id + '\');">...Read Less</a>';
  //       }
  //    }
  // }
  const getAutoSuggestDataFunc = (value, section, type, event) => {
    switch (section) {
      case 'flight':
        if (event === 'click') {
          if (value.length === 0) {
            if (type === 'departure') {
              setDepartureSuggestionList([...initFlightData])
            } else {
              setGoingToSuggestionList([...initFlightData])
            }
          }
        } else {
          autoSuggestHomeWidget({ autoSuggest: value }).then((res) => {
            if (!cf.isValueEmpty(res) && res.status === true && !cf.isValueEmpty(res.data)) {
              if (type === 'departure') {
                setDepartureSuggestionList([...res.data])
              } else {
                setGoingToSuggestionList([...res.data])
              }
            }
          })
        }
        break
      case 'hotel':
        if (value.length > 0) {
          SearchHotelHomeWidget({ autoSuggest: value }).then((res) => {
            if (!cf.isValueEmpty(res) && res.status === true && !cf.isValueEmpty(res.data)) {
              if (res.data.status === 'success' && !cf.isValueEmpty(res.data.result)) {
                setHotelAutoSuggestList([...res.data.result.records])
                setExpandHotelName(true)
              }
            }
          })
        } else {
          setHotelAutoSuggestList([...initHotelData])
          setExpandHotelName(true)
        }
        break
      case 'activity':
        SearchActivityHomeWidget({ autoSuggest: value }).then((res) => {
          if (!cf.isValueEmpty(res) && !cf.isValueEmpty(res.data) && res.data.status === true) {
            if (
              !cf.isValueEmpty(res.data.searchBycity) &&
              res.data.searchBycity !== 'Not Found' &&
              res.data.searchBycity.status === 'success'
            ) {
              if (
                !cf.isValueEmpty(res.data.searchBycity.result) &&
                res.data.searchBycity.result.length > 0
              ) {
                getActivityAutoSuggestList.cityList = [...res.data.searchBycity.result]
                //     setActivityAutoSuggestList({ ...getActivityAutoSuggestList })
                setActivityAutoSuggestList([...res.data.searchBycity.result])
                if (expandActivityName === false) {
                  setExpandActivityName(true)
                }
              }
              if (
                !cf.isValueEmpty(res.data.searchBycountry) &&
                res.data.searchBycountry !== 'Not Found' &&
                res.data.searchBycountry.status === 'success'
              ) {
                if (
                  !cf.isValueEmpty(res.data.searchBycountry.result) &&
                  res.data.searchBycountry.result.length > 0
                ) {
                  getActivityAutoSuggestList.countryList = [...res.data.searchBycountry.result]
                  //      setActivityAutoSuggestList({ ...getActivityAutoSuggestList })
                  setActivityAutoSuggestList([
                    ...getActivityAutoSuggestList.cityList,
                    ...getActivityAutoSuggestList.countryList,
                  ])
                  if (expandActivityName === false) {
                    setExpandActivityName(true)
                  }
                }
              }
            }
          }
        })
        break
      default:
        break
    }
  }
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      if (expandHotelNameBoolean === true) setExpandHotelName(false)
    }
  }
  const onClickHeaderMenu = (data, i) => {
    if (data.text == 'Holidays') {
      window.location.href = '/holiday/search'
      return
    }
    setMenuData(data.text)
    setPopup(true)
  }
  const renderInfo = (menudata) => {
    console.log('renderInfo', menudata)
    switch (menudata) {
      case 'Flight':
        return (
          <div className="renderinfo-flight">
            <ModalPopupComponent
              isMobile={true}
              isPopup={isPopup}
              onClosePopup={() => {
                setPopup(false)
              }}
              modalCss={swPopupCss}
              showCrossButton={false}
            >
              {/* {this.renderLoginPopup(this.state.popupType)} */}
              <FlightMobileSearchWidget
                pageTheme={props?.home?.data?.site?.site_theme}
                isSRP={false}
                onClosePopup={(bool) => {
                  setPopup(bool)
                }}
                homeData={!cf.isValueEmpty(initFlightData) ? initFlightData : []}
                styles={FlightSearchMobileThemeOneCSS}
                calendarStyles={DesktopCalenderThemeOneCss}
                getAutoSuggestData={(value, type, event) =>
                  getAutoSuggestDataFunc(value, 'flight', type, event)
                }
                getGoingToSuggestionList={getGoingToSuggestionList}
                getDepartureSuggestionList={getDepartureSuggestionList}
              />
            </ModalPopupComponent>
          </div>
        )
      case 'Hotels':
        return (
          <div className="renderinfo-flight">
            <ModalPopupComponent
              isMobile={true}
              isPopup={isPopup}
              onClosePopup={() => {
                setPopup(false)
              }}
              modalCss={modalCss}
              showCrossButton={false}
            >
              <HotelMobileSearchWidget
                pageTheme={props?.home?.data?.site?.site_theme}
                isSRP={false}
                styles={HotelSearchMobileThemeOneCSS}
                getAutoSuggestValue={(value) => getAutoSuggestDataFunc(value, 'hotel')}
                expandHotelNameBoolean={expandHotelNameBoolean}
                getAutoSuggestList={getHotelAutoSuggestList}
                expandHotelNameBooleanfunc={(value) => setExpandHotelName(value)}
                homeData={!cf.isValueEmpty(initHotelData) ? initHotelData : []}
                onClosePopup={() => setPopup(false)}
              />
            </ModalPopupComponent>
          </div>
        )
      case 'Holidays':
        return (
          <div className="renderinfo-flight">
            <ModalPopupComponent
              isMobile={true}
              isPopup={isPopup}
              onClosePopup={() => {
                setPopup(false)
              }}
              modalCss={modalCss}
            >
              Holiday
            </ModalPopupComponent>
          </div>
        )
      case 'Activities':
        return (
          <div className="renderinfo-flight">
            <ModalPopupComponent
              isMobile={true}
              showCrossButton={false}
              isPopup={isPopup}
              onClosePopup={() => {
                setPopup(false)
              }}
              modalCss={modalCss}
            >
              <ActivityMobileSearchWidget
                pageTheme={props?.home?.data?.site?.site_theme}
                isSRP={false}
                cssfileMobile={ActivitySearchMobileThemeOneCSS}
                getAutoSuggestValue={(value) => getAutoSuggestDataFunc(value, 'activity')}
                expandActivityNameBoolean={expandActivityName}
                getAutoSuggestList={getActivityAutoSuggestList}
                expandActivityBooleanfunc={(value) => setExpandActivityName(value)}
                homeData={!cf.isValueEmpty(initHotelData) ? initHotelData : []}
                onClosePopup={() => setPopup(false)}
                dateFormat={props?.home?.data?.site?.date_format || 'DD-MMM-YYYY'}
              />
            </ModalPopupComponent>
          </div>
        )
      case 'Experience':
        return (
          <div className="renderinfo-flight">
            <ModalPopupComponent
              isMobile={true}
              showCrossButton={false}
              isPopup={isPopup}
              onClosePopup={() => {
                setPopup(false)
              }}
              modalCss={modalCss}
            >
              <ExperienceMobileSearchWidget
                pageTheme={props?.home?.data?.site?.site_theme}
                isSRP={false}
                cssfileMobile={ActivitySearchMobileThemeOneCSS}
                getAutoSuggestValue={(value) => getAutoSuggestDataFunc(value, 'activity')}
                expandActivityNameBoolean={expandActivityName}
                getAutoSuggestList={getActivityAutoSuggestList}
                expandActivityBooleanfunc={(value) => setExpandActivityName(value)}
                homeData={!cf.isValueEmpty(initHotelData) ? initHotelData : []}
                onClosePopup={() => setPopup(false)}
                dateFormat={props?.home?.data?.site?.date_format || 'DD-MMM-YYYY'}
              />
            </ModalPopupComponent>
          </div>
        )
    }
  }
  const imageLoader = ({ src }) => {
    return `${'/shared/api/media/' + props.site_id + '/' + src}`
  }
  const onUpdateAirline = (selectedList, selectedItem) => {
    setSelectedAirlines(selectedList)
  }

  const selectFareTypeDropdown = () => {
    return (
      <MultiSuppilerDropDownoption
        options={SelectFareType}
        setoptions={setSelectFareType}
        inputPlaceHolder={'Select Fare Type'}
        style={styleDerpt}
        box={box}
      />
    )
  }
  const multiSelectDropdown = () => {
    airlineList.forEach((option) => {
      option.displayValue = option.airline_name
    })
    return (
      <Multiselect
        displayValue="displayValue"
        onKeyPressFn={function noRefCheck() {}}
        onRemove={onUpdateAirline}
        onSearch={function noRefCheck() {}}
        selectedValues={selectedAirlines}
        onSelect={onUpdateAirline}
        options={airlineList}
        showCheckbox={true}
      />
    )
  }
  const onUpdateSupplier = (selectedList, selectedItem) => {
    setSelectedSupplier(selectedList)
  }
  const multiSelectSupplierDropdown = () => {
    supplierList.forEach((option) => {
      option.displayValue = option.name
    })

    return (
      <Multiselect
        displayValue="displayValue"
        onKeyPressFn={function noRefCheck() {}}
        onRemove={onUpdateSupplier}
        onSearch={function noRefCheck() {}}
        selectedValues={selectedSupplier}
        onSelect={onUpdateSupplier}
        options={supplierList}
        //   showCheckbox={true}
      />
    )
  }

  const onUpdateDeparturTime = (selectedList, selectedItem) => {
    setSelectedDepartureTime(selectedList)
  }
  const multiSelectDepartureTimeDropdown = () => {
    return (
      <Multiselect
        isObject={false}
        onKeyPressFn={function noRefCheck() {}}
        onRemove={onUpdateDeparturTime}
        onSearch={function noRefCheck() {}}
        selectedValues={selectedDepartureTime}
        onSelect={onUpdateDeparturTime}
        options={times}
        showCheckbox={true}
      />
    )
  }
  const onUpdateRBDClass = (selectedList, selectedItem) => {
    setSelectedRbdClass(selectedList)
    setSelectedClassValue({
      label: '',
      id: '',
    })
  }
  const multiSelectRBDClassDropdown = () => {
    return (
      <Multiselect
        isObject={false}
        onKeyPressFn={function noRefCheck() {}}
        onRemove={onUpdateRBDClass}
        onSearch={function noRefCheck() {}}
        selectedValues={selectedRbdClass}
        onSelect={onUpdateRBDClass}
        options={rbdClass}
        showCheckbox={true}
      />
    )
  }
  const onChangeClassValue = (element) => {
    setSelectedClassValue({
      label: element.label,
      id: element.id,
    })
    setSelectedRbdClass([])
  }
  return (
    <>
      {/* <TimeoutWrap theme="theme2" type="flight"/> */}
      <div className={styles.home_container}>
        <div className="home-page-offer">
          <div className="main-banner">
            <div>
              {homeBodyDesktop.map((res, i) => {
                return (
                  <div key={'homeBodyDsktp' + i}>
                    {!cf.isValueEmpty(res.type) && res.type == 'image_banner' && (
                      <div className="img_banner_home">
                        <Image
                          alt=""
                          loader={imageLoader}
                          src={res.banners}
                          width={500}
                          layout="fill"
                          priority
                        />
                      </div>
                    )}
                    {indexOfWidgetToshow == i &&
                      !cf.isValueEmpty(res.type) &&
                      res.type == 'flight_search' && (
                        <div>
                          <div>
                            {!props.isMobile && (
                              <FlightSearchWidget
                                pageTheme={props?.home?.data?.site?.site_theme}
                                isSRP={false}
                                isOffline={false}
                                homeData={!cf.isValueEmpty(initFlightData) ? initFlightData : []}
                                b2bStyles={b2bWidgetStyle}
                                styles={FlightSearchThemeOneCSS}
                                calendarStyles={DesktopCalenderThemeOneCss}
                                getAutoSuggestData={(value, type, event) =>
                                  getAutoSuggestDataFunc(value, 'flight', type, event)
                                }
                                getGoingToSuggestionList={getGoingToSuggestionList}
                                getDepartureSuggestionList={getDepartureSuggestionList}
                                dateFormat={props?.home?.data?.site?.date_format}
                                userProfile={userProfile}
                                multiSelectDropdown={multiSelectDropdown}
                                multiSelectSupplierDropdown={multiSelectSupplierDropdown}
                                multiSelectDepartureTimeDropdown={multiSelectDepartureTimeDropdown}
                                multiSelectRBDClassDropdown={multiSelectRBDClassDropdown}
                                selectedAirlines={selectedAirlines}
                                selectedSupplier={selectedSupplier}
                                selectedDepartureTime={selectedDepartureTime}
                                selectedRbdClass={selectedRbdClass}
                                SelectedClassValue={SelectedClassValue}
                                onChangeClassValue={onChangeClassValue}
                                selectFareTypeDropdown={selectFareTypeDropdown}
                                FareType={SelectFareType}
                              />
                            )}
                          </div>{' '}
                          {/* Flight Desktop */}
                        </div>
                      )}
                    {indexOfWidgetToshow == i &&
                      !cf.isValueEmpty(res.type) &&
                      res.type == 'hotel_search' && (
                        <div>
                          <HotelSearchWidget
                            pageTheme={props?.home?.data?.site?.site_theme}
                            isSRP={false}
                            isOffline={false}
                            styles={DesktopHotelStylesTheme1}
                            b2bStyles={b2bWidgetStyle}
                            userProfile={userProfile}
                            getAutoSuggestValue={(value) => getAutoSuggestDataFunc(value, 'hotel')}
                            expandHotelNameBoolean={expandHotelNameBoolean}
                            getAutoSuggestList={getHotelAutoSuggestList}
                            expandHotelNameBooleanfunc={(value) => setExpandHotelName(value)}
                            homeData={!cf.isValueEmpty(initHotelData) ? initHotelData : []}
                            dateFormat={props?.home?.data?.site?.date_format}
                            Countrystyles={Countrystyles}
                          />
                        </div>
                      )}
                    {indexOfWidgetToshow == i &&
                      !cf.isValueEmpty(res.type) &&
                      res.type == 'activity_search' && (
                        <SearchWidgetActivity
                          {...props}
                          cssfile={cssfile}
                          pageTheme={props?.home?.data?.site?.site_theme}
                          calendarStyles={DesktopCalenderThemeOneCss}
                          dateFormat={props?.home?.data?.site?.date_format}
                          b2bStyles={b2bWidgetStyle}
                        />
                      )}

                    {indexOfWidgetToshow == i &&
                      !cf.isValueEmpty(res.type) &&
                      res.type == 'experience_search' && (
                        <SearchWidgetExperience
                          {...props}
                          cssfile={cssfile}
                          pageTheme={props?.home?.data?.site?.site_theme}
                          calendarStyles={DesktopCalenderThemeOneCss}
                          dateFormat={props?.home?.data?.site?.date_format}
                          b2bStyles={b2bWidgetStyle}
                          userProfile={userProfile}
                        />
                      )}
                    <div>
                      {!cf.isValueEmpty(res.html)
                        ? parse(res.html)
                        : // <Interweave content={res.html} />
                          ''}
                    </div>
                    {!cf.isValueEmpty(res.type) && res.type == 'image_carousal' && (
                      <div>
                        <Carousel
                          swipeable={false}
                          draggable={false}
                          showDots={true}
                          responsive={responsive}
                          ssr={false} // means to render carousel on server-side.
                          infinite={true}
                          autoPlay={props.isMobile ? true : true}
                          autoPlaySpeed={3000}
                          keyBoardControl={true}
                          customTransition="all .5"
                          transitionDuration={500}
                          containerClass="carousel-container"
                          // deviceType={this.props.deviceType}
                          dotListClass="custom-dot-list-style"
                          itemClass="carousel-item-padding-40-px"
                        >
                          {!cf.isValueEmpty(res) &&
                            !cf.isValueEmpty(res.banners) &&
                            res.banners.length > 0 &&
                            res.banners.map((imagedata, index) => {
                              return (
                                // <div>index====={index}</div>
                                // <img style={{ height: 300, background: '#EEE' }} src={`${ '/shared/api/media/'+props.site_id+'/' + imagedata.image}`} />
                                <div key={'banner' + index} className="img_banner_home">
                                  <CustomLink href={imagedata.banner_url}>
                                    <Image
                                      loader={imageLoader}
                                      src={imagedata.image}
                                      width={500}
                                      alt=""
                                      layout="fill"
                                      priority
                                    />
                                  </CustomLink>
                                  {/* <img src={`${ '/shared/api/media/' + props.site_id + '/' + imagedata.image}`} /> */}
                                </div>
                                // <div className="testimonial_carousel">
                                //    <div className='container mx-auto xl:px-8 flex items-center justify-center'>
                                //       <div className="testimonial_left">
                                //          <img src={`${ '/shared/api/media/' + props.site_id + '/' + imagedata.image}`} />
                                //       </div>
                                //       <div className="testimonial_right">
                                //          <div className="testimonial_header">Test</div>
                                //          <div className="testimonial_text">
                                //             Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                //             Minima reiciendis, aliquid ipsam doloribus dignissimos
                                //             ipsa. Quidem, architecto laudantium! Aut quas dolor nam
                                //             eaque ullam vero voluptatibus non, molestiae quae beatae.
                                //          </div>
                                //          <div className='testimonial_name'>Test User</div>
                                //          <div class="testimonial_star">
                                //             <img src="https://uat.elevatetrips.com/image/star-rating-4.0.svg" alt="" />
                                //          </div>
                                //       </div>
                                //    </div>
                                // </div>
                              )
                            })}
                        </Carousel>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
