import React from 'react';

class AirportTransferSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      airportPickupDrop: {},
      show: false
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    if(Object.keys(this.props.tripTypeDetails).length === 0) {
      this.setState({ airportPickupDrop: { label: 'Airport Pick Up', value: 'AIRPORT_PICKUP' } }, () => {
        this.props.apply('tripTypeDetails', this.state.airportPickupDrop)
      })
    }
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        show: false
      });
    }
  };

  handleClick = () => {
    this.setState({ show: !this.state.show });
  };

  handleOptionClick = (value) => {
    this.setState({
      airportPickupDrop: value,
      show: false
    }, () => {
      this.props.apply('tripTypeDetails', value)
    });
  };

  render() {
    console.log("props===========>", this.props);
    const options = [
      { label: 'Airport Pick Up', value: 'AIRPORT_PICKUP' },
      { label: 'Airport Drop', value: 'AIRPORT_DROP' },
    ];

    return (
      <>
      {!this.props.isMobile ?
        <div ref={this.wrapperRef} className={this.props.styles.airport_selector}>
          <div className={this.props.styles.airport_selector__display_wrap}>
            <div onClick={this.handleClick} className={this.props.styles.airport_selector__display}>
              <h3 className={this.props.styles.package_selector__title}>
                <span className={this.props.styles.package_selector__label}>Airport</span>
                <span className={this.props.styles.package_selector__value}>
                  {this.props.tripTypeDetails?.label || 'Select an option'}
                </span>
              </h3>
            </div>
            <span className="">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12" fill="none">
                <path d="M17.1827 1.27698C16.6377 0.727984 15.7538 0.727984 15.2078 1.27698L8.79475 7.74198L2.38375 1.27798C1.83875 0.727985 0.95475 0.727985 0.40875 1.27798C-0.13625 1.82798 -0.13625 2.71898 0.40875 3.26898L7.80375 10.722C8.21275 11.135 8.81375 11.239 9.31775 11.032C9.48975 10.963 9.65075 10.86 9.78975 10.72L17.1838 3.26698C17.7288 2.71698 17.7287 1.82698 17.1827 1.27698Z" fill="#266AAC"/>
              </svg>
            </span>
          </div>
          {this.state.show && (
            <div className={this.props.styles.package_selector__dropdown}>
              <div className={this.props.styles.package_selector__options}>
                {options.map((option) => (
                  <div
                    key={option.value}
                    onClick={() => this.handleOptionClick(option)}
                    className={`${this.props.styles.package_selector__option} ${option.value === this.props.tripTypeDetails?.value ? this.props.styles.active : ''}`}
                  >
                    {option.label}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        :
        <div className={`${this.props.styles.car_way_btngrp} ${this.props.styles.flight_way_btngrp} ${this.props.styles.radio_cont}`}>
            {options.map(option => (
              <label
                key={option.value}
                className={`${this.props.styles.radio_button} ${option.value === this.props.tripTypeDetails?.value
                  ? this.props.styles.radio_active
                  : ""
                }`}
                onClick={() => this.handleOptionClick(option)}
              >
                <input
                  type="radio"
                  value={option.value}
                  checked={option.value === this.props.tripTypeDetails?.value}
                />
                {option.label}
                <span></span>
              </label>
            ))}
        </div>
        }
      </>
    );
  }
}

export default AirportTransferSelector;
