import React, { Component } from "react";
import { format, addDays, differenceInCalendarDays } from "date-fns";
import HotelAutoComplete from "./HotelAutoComplete";
import MobileCalender from "../../../calendar/hotelCalender/theme4/MobileCalender";
// import commonFunction from '@/lib/utils/common';
import commonFunction from "../../../../lib/utils/common";
import NationalityDropdownComp from "../../../NationalityDropDown/theme4/index";
import B2BWidgets from "../../../b2bwidgets/theme4/mobile/index";
import HotelAdvSearch from "./HotelAdvSearch";
import getNationalitiesList from "../../../../lib/getNationalitiesList";
const childAgeList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
// let regions = ""
export default class Theme1 extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.setScrollDown = React.createRef();
    this.advanceSearchWrapper = React.createRef();
    this.guestDetailWrapper = React.createRef();
    this.bfSearchWrapper = React.createRef();
    this.rtSearchWrapper = React.createRef();
    this.starSearchWrapper = React.createRef();
    this.state = {
      hotelName: "",
      expandHotelNameBoolean: false,
      checkInOutValue: "",
      checkInValue: commonFunction.getDateWithFormat(
        new Date(),
        this?.props?.dateFormat
      ),
      checkOutValue: commonFunction.getDateWithFormat(
        addDays(new Date(), 2),
        this?.props?.dateFormat
      ),
      dateLimit: commonFunction.getDateWithFormat(
        new Date(),
        this?.props?.dateFormat
      ),
      openRoomDetailList: false,
      expandCheckInOutDateBoolean: false,
      selectedRoomDetails: { rooms: 1, guest: 1, adult: 1, child: 0 },
      getAutoSuggestList: this.props.getAutoSuggestList,
      isError: {
        hotelName: false,
        checkInOutDate: false,
        roomGuest: false,
        selectedNationality: false,
      },
      getDestinationId: "",
      getSearchType: "",
      roomList: [
        {
          id: 1,
          adults: 1,
          children: 0,
          adultAddCountDisable: false,
          adultMinusCountDisable: false,
          childrenAddCountDisable: false,
          childrenMinusCountDisable: true,
          childDetails: [],
        },
      ],
      type: "hotel",
      openTraveler: false,
      advSearch: false,
      nationallitySearch: null,
      is_breakfast: "",
      // isBreakfastChecked: false,
      originCountries: [],
      selectedNationality: { value: "", countryCode: null },
      response: [],
      isOneStar: false,
      isTwoStar: false,
      isThreeStar: false,
      isFourStar: false,
      isFiveStar: false,
      selectedCheckBoxes: {},
      guest_id: "",
      showStarSearch: false,
      showRoomTypeSeach: false,
      showBreakfastSearch: false,
      allStar: false,
      clientNationality: { value: "", countryCode: null },
    };
  }
  componentDidMount() {
    getNationalitiesList().then((resp) => {
      let nationalityList = [];
      if (resp?.data?.data.length > 0) {
        resp?.data.data.map((item) => {
          nationalityList.push({
            ...item,
            value: item.nationality_name,
            countryCode: item.nationality_code,
          });
        });
      }
      this.setState({ originCountries: nationalityList });
    });
    // regions = new URLSearchParams(window.location.search).get("region")
    if (
      !commonFunction.isValueEmpty(this.props) &&
      this.props.isSRP === true &&
      !commonFunction.isValueEmpty(this.props.query)
    ) {
      let checkInArr = this.props.query.checkin.split("-");
      let checkOutArr = this.props.query.checkout.split("-");
      let checkInValue = commonFunction.getDateWithFormat(
        new Date(checkInArr[0], Number(checkInArr[1]) - 1, checkInArr[2]),
        this?.props?.dateFormat
      );
      let checkOutValue = commonFunction.getDateWithFormat(
        new Date(checkOutArr[0], Number(checkOutArr[1]) - 1, checkOutArr[2]),
        this?.props?.dateFormat
      );
      let checkInOut =
        commonFunction.getDateWithFormat(
          new Date(checkInArr[0], Number(checkInArr[1]) - 1, checkInArr[2]),
          this?.props?.dateFormat
        ) +
        " - " +
        commonFunction.getDateWithFormat(
          new Date(checkOutArr[0], Number(checkOutArr[1]) - 1, checkOutArr[2]),
          this?.props?.dateFormat
        );
      let roomsdetail = this.props.query.rooms.split("_");
      let totalRooms = roomsdetail.length;
      let totalAdult = 0;
      let totalChildren = 0;
      let roomListArr = [];
      roomsdetail.map((ele, index) => {
        let split = ele.split("-").filter((item) => item);
        totalAdult = Number(split[0].charAt(0)) + totalAdult;
        totalChildren = Number(split[1].charAt(0)) + totalChildren;
        roomListArr = [
          ...roomListArr,
          {
            id: index + 1,
            adults: Number(split[0].charAt(0)),
            children: Number(split[1].charAt(0)),
            adultAddCountDisable: false,
            adultMinusCountDisable: true,
            childrenAddCountDisable: false,
            childrenMinusCountDisable:
              Number(split[1].charAt(0)) == 1 ? false : true,
            childDetails: [],
          },
        ];
        split.map((element, id) => {
          if (id > 1)
            roomListArr[index].childDetails = [
              ...roomListArr[index].childDetails,
              { age: element, isOpen: false, isError: false },
            ];
        });
      });
      this.setState(
        {
          checkInOutValue: checkInOut,
          checkInValue: checkInValue,
          checkOutValue: checkOutValue,
          getDestinationId: this.props.query.destid,
          getSearchType: this.props.query.search_type,
          hotelName: this.props.query.region + ", " + this.props.query.country,
          selectedRoomDetails: {
            rooms: totalRooms,
            guest: totalAdult + totalChildren,
            adult: totalAdult,
            child: totalChildren,
          },
          roomList: roomListArr,
        },
        () => {
          this.checkRoomDetails();
        }
      );
    } else {
      // if (regions != null && regions != "" && regions != undefined) {
      //     this.renderInfo()
      // }
      this.setState({
        checkInOutValue: this.getCheckInOutValue(
          this.state.checkInValue,
          this.state.checkOutValue
        ),
      });
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  checkRoomDetails() {
    let { roomList } = this.state;
    roomList.map((ele, index) => {
      let totalAccomodation = ele.adults + ele.children;
      if (totalAccomodation <= 12) {
        if (totalAccomodation === 12) {
          roomList[index].adultAddCountDisable = true;
          roomList[index].childrenAddCountDisable = true;
        } else if (totalAccomodation < 12) {
          if (
            roomList[index].adultAddCountDisable === true &&
            roomList[index].childrenAddCountDisable === true
          ) {
            roomList[index].adultAddCountDisable = false;
            roomList[index].childrenAddCountDisable = false;
          }
          if (roomList[index].adults > 1) {
            roomList[index].adultMinusCountDisable = false;
          } else if (roomList[index].adults === 1) {
            roomList[index].adultMinusCountDisable = true;
            roomList[index].adultAddCountDisable = false;
          }
          if (roomList[index].children > 1) {
            roomList[index].childrenMinusCountDisable = false;
          } else if (roomList[index].children > 0) {
            roomList[index].childrenMinusCountDisable = false;
            roomList[index].childrenAddCountDisable = false;
          }
        }
      }
    });
    this.setState({ roomList: [...roomList] });
  }
  handleClickOutside = (e) => {
    if (
      this.dropdownWrapperRef &&
      !this.dropdownWrapperRef.contains(e.target)
    ) {
      if (this.props.expandHotelNameBoolean === true)
        this.props.expandHotelNameBooleanfunc(false);
      if (
        this.state.expandCheckInOutDateBoolean === true ||
        this.state.openRoomDetailList === true
      ) {
        this.setState({
          expandCheckInOutDateBoolean: false,
          openRoomDetailList: false,
        });
      }
    }
    if (
      this.dropdownWrapperRef &&
      !this.dropdownWrapperRef.contains(e.target)
    ) {
      if (this.props.expandHotelNameBoolean === true)
        this.props.expandHotelNameBooleanfunc(false);
      // if (
      //   this.advanceSearchWrapper.current &&
      //   !this.advanceSearchWrapper.current.contains(event.target)
      // ) {
      //   this.setState({ advSearch: false });
      // }
      if (
        this.guestDetailWrapper.current &&
        !this.guestDetailWrapper.current.contains(event.target)
      ) {
        this.setState({ openTraveler: false });
      }

      if (
        this.state.expandCheckInOutDateBoolean === true ||
        this.state.openRoomDetailList === true
      ) {
        this.setState({
          expandCheckInOutDateBoolean: false,
          openRoomDetailList: false,
        });
      }
    }

    if (
      this.rtSearchWrapper.current &&
      !this.rtSearchWrapper.current.contains(event.target)
    ) {
      this.setState({ showRoomTypeSeach: false });
    }
    if (
      this.bfSearchWrapper.current &&
      !this.bfSearchWrapper.current.contains(event.target)
    ) {
      this.setState({ showBreakfastSearch: false });
    }
    if (
      this.starSearchWrapper.current &&
      !this.starSearchWrapper.current.contains(event.target)
    ) {
      this.setState({ showStarSearch: false });
    }
  };
  setDropdownWrapperRef = (node) => {
    this.dropdownWrapperRef = node;
  };
  onClickDoneButton = () => {
    let totalGuest = 0;
    let count = 0;
    let Validation = true;
    let validation_arr = [];
    let roomList = this.state.roomList;
    let totalAdult = 0;
    let totalChilds = 0;
    // let finalRoomListArr = []; //new
    while (count <= roomList.length) {
      if (
        !commonFunction.isValueEmpty(roomList[count]) &&
        !commonFunction.isValueEmpty(roomList[count].childDetails) &&
        roomList[count].childDetails.length > 0
      ) {
        // finalRoomListArr = [...finalRoomListArr, { id: count + 1, adults: roomList[count].adults, children: roomList[count].children, childDetails: [{ age: '' }] }] //new
        roomList[count].childDetails.map((element, index) => {
          if (commonFunction.isValueEmpty(element.age)) {
            roomList[count].childDetails[index].isError = true;
          }
        });
        // Validation = roomList[count].childDetails.every(item => !commonFunction.isValueEmpty(item.age));
        validation_arr.push(
          roomList[count].childDetails.every(
            (item) => !commonFunction.isValueEmpty(item.age)
          )
        );
        let checker = validation_arr.every(Boolean);
        Validation = checker;
      }
      count++;
    }
    // this.setState({ roomList: [...roomList] })
    this.setState({ roomList: [...roomList] }, () => {
      if (Validation === true) {
        this.state.roomList.length > 0 &&
          this.state.roomList.map((element) => {
            totalGuest = totalGuest + element.adults + element.children;
            totalAdult = totalAdult + element.adults;
            totalChilds = totalChilds + element.children;
            //new
            // element.childDetails.map((ele, i) => {
            //     finalRoomListArr[idx].childDetails[i].age = ele.age;
            // })
          });
        this.setState({
          // finalRoomList: finalRoomListArr,
          selectedRoomDetails: {
            rooms: this.state.roomList.length,
            guest: totalGuest,
            adult: totalAdult,
            child: totalChilds,
          },
        });
        const deepClone = JSON.parse(JSON.stringify(roomList));
        this.setState({
          finalRoomList: deepClone,
        });
        if (this.state.openRoomDetailList === true) {
          this.setState({ openRoomDetailList: false });
        }
      }
    });
  };

  removeRoomItem = (id) => {
    const filteredRoom = this.state.roomList.filter((item) => item.id !== id);
    this.setState({ roomList: [...filteredRoom] });
  };
  addAccomodationCount = async (id, index, type) => {
    let { roomList } = this.state;
    if (type === "adult") {
      if (roomList[index].id === id) {
        roomList[index].adults = roomList[index].adults + 1;
      }
    } else {
      if (roomList[index].id === id) {
        roomList[index].children = roomList[index].children + 1;
        roomList[index].childDetails = [
          ...roomList[index].childDetails,
          { age: "", isOpen: false, isError: false },
        ];
      }
    }
    let totalAccomodation = roomList[index].adults + roomList[index].children;
    if (totalAccomodation <= 7) {
      if (totalAccomodation === 7) {
        roomList[index].adultAddCountDisable = true;
        roomList[index].childrenAddCountDisable = true;
      } else if (totalAccomodation < 7) {
        if (
          roomList[index].adultAddCountDisable === true &&
          roomList[index].childrenAddCountDisable === true
        ) {
          roomList[index].adultAddCountDisable = false;
          roomList[index].childrenAddCountDisable = false;
        }
      }
      if (roomList[index].adults > 1) {
        roomList[index].adultMinusCountDisable = false;
      }
      if (roomList[index].children > 0) {
        roomList[index].childrenMinusCountDisable = false;
      }
      await this.setState({ roomList: [...roomList] });
    }
  };
  minusAccomodationCount = async (id, index, type) => {
    let { roomList } = this.state;
    if (type === "adult") {
      if (roomList[index].id === id) {
        if (roomList[index].adults >= 2) {
          roomList[index].adults = roomList[index].adults - 1;
          roomList[index].adultMinusCountDisable = false;
          if (roomList[index].adultAddCountDisable === true) {
            roomList[index].adultAddCountDisable = false;
          }
          if (roomList[index].childrenAddCountDisable === true) {
            roomList[index].childrenAddCountDisable = false;
          }
        } else if (roomList[index].adults === 1) {
          roomList[index].adultMinusCountDisable = true;
        }
      }
    } else {
      if (roomList[index].id === id) {
        roomList[index].childDetails.pop();
        if (roomList[index].children >= 1) {
          roomList[index].children = roomList[index].children - 1;
          roomList[index].childrenMinusCountDisable = false;
          if (roomList[index].childrenAddCountDisable === true) {
            roomList[index].childrenAddCountDisable = false;
          }
          if (roomList[index].adultAddCountDisable === true) {
            roomList[index].adultAddCountDisable = false;
          }
        } else if (roomList[index].children === 0) {
          roomList[index].childrenMinusCountDisable = true;
        }
      }
    }
    await this.setState({ roomList: [...roomList] });
  };
  isChildrenAge = (index, ind, type, age) => {
    let { roomList } = this.state;
    if (type === "isOpen") {
      roomList[index].childDetails[ind].isOpen =
        !roomList[index].childDetails[ind].isOpen;
    } else {
      roomList[index].childDetails[ind].age = age;
      roomList[index].childDetails[ind].isOpen = false;
      if (roomList[index].childDetails[ind].isError === true) {
        roomList[index].childDetails[ind].isError = false;
      }
    }
    this.setState({ roomList: [...roomList] });
  };
  onChangeCheckInOutValue = (value, type) => {
    if (type === "startDate") {
      this.setState({ checkInValue: value });
    } else if (type === "endDate") {
      let differenceInDays = differenceInCalendarDays(
        new Date(value),
        new Date(this.state.checkInValue)
      );
      if (differenceInDays === 0) {
        this.setState({ checkOutValue: addDays(new Date(value), 1) }, () => {
          if (!commonFunction.isValueEmpty(value)) {
            let checkInOut =
              commonFunction.getDateWithFormat(
                this?.state?.checkInValue,
                this?.props?.dateFormat
              ) +
              " - " +
              commonFunction.getDateWithFormat(
                this?.state?.checkOutValue,
                this?.props?.dateFormat
              );
            this.setState({ checkInOutValue: checkInOut });
          }
        });
      } else {
        this.setState({ checkOutValue: value }, () => {
          if (!commonFunction.isValueEmpty(value)) {
            let checkInOut =
              commonFunction.getDateWithFormat(
                this?.state?.checkInValue,
                this?.props?.dateFormat
              ) +
              " - " +
              commonFunction.getDateWithFormat(value, this?.props?.dateFormat);
            this.setState({ checkInOutValue: checkInOut });
          }
        });
      }
    }
  };
  onChangeSetHotelName = (value) => {
    this.setState({ hotelName: value, getDestinationId: "" }, () => {
      if (this.state.isError.hotelName === true) {
        this.validateErrorMsg();
      }
      this.props.getAutoSuggestValue(value);
    });
  };
  validateErrorMsg = () => {
    const mapDispatch = {
      hotelName: commonFunction.isValueEmpty(this.state.hotelName)
        ? true
        : false,
      checkInOutDate: commonFunction.isValueEmpty(this.state.checkInOutValue)
        ? true
        : false,
      roomGuest: commonFunction.isValueEmpty(this.state.selectedRoomDetails)
        ? true
        : false,
      selectedNationality: commonFunction.isValueEmpty(
        this.state.selectedNationality.value
      )
        ? true
        : false,
    };
    this.setState({ isError: mapDispatch });
  };
  onClickSearchHotel = () => {
    const mapDispatch = {
      hotelName: commonFunction.isValueEmpty(this.state.hotelName)
        ? true
        : false,
      checkInOutDate: commonFunction.isValueEmpty(this.state.checkInOutValue)
        ? true
        : false,
      roomGuest: commonFunction.isValueEmpty(this.state.selectedRoomDetails)
        ? true
        : false,
      selectedNationality: commonFunction.isValueEmpty(
        this.state.selectedNationality.value
      )
        ? true
        : false,
    };
    if (!this.props?.userProfile?.type) {
      mapDispatch.selectedNationality = commonFunction.isValueEmpty(
        this.state.selectedNationality.value
      )
        ? false
        : true;
    }
    // if (this.props?.userProfile?.type !== "B2B") {
    //   mapDispatch.selectedNationality = commonFunction.isValueEmpty(
    //     this.state.selectedNationality
    //   )
    //     ? false
    //     : true;
    // }
    mapDispatch.hotelName = this.state.getDestinationId ? false : true;
    this.setState({ isError: mapDispatch }, () => {
      let Validate = true;
      for (const [key, value] of Object.entries(this.state.isError)) {
        if (value === true) {
          Validate = false;
          break;
        }
      }
      if (Validate === true) {
        let hotelNameArry = this.state.hotelName.split(",");
        let roomListArr = [];
        this.state.roomList.map((res, id) => {
          roomListArr = [
            ...roomListArr,
            res.adults + "a-" + res.children + "c",
          ];
          res.childDetails.map((ele) => {
            roomListArr[id] = roomListArr[id].concat("-", ele.age);
          });
        });
        //IF CHECKOUT DATE IS EMPTY
        if (!this.state.checkOutValue) {
          this.state.checkOutValue = commonFunction.getDateWithFormat(
            addDays(new Date(this.state.checkInValue), 2),
            this?.props?.dateFormat
          );
        }
        let starb2bFilter = [...new Set(this.state.response)];
        let urlQuery = {
          region: hotelNameArry[0]?.trim(),
          country:
            this.props.isSRP === true
              ? hotelNameArry[1]?.trim()
              : hotelNameArry[hotelNameArry.length - 1]?.trim(),
          checkin: format(new Date(this.state.checkInValue), "yyyy-MM-dd"),
          checkout: format(new Date(this.state.checkOutValue), "yyyy-MM-dd"),
          rooms: roomListArr.join("_"),
          type: "location",
          roomtype: "single",
          page: 1,
          orderby: "desc",
          mop: "accrual",
          destid: this.state.getDestinationId,
          search_type: this.state.getSearchType,
          qc: "",
          nationallity: undefined,
          guests: undefined,
          is_web_app: false,
          ratings: starb2bFilter.join("_"),
          bf: this.state.selectedCheckBoxes?.breakfast?.[0]
            ? this.state.selectedCheckBoxes?.breakfast?.[0]
            : null,
          withoutbf: this.state.selectedCheckBoxes?.breakfast?.[1]
            ? this.state.selectedCheckBoxes?.breakfast?.[1]
            : null,
          rf: this.state.selectedCheckBoxes?.room_type?.[0]
            ? this.state.selectedCheckBoxes?.room_type?.[0]
            : null,
          nonrf: this.state.selectedCheckBoxes?.room_type?.[1]
            ? this.state.selectedCheckBoxes?.room_type?.[1]
            : null,
          nationality: this.state.nationallitySearch,
          guestId: this.state.guest_id,
          is_web_app: false,
          hotelLocation: this.state.hotelName,
        };
        commonFunction.redirectTo("/hotel/search", urlQuery);
      }
    });
  };
  onClickSetHotelName = (element) => {
    this.setState({
      hotelName: element.search_text,
      getDestinationId: element.destination_id,
      getSearchType: element.search_type ? element.search_type : 6,
    });
  };
  getCheckInOutValue = (checkIn, checkOut) => {
    let checkInArr = checkIn.split("/");
    let checkOutArr = checkOut.split("/");
    return checkIn + " - " + checkOut;
  };
  onClickVisibilityStatus = (type, value) => {
    switch (type) {
      case "hotel":
        this.props.expandHotelNameBooleanfunc(value);
        if (
          this.state.expandCheckInOutDateBoolean === true ||
          this.state.openRoomDetailList === true
        ) {
          this.setState({
            expandCheckInOutDateBoolean: false,
            openRoomDetailList: false,
          });
        }
        break;
      case "calendar":
        this.setState({ expandCheckInOutDateBoolean: value }, () => {
          if (
            this.props.expandHotelNameBoolean === true ||
            this.state.openRoomDetailList === true
          ) {
            this.setState({ openRoomDetailList: false });
            this.props.expandHotelNameBooleanfunc(false);
          }
        });
        break;
      case "rooms":
        if (this.state.openRoomDetailList === false) {
          this.setState({ openRoomDetailList: true }, () => {
            if (
              this.props.expandHotelNameBoolean === true ||
              this.state.expandCheckInOutDateBoolean === true
            ) {
              this.setState({ expandCheckInOutDateBoolean: false });
              this.props.expandHotelNameBooleanfunc(false);
            }
          });
        } else {
          this.onClickDoneButton();
        }
        break;
      default:
        break;
    }
  };
  onClickHotelInput = () => {
    this.props.getAutoSuggestValue("");
  };
  // renderInfo = () => {
  //     this.props.getAutoSuggestValue(regions)
  // }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //     if (regions != null && regions != "" && regions != undefined && !this.props.isSRP) {
  //         if (prevProps.getAutoSuggestList.length < this.props.getAutoSuggestList.length) {
  //             prevProps.getAutoSuggestList = this.props.getAutoSuggestList
  //             this.setState({
  //                 hotelName: prevProps.getAutoSuggestList[0].search_text, getDestinationId: prevProps.getAutoSuggestList[0].destination_id,
  //                 getSearchType: prevProps.getAutoSuggestList[0].search_type,
  //             }, () => {
  //                 this.props.expandHotelNameBooleanfunc(false)
  //             })
  //         }
  //     }
  // }

  onClickTraveller = () => {
    if (!this.state.openTraveler) {
      this.setState({ openTraveler: true });
    } else {
      this.setState({ openTraveler: false });
    }
  };

  onClickAdvSearch = () => {
    if (!this.state.advSearch) {
      this.setState({ advSearch: true });
    } else {
      this.setState({ advSearch: false });
    }
  };

  onClickNationality = () => {
    if (!this.state.nationallitySearch) {
      this.setState({ nationallitySearch: true });
    } else {
      this.setState({ nationallitySearch: false });
    }
  };

  onTickAdvSearch = () => {
    commonFunction.redirectTo("/hotel/search", urlQuery);
  };

  applyb2bFilters = (filterName, event, checkbox, index) => {
    console.log("filterName, event", filterName, event);
    switch (filterName) {
      case "star":
        const { value, checked } = event.target;
        if (checked && !this.state.response.includes(value)) {
          this.setState({ response: [...this.state.response, value] });
        } else {
          this.setState({
            response: this.state.response.filter((val) => {
              return val !== value;
            }),
          });
        }
        break;
      default:
        break;
    }
  };

  handleStarCheck = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  selectNationality = (params) => {
    this.setState({ selectedNationality: params });
    this.setState({ nationallitySearch: params.countryCode });
  };

  onChangeNationalityCode = (params) => {
    console.log("params", params);
    this.setState({ selectedNationality: { flag: "/images/ax-tick.svg" } });
  };

  handleCheckboxSelection = (event, index) => {
    console.log("handleCheckboxSelection", event, index);

    let updatedSelections = new Array(index + 1).fill(false);
    updatedSelections[index] = event.target.checked;

    this.setState((prev) => ({
      selectedCheckBoxes: {
        ...prev.selectedCheckBoxes,
        [event.target.name]: updatedSelections,
      },
    }));
  };

  adGuestId = (id) => {
    this.setState({ guest_id: id }, () => {
      if (this.state.isError.selectedGuest === true) {
        // this.validateErrorMsg();
      }
      this.setState({ guest_id: id });
    });
  };

  rmGuestId = () => {
    this.setState({ guest_id: "" });
  };

  onClickStarSearch = () => {
    if (!this.state.showStarSearch) {
      this.setState({ showStarSearch: true });
    } else {
      this.setState({ showStarSearch: false });
    }
  };

  onClickRoomTypeSearch = () => {
    if (!this.state.showRoomTypeSeach) {
      this.setState({ showRoomTypeSeach: true });
    } else {
      this.setState({ showRoomTypeSeach: false });
    }
  };

  onClickBreakfastSearch = () => {
    if (!this.state.showBreakfastSearch) {
      this.setState({ showBreakfastSearch: true });
    } else {
      this.setState({ showBreakfastSearch: false });
    }
  };

  onClickClientNationality = (nationality, id, email, isAdd) => {
    console.log("params", id, email);
    console.log("isAdd", isAdd);
    console.log("nationality", nationality);
    if (isAdd) {
      let nationalityList = { value: nationality, countryCode: "" };
      this.setState({ selectedNationality: nationalityList });
      this.setState({ clientNationality: nationalityList });
    } else {
      let nationalityList = { value: "", countryCode: "" };
      this.setState({ selectedNationality: nationalityList });
      this.setState({ clientNationality: nationalityList });
    }
  };

  render() {
    return (
      <div className={this.props.styles.mob_hotel_search_wid_container}>
        <div className={this.props.styles.mob_hotel_search_wid_main}>
          <div id={this.props.styles.tab_submenu_content}>
            <div
              className={
                this.props.styles.landing_tab_submenu_new +
                " " +
                this.props.styles.sub_tab_new
              }
            >
              <div className={this.props.styles.mobile_visible}>
                <div
                  className={
                    this.props.styles.mobo_tab_header +
                    " " +
                    this.props.styles.flight_header_mobo
                  }
                >
                  {this.props.isSRP ? (
                    <img
                      src="/images/blue-backarrow.svg"
                      alt=""
                      className={this.props.styles.arrow_image}
                      onClick={() => this.props.onClosePopup(false)}
                    />
                  ) : (
                    <img
                      src="/images/blue-backarrow.svg"
                      alt=""
                      className={this.props.styles.arrow_image}
                      onClick={() => this.props.onClosePopup(false)}
                    />
                  )}
                  <h3 className={this.props.styles.search_filter_header}>
                    {" "}
                    {this.props.isSRP ? "Modify search" : "Hotel"}
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className={this.props.styles.mob_hotel_search_details_container}>
            <div className={this.props.styles.mob_hotel_search_details_main}>
              {!this.props.isSRP && this.props?.userProfile?.type === "B2B" && (
                <div
                  style={{ paddingTop: 20 }}
                  className={this.props.styles.hotelNationBtob}
                >
                  {this.state.originCountries &&
                    this.state.originCountries?.length > 0 && (
                      <NationalityDropdownComp
                        theme="theme4"
                        countryFlag={this.state.originCountries}
                        searchText={
                          this.state.clientNationality.value == ("" || null)
                            ? ""
                            : this.state.clientNationality.value
                        }
                        // searchText=""
                        selectNationality={this.selectNationality}
                        selectedNationality={this.state.selectedNationality}
                        onChangeNationalityCode={this.onChangeNationalityCode}
                        placeholder={"Select Nationality"}
                        // placeholder={this.state.clientNationality == ("" || undefined) ?"Select Nationality":this.state.clientNationality}
                        isSignUpNationality={false}
                        isB2B={true}
                        errorMessage={
                          this.state.selectedNationality.value == "" ||
                          this.state.selectedNationality?.flag
                            ? "Select Nationality"
                            : ""
                        }
                        isError={
                          this.state.selectedNationality.value == "" ||
                          this.state.clientNationality?.value == ""
                            ? this.state.isError.selectedNationality
                            : ""
                        }
                        // styles={this.props.styles}
                        {...this.props.styles}
                        {...this.props.Countrystyles}
                        // {...this.props.data.Countrystyles}
                      />
                    )}

                  <B2BWidgets
                    {...this.state}
                    theme="theme4"
                    isMobile={true}
                    styles={this.props.b2bStyles}
                    onClickTraveller={this.onClickTraveller}
                    onClickAdvSearch={this.onClickAdvSearch}
                    onClickSearchHotel={this.onClickSearchHotel}
                    onTickAdvSearch={this.onTickAdvSearch}
                    onClickNationality={this.onClickNationality}
                    applyb2bFilters={this.applyb2bFilters}
                    handleCheckboxSelection={this.handleCheckboxSelection}
                    selectedCheckBoxes={this.state.selectedCheckBoxes}
                    handleStarCheck={this.handleStarCheck}
                    isOneStar={this.state.isOneStar}
                    isTwoStar={this.state.isTwoStar}
                    isThreeStar={this.state.isThreeStar}
                    isFourStar={this.state.isFourStar}
                    isFiveStar={this.state.isFiveStar}
                    addGuestId={this.adGuestId}
                    removeGuestId={this.rmGuestId}
                    listWrapper={this.advanceSearchWrapper}
                    guestWrapper={this.guestDetailWrapper}
                    isOffline={this.props.isOffline}
                    errorMessage={this.props.isOffline ? "Select Traveler" : ""}
                    isError={this.state.isError.selectedGuest}
                    clientNationality={this.onClickClientNationality}
                  />
                </div>
              )}
              <div className={this.props.styles.mob_hotel_search_details_row}>
                <div
                  className={
                    this.props.styles.mob_hotel_search_column +
                    " " +
                    this.props.styles.mob_hotel_search_name
                  }
                >
                  <HotelAutoComplete
                    isDisable={this.props.isDisable === true}
                    label="Destination or Hotel Name"
                    styles={this.props.styles}
                    value={this.state.hotelName}
                    placholder="Where are you going?"
                    leftIcon={
                      <svg width="17" height="19">
                        <path
                          fill-rule="evenodd"
                          fill="#01445F"
                          d="M9.33 18.152c-.39.042-.49.063-.589.063a.707.707 0 0 1-.292-.063C3.569 15.937.656 12.24.656 8.26.656 3.791 4.283.155 8.741.155c4.458 0 8.084 3.636 8.084 8.105 0 3.98-2.913 7.677-7.495 9.892zM8.741 1.574c-3.111 0-6.021 3-6.021 6.686 0 3.33 1.838 6.476 6.021 8.463 4.183-1.987 6.669-5.133 6.669-8.463 0-3.686-2.992-6.686-6.669-6.686zm0 10.17A3.483 3.483 0 0 1 5.267 8.26a3.483 3.483 0 0 1 3.474-3.483 3.483 3.483 0 0 1 0 6.967zm0-5.547a2.063 2.063 0 0 0 0 4.127 2.063 2.063 0 0 0 0-4.127z"
                        ></path>
                      </svg>
                    }
                    onClickHotelInput={() => this.onClickHotelInput()}
                    onClickHotelName={(value) =>
                      this.onClickSetHotelName(value)
                    }
                    onChangeHotelName={(value) =>
                      this.onChangeSetHotelName(value)
                    }
                    isAutoCompleteExpand={(value) =>
                      this.onClickVisibilityStatus("hotel", value)
                    }
                    autoCompleteData={
                      !commonFunction.isValueEmpty(
                        this.props.getAutoSuggestList
                      )
                        ? this.props.getAutoSuggestList
                        : []
                    }
                    expandAutoCompleteBool={
                      !commonFunction.isValueEmpty(
                        this.props.expandHotelNameBoolean
                      )
                        ? this.props.expandHotelNameBoolean
                        : false
                    }
                    // leftIcon={'/images/et-location.png'}
                    errorMessage={
                      this.state.hotelName != ""
                        ? ""
                        : "Please select your Destination"
                    }
                    isError={this.state.isError.hotelName}
                  />
                </div>
                <div
                  className={
                    this.props.styles.mob_hotel_search_column +
                    " " +
                    this.props.styles.mob_hotel_search_date
                  }
                >
                  <MobileCalender
                    // label="Check In & Check Out Date"
                    styles={this.props.styles}
                    value={
                      !commonFunction.isValueEmpty(this.state.checkInOutValue)
                        ? this.state.checkInOutValue
                        : ""
                    }
                    placholder="Select the date"
                    onChangeDate={(type, value) => {
                      this.onChangeCheckInOutValue(type, value);
                    }}
                    iconImage="/images/cyan-calendar.svg"
                    startDate={
                      !commonFunction.isValueEmpty(this.state.checkInValue)
                        ? this.state.checkInValue
                        : ""
                    }
                    endDate={
                      !commonFunction.isValueEmpty(this.state.checkOutValue)
                        ? this.state.checkOutValue
                        : ""
                    }
                    startDateLimit={
                      !commonFunction.isValueEmpty(this.state.dateLimit)
                        ? this.state.dateLimit
                        : ""
                    }
                    dateFormat={"DD-MMM-YYYY"}
                    changeVisibility={(bool) =>
                      this.onClickVisibilityStatus("calendar", bool)
                    }
                    isVisible={this.state.expandCheckInOutDateBoolean}
                    type={"double"}
                    doubleSelection={true}
                    totalMonths={12}
                    serviceType="hotel"
                  />
                </div>
                <div
                  className={
                    this.props.styles.mob_hotel_search_column +
                    " " +
                    this.props.styles.mob_hotel_search_accomodation
                  }
                >
                  <div
                    className={this.props.styles.mob_hotel_cabin_class_cont}
                    onClick={() => this.onClickVisibilityStatus("rooms")}
                  >
                    <div
                      className={this.props.styles.mob_hotel_cabin_class_icon}
                    >
                      {/* <img src="/images/room-guest.svg" className={this.props.styles.mob_hotel_cabin_class_icon_img} alt=""/> */}
                    </div>
                    <label>Guest(s)</label>
                    <div className={this.props.styles.mob_guestFlx}>
                      <span>
                        <svg width="21" height="21">
                          <path
                            fill-rule="evenodd"
                            fill="#01445F"
                            d="M10.566 20.332C5.181 20.332.8 15.951.8 10.566S5.181.8 10.566.8s9.766 4.381 9.766 9.766-4.381 9.766-9.766 9.766zm-5.587-3.143a8.629 8.629 0 0 0 5.587 2.046c2.127 0 4.077-.77 5.587-2.046-.209-2.704-3.952-3.002-5.587-3.002-1.635 0-5.378.298-5.587 3.002zm5.587-3.299c3.555 0 5.894.367 7.324 2.379.534-1.525 1.345-3.522 1.345-5.703 0-4.78-3.889-8.669-8.669-8.669-4.78 0-8.669 3.889-8.669 8.669 0 2.181.811 4.178 2.533 5.703.241-2.012 2.68-2.379 6.136-2.379zm0-2.776a3.625 3.625 0 0 1-3.621-3.621 3.625 3.625 0 0 1 3.621-3.621 3.626 3.626 0 0 1 3.621 3.621 3.625 3.625 0 0 1-3.621 3.621zm0-6.144c-1.392 0-2.146 1.132-2.146 2.523 0 1.392.754 2.677 2.146 2.677 1.391 0 3.334-1.285 3.334-2.677 0-1.391-1.943-2.523-3.334-2.523z"
                          ></path>
                        </svg>
                      </span>
                      <div
                        className={this.props.styles.mob_hotel_cabin_class_name}
                      >
                        {/* {!commonFunction.isValueEmpty(this.state.selectedRoomDetails) ? this.state.selectedRoomDetails.rooms + " Room, " + this.state.selectedRoomDetails.guest + " Guest" : "1 Adult ,0 Child , 1 Room"} */}
                        {!commonFunction.isValueEmpty(
                          this.state.selectedRoomDetails
                        )
                          ? this.state.selectedRoomDetails.adult +
                            " Adult, " +
                            this.state.selectedRoomDetails.child +
                            " Child, " +
                            this.state.selectedRoomDetails.rooms +
                            " Room "
                          : // this.state.selectedRoomDetails.guest +
                            // " Guest"
                            "1 Adult ,0 Child , 1 Room"}
                      </div>
                    </div>
                  </div>

                  {this.state.openRoomDetailList === true ? (
                    <div className={this.props.styles.hotel_room_list}>
                      {/* <svg className={this.props.styles.hotel_arrow_pax} width="151" height="101" viewBox="0 0 151 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M75.5 1.3811L140.885 76.0953H10.1151L75.5 1.3811Z" fill="white"/>
                      <line y1="-1.5" x2="100.715" y2="-1.5" transform="matrix(0.666347 -0.745642 0.939264 0.343196 9.5873 76.4784)" stroke="#2FC7C9" stroke-width="3"/>
                      <path d="M76.0269 1.19861L140.214 76.0953" stroke="#2FC7C9" stroke-width="3"/>
                      </svg> */}
                      <div className={this.props.styles.hotel_room_list_main}>
                        <table className={this.props.styles.table_head}>
                          <thead>
                            <tr>
                              <th>
                                Rooms <br />
                                <span
                                  className={this.props.styles.table_subhead}
                                >
                                  {" "}
                                  (Max 4)
                                </span>
                              </th>
                              <th>
                                Adults
                                <br />
                                <span
                                  className={this.props.styles.table_subhead}
                                >
                                  {" "}
                                  (12+ yrs)
                                </span>
                              </th>
                              <th>
                                Children <br />
                                <span
                                  className={this.props.styles.table_subhead}
                                >
                                  {" "}
                                  (0-12 yrs)
                                </span>
                              </th>
                            </tr>
                          </thead>
                        </table>
                        {this.state.roomList.map((element, index) => {
                          return (
                            <div
                              className={
                                this.props.styles.hotel_room_detail_ele
                              }
                              key={index}
                            >
                              <table className={this.props.styles.table_body}>
                                <tbody>
                                  <tr>
                                    <td>
                                      <div style={{ display: "flex" }}>
                                        {index > 0 ? (
                                          <div
                                            className={
                                              this.props.styles
                                                .hotel_room_remove
                                            }
                                            onClick={() =>
                                              this.removeRoomItem(element.id)
                                            }
                                          >
                                            X
                                          </div>
                                        ) : null}
                                        <>
                                          {index == 0 ? (
                                            <div
                                              className={
                                                this.props.styles
                                                  .hotel_remove_disabled
                                              }
                                            >
                                              X
                                            </div>
                                          ) : null}
                                          <div
                                            className={
                                              this.props.styles
                                                .hotel_adult_accomodation_tag
                                            }
                                          >
                                            <p>Room {index + 1}</p>
                                          </div>
                                        </>
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className={
                                          this.props.styles
                                            .hotel_accomodation_conf
                                        }
                                      >
                                        <span
                                          className={
                                            element.adultMinusCountDisable
                                              ? this.props.styles
                                                  .disbale_traveller +
                                                " " +
                                                this.props.styles
                                                  .hotel_accomodation_icon
                                              : this.props.styles
                                                  .hotel_accomodation_icon
                                          }
                                          onClick={() =>
                                            this.minusAccomodationCount(
                                              element.id,
                                              index,
                                              "adult"
                                            )
                                          }
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            id="minus"
                                          >
                                            <path
                                              fill="#00A4F3"
                                              d="M19,11H5a1,1,0,0,0,0,2H19a1,1,0,0,0,0-2Z"
                                            ></path>
                                          </svg>
                                        </span>
                                        <div
                                          className={this.props.styles.ac_count}
                                        >
                                          {element.adults}
                                        </div>
                                        <span
                                          className={
                                            element.adultAddCountDisable
                                              ? this.props.styles
                                                  .disbale_traveller +
                                                " " +
                                                this.props.styles
                                                  .hotel_accomodation_icon
                                              : this.props.styles
                                                  .hotel_accomodation_icon
                                          }
                                          onClick={() =>
                                            this.addAccomodationCount(
                                              element.id,
                                              index,
                                              "adult"
                                            )
                                          }
                                        >
                                          {/* <img src="/images/plus.png" className={this.this.props.styles.hotel_accomodation_icon} /> */}
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            id="plus"
                                          >
                                            <path
                                              fill="#00A4F3"
                                              d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
                                            ></path>
                                          </svg>
                                        </span>
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        className={
                                          this.props.styles
                                            .hotel_accomodation_conf
                                        }
                                      >
                                        <span
                                          className={
                                            element.childrenMinusCountDisable ||
                                            element.children < 1
                                              ? this.props.styles
                                                  .disbale_traveller +
                                                " " +
                                                this.props.styles
                                                  .hotel_accomodation_icon
                                              : this.props.styles
                                                  .hotel_accomodation_icon
                                          }
                                          onClick={() =>
                                            this.minusAccomodationCount(
                                              element.id,
                                              index,
                                              "children"
                                            )
                                          }
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            id="minus"
                                          >
                                            <path
                                              fill="#00A4F3"
                                              d="M19,11H5a1,1,0,0,0,0,2H19a1,1,0,0,0,0-2Z"
                                            ></path>
                                          </svg>
                                        </span>
                                        <div
                                          className={this.props.styles.ac_count}
                                        >
                                          {element.children}
                                        </div>
                                        <span
                                          className={
                                            element.childrenAddCountDisable
                                              ? this.props.styles
                                                  .disbale_traveller +
                                                " " +
                                                this.props.styles
                                                  .hotel_accomodation_icon
                                              : this.props.styles
                                                  .hotel_accomodation_icon
                                          }
                                          onClick={() =>
                                            this.addAccomodationCount(
                                              element.id,
                                              index,
                                              "children"
                                            )
                                          }
                                        >
                                          {/* <img src="/images/plus.png" className={this.this.props.styles.hotel_accomodation_icon} /> */}
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            id="plus"
                                          >
                                            <path
                                              fill="#00A4F3"
                                              d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
                                            ></path>
                                          </svg>
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div
                                className={this.props.styles.hotel_room_head}
                              >
                                {/* <div
                                      className={
                                        this.props.styles
                                          .hotel_adult_accomodation_tag
                                      }
                                    >
                                      <p>
                                      Room {index + 1} (Max 8)
                                      </p>
                                    </div> */}
                                {/* {index > 0 ? (
                                    <div
                                      className={
                                        this.props.styles.hotel_room_remove
                                      }
                                      onClick={() =>
                                        this.removeRoomItem(element.id)
                                      }
                                    >
                                      Remove
                                    </div>
                                  ) : null} */}
                              </div>

                              <div
                                className={
                                  this.props.styles.child_row_container
                                }
                              >
                                {element.childDetails.length > 0 &&
                                  element.childDetails.map((item, ind) => {
                                    return (
                                      <div
                                        className={
                                          this.props.styles.child_row_main
                                        }
                                        key={ind}
                                      >
                                        <span>
                                          {/* <label>
                                              Child {ind + 1} Age
                                            </label> */}
                                          <input
                                            type="text"
                                            className={
                                              item.isError === true
                                                ? `${this.props.styles.selected_age} ${this.props.styles.selected_age_err}`
                                                : this.props.styles.selected_age
                                            }
                                            placeholder={
                                              "Child" + ` ${ind + 1} ` + "Age"
                                            }
                                            value={item.age}
                                            onClick={() =>
                                              this.isChildrenAge(
                                                index,
                                                ind,
                                                "isOpen"
                                              )
                                            }
                                            readOnly
                                          />
                                          {/* {item.isError === true ? (
                                              <span
                                                className={
                                                  this.props.styles
                                                    .err_message_text
                                                }
                                              >
                                                Please enter valid age
                                              </span>
                                            ) : null} */}
                                          <span
                                            className={
                                              this.props.styles
                                                .child_age_select_arrow
                                            }
                                            onClick={() =>
                                              this.isChildrenAge(
                                                index,
                                                ind,
                                                "isOpen"
                                              )
                                            }
                                          >
                                            <svg
                                              width="12"
                                              height="12"
                                              viewBox="0 0 11 7"
                                              fill="currentFill"
                                            >
                                              <path d="M5.464 6.389.839 1.769a.38.38 0 0 1 0-.535l.619-.623a.373.373 0 0 1 .531 0l3.74 3.73L9.47.61a.373.373 0 0 1 .531 0l.619.623a.38.38 0 0 1 0 .535l-4.624 4.62a.373.373 0 0 1-.531 0Z"></path>
                                            </svg>
                                          </span>
                                        </span>
                                        {item.isOpen === true ? (
                                          <div
                                            className={
                                              this.props.styles.age_list_cont
                                            }
                                          >
                                            <div
                                              className={
                                                this.props.styles.age_list_main
                                              }
                                            >
                                              {childAgeList.map((ele, i) => {
                                                return (
                                                  <div
                                                    key={i}
                                                    className={
                                                      this.props.styles
                                                        .age_list_ele
                                                    }
                                                    onClick={() =>
                                                      this.isChildrenAge(
                                                        index,
                                                        ind,
                                                        "clickAge",
                                                        ele
                                                      )
                                                    }
                                                  >
                                                    <span>{ele}</span>
                                                  </div>
                                                );
                                              })}
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          );
                        })}
                        {this.state.roomList.length < 4 ? (
                          <div
                            className={this.props.styles.hotel_add_rooms}
                            onClick={() => {
                              this.setState({
                                roomList: [
                                  ...this.state.roomList,
                                  {
                                    id: this.state.roomList.length + 1,
                                    adults: 1,
                                    children: 0,
                                    adultAddCountDisable: false,
                                    adultMinusCountDisable: true,
                                    childrenAddCountDisable: false,
                                    childrenMinusCountDisable: true,
                                    childDetails: [],
                                  },
                                ],
                              });
                            }}
                          >
                            {/* <span> + </span> */}
                            <p style={{ paddingLeft: 3 }}>ADD ROOM </p>
                            {/* <span > - </span> */}
                          </div>
                        ) : null}
                      </div>
                      <div className={this.props.styles.done_button_main}>
                        <input
                          tabindex="4"
                          value="Done"
                          className={this.props.styles.done_button}
                          readOnly
                          onClick={() => this.onClickDoneButton()}
                          onBlur={this.focusOutDone}
                        />
                        {/* <button  className={this.props.styles.done_button} onClick={() => this.onClickDoneButton()}>Done</button> */}
                      </div>
                    </div>
                  ) : null}
                </div>
                {this.state.advSearch && (
                  <HotelAdvSearch
                    {...this.state}
                    styles={this.props.styles}
                    onClickTraveller={this.onClickTraveller}
                    onClickAdvSearch={this.onClickAdvSearch}
                    onClickSearchHotel={this.onClickSearchHotel}
                    onTickAdvSearch={this.onTickAdvSearch}
                    onClickNationality={this.onClickNationality}
                    applyb2bFilters={this.applyb2bFilters}
                    handleCheckboxSelection={this.handleCheckboxSelection}
                    selectedCheckBoxes={this.state.selectedCheckBoxes}
                    handleStarCheck={this.handleStarCheck}
                    isOneStar={this.state.isOneStar}
                    isTwoStar={this.state.isTwoStar}
                    isThreeStar={this.state.isThreeStar}
                    isFourStar={this.state.isFourStar}
                    isFiveStar={this.state.isFiveStar}
                    addGuestId={this.adGuestId}
                    removeGuestId={this.rmGuestId}
                    listWrapper={this.advanceSearchWrapper}
                    guestWrapper={this.guestDetailWrapper}
                    isOffline={this.props.isOffline}
                    errorMessage={"Select Traveler"}
                    isError={this.state.isError.selectedGuest}
                    isStarSearch={this.state.showStarSearch}
                    onClickStarSearch={this.onClickStarSearch}
                    isRoomTypeSearch={this.state.showRoomTypeSeach}
                    onClickRoomTypeSearch={this.onClickRoomTypeSearch}
                    isBreakfastSearch={this.state.showBreakfastSearch}
                    onClickBreakfastSearch={this.onClickBreakfastSearch}
                    allStar={this.state.allStar}
                    rtSearchWrapper={this.rtSearchWrapper}
                    bfSearchWrapper={this.bfSearchWrapper}
                    starSearchWrapper={this.starSearchWrapper}
                  />
                )}
                <div className={this.props.styles.mob_hotel_search_class}>
                  <button
                    className={this.props.styles.mob_hotel_search_widget_btn}
                    onClick={() => this.onClickSearchHotel()}
                  >
                    {/* <img className={this.props.styles.arrowwhite} src="images/whitearrowfront.svg" alt='arrow' />  */}
                    {this.props.isSRP ? "Modify" : "Hotels Search"}
                  </button>
                </div>
              </div>
              {/* <div className={this.props.styles.mob_hotel_search_details_row}>
                                <div className={this.props.styles.mob_hotel_search_class}>
                                    <button className={this.props.styles.mob_hotel_search_widget_btn} onClick={() => this.onClickSearchHotel()}>{this.props.isSRP ? 'Modify' : 'Search'}</button>
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
