import React, { Component } from "react";
import { format, parseISO, add } from "date-fns";
import commonFunction from "../../../../lib/utils/common";
import autoSuggest from "../../../../lib/autoSuggestExperience";
import MobileCalender from "@/commonComponents/calendar/MobileCalender";

const popularCities = [
  {
    destination_id: "12630",
    search_text: "Abu Dhabi",
    location_type: 1,
  },
  {
    destination_id: "12631",
    search_text: "Ajman",
    location_type: 1,
  },
  {
    destination_id: "12633",
    search_text: "Dubai",
    location_type: 1,
  },
  {
    destination_id: "12634",
    search_text: "Fujairah",
    location_type: 1,
  },
  {
    destination_id: "12635",
    search_text: "Ras Al Khaimah",
    location_type: 1,
  },
];

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
};
const fetchData = async (query, callBackFunc) => {
  if (query != "") {
    const res = await autoSuggest({ searchText: query });
    callBackFunc(res);
  } else {
    callBackFunc(null);
  }
};
const debouncedFetchData = debounce((query, cb) => {
  fetchData(query, cb);
}, 400);

export default class MobileTheme1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activityName: "",
      isAutoSuggestVisible: false,
      checkInOutValue: "",
      checkInValue: null,
      checkOutValue: null,
      isCalendarVisible: false,
      autoSuggestCountryData: [],
      autoSuggestCityData: popularCities,
      selectedAutoSuggestData: null,
      isError: false,
      isDateError: false,
    };
  }

  onChangeCheckInOutValue = (dateValue) => {
    console.log("date=value", dateValue);
    this.setState({
      checkInValue: commonFunction.getDateWithFormat(
        new Date(dateValue),
        this?.props?.dateFormat
      ),
      checkOutValue: commonFunction.getDateWithFormat(
        add(new Date(dateValue), { days: 1 }),
        this?.props?.dateFormat
      ),
      checkInOutValue: commonFunction.getDateWithFormat(
        dateValue,
        this?.props?.dateFormat
      ),
      isDateError: false,
    });
  };

  onChanegeSetActivityName = (value) => {
    this.setState({ activityName: value });
    if (value.trim() == "") {
      this.setState({
        autoSuggestCityData: popularCities,
        isAutoSuggestVisible: false,
        selectedAutoSuggestData: null,
        isError: false,
      });
    } else {
      debouncedFetchData(value, (data) => {
        if (
          data &&
          data.data &&
          data.data.searchBycity &&
          typeof data.data.searchBycity === "object"
        ) {
          this.setState({
            autoSuggestCityData: [...data.data.searchBycity.result],
            isAutoSuggestVisible: true,
          });
        } else if (data && data.data && data.data.status == false) {
          this.setState({
            autoSuggestCityData: popularCities,
            isAutoSuggestVisible: true,
          });
        }
        //   else if(data && data.data && data.data.searchBycountry && typeof (data.data.searchBycountry) === 'object'){
        //     this.setState({ autoSuggestCountryData: [...data.data.searchBycity.result] , isAutoSuggestVisible:true});
        // }
      });
    }
  };

  onClickSearchActivity = () => {
    if (
      !(
        this.state.selectedAutoSuggestData &&
        this.state.selectedAutoSuggestData.search_text
      )
    ) {
      this.setState({ isError: true });
    }
    if (this.state.checkInOutValue == "") {
      this.setState({ isDateError: true });
    }
    if (
      this.state.selectedAutoSuggestData &&
      this.state.selectedAutoSuggestData.search_text &&
      this.state.checkInOutValue
    ) {
      let urlQuery = {
        location: this.state.selectedAutoSuggestData.search_text,
        checkin: format(new Date(this.state.checkInValue), "yyyy-MM-dd"),
        checkout: format(new Date(this.state.checkOutValue), "yyyy-MM-dd"),
        from: 1,
        to: 30,
        search_type: 1,// this.state.selectedAutoSuggestData.location_type,
        search_text: this.state.selectedAutoSuggestData.destination_id,
        currency: "AED",
      };
      commonFunction.redirectTo("/experience/search", urlQuery);
    }
  };
  onClickSetActivityName = (element) => {
    this.setState({
      activityName: element.search_text,
      selectedAutoSuggestData: element,
      isError: false,
    });
  };
  render() {
    return (
      <div
        className=""
        onClick={(e) => {
          e.stopPropagation();
          if (this.state.isAutoSuggestVisible) {
            this.setState({
              isAutoSuggestVisible: false,
            });
          }
          if (this.state.isCalendarVisible) {
            this.setState({
              isCalendarVisible: false,
            });
          }
        }}
      >
        <div className={this.props?.cssfileMobile?.activity_way_title}>
          <img
            src="/images/back-arrow.png"
            alt=""
            class="arrow_image"
            onClick={(e) => {
              e.stopPropagation();
              this.props.onClosePopup();
            }}
          />
          <h2>Experiences</h2>
        </div>
        <div className={this.props?.cssfileMobile?.activity_search_wid_container}>
          <div className="activity_search_wid_main">
            {/* <div className={this.props.cssfileMobile.activity_way_title}>
                          <h2>Experiences</h2>
                      </div> */}
            <div
              className={
                this.props?.cssfileMobile?.activity_search_details_container
              }
            >
              <div
                className={
                  this.props?.cssfileMobile?.activity_search_details_main
                }
              >
                <div
                  className={
                    this.props?.cssfileMobile?.activity_search_details_row
                  }
                >
                  <div
                    className={
                      this.props?.cssfileMobile?.activity_search_column +
                      " " +
                      this.props.cssfileMobile?.activity_search_name
                    }
                  >
                    <div
                      id="search-widget-main"
                      className={
                        this.props.cssfileMobile?.activity_search_wid_inputfield
                      }
                    >
                      <label>{`Enter Destination`}</label>
                      <div
                        className={
                          this.props.cssfileMobile
                            .activity_search_wid_inputfield_main
                        }
                      >
                        <span>
                          <img src="images/location-map-01.svg" alt=""/>
                        </span>
                        <input
                          type="text"
                          autoComplete={"off"}
                          id="myTextField"
                          placeholder={`City`}
                          className={
                            this.props.cssfileMobile.search_widget_input_new
                          }
                          value={
                            !commonFunction.isValueEmpty(
                              this.state.activityName
                            )
                              ? this.state.activityName
                              : ""
                          }
                          onChange={(event) => {
                            this.onChanegeSetActivityName(event.target.value);
                          }}
                          onClick={() => {
                            this.setState((prevState) => {
                              return {
                                isAutoSuggestVisible:
                                  !prevState.isAutoSuggestVisible,
                                isCalendarVisible: false,
                              };
                            });
                          }}
                        />
                        {this.state.isError && (
                          <div className={this.props.cssfileMobile.error}>
                            {"Please select your Destination"}
                          </div>
                        )}

                        {this.state.isAutoSuggestVisible === true ? (
                          <div
                            className={
                              this.props.cssfileMobile
                                .activity_autocomplete_value
                            }
                          >
                            {!commonFunction.isValueEmpty(
                              this.state.autoSuggestCountryData
                            ) &&
                              this.state.autoSuggestCountryData.length > 0 &&
                              this.state.countryData.map((element) => {
                                return (
                                  <div
                                    className={
                                      this.props.cssfileMobile
                                        .activity_autocomplete_element
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      this.setState({
                                        activityName: element.search_text,
                                        isAutoSuggestVisible: false,
                                      });
                                      this.onClickSetActivityName(element);
                                    }}
                                  >
                                    <img src="images/location-01.svg" alt=""/>
                                    <div>
                                      <div
                                        className={
                                          this.props.cssfileMobile
                                            .activity_autocomplete_name
                                        }
                                      >
                                        {element.search_text}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            {!commonFunction.isValueEmpty(
                              this.state.autoSuggestCityData
                            ) && this.state.autoSuggestCityData.length > 0 ? (
                              this.state.autoSuggestCityData.map((element) => {
                                return (
                                  <div
                                    key={`city-${element.search_text}`}
                                    className={
                                      this.props.cssfileMobile
                                        .activity_autocomplete_element
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      this.setState({
                                        activityName: element.search_text,
                                        isAutoSuggestVisible: false,
                                      });
                                      this.onClickSetActivityName(element);
                                    }}
                                  >
                                    <img src="images/location-01.svg" alt=""/>
                                    <div>
                                      <div
                                        className={
                                          this.props.cssfileMobile
                                            .activity_autocomplete_name
                                        }
                                      >
                                        {element.search_text}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div
                                key={`noresults`}
                                className={
                                  this.props.cssfileMobile
                                    .activity_autocomplete_element
                                }
                              >
                                <img src="images/location-01.svg" alt=""/>
                                <div>
                                  <div
                                    className={
                                      this.props.cssfileMobile
                                        .activity_autocomplete_name
                                    }
                                  >
                                    No Results Found
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div
                    className={`${this.props.cssfileMobile.activity_search_column} ${this.props.cssfileMobile.activity_search_date}`}
                  >
                    <MobileCalender
                      label="Date"
                      styles={this.props.cssfileMobile}
                      //  styles={styles}
                      value={
                        !commonFunction.isValueEmpty(this.state.checkInOutValue)
                          ? this.state.checkInOutValue
                          : ""
                      }
                      placholder="Select the date"
                      onChangeDate={(dateVal) => {
                        this.onChangeCheckInOutValue(dateVal);
                      }}
                      iconImage={"images/calendar-01.svg"}
                      startDate={commonFunction.getDateWithFormat(
                        new Date(),
                        this?.props?.dateFormat
                      )}
                      startDateLimit={commonFunction.getDateWithFormat(
                        new Date(),
                        this?.props?.dateFormat
                      )}
                      dateFormat={this?.props?.dateFormat}
                      changeVisibility={(bool) => {
                        console.log("calander visibile", bool);
                        this.setState({
                          isCalendarVisible: bool,
                          isAutoSuggestVisible: false,
                          isDateError: false,
                        });
                      }}
                      isVisible={this.state.isCalendarVisible}
                      doubleSelection={false}
                    />
                    {this.state.isDateError && (
                      <div className={this.props.cssfileMobile.errordate}>
                        {"Please select Date"}
                      </div>
                    )}
                  </div>
                  <div
                    className={`${this.props.cssfileMobile.activity_search_details_row} ${this.props.cssfileMobile.activity_nw}`}
                  >
                    <div
                      className={this.props.cssfileMobile.activity_search_class}
                    >
                      <button
                        className={
                          this.props.cssfileMobile.activity_search_widget_btn
                        }
                       
                        onClick={() => this.onClickSearchActivity()}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
