import React, { Component } from 'react';
import { format, addDays, differenceInCalendarDays } from 'date-fns';
import HotelAutoComplete from './HotelAutoComplete';
import MobileCalender from '../../../calendar/hotelCalender/theme2/MobileCalender';
// import commonFunction from '@/lib/utils/common';
import commonFunction from '../../../../lib/utils/common';

const childAgeList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
// let regions = ""
export default class Theme2 extends Component {
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
        this.state = {
            hotelName: "",
            expandHotelNameBoolean: false,
            checkInOutValue: "",
            checkInValue: commonFunction.getDateWithFormat(new Date(), this?.props?.dateFormat),
            checkOutValue: commonFunction.getDateWithFormat(addDays(new Date(), 2), this?.props?.dateFormat),
            dateLimit: commonFunction.getDateWithFormat(new Date(), this?.props?.dateFormat),
            openRoomDetailList: false,
            expandCheckInOutDateBoolean: false,
            selectedRoomDetails: { rooms: 1, guest: 2 },
            getAutoSuggestList: this.props.getAutoSuggestList,
            isError: { hotelName: false, checkInOutDate: false, roomGuest: false },
            getDestinationId: '',
            getSearchType: '',
            roomList: [{
                id: 1, adults: 2, children: 0, adultAddCountDisable: false, adultMinusCountDisable: false,
                childrenAddCountDisable: false, childrenMinusCountDisable: true, childDetails: []
            }],
        }
    }
    componentDidMount() {
        // regions = new URLSearchParams(window.location.search).get("region")
        if (!commonFunction.isValueEmpty(this.props) && this.props.isSRP === true && !commonFunction.isValueEmpty(this.props.query)) {
            let checkInArr = this.props.query.checkin.split('-'); let checkOutArr = this.props.query.checkout.split('-');
            let checkInValue = commonFunction.getDateWithFormat(new Date(checkInArr[0], Number(checkInArr[1]) - 1, checkInArr[2]), this?.props?.dateFormat);
            let checkOutValue = commonFunction.getDateWithFormat(new Date(checkOutArr[0], Number(checkOutArr[1]) - 1, checkOutArr[2]), this?.props?.dateFormat);
            let checkInOut = commonFunction.getDateWithFormat(new Date(checkInArr[0], Number(checkInArr[1]) - 1, checkInArr[2]), this?.props?.dateFormat) + ' - ' + commonFunction.getDateWithFormat(new Date(checkOutArr[0], Number(checkOutArr[1]) - 1, checkOutArr[2]), this?.props?.dateFormat);
            let roomsdetail = this.props.query.rooms.split('_');
            let totalRooms = roomsdetail.length; let totalAdult = 0; let totalChildren = 0; let roomListArr = [];
            roomsdetail.map((ele, index) => {
                let split = ele.split('-').filter(item => item);
                totalAdult = Number(split[0].charAt(0)) + totalAdult;
                totalChildren = Number(split[1].charAt(0)) + totalChildren;
                roomListArr = [...roomListArr, {
                    id: index + 1, adults: Number(split[0].charAt(0)), children: Number(split[1].charAt(0)), adultAddCountDisable: false, adultMinusCountDisable: true,
                    childrenAddCountDisable: false, childrenMinusCountDisable: Number(split[1].charAt(0)) == 1 ? false : true, childDetails: []
                }]
                split.map((element, id) => {
                    if (id > 1) roomListArr[index].childDetails = [...roomListArr[index].childDetails, { age: element, isOpen: false, isError: false }]
                })
            });
            this.setState({
                checkInOutValue: checkInOut, checkInValue: checkInValue, checkOutValue: checkOutValue, getDestinationId: this.props.query.destid,
                getSearchType: this.props.query.search_type, hotelName: this.props.query.region + ", " + this.props.query.country,
                selectedRoomDetails: { rooms: totalRooms, guest: totalAdult + totalChildren }, roomList: roomListArr
            }, () => {
                this.checkRoomDetails()
            })
        }
        else {
            // if (regions != null && regions != "" && regions != undefined) {
            //     this.renderInfo()
            // }
            this.setState({
                checkInOutValue: this.getCheckInOutValue(this.state.checkInValue, this.state.checkOutValue,),
            })
        }
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    checkRoomDetails() {
        let { roomList } = this.state;
        roomList.map((ele, index) => {
            let totalAccomodation = ele.adults + ele.children;
            if (totalAccomodation <= 12) {
                if (totalAccomodation === 12) {
                    roomList[index].adultAddCountDisable = true;
                    roomList[index].childrenAddCountDisable = true;
                } else if (totalAccomodation < 12) {
                    if (roomList[index].adultAddCountDisable === true && roomList[index].childrenAddCountDisable === true) {
                        roomList[index].adultAddCountDisable = false;
                        roomList[index].childrenAddCountDisable = false;
                    }
                    if (roomList[index].adults > 1) {
                        roomList[index].adultMinusCountDisable = false;
                    } else if (roomList[index].adults === 1) {
                        roomList[index].adultMinusCountDisable = true;
                        roomList[index].adultAddCountDisable = false;
                    }
                    if (roomList[index].children > 1) {
                        roomList[index].childrenMinusCountDisable = false;
                    } else if (roomList[index].children > 0) {
                        roomList[index].childrenMinusCountDisable = false;
                        roomList[index].childrenAddCountDisable = false;
                    }
                }
            }
        })
        this.setState({ roomList: [...roomList] });
    }
    handleClickOutside = e => {
        if (this.dropdownWrapperRef && !this.dropdownWrapperRef.contains(e.target)) {
            if (this.props.expandHotelNameBoolean === true) this.props.expandHotelNameBooleanfunc(false);
            if (this.state.expandCheckInOutDateBoolean === true || this.state.openRoomDetailList === true) {
                this.setState({ expandCheckInOutDateBoolean: false, openRoomDetailList: false });
            }
        }
    };
    setDropdownWrapperRef = (node) => {
        this.dropdownWrapperRef = node;
    }
    onClickDoneButton = () => {
        let totalGuest = 0;
        let count = 0;
        let Validation = true;
        let roomList = this.state.roomList;
        while (count <= roomList.length) {
            if (!commonFunction.isValueEmpty(roomList[count]) && !commonFunction.isValueEmpty(roomList[count].childDetails)
                && roomList[count].childDetails.length > 0) {
                roomList[count].childDetails.map((element, index) => {
                    if (commonFunction.isValueEmpty(element.age)) {
                        roomList[count].childDetails[index].isError = true;
                    }
                })
                Validation = roomList[count].childDetails.every(item => !commonFunction.isValueEmpty(item.age));
            }
            count++;
        }
        // this.setState({ roomList: [...roomList] })
        this.setState({ roomList: [...roomList] }, () => {
            if (Validation === true) {
                this.state.roomList.length > 0 && this.state.roomList.map(element => {
                    totalGuest = totalGuest + element.adults + element.children;
                })
                this.setState({ selectedRoomDetails: { rooms: this.state.roomList.length, guest: totalGuest } })
                if (this.state.openRoomDetailList === true) {
                    this.setState({ openRoomDetailList: false });
                }
            }
        });
    }

    removeRoomItem = (id) => {
        const filteredRoom = this.state.roomList.filter((item) => item.id !== id);
        this.setState({ roomList: [...filteredRoom] });
    }
    addAccomodationCount = async (id, index, type) => {
        let { roomList } = this.state;
        if (type === "adult") {
            if (roomList[index].id === id) {
                roomList[index].adults = roomList[index].adults + 1;
            }
        } else {
            if (roomList[index].id === id) {
                roomList[index].children = roomList[index].children + 1;
                roomList[index].childDetails = [...roomList[index].childDetails, { age: '', isOpen: false, isError: false }]
            }
        }
        let totalAccomodation = roomList[index].adults + roomList[index].children;
        if (totalAccomodation <= 7) {
            if (totalAccomodation === 7) {
                roomList[index].adultAddCountDisable = true;
                roomList[index].childrenAddCountDisable = true;
            } else if (totalAccomodation < 7) {
                if (roomList[index].adultAddCountDisable === true && roomList[index].childrenAddCountDisable === true) {
                    roomList[index].adultAddCountDisable = false;
                    roomList[index].childrenAddCountDisable = false;
                }
            }
            if (roomList[index].adults > 1) {
                roomList[index].adultMinusCountDisable = false;
            }
            if (roomList[index].children > 0) {
                roomList[index].childrenMinusCountDisable = false;
            }
            await this.setState({ roomList: [...roomList] });
        }
    }
    minusAccomodationCount = async (id, index, type) => {
        let { roomList } = this.state;
        if (type === "adult") {
            if (roomList[index].id === id) {
                if (roomList[index].adults >= 2) {
                    roomList[index].adults = roomList[index].adults - 1;
                    roomList[index].adultMinusCountDisable = false;
                    if (roomList[index].adultAddCountDisable === true) {
                        roomList[index].adultAddCountDisable = false;
                    }
                    if (roomList[index].childrenAddCountDisable === true) {
                        roomList[index].childrenAddCountDisable = false;
                    }
                } else if (roomList[index].adults === 1) {
                    roomList[index].adultMinusCountDisable = true;
                }
            }
        } else {
            if (roomList[index].id === id) {
                roomList[index].childDetails.pop();
                if (roomList[index].children >= 1) {
                    roomList[index].children = roomList[index].children - 1;
                    roomList[index].childrenMinusCountDisable = false;
                    if (roomList[index].childrenAddCountDisable === true) {
                        roomList[index].childrenAddCountDisable = false;
                    }
                    if (roomList[index].adultAddCountDisable === true) {
                        roomList[index].adultAddCountDisable = false;
                    }
                } else if (roomList[index].children === 0) {
                    roomList[index].childrenMinusCountDisable = true;
                }
            }
        }
        await this.setState({ roomList: [...roomList] });
    }
    isChildrenAge = (index, ind, type, age) => {
        let { roomList } = this.state;
        if (type === "isOpen") {
            roomList[index].childDetails[ind].isOpen = !roomList[index].childDetails[ind].isOpen;
        } else {
            roomList[index].childDetails[ind].age = age;
            roomList[index].childDetails[ind].isOpen = false;
            if (roomList[index].childDetails[ind].isError === true) {
                roomList[index].childDetails[ind].isError = false;
            }
        }
        this.setState({ roomList: [...roomList] })
    }
    onChangeCheckInOutValue = (value, type) => {
        if (type === "startDate") {
            this.setState({ checkInValue: value });
        } else if (type === "endDate") {
            let differenceInDays = differenceInCalendarDays(new Date(value), new Date(this.state.checkInValue));
            if (differenceInDays === 0) {
                this.setState({ checkOutValue: addDays(new Date(value), 1) }, () => {
                    if (!commonFunction.isValueEmpty(value)) {
                        let checkInOut = commonFunction.getDateWithFormat(this?.state?.checkInValue, this?.props?.dateFormat) + ' - ' + commonFunction.getDateWithFormat(this?.state?.checkOutValue, this?.props?.dateFormat);
                        this.setState({ checkInOutValue: checkInOut });
                    }
                })
            } else {
                this.setState({ checkOutValue: value }, () => {
                    if (!commonFunction.isValueEmpty(value)) {
                        let checkInOut = commonFunction.getDateWithFormat(this?.state?.checkInValue, this?.props?.dateFormat) + ' - ' + commonFunction.getDateWithFormat(value, this?.props?.dateFormat);
                        this.setState({ checkInOutValue: checkInOut });
                    }
                })
            }
        }
    }
    onChangeSetHotelName = (value) => {
        this.setState({ hotelName: value }, () => {
            if (this.state.isError.hotelName === true) {
                this.validateErrorMsg();
            }
            this.props.getAutoSuggestValue(value)
        })
    }
    validateErrorMsg = () => {
        const mapDispatch = {
            hotelName: commonFunction.isValueEmpty(this.state.hotelName)||"" ? true : false,
            checkInOutDate: commonFunction.isValueEmpty(this.state.checkInOutValue) ? true : false,
            roomGuest: commonFunction.isValueEmpty(this.state.selectedRoomDetails) ? true : false,
        }
        this.setState({ isError: mapDispatch })
    }
    onClickSearchHotel = () => {
        const mapDispatch = {
            hotelName: commonFunction.isValueEmpty(this.state.hotelName) ? true : false,
            checkInOutDate: commonFunction.isValueEmpty(this.state.checkInOutValue) ? true : false,
            roomGuest: commonFunction.isValueEmpty(this.state.selectedRoomDetails) ? true : false,
        }
        mapDispatch.hotelName =  this.state.getDestinationId ? false : true;
        this.setState({ isError: mapDispatch }, () => {
            let Validate = true;
            for (const [key, value] of Object.entries(this.state.isError)) {
                if (value === true) {
                    Validate = false;
                    break;
                }
            }
            if (Validate === true) {
                let hotelNameArry = this.state.hotelName.split(',');
                let roomListArr = [];
                this.state.roomList.map((res, id) => {
                    roomListArr = [...roomListArr, res.adults + 'a-' + res.children + 'c'];
                    res.childDetails.map(ele => {
                        roomListArr[id] = roomListArr[id].concat('-', ele.age)
                    })
                });
                //IF CHECKOUT DATE IS EMPTY
                if (!this.state.checkOutValue) {
                    this.state.checkOutValue = commonFunction.getDateWithFormat(addDays(new Date(this.state.checkInValue), 2), this?.props?.dateFormat);
                }
                let urlQuery = {
                    region: hotelNameArry[0]?.trim(), country: (this.props.isSRP === true) ? hotelNameArry[1]?.trim() : hotelNameArry[hotelNameArry.length - 1]?.trim(), checkin: format(new Date(this.state.checkInValue), 'yyyy-MM-dd'),
                    checkout: format(new Date(this.state.checkOutValue), 'yyyy-MM-dd'), rooms: roomListArr.join('_'), type: 'location', roomtype: 'single', page: 1,
                    orderby: 'desc', mop: 'accrual', destid: this.state.getDestinationId, search_type: this.state.getSearchType, ratings: '', qc: '',
                    nationallity: undefined, guests: undefined, is_web_app: false
                };
                commonFunction.redirectTo("/hotel/search", urlQuery);
            }
        })
    }
    onClickSetHotelName = (element) => {
        this.setState({
            hotelName: element.search_text, getDestinationId: element.destination_id,
            getSearchType: element.search_type ? element.search_type : 6
        })
    }
    getCheckInOutValue = (checkIn, checkOut) => {
        let checkInArr = checkIn.split('/');
        let checkOutArr = checkOut.split('/');
        return checkIn + ' - ' + checkOut;
    }
    onClickVisibilityStatus = (type, value) => {
        switch (type) {
            case "hotel":
                this.props.expandHotelNameBooleanfunc(value)
                if (this.state.expandCheckInOutDateBoolean === true || this.state.openRoomDetailList === true) {
                    this.setState({ expandCheckInOutDateBoolean: false, openRoomDetailList: false });
                }
                break;
            case "calendar":
                this.setState({ expandCheckInOutDateBoolean: value }, () => {
                    if (this.props.expandHotelNameBoolean === true || this.state.openRoomDetailList === true) {
                        this.setState({ openRoomDetailList: false });
                        this.props.expandHotelNameBooleanfunc(false);
                    }
                });
                break;
            case "rooms":
                if (this.state.openRoomDetailList === false) {
                    this.setState({ openRoomDetailList: true }, () => {
                        if (this.props.expandHotelNameBoolean === true || this.state.expandCheckInOutDateBoolean === true) {
                            this.setState({ expandCheckInOutDateBoolean: false });
                            this.props.expandHotelNameBooleanfunc(false);
                        }
                    });
                } else {
                    this.onClickDoneButton();
                }
                break;
            default:
                break;
        }
    }
    onClickHotelInput = () => {
        this.props.getAutoSuggestValue('')
    }
    // renderInfo = () => {
    //     this.props.getAutoSuggestValue(regions)
    // }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if (regions != null && regions != "" && regions != undefined && !this.props.isSRP) {
    //         if (prevProps.getAutoSuggestList.length < this.props.getAutoSuggestList.length) {
    //             prevProps.getAutoSuggestList = this.props.getAutoSuggestList
    //             this.setState({
    //                 hotelName: prevProps.getAutoSuggestList[0].search_text, getDestinationId: prevProps.getAutoSuggestList[0].destination_id,
    //                 getSearchType: prevProps.getAutoSuggestList[0].search_type,
    //             }, () => {
    //                 this.props.expandHotelNameBooleanfunc(false)
    //             })
    //         }
    //     }
    // }
    render() {
        return (
            <div className={this.props.styles.mob_hotel_search_wid_container}>
                <div className={this.props.styles.mob_hotel_search_wid_main}>
                    <div id={this.props.styles.tab_submenu_content}>
                        <div class={this.props.styles.landing_tab_submenu_new + " " + this.props.styles.sub_tab_new}>
                            <div class={this.props.styles.mobile_visible}>
                                <div class={this.props.styles.mobo_tab_header + " " + this.props.styles.flight_header_mobo}>
                                    {this.props.isSRP ? <img src="images/b-cancel.png" alt="" className={this.props.styles.arrow_image} onClick={() => this.props.onClosePopup(false)} /> : <img src="/images/back_arrow.png" alt="" className={this.props.styles.arrow_image} onClick={() => this.props.onClosePopup(false)} />}
                                    
                                    <div className={this.props.styles.flex_img_sec}>
                                            <div className={this.props.styles.modify_cancel}>
                                            
                                    </div>

                                    <h3 className={this.props.styles.search_filter_header}> {this.props.isSRP ? 'Update search' : 'Hotel Search'}</h3>
                                        <div className={this.props.styles.close_icon_sec}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={this.props.styles.mob_hotel_search_details_container}>
                        <div className={this.props.styles.mob_hotel_search_details_main}>
                            <div className={this.props.styles.mob_hotel_search_details_row}>
                                <div className={this.props.styles.mob_hotel_search_column + " " + this.props.styles.mob_hotel_search_name}>
                                    <HotelAutoComplete
                                        isDisable={this.props.isDisable === true}
                                        label="City / Area / Hotel Name"
                                        styles={this.props.styles}
                                        value={this.state.hotelName}
                                        placholder="City / Area / Hotel Name"
                                        onClickHotelInput={() => this.onClickHotelInput()}
                                        onClickHotelName={(value) => this.onClickSetHotelName(value)}
                                        onChangeHotelName={(value) => this.onChangeSetHotelName(value)}
                                        isAutoCompleteExpand={(value) => this.onClickVisibilityStatus("hotel", value)}
                                        autoCompleteData={!commonFunction.isValueEmpty(this.props.getAutoSuggestList) ? this.props.getAutoSuggestList : []}
                                        expandAutoCompleteBool={!commonFunction.isValueEmpty(this.props.expandHotelNameBoolean) ? this.props.expandHotelNameBoolean : false}
                                        leftIcon={'/images/et-location.png'}
                                        errorMessage={this.state.hotelName == ""?"Please select your Destination":""}
                                        isError={this.state.isError.hotelName}
                                    />
                                </div>
                                <div className={this.props.styles.mob_hotel_search_column + " " + this.props.styles.mob_hotel_search_date}>
                                    <MobileCalender
                                        // label="Check In & Check Out Date"
                                        styles={this.props.styles}
                                        value={!commonFunction.isValueEmpty(this.state.checkInOutValue) ? this.state.checkInOutValue : ""}
                                        placholder="Select the date"
                                        onChangeDate={(type, value) => { this.onChangeCheckInOutValue(type, value) }}
                                        iconImage="/images/new-calendar.svg"
                                        startDate={!commonFunction.isValueEmpty(this.state.checkInValue) ? this.state.checkInValue : ""}
                                        endDate={!commonFunction.isValueEmpty(this.state.checkOutValue) ? this.state.checkOutValue : ""}
                                        startDateLimit={!commonFunction.isValueEmpty(this.state.dateLimit) ? this.state.dateLimit : ""}
                                        dateFormat={"DD-MMM-YYYY"}
                                        changeVisibility={(bool) => this.onClickVisibilityStatus("calendar", bool)}
                                        isVisible={this.state.expandCheckInOutDateBoolean}
                                        type={"double"}
                                        doubleSelection={true}
                                        totalMonths={12}
                                        serviceType="hotel"
                                        theme={'theme2'}
                                    />
                                </div>
                                <div className={this.props.styles.mob_hotel_search_column + " " + this.props.styles.mob_hotel_search_accomodation}>
                                    <div className={this.props.styles.mob_hotel_cabin_class_cont} onClick={() => this.onClickVisibilityStatus("rooms")}>
                                        {/* <div className={this.props.styles.mob_hotel_cabin_class_icon}>
                                            <img src="/images/psgr.png" className={this.props.styles.mob_hotel_cabin_class_icon_img} alt=""/>
                                        </div> */}
                                        {/* <label>Adults & Rooms</label> */}
                                        <div className={this.props.styles.mob_hotel_cabin_class_name}>
                                            {!commonFunction.isValueEmpty(this.state.selectedRoomDetails) ? this.state.selectedRoomDetails.guest + " Adult(s), " + this.state.selectedRoomDetails.rooms + " Room" : "2 Adult(s), 1 Room"}
                                        </div>
                                    </div>
                                    {this.state.openRoomDetailList === true ? (
                                        <div className={this.props.styles.mob_hotel_room_list}>
                                            <div>
                                                <div className={this.props.styles.mob_hotel_room_list_main}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                                    <g id="Group_1398" data-name="Group 1398" transform="translate(-375 -557)">
                                                        <g id="Ellipse_35" data-name="Ellipse 35" transform="translate(375 557)" fill="#fff" stroke="#d9d9e3" stroke-width="2">
                                                        <circle cx="20" cy="20" r="20" stroke="none"/>
                                                        <circle cx="20" cy="20" r="19" fill="none"/>
                                                        </g>
                                                        <rect id="Rectangle_288" data-name="Rectangle 288" width="3.602" height="19.811" rx="1.801" transform="translate(386.723 571.269) rotate(-45)" fill="#d9d9e3"/>
                                                        <rect id="Rectangle_289" data-name="Rectangle 289" width="3.602" height="19.811" rx="1.801" transform="translate(389.27 585.278) rotate(-135)" fill="#d9d9e3"/>
                                                    </g>
                                                    </svg>

                                                    {this.state.roomList.map((element, index) => {
                                                        return (
                                                            <div className={this.props.styles.mob_hotel_room_detail_ele} key={index}>
                                                                <div className={this.props.styles.mob_hotel_room_head}>
                                                                    <div className={this.props.styles.mob_hotel_room_title}>
                                                                        Room {index + 1}
                                                                    </div>
                                                                    {index > 0 ? (
                                                                        <div className={this.props.styles.mob_hotel_room_remove} onClick={() => this.removeRoomItem(element.id)}>Remove</div>
                                                                    ) : null
                                                                    }
                                                                </div>
                                                                <div className={this.props.styles.mob_hotel_accomodation_main}>
                                                                    <div className={this.props.styles.mob_hotel_adult_accomodation}>
                                                                        <div className={this.props.styles.mob_hotel_adult_accomodation_tag}>
                                                                            <p>Adult</p>
                                                                            <span>(12 yrs & above)</span>
                                                                        </div>
                                                                        <div className={this.props.styles.mob_hotel_accomodation_conf}>
                                                                            <span className={element.adultMinusCountDisable ? this.props.styles.disbale_traveller : ''} onClick={() => this.minusAccomodationCount(element.id, index, "adult")}>
                                                                                <img src="/images/minus1.svg" className={this.props.styles.mob_hotel_accomodation_icon} alt=""/>
                                                                            </span>
                                                                            <div className={this.props.styles.ac_count}>{element.adults}</div>
                                                                            <span className={element.adultAddCountDisable ? this.props.styles.disbale_traveller : ''} onClick={() => this.addAccomodationCount(element.id, index, "adult")}>
                                                                                <img src="/images/plus1.svg" className={this.props.styles.mob_hotel_accomodation_icon} alt=""/>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mob_hotel_children_accomodation">
                                                                        <div className="mob_hotel_children_accomodation_tag">
                                                                            <p>Children</p>
                                                                            <span>(0 - 12 yrs)</span>
                                                                        </div>
                                                                        <div className={this.props.styles.mob_hotel_accomodation_conf}>
                                                                            <span className={element.childrenMinusCountDisable ? this.props.styles.disbale_traveller : ''} onClick={() => this.minusAccomodationCount(element.id, index, "children")}>
                                                                            <img src="/images/minus1.svg" className={this.props.styles.mob_hotel_accomodation_icon} alt=""/>
                                                                            </span>
                                                                            <div className={this.props.styles.ac_count}>{element.children}</div>
                                                                            <span className={element.childrenAddCountDisable ? this.props.styles.disbale_traveller : ''} onClick={() => this.addAccomodationCount(element.id, index, "children")}>
                                                                            <img src="/images/plus1.svg" className={this.props.styles.mob_hotel_accomodation_icon} alt=""/>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {element.childDetails.length > 0 && element.childDetails.map((item, ind) => {
                                                                    return (
                                                                        <div className={this.props.styles.child_row_container} key={ind}>
                                                                            <div className={this.props.styles.child_row_main}>
                                                                                <label>Age of Child {ind + 1}</label>
                                                                                <input type="text" className={this.props.styles.selected_age} placeholder="Select Age" value={item.age}
                                                                                    onClick={() => this.isChildrenAge(index, ind, 'isOpen')} readOnly />
                                                                                {item.isError === true ? (
                                                                                    <span className={this.props.styles.err_message_text}>Please enter valid age</span>
                                                                                ) : null}
                                                                                {item.isOpen === true ? (
                                                                                    <div className={this.props.styles.age_list_cont} >
                                                                                        <div className={this.props.styles.age_list_main}>
                                                                                            {childAgeList.map((ele, i) => {
                                                                                                return (
                                                                                                    <div key={i} className={this.props.styles.age_list_ele} onClick={() => this.isChildrenAge(index, ind, 'clickAge', ele)}>
                                                                                                        <span>{ele}</span>
                                                                                                    </div>)
                                                                                            })}
                                                                                        </div>
                                                                                    </div>) : null
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        )
                                                    })}
                                                    {this.state.roomList.length < 4 ? (
                                                        <div className={this.props.styles.mob_hotel_add_rooms} onClick={() => this.setState({
                                                            roomList: [...this.state.roomList, {
                                                                id: this.state.roomList.length + 1, adults: 1, children: 0,
                                                                adultAddCountDisable: false, adultMinusCountDisable: true, childrenAddCountDisable: false, childrenMinusCountDisable: true, childDetails: []
                                                            }]
                                                        })}>
                                                            {/* <span>+</span> */}
                                                            <button>Add Room</button>
                                                        </div>
                                                    ) : null
                                                    }
                                                    <div className={this.props.styles.done_button_main}>                                                   <button className={this.props.styles.done_button} onClick={() => this.onClickDoneButton()}>Done</button>                                           </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null
                                    }
                                </div>
                            </div>
                            <div className={this.props.styles.mob_hotel_search_details_row}>
                                <div className={this.props.styles.mob_hotel_search_class}>
                                    <button className={this.props.styles.mob_hotel_search_widget_btn} onClick={() => this.onClickSearchHotel()}><svg width="50px" height="50px" viewBox="0,0,256,256"><g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none"><g transform="scale(5.12,5.12)"><path d="M21,3c-9.39844,0 -17,7.60156 -17,17c0,9.39844 7.60156,17 17,17c3.35547,0 6.46094,-0.98437 9.09375,-2.65625l12.28125,12.28125l4.25,-4.25l-12.125,-12.09375c2.17969,-2.85937 3.5,-6.40234 3.5,-10.28125c0,-9.39844 -7.60156,-17 -17,-17zM21,7c7.19922,0 13,5.80078 13,13c0,7.19922 -5.80078,13 -13,13c-7.19922,0 -13,-5.80078 -13,-13c0,-7.19922 5.80078,-13 13,-13z"></path></g></g></svg>{this.props.isSRP ? 'Update Search' : 'Search'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}