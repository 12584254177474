import 'react-multi-carousel/lib/styles.css'
import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  SearchActivityHomeWidget,
  SearchHotelHomeWidget,
  autoSuggestHomeWidget,
} from '@/lib/searchHomeWidget'
import ActivityTheme2cssfile from '../../../../../common/components/widgets/activityWidget/theme2/index.module.css'
import AuthContext from 'store/auth-context'
import Carousel from 'react-multi-carousel'
import Countrystyles from '../../../../../common/components/CountryDropdown/theme1/index.module.css'
import CustomLink from '@/components/Link'
import DesktopCalenderThemeOneCss from '../../../../../common/components/widgets/flightSearchWidget/theme1/index.module.css'
import DesktopHotelStylesTheme2 from '../../../../../common/components/widgets/hotelSearchWidget/theme2/index.module.css'
import FlightSearchThemeTwoCSS from '../../../../../common/components/widgets/flightSearchWidget/theme2/index.module.css'
import FlightSearchWidget from '../../../../../common/components/widgets/flightSearchWidget'
import HideHeader from 'store/hideHeader'
import HotelSearchWidget from '../../../../../common/components/widgets/hotelSearchWidget'
import Image from 'next/image'
import SearchWidgetActivity from '../../../../../common/components/widgets/activityWidget/index'
import SearchWidgetExperience from '../../../../../common/components/widgets/experienceWidget'
import b2bWidgetStyle from '../../../../../common/components/b2bwidgets/theme1/index.module.css'
import cf from '@/lib/utils/common'
import configUrl from '../../../../../config/config.json'
import cssfile from '../../../../../common/components/widgets/experienceWidget/theme2/index.module.css'
import parse from 'html-react-parser'
import styles from './index.module.css'
import { useRouter } from 'next/router'

export default function Theme2Flow(props) {
  const [initFlightData, setInitFlightData] = useState([])
  const [getDepartureSuggestionList, setDepartureSuggestionList] = useState([])
  const [getGoingToSuggestionList, setGoingToSuggestionList] = useState([])
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const wrapperRef = useRef(null)
  const [expandHotelNameBoolean, setExpandHotelName] = useState(false)
  const [expandActivityName, setExpandActivityName] = useState(false)
  const [getHotelAutoSuggestList, setHotelAutoSuggestList] = useState([])
  const [getActivityAutoSuggestList, setActivityAutoSuggestList] = useState([])
  const [initHotelData, setInitHotelData] = useState([])
  const [homeBodyDesktop, setHomeBodyDesktop] = useState([])

  const [homeBodyMobile, setHomeBodyMobile] = useState([])
  const [hotelDefultDays, setHotelDefultDays] = useState(0)
  const [userProfile, setUserProfile] = useState('')
  const ctx = useContext(AuthContext)
  const [SerachWidget, setSerachWidget] = useState('flight')
  const HideHeaderTheme2 = useContext(HideHeader)
  const [headerSticky, setheaderSticky] = useState(HideHeaderTheme2)
  const [expName, setexpName] = useState('UAE Attractions')

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      // slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  }

  const homeIndex = props.home.data.data.body.desktop.findIndex(
    (items) => items.type === 'flight_search'
  )
  const hotelIndex = props?.home.data.data.body.desktop.findIndex(
    (items) => items.type === 'hotel_search'
  )
  const activityIndex = props?.home.data.data.body.desktop.findIndex(
    (items) => items.type === 'activity_search'
  )
  const expIndex = props?.home.data.data.body.desktop.findIndex(
    (items) => items.type === 'experience_search'
  )

  const router = useRouter()
  const query = router.query.index

  useEffect(() => {
    let userToken = cf.getCookieTocken('userToken')
    cf.getUserProfile(userToken, props?.site_id).then((res) => {
      if (res.status === true) {
        setUserProfile(ctx?.user)
      } else {
        setUserProfile(res)
      }
    })

    if (props?.home?.data?.data?.body?.desktop) {
      setHomeBodyDesktop(props?.home.data.data.body.desktop)

      if (hotelIndex > -1) {
        setHotelDefultDays(props?.home.data.data.body.desktop[hotelIndex].default_days)
      }
    }
    if (props?.home?.data?.data?.body?.mobile) setHomeBodyMobile(props.home.data.data.body.mobile)
    const style = document.createElement('style')
    if (
      !cf.isValueEmpty(props) &&
      !cf.isValueEmpty(props.home) &&
      !cf.isValueEmpty(props.home.data)
    ) {
      style.innerHTML = props.home.data.data.css
      document.head.appendChild(style)
    }
    setActiveItemIndex(activeItemIndex)

    if (!cf.isValueEmpty(props) && !cf.isValueEmpty(props.home) && props.home.status === true) {
      if (
        !cf.isValueEmpty(props.home.data.data) &&
        !cf.isValueEmpty(props.home.data.data.body) &&
        !cf.isValueEmpty(props.home.data.data.body.desktop) &&
        props.home.data.data.body.desktop.length > 0
      ) {
        let index = props.home.data.data.body.desktop.findIndex(
          (items) => items.type === 'flight_search'
        )
        // let hotelIndex = props.home.data.data.body.desktop.findIndex((items) => items.type === "hotel_search");

        if (index !== -1) {
          if (
            !cf.isValueEmpty(props.home.data.data.body.desktop[index].airports) &&
            props.home.data.data.body.desktop[index].airports.length > 0
          ) {
            // let sortedDefaultAirports = props?.home?.data?.data?.body?.desktop &&
            let sortedDefaultAirports = []
            if (
              props?.home?.data?.data?.body?.desktop &&
              props?.home?.data?.data?.body?.desktop[index] &&
              props?.home?.data?.data?.body?.desktop[index].airports &&
              props?.home?.data?.data?.body?.desktop[index].airports.length
            ) {
              sortedDefaultAirports = props?.home?.data?.data?.body?.desktop[index].airports.sort(
                (a, b) => {
                  return a.ORDER - b.ORDER
                }
              )
            }
            setInitFlightData([...sortedDefaultAirports])
            setDepartureSuggestionList([...sortedDefaultAirports])
            setGoingToSuggestionList([...sortedDefaultAirports])
          }
        }

        if (hotelIndex !== -1) {
          if (
            !cf.isValueEmpty(props.home.data.data.body.desktop[hotelIndex].locations) &&
            props.home.data.data.body.desktop[hotelIndex].locations.length > 0
          ) {
            setInitHotelData([...props.home.data.data.body.desktop[hotelIndex].locations])
            setHotelAutoSuggestList([...props.home.data.data.body.desktop[hotelIndex].locations])
          }
        }
      }
    }

    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [])

  useEffect(() => {
    if (window.location.pathname == '/') {
      setSerachWidget('flight')
    }
    if (window.location.pathname == '/hotels') {
      setSerachWidget('hotel')
    }
    if (window.location.pathname == '/activities') {
      setSerachWidget('activity')
    }
    if (window.location.pathname == '/experiences') {
      setSerachWidget('experience')
    }
  }, [query])

  const getAutoSuggestDataFunc = (value, section, type, event) => {
    switch (section) {
      case 'flight':
        if (event === 'click') {
          if (value.length === 0) {
            if (type === 'departure') {
              setDepartureSuggestionList([...initFlightData])
            } else {
              setGoingToSuggestionList([...initFlightData])
            }
          }
        } else {
          autoSuggestHomeWidget({ autoSuggest: value }).then((res) => {
            if (!cf.isValueEmpty(res) && res.status === true && !cf.isValueEmpty(res.data)) {
              if (type === 'departure') {
                setDepartureSuggestionList([...res.data])
              } else {
                setGoingToSuggestionList([...res.data])
              }
            }
          })
        }
        break
      case 'hotel':
        if (value.length > 0) {
          SearchHotelHomeWidget({ autoSuggest: value }).then((res) => {
            if (!cf.isValueEmpty(res) && res.status === true && !cf.isValueEmpty(res.data)) {
              if (res.data.status === 'success' && !cf.isValueEmpty(res.data.result)) {
                setHotelAutoSuggestList([...res.data.result.records])
                setExpandHotelName(true)
              }
            }
          })
        } else {
          setHotelAutoSuggestList([...initHotelData])
          setExpandHotelName(true)
        }
        break
      case 'activity':
        SearchActivityHomeWidget({ autoSuggest: value }).then((res) => {
          if (!cf.isValueEmpty(res) && !cf.isValueEmpty(res.data) && res.data.status === true) {
            if (
              !cf.isValueEmpty(res.data.searchBycity) &&
              res.data.searchBycity !== 'Not Found' &&
              res.data.searchBycity.status === 'success'
            ) {
              if (
                !cf.isValueEmpty(res.data.searchBycity.result) &&
                res.data.searchBycity.result.length > 0
              ) {
                getActivityAutoSuggestList.cityList = [...res.data.searchBycity.result]
                //     setActivityAutoSuggestList({ ...getActivityAutoSuggestList })
                setActivityAutoSuggestList([...res.data.searchBycity.result])
                if (expandActivityName === false) {
                  setExpandActivityName(true)
                }
              }
              if (
                !cf.isValueEmpty(res.data.searchBycountry) &&
                res.data.searchBycountry !== 'Not Found' &&
                res.data.searchBycountry.status === 'success'
              ) {
                if (
                  !cf.isValueEmpty(res.data.searchBycountry.result) &&
                  res.data.searchBycountry.result.length > 0
                ) {
                  getActivityAutoSuggestList.countryList = [...res.data.searchBycountry.result]
                  //      setActivityAutoSuggestList({ ...getActivityAutoSuggestList })
                  setActivityAutoSuggestList([
                    ...getActivityAutoSuggestList.cityList,
                    ...getActivityAutoSuggestList.countryList,
                  ])

                  if (expandActivityName === false) {
                    setExpandActivityName(true)
                  }
                }
              }
            }
          }
        })
        break
      default:
        break
    }
  }
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      if (expandHotelNameBoolean === true) setExpandHotelName(false)
    }
  }

  const imageLoader = ({ src }) => {
    return `${'/shared/api/media/' + props.site_id + '/' + src}`
  }

  // console.log("props",props)

  return (
    <>
      {/* carousal slider  starts */}

      {/* <div className={styles.img_banner_home_thtwo}>
                <img  className={styles.styleBanner} src='/theme2/logn_bg.png' />
           </div> */}
      <div className={styles.home_container}>
        <div style={{ margin: 0 }}>
          {homeBodyDesktop.map((res, i) => {
            return (
              <div key={'homeBodyDsktp' + i}>
                {!cf.isValueEmpty(res.type) && res.type == 'image_carousal' && (
                  <div style={{ margin: 0 }}>
                    <Carousel
                      swipeable={false}
                      draggable={false}
                      showDots={true}
                      responsive={responsive}
                      ssr={false} // means to render carousel on server-side.
                      infinite={true}
                      autoPlay={props.isMobile ? true : true}
                      autoPlaySpeed={3000}
                      keyBoardControl={true}
                      customTransition="all .5"
                      transitionDuration={500}
                      containerClass={styles.carouse_container2}
                      //  containerClass="carousel-container"
                      // deviceType={this.props.deviceType}
                      dotListClass="custom-dot-list-style"
                      itemClass="carousel-item-padding-40-px"
                    >
                      {!cf.isValueEmpty(res) &&
                        !cf.isValueEmpty(res.banners) &&
                        res.banners.length > 0 &&
                        res.banners.map((imagedata, index) => {
                          return (
                            //  <div>index====={index}</div>
                            //  <img style={{ height: 300, background: '#EEE' }} src={`${ '/shared/api/media/'+props.site_id+'/' + imagedata.image}`} />
                            <div
                              style={{ margin: 0 }}
                              key={'banner' + index}
                              className={styles.img_banner_home2}
                            >
                              <CustomLink href={imagedata.banner_url}>
                                <Image
                                  loader={imageLoader}
                                  src={imagedata.image}
                                  width={500}
                                  layout="fill"
                                  alt="image-carousel"
                                />
                              </CustomLink>
                              {/* <img src={`${ '/shared/api/media/' + props.site_id + '/' + imagedata.image}`} /> */}
                            </div>
                          )
                        })}
                    </Carousel>
                  </div>
                )}
              </div>
            )
          })}
        </div>
        {/* carousal slider end */}
        {/* tab navbar starts */}
        <div className={styles.widget_container}>
          <div className="container mx-auto offiletab xl:px-8">
            <div className={styles.header_hero_cont}>
              <h2>Let’s book your next trip!</h2>
              <p>Choose from over 1.5 million hotels & 450+ airlines</p>
            </div>
            <div className={styles.search}>
              <div className={styles.holidaymaintabnavbarrow}>
                <div className={styles.holidaycarouselcontainer}>
                  <nav className={styles.navbartab}>
                    <ul>
                      {homeBodyDesktop.map((res, i) => {
                        // console.log("homeBodyDesktop", res,i)
                        return (
                          <>
                            {res.type == 'flight_search' && (
                              <li
                                className={
                                  SerachWidget === 'flight'
                                    ? `${styles.activetab}`
                                    : `${styles.inactive}`
                                }
                                onClick={() => {
                                  window.open(res.url, '_self')
                                  setSerachWidget('flight')
                                }}
                              >
                                <div className={styles.service_icon}>
                                  <img
                                    // src="/shared/api/media/6124e1368ede4f08bf2d9ad4/60316087685508976Flight_header.svg"
                                    src="/images/widgetIcons/Flight_header.svg"
                                    alt=""
                                  />
                                </div>
                                {res.text}
                              </li>
                            )}
                            {res.type == 'hotel_search' && (
                              <li
                                className={
                                  SerachWidget === 'hotel'
                                    ? `${styles.activetab}`
                                    : `${styles.inactive}`
                                }
                                onClick={() => {
                                  window.open(res.url, '_self')
                                  setSerachWidget('hotel')
                                }}
                              >
                                <div className={styles.service_icon}>
                                  <img
                                    // src="/shared/api/media/6124e1368ede4f08bf2d9ad4/553509475291467969Hotel_Header.svg"
                                    src="/images/widgetIcons/Hotel_Header.svg"
                                    alt=""
                                  />
                                </div>
                                {res.text}
                              </li>
                            )}

                            {res.type == 'activity_search' && (
                              <li
                                className={
                                  SerachWidget == 'activity'
                                    ? `${styles.activetab}`
                                    : `${styles.inactive}`
                                }
                                onClick={() => {
                                  window.open(res.url, '_self')
                                  setSerachWidget('activity')
                                }}
                              >
                                <div className={styles.service_icon}>
                                  <img
                                    // src="/shared/api/media/6124e1368ede4f08bf2d9ad4/9483125240385631Activities_Header.svg"
                                    src="/images/widgetIcons/Activities_Header.svg"
                                    alt=""
                                  />
                                </div>
                                {res.text}
                              </li>
                            )}

                            {res.type == 'experience_search' && (
                              <li
                                className={
                                  SerachWidget == 'experience'
                                    ? `${styles.activetab}`
                                    : `${styles.inactive}`
                                }
                                onClick={() => {
                                  window.open(res.url, '_self')
                                  setSerachWidget('experience')
                                }}
                              >
                                <div className={styles.service_icon}>
                                  <img
                                    // src="/shared/api/media/miscellaneous/msn_img_home_uaeattraction.svg"
                                    src="/images/widgetIcons/home_uaeattraction.svg"
                                    alt=""
                                  />
                                </div>
                                {res.text}
                              </li>
                            )}
                            {res.type == 'holiday_search' && (
                              <li
                                className={
                                  SerachWidget == 'holiday_search'
                                    ? `${styles.activetab}`
                                    : `${styles.inactive}`
                                }
                                onClick={() => {
                                  // setSerachWidget('others_search')
                                  window.open(res.url, '_self')
                                }}
                              >
                                <div className={styles.service_icon}>
                                  <img
                                    src={
                                      res.text == 'Cruises'
                                        ? '/images/widgetIcons/cruise_icon.svg'
                                        : '/images/widgetIcons/Activities_Header.svg'
                                    }
                                    alt=""
                                  />
                                </div>
                                {res.text}
                              </li>
                            )}
                            {res.type == 'others_search' && (
                              <li
                                className={
                                  SerachWidget == 'others_search'
                                    ? `${styles.activetab}`
                                    : `${styles.inactive}`
                                }
                                onClick={() => {
                                  // setSerachWidget('others_search')
                                  window.open(res.url, '_self')
                                }}
                              >
                                <div className={styles.service_icon}>
                                  <img
                                    className={styles.other_icon}
                                    src="/images/widgetIcons/LuxuryHolidaysProduct1.svg"
                                    alt=""
                                  />
                                </div>
                                {res.text}
                              </li>
                            )}
                          </>
                        )
                      })}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          {/* tab navbar end */}
          {/* widgets components starts */}

          <div>
            {homeBodyDesktop.map((res, i) => {
              return (
                <div key={'homeBodyDsktp' + i}>
                  {/* {!cf.isValueEmpty(res.type) && res.type == 'image_banner' && (
                                 <div className="img_banner_home">
                                   <Image loader={imageLoader} src={res.banners} width={500} />
                                 </div>
                               )} */}
                  {SerachWidget === 'flight' && res.type == 'flight_search' && (
                    <div>
                      <div>
                        <FlightSearchWidget
                          pageTheme={props?.home?.data?.site?.site_theme}
                          isSRP={false}
                          isOffline={false}
                          homeData={!cf.isValueEmpty(initFlightData) ? initFlightData : []}
                          styles={FlightSearchThemeTwoCSS}
                          cssfile={FlightSearchThemeTwoCSS}
                          b2bStyles={b2bWidgetStyle}
                          calendarStyles={DesktopCalenderThemeOneCss}
                          getAutoSuggestData={(value, type, event) =>
                            getAutoSuggestDataFunc(value, 'flight', type, event)
                          }
                          getGoingToSuggestionList={getGoingToSuggestionList}
                          getDepartureSuggestionList={getDepartureSuggestionList}
                          dateFormat={props?.home?.data?.site?.date_format}
                          userProfile={userProfile}
                        />
                      </div>
                    </div>
                  )}

                  {SerachWidget === 'hotel' && res.type == 'hotel_search' && (
                    <div>
                      <HotelSearchWidget
                        pageTheme={props?.home?.data?.site?.site_theme}
                        isSRP={false}
                        isOffline={false}
                        styles={DesktopHotelStylesTheme2}
                        b2bStyles={b2bWidgetStyle}
                        getAutoSuggestValue={(value) => getAutoSuggestDataFunc(value, 'hotel')}
                        expandHotelNameBoolean={expandHotelNameBoolean}
                        getDefaultDays={hotelDefultDays}
                        getAutoSuggestList={getHotelAutoSuggestList}
                        expandHotelNameBooleanfunc={(value) => setExpandHotelName(value)}
                        homeData={!cf.isValueEmpty(initHotelData) ? initHotelData : []}
                        dateFormat={props?.home?.data?.site?.date_format}
                        userProfile={userProfile}
                        Countrystyles={Countrystyles}
                      />
                    </div>
                  )}
                  {SerachWidget == 'activity' && res.type == 'activity_search' && (
                    <SearchWidgetActivity
                      {...props}
                      //  cssfile={cssfile}
                      cssfile={ActivityTheme2cssfile}
                      pageTheme={props?.home?.data?.site?.site_theme}
                      calendarStyles={DesktopCalenderThemeOneCss}
                      dateFormat={props?.home?.data?.site?.date_format}
                      b2bStyles={b2bWidgetStyle}
                    />
                  )}
                  {SerachWidget == 'experience' && res.type == 'experience_search' && (
                    <SearchWidgetExperience
                      {...props}
                      cssfile={ActivityTheme2cssfile}
                      pageTheme={props?.home?.data?.site?.site_theme}
                      calendarStyles={DesktopCalenderThemeOneCss}
                      dateFormat={props?.home?.data?.site?.date_format}
                      b2bStyles={b2bWidgetStyle}
                      userProfile={userProfile}
                    />
                  )}
                </div>
              )
            })}
          </div>
        </div>

        {/* widgets components starts */}
        {/* cards & other data starts */}
        {/* <Theme2Cards 
                props={props}
                /> */}
        <div>
          {homeBodyDesktop.map((res, i) => {
            return (
              <div key={'homeBodyDsktp' + i}>
                <div>
                  {!cf.isValueEmpty(res.html)
                    ? parse(res.html)
                    : // <Interweave content={res.html} />
                      ''}
                </div>
              </div>
            )
          })}
        </div>
      </div>

      {/* cards & other data  end */}
    </>
  )
}
