import React, { Component } from "react";
import { addDays, differenceInCalendarDays, format } from "date-fns";

import FlightAutoComplete from "./FlightAutoComplete";
import MobileCalender from "../../../calendar/flightCalender/theme2/MobileCalenderNew";
import ModalPopupComponent from "../../../ModalPopupComponent/theme2/ModalPopupComponent";
import PaxConfig from "./PaxConfig";
import cf from "../../../../lib/utils/common";
import commonFunction from "../../../../lib/utils/common";

const cabinClass = [
  { id: 1, value: "Economy", label: "Economy" },
  { id: 2, value: "Business", label: "Business" },
  { id: 3, value: "First Class", label: "First Class" },
  { id: 4, value: "Premium Economy", label: "Premium Economy" },
];

const todaysDate = format(new Date(), "dd-LLL-yyyy");
export default class Theme2 extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      departureAirPortName: "",
      goingToAirPortName: "",
      selectPax: false,
      cabinClassSelection: false,
      isOnewaySearch: true,
      departureValue: "",
      goingToValue: "",
      expandDepartureBoolean: false,
      expandGoingToBoolean: false,
      departureDateValue: format(new Date(), "dd-LLL-yyyy"),
      goingToDateValue: format(addDays(new Date(), 7), "dd-LLL-yyyy"),
      expandDepartureDateBoolean: false,
      expandGoingToDateBoolean: false,
      paxAdultValue: 1,
      paxChildrenValue: 0,
      paxInfantValue: 0,
      SelectedClassValue: { label: "Economy", id: 1 },
      SelectedClassValueTwo: { label: "Economy", id: 1 },
      openCabinClassList: false,
      openCabinClassListTwo: false,
      getDepartureSuggestionList: [],
      getGoingToSuggestionList: [],
      getDepartureAirportCode: "",
      getGoingToAirportCode: "",
      isError: {
        departure: false,
        goingTo: false,
        departureDate: false,
        goingToDate: false,
      },
      returnCity: "",
      departureCity: "",
      isCalenderVisible: false
    };
  }
  componentDidMount() {
    this.setState({
      getDepartureSuggestionList: this.props.airports,
      getGoingToSuggestionList: this.props.airports,
      goingToValue:
        new URLSearchParams(window.location.search).get("to") &&
          new URLSearchParams(window.location.search).get("toCity")
          ? new URLSearchParams(window.location.search).get("toCity") +
          " - (" +
          new URLSearchParams(window.location.search).get("to") +
          ")"
          : "",
      getGoingToAirportCode: new URLSearchParams(window.location.search).get(
        "to"
      )
        ? new URLSearchParams(window.location.search).get("to")
        : "",
      returnCity: new URLSearchParams(window.location.search).get("toCity")
        ? new URLSearchParams(window.location.search).get("toCity")
        : "",
    });
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = () => {
    if (this.state.openCabinClassList === true)
      this.setState({ openCabinClassList: false });
    if (this.state.expandDepartureBoolean === true)
      this.setState({ expandDepartureBoolean: false });
    if (this.state.expandGoingToBoolean === true)
      this.setState({ expandGoingToBoolean: false });
  };
  onClickOpenCabinClassList = () => {
    console.log("dfgnfj")
    // document.getElementById("modal_container-popup").scrollIntoView({ behavior: 'smooth', block: 'start' })
    if (this.state.openCabinClassList === false) {
      this.setState({ openCabinClassList: true });
    } else if (this.state.openCabinClassList === true) {
      this.setState({ openCabinClassList: false });
    }
  };

  onClickOpenCabinClassListTwo = () => {
    if (this.state.openCabinClassListTwo === false) {
      this.setState({ openCabinClassListTwo: true });
    } else if (this.state.openCabinClassListTwo === true) {
      this.setState({ openCabinClassListTwo: false });
    }
  };

  onClickSearchFlight = () => {
    const mapDispatch = {
      departure: commonFunction.isValueEmpty(this.state.departureValue)
        ? true
        : false,
      goingTo: commonFunction.isValueEmpty(this.state.goingToValue)
        ? true
        : this.state.departureValue === this.state.goingToValue
          ? true
          : false,
      departureDate: commonFunction.isValueEmpty(this.state.departureDateValue)
        ? true
        : false,
      goingToDate: commonFunction.isValueEmpty(this.state.goingToDateValue)
        ? true
        : false,
    };
    this.setState({ isError: mapDispatch }, () => {
      let Validate = true;
      for (const [key, value] of Object.entries(this.state.isError)) {
        if (value === true) {
          Validate = false;
          break;
        }
      }
      let arrivalDate = "";
      let departureDateArr = !commonFunction.isValueEmpty(
        this.state.departureDateValue
      )
        ? this.state.departureDateValue
        : "";
      let departureDate = !commonFunction.isValueEmpty(departureDateArr)
        ? format(new Date(departureDateArr), "yyyy-MM-dd")
        : "";
      if (this.state.isOnewaySearch === false) {
        let arrivalDateArr = !commonFunction.isValueEmpty(
          this.state.goingToDateValue
        )
          ? this.state.goingToDateValue
          : "";
        arrivalDate = !commonFunction.isValueEmpty(arrivalDateArr)
          ? format(new Date(arrivalDateArr), "yyyy-MM-dd")
          : "";
      }
      let urlQuery = {
        from: this.state.getDepartureAirportCode,
        to: this.state.getGoingToAirportCode,
        type: 1,
        class: this.state.SelectedClassValue.id,
        returnclass: this.state.SelectedClassValueTwo?.id,
        adults: this.state.paxAdultValue,
        child: this.state.paxChildrenValue,
        infants: this.state.paxInfantValue,
        departure: departureDate,
        departureAirportName: this.state.departureAirPortName,
        goingToAirPortName: this.state.goingToAirPortName,
        arrival: arrivalDate,
        fromCity: this.state.departureCity,
        toCity: this.state.returnCity,
      };
      if (Validate === true)
        commonFunction.redirectTo("flight/search", urlQuery);
    });
  };

  onchangeVisibility = (bool, type) => {
    console.log("aartifgnfmmmmmm",bool, type)
    // if (type === "goingTo") {
    //   this.setState(
    //     { expandGoingToDateBoolean: bool, expandDepartureDateBoolean: false },
    //     () => {
    //       if (this.state.isOnewaySearch === true) {
    //         this.setState({ isOnewaySearch: false });
    //       }
    //     }
    //   );
    // } else {
    //   this.setState({
    //     expandGoingToDateBoolean: false,
    //     expandDepartureDateBoolean: bool,
    //   });
    // }
    this.setState({
      isCalenderVisible: bool
    }, () => {
      if (type == "return") {
        if (this.state.isOnewaySearch === true) {
          this.setState({ isOnewaySearch: false });
        }
      }
    })

  };
  onChangeSetDepartureGoingToValue = (value, type) => {
    if (type === "departure") {
      this.setState({ departureValue: value }, () => {
        if (this.state.isError.departure === true) {
          this.validateErrorMsg();
        }
        if (value.length === 0) {
          this.setState({ getDepartureSuggestionList: [] });
        }
      });
    } else {
      this.setState({ goingToValue: value }, () => {
        if (this.state.isError.goingTo === true) {
          this.validateErrorMsg();
          if (value.length === 0)
            this.setState({ getGoingToSuggestionList: [] });
        }
      });
    }
    if (type === "departure") {
      this.props.getAutoSuggestData(value, type);
      this.setState({ expandDepartureBoolean: true });
    } else {
      this.props.getAutoSuggestData(value, type);
      this.setState({ expandGoingToBoolean: true });
    }
  };
  onClickSetDepartureGoingToValue = (city, airPortCode, airPortName, type) => {
    if (type === "departure") {
      this.setState(
        {
          departureValue: city,
          getDepartureAirportCode: airPortCode,
          departureCity: city,
          departureAirPortName: airPortName,
        },
        () => {
          if (this.state.isError.departure === true) {
            this.validateErrorMsg();
          }
        }
      );
    } else {
      this.setState(
        {
          goingToValue: city,
          getGoingToAirportCode: airPortCode,
          returnCity: city,
          goingToAirPortName: airPortName,
        },
        () => {
          if (this.state.isError.goingTo === true) {
            this.validateErrorMsg();
          }
        }
      );
    }
  };
  onclickFlightAutoComplete(type) {
    if (type === "departure") {
      this.setState({ departureValue: "", departureAirPortName: "" }, () => {
        this.props.getAutoSuggestData(this.state.departureValue, type, "click");
      });
    } else {
      this.setState({ goingToValue: "", goingToAirPortName: "" }, () => {
        this.props.getAutoSuggestData(this.state.goingToValue, type, "click");
      });
    }
  }
  onClickChangePaxNumber = (pax, value, type) => {
    if (pax === "adult") {
      // console.log("mob_flight_search_wid_container111", type);
      if (
        this.state.paxChildrenValue + value <= 9 &&
        this.state.paxChildrenValue + value > 0
      ) {
        this.setState({ paxAdultValue: value }, () => {
          if (type === "minus") {
            if (this.state.paxInfantValue > value) {
              this.setState({ paxInfantValue: --this.state.paxInfantValue });
            }
          }
        });
      }
    } else if (pax === "children") {
      if (
        value + this.state.paxAdultValue <= 9 &&
        value + this.state.paxAdultValue > 0
      ) {
        this.setState({ paxChildrenValue: value });
      }
    } else if (pax === "infant") {
      // console.log("flight_search_wid_main6666", value);
      if (value > this.state.paxAdultValue) {
        this.setState({ paxInfantValue: --value });
      } else {
        this.setState({ paxInfantValue: value });
      }
    }
  };
  validateErrorMsg = () => {
    const mapDispatch = {
      departure: commonFunction.isValueEmpty(this.state.departureValue)
        ? true
        : false,
      goingTo: commonFunction.isValueEmpty(this.state.goingToValue)
        ? true
        : false,
      departureDate: commonFunction.isValueEmpty(this.state.departureDateValue)
        ? true
        : false,
      goingToDate: commonFunction.isValueEmpty(this.state.goingToDateValue)
        ? true
        : false,
    };
    this.setState({ isError: mapDispatch });
  };
  onClickSwap = () => {
    if (this.state.departureValue || this.state.goingToValue) {
      let departureValue = this.state.departureValue;
      let departureAirportCode = this.state.getDepartureAirportCode;
      let departureCity = this.state.departureCity;
      this.setState({
        departureValue: this.state.goingToValue,
        goingToValue: departureValue,
        getDepartureAirportCode: this.state.getGoingToAirportCode,
        getGoingToAirportCode: departureAirportCode,
        departureCity: this.state.returnCity,
        returnCity: departureCity,
      });
    }
  };

  onChangeDepartureGoingDateValue = (value, type) => {
    console.log("aartiiiiii", type, value);
    if (value) {
      if (type === "endDate") {
        let startDate = this.state.departureDateValue;
        // let endDate = value;
        let dayDifference = differenceInCalendarDays(
          new Date(value),
          new Date(this.state.departureDateValue)
        );
        if (dayDifference < 0) {
          this.setState({
            isOnewaySearch: true,
            goingToDateValue: commonFunction.getDateWithFormat(
              addDays(new Date(startDate), 7),
              this?.props?.dateFormat
            ),
          });
        } else {
          this.setState({ goingToDateValue: commonFunction.getDateWithFormat(value, this?.props?.dateFormat) });
        }
      } else {
        let dayDifference = differenceInCalendarDays(
          new Date(this.state.goingToDateValue),
          new Date(value)
        );
        let goingToDateValue = this.state.goingToDateValue;
        console.log("dayDifference", dayDifference)
        if (dayDifference < 0) {
          goingToDateValue = commonFunction.getDateWithFormat(addDays(new Date(value), 7), this?.props?.dateFormat);
        }
        let departureValue = this.state.isOnewaySearch
          ? value
          : commonFunction.getDateWithFormat(value, this?.props?.dateFormat);

        if (this.state.isOnewaySearch) {
          this.setState({
            departureDateValue: departureValue,
            goingToDateValue,
          });
        } else {
          this.setState({ departureDateValue: departureValue, goingToDateValue }, () => {
            console.log(
              "fghmhgmfhdepartureValue:",
              this.state.departureDateValue, this.state.goingToDateValue
            );
          });
        }
      }
    }
  };

  render() {
    // console.log("styles.mob_flight_search_wid_container", this.state)
    return (
      <div className={this.props.styles.mob_flight_search_wid_container}>
        <div className={this.props.styles.mob_flight_search_wid_main}>
          <div id={this.props.styles.tab_submenu_content}>
            <div
              className={
                this.props.styles.landing_tab_submenu_new +
                " " +
                this.props.styles.sub_tab_new
              }
            >
              <div className={this.props.styles.mobile_visible}>
                <div
                  className={
                    this.props.styles.mobo_tab_header +
                    " " +
                    this.props.styles.flight_header_mobo
                  }
                >
                  {/* <img src="/images/back-arrow.png" alt="" className={this.props.styles.arrow_image} onClick={() => this.props.onClosePopup(false)} /> */}
                  <span
                    className={this.props.styles.arrow_image}
                    onClick={() => this.props.onClosePopup(false)}
                  >
                    <img src="images/back_arrow.png" alt="back_btn" />
                    {/* <svg
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M10 19l-7-7m0 0l7-7m-7 7h18"
                      />
                    </svg> */}
                  </span>
                  <h3 className={this.props.styles.search_filter_header}>
                    Flight Search
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className={this.props.styles.main_flight}>
            <div className={this.props.styles.mob_flight_way_tab}>
              <div className={this.props.styles.mob_flight_way_btngrp}>
                <button
                  className={
                    this.state.isOnewaySearch
                      ? this.props.styles.active_way
                      : this.props.styles.button_style
                  }
                  onClick={() => {
                    this.setState({
                      isOnewaySearch: true,
                      expandGoingToDateBoolean: false,
                      expandDepartureDateBoolean: false,
                    });
                  }}
                >
                  One-way
                </button>
                <button
                  className={
                    !this.state.isOnewaySearch
                      ? this.props.styles.active_way
                      : this.props.styles.button_style
                  }
                  onClick={() => {
                    this.setState({ isOnewaySearch: false });
                  }}
                >
                  Round-way
                </button>
              </div>
            </div>
            <div
              id="modal_container-popup"
              className={this.props.styles.mob_flight_search_details_container}
            >
              <div className={this.props.styles.mob_flight_search_details_main}>
                <div
                  className={this.props.styles.mob_flight_search_details_row}
                >
                  {/* Flight autoComplete */}
                  <div className={this.props.styles.flgt_cont_flx}>
                    <div
                      className={
                        this.props.styles.mob_flight_search_column +
                        " " +
                        this.props.styles.mob_flight_search_depart
                      }
                    >
                      <FlightAutoComplete
                        label="Origin"
                        value={this.state.departureValue}
                        onclickFlightAutoComplete={() =>
                          this.onclickFlightAutoComplete("departure")
                        }
                        styles={this.props.styles}
                        placholder="City or Airport"
                        onClickSourceAndDestination={(
                          city,
                          airPortCode,
                          airPortName
                        ) =>
                          this.onClickSetDepartureGoingToValue(
                            city,
                            airPortCode,
                            airPortName,
                            "departure"
                          )
                        }
                        onChangeSourceAndDestination={(value) =>
                          this.onChangeSetDepartureGoingToValue(
                            value,
                            "departure"
                          )
                        }
                        isAutoCompleteExpand={(bool) =>
                          this.setState({ expandDepartureBoolean: bool })
                        }
                        autoCompleteData={
                          !commonFunction.isValueEmpty(
                            this.props.getDepartureSuggestionList
                          )
                            ? this.props.getDepartureSuggestionList
                            : []
                        }
                        expandAutoCompleteBool={
                          !commonFunction.isValueEmpty(
                            this.state.expandDepartureBoolean
                          )
                            ? this.state.expandDepartureBoolean
                            : false
                        }
                        leftIcon={"/images/departure-wid.svg"}
                        errorMessage={"Enter Origin City"}
                        isError={this.state.isError.departure}
                        airPortName={this.state.departureAirPortName}
                        airPortCode={this.state.getDepartureAirportCode}
                      />
                    </div>
                    <div
                      className={this.props.styles.mob_flight_swap_main}
                      onClick={() => {
                        this.onClickSwap();
                      }}
                    >
                      <img
                        src="/images/arrow-wid.svg"
                        className={this.props.styles.mob_flight_swap}
                        alt="flight arrow"
                        value="Swap"
                      ></img>
                    </div>
                    <div
                      className={
                        this.props.styles.mob_flight_search_column +
                        " " +
                        this.props.styles.mob_flight_search_depart
                      }
                    >
                      <FlightAutoComplete
                        label="Destination"
                        value={this.state.goingToValue}
                        onclickFlightAutoComplete={() =>
                          this.onclickFlightAutoComplete("goingTo")
                        }
                        styles={this.props.styles}
                        placholder="City or Airport"
                        onClickSourceAndDestination={(
                          city,
                          airPortCode,
                          airPortName
                        ) =>
                          this.onClickSetDepartureGoingToValue(
                            city,
                            airPortCode,
                            airPortName,
                            "goingTo"
                          )
                        }
                        onChangeSourceAndDestination={(value) =>
                          this.onChangeSetDepartureGoingToValue(
                            value,
                            "goingTo"
                          )
                        }
                        isAutoCompleteExpand={(bool) =>
                          this.setState({ expandGoingToBoolean: bool })
                        }
                        autoCompleteData={
                          !commonFunction.isValueEmpty(
                            this.props.getGoingToSuggestionList
                          )
                            ? this.props.getGoingToSuggestionList
                            : []
                        }
                        expandAutoCompleteBool={
                          !commonFunction.isValueEmpty(
                            this.state.expandGoingToBoolean
                          )
                            ? this.state.expandGoingToBoolean
                            : false
                        }
                        leftIcon={"/images/arrival-wid.svg"}
                        errorMessage={
                          this.state.goingToValue &&
                            this.state.departureValue === this.state.goingToValue
                            ? "Origin and destination cannot be same"
                            : "Enter Destination city"
                        }
                        isError={this.state.isError.goingTo}
                        airPortName={this.state.goingToAirPortName}
                        airPortCode={this.state.getGoingToAirportCode}
                      />
                    </div>
                  </div>

                  {/* Economy And Cal 1*/}
                  <div className={this.props.styles.flgt_cont_flx}>

                    {/* newcalender */}


                    <MobileCalender
                      styles={this.props.styles}
                      value={
                        !cf.isValueEmpty(this.state.departureDateValue)
                          ? this.state.departureDateValue
                          : ""
                      }
                      departureValue={
                        this.state.departureDateValue
                          ? this.state.departureDateValue
                          : ""
                      }

                      returnValue={!commonFunction.isValueEmpty(this.state.goingToDateValue) && this.state.goingToDateValue !== "NA" ? this.state.goingToDateValue : "Tap to add return"}

                      departureDate={this.state.departureDateValue}
                      onChangeDate={(value, type) => {
                        this.onChangeDepartureGoingDateValue(
                          value,
                          type
                        );
                      }}
                      iconImage={
                        <svg
                          version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          width="35px"
                          height="35px"
                          viewBox="0 0 35 35"
                          enableBackground="new 0 0 35 35"
                        >
                          <g id="Group_741" data-name="Group 741" transform="translate(-1010 -888.5)">
                            <path id="calendar_icn" d="M28.583,0H3.707A3.6,3.6,0,0,0,0,3.744V28.7a3.63,3.63,0,0,0,3.707,3.8H28.583A3.818,3.818,0,0,0,32.5,28.7V3.744A3.749,3.749,0,0,0,28.583,0ZM3.707,1.354H28.583a2.426,2.426,0,0,1,2.562,2.39V9.479H1.354V3.744A2.245,2.245,0,0,1,3.707,1.354Zm9.835,18.958V16.25h5.417v4.063Zm5.417,1.354v4.062H13.542V21.667Zm-6.771-1.354H6.771V16.25h5.417Zm8.125-4.063h5.416v4.063H20.312Zm0-1.354V10.834h5.416V14.9Zm-1.354,0H13.542V10.834h5.417Zm-6.771,0H6.771V10.834h5.417Zm-6.771,0H1.354V10.834H5.417Zm0,1.354v4.063H1.354V16.25Zm0,5.417v4.062H1.354V21.667Zm1.354,0h5.417v4.062H6.771Zm5.417,5.417v4.063H6.771V27.083Zm1.354,0h5.417v4.063H13.542Zm6.771,0h5.416v4.063H20.312Zm0-1.354V21.667h5.416v4.062Zm6.771-4.062h4.063v4.062H27.083Zm0-1.354V16.25h4.063v4.063Zm0-5.417V10.834h4.063V14.9ZM1.354,28.7V27.083H5.417v4.063H3.707A2.3,2.3,0,0,1,1.354,28.7Zm27.229,2.445h-1.5V27.083h4.063V28.7A2.45,2.45,0,0,1,28.583,31.146Z" transform="translate(1010 888.5)" fill="#3479c5" />
                            <path id="Rectangle_22" data-name="Rectangle 22" d="M3,0H29a3,3,0,0,1,3,3V15a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V3A3,3,0,0,1,3,0Z" transform="translate(1010 889)" fill="#3479c5" />
                            <path id="Path_161" data-name="Path 161" d="M14.3,0H0" transform="translate(1032.78 897.708) rotate(180)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1" />
                            <path id="Path_163" data-name="Path 163" d="M2.475,0,0,2.715,2.475,5.19h0" transform="translate(1033.864 900.303) rotate(180)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1" />
                          </g>
                        </svg>
                      }
                      startDate={
                        !cf.isValueEmpty(this.state.departureDateValue)
                          ? this.state.departureDateValue
                          : ""
                      }
                      endDate={
                        !commonFunction.isValueEmpty(
                          this.state.goingToDateValue
                        )
                          ? this.state.goingToDateValue
                          : ""
                      }
                      startDateLimit={
                        !commonFunction.isValueEmpty(todaysDate)
                          ? todaysDate
                          : ""
                      }
                      dateFormat={"DD-MMM-YYYY"}
                      changeVisibility={(bool, type) =>
                        this.onchangeVisibility(bool, type)
                      }
                      totalMonths={12}

                      isVisible={this.state.isCalenderVisible}
                      type={"double"}
                      searchType={this.state.isOnewaySearch ? "oneWay" : "roundTrip"}
                      oneWayReturnClick={() => {
                        this.setState({
                          isOnewaySearch: false,
                        })
                        console.log("aartuoifhdddddd")
                      }}
                      roundWayReturnClick={() => { this.setState({ isOnewaySearch: true }) }}
                      showCrossIcon={true}
                      onClickOpenCabinClassList={()=>{this.onClickOpenCabinClassList()}}
                      SelectedClassValue={this.state.SelectedClassValue}
                    />
                    {/* new calender end */}
                    {/* Economy And Cal 2*/}
                  </div>


                 

                
                  {/* passenger */}

                  <div
                    className={this.props.styles.passDrop}
                    onClick={() => {
                      this.setState({
                        selectPax: true,
                      });
                    }}
                  >
                    {`${this.state.paxAdultValue +
                      this.state.paxChildrenValue +
                      this.state.paxInfantValue
                      } Passenger`}
                    <div
                      className={this.props.styles.mob_flight_cabin_class_arrow}
                    >
                      {/* <img
                        src="/images/down_arrow.jpg"
                        className={
                          this.props.styles.mob_flight_cabin_class_arrow_img
                        }
                        alt=""
                      /> */}
                      <svg
                        version="1.1"
                        id="Layer_1"
                        x="0px"
                        y="0px"
                        width="30px"
                        height="30px"
                        viewBox="0 0 30 30"
                        enable-background="new 0 0 30 30"
                      >
                        <path
                          id="Path_1101"
                          data-name="Path 1101"
                          d="M339.293,85.293l1.414,1.414L334,93.414l-6.707-6.707,1.414-1.414L334,90.586Z"
                          transform="translate(-327.293 -85.293)"
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </div>
                  </div>

                  {/* Search Button */}
                  {/* <div className={this.props.styles.check_add_box}>
                    <div className={this.props.styles.non_stop_box}>
                    <input type="checkbox" id="non_stop" />
                      <label for="non_stop">
                        Non Stop
                      </label>
                    </div>
                    <div className={this.props.styles.prefAir_box}>
                        <input type="checkbox" id="prefAir" />
                      <label for="prefAir">
                      Preferred Airlines
                      </label>
                    </div>
                  </div> */}
                  <div className={this.props.styles.btn_search}>
                    <svg width="50px" height="50px" viewBox="0,0,256,256">
                      <g
                        fill="#ffffff"
                        fill-rule="nonzero"
                        stroke="none"
                        stroke-width="1"
                        stroke-linecap="butt"
                        stroke-linejoin="miter"
                        stroke-miterlimit="10"
                        stroke-dasharray=""
                        stroke-dashoffset="0"
                        font-family="none"
                        font-weight="none"
                        font-size="none"
                        text-anchor="none"
                      >
                        <g transform="scale(5.12,5.12)">
                          <path d="M21,3c-9.39844,0 -17,7.60156 -17,17c0,9.39844 7.60156,17 17,17c3.35547,0 6.46094,-0.98437 9.09375,-2.65625l12.28125,12.28125l4.25,-4.25l-12.125,-12.09375c2.17969,-2.85937 3.5,-6.40234 3.5,-10.28125c0,-9.39844 -7.60156,-17 -17,-17zM21,7c7.19922,0 13,5.80078 13,13c0,7.19922 -5.80078,13 -13,13c-7.19922,0 -13,-5.80078 -13,-13c0,-7.19922 5.80078,-13 13,-13z"></path>
                        </g>
                      </g>
                    </svg>
                    <button
                      className={this.props.styles.mob_flight_search_widget_btn}
                      onClick={() => this.onClickSearchFlight()}
                    >
                      Search
                    </button>
                  </div>

                  {/* Class Select popup */}
                  {this.state.openCabinClassList === true ? (
                    <>
                      <div
                        className={this.props.styles.backDrop}
                        onClick={() => {
                          this.setState({
                            openCabinClassList: false,
                          });
                        }}
                      ></div>

                      <div
                        className={`${this.props.styles.mob_flight_cabin_class_list} ${this.props.styles.pop_up_drop_down}`}
                      >
                        <div className={this.props.styles.mob_flight_main_tops}>
                          <div
                            className={this.props.styles.mob_flight_main_top}
                            onClick={() => {
                              this.setState({
                                openCabinClassList: false,
                              });
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </div>
                        </div>
                        {cabinClass.map((element, index) => {
                          return (
                            <div className={this.props.styles.mob_flight_whole}>
                              <div
                                className={
                                  this.props.styles.mob_flight_cabin_class_ele
                                }
                                onClick={() => {
                                  this.setState({
                                    SelectedClassValue: {
                                      label: element.label,
                                      id: element.id,
                                    },
                                    openCabinClassList: false,
                                  });
                                }}
                              >
                                {element.label}
                              </div>
                            </div>
                          );
                        })}
                    
                      </div>
                    </>
                  ) : null}

                  {/* {this.state.openCabinClassListTwo ? (
                    <>
                      <div
                        className={this.props.styles.backDrop}
                        onClick={() => {
                          this.setState({
                            openCabinClassListTwo:false
                          });
                        }}
                      ></div>
                      <div
                        className={`${this.props.styles.mob_flight_cabin_class_list} ${this.props.styles.pop_up_drop_down}`}
                      >
                        <div className={this.props.styles.mob_flight_main_tops}>
                        <div className={this.props.styles.mob_flight_main_top}
                            onClick={() => {
                              this.setState({
                                openCabinClassListTwo:false
                              });
                            }}
                        >
                        <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                    </div>
                    </div>
                        {cabinClass.map((element, index) => {
                          return (
                            <div className={this.props.styles.mob_flight_whole}>
                            <div
                              className={
                                this.props.styles.mob_flight_cabin_class_ele
                              }
                              onClick={() => {
                                this.setState({
                                  SelectedClassValueTwo: {
                                    label: element.label,
                                    id: element.id,
                                  },
                                  openCabinClassListTwo: false,
                                });
                              }}
                            >
                              {element.label}
                            </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : null} */}

                  {/* Pax Config */}

                  {this.state.selectPax == true ? (
                    <>
                      <div
                        className={this.props.styles.backDrop}
                        onClick={() => {
                          this.setState({
                            selectPax: false,
                          });
                        }}
                      ></div>

                      <div
                        className={`${this.props.styles.mob_flight_cabin_class_list} ${this.props.styles.pop_up_drop_down}`}
                      >
                        <div className={this.props.styles.mob_flight_main_tops}>
                          <div
                            className={this.props.styles.mob_flight_main_top}
                            onClick={() => {
                              this.setState({
                                selectPax: false,
                              });
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </div>
                        </div>

                        <div
                          className={
                            this.props.styles.mob_flight_search_column +
                            " " +
                            this.props.styles.mob_flight_search_pax
                          }
                        >
                          <div className={this.props.styles.mob_flight_pax_new}>
                            <label>Adults</label>
                            <span>(12+ Years)</span>
                          </div>
                          <PaxConfig
                            value={
                              !commonFunction.isValueEmpty(
                                this.state.paxAdultValue
                              )
                                ? this.state.paxAdultValue
                                : 0
                            }
                            onChangePaxValue={(value, type) =>
                              this.onClickChangePaxNumber("adult", value, type)
                            }
                            maxLimit={9}
                            minLimit={1}
                            totalPax={
                              this.state.paxChildrenValue +
                              this.state.paxAdultValue
                            }
                            styles={this.props.styles}
                          />
                        </div>

                        <div
                          className={
                            this.props.styles.mob_flight_search_column +
                            " " +
                            this.props.styles.mob_flight_search_pax
                          }
                        >
                          <div className={this.props.styles.mob_flight_pax_new}>
                            <label>Children</label>
                            <span>(2-11 Years)</span>
                          </div>
                          <PaxConfig
                            value={
                              !commonFunction.isValueEmpty(
                                this.state.paxChildrenValue
                              )
                                ? this.state.paxChildrenValue
                                : 0
                            }
                            onChangePaxValue={(value, type) =>
                              this.onClickChangePaxNumber(
                                "children",
                                value,
                                type
                              )
                            }
                            maxLimit={9}
                            minLimit={0}
                            totalPax={
                              this.state.paxChildrenValue +
                              this.state.paxAdultValue
                            }
                            styles={this.props.styles}
                          />
                        </div>

                        <div
                          className={
                            this.props.styles.mob_flight_search_column +
                            " " +
                            this.props.styles.mob_flight_search_pax
                          }
                        >
                          <div className={this.props.styles.mob_flight_pax_new}>
                            <label>Infant</label>
                            <span>(Below 2 Years)</span>
                          </div>
                          <PaxConfig
                            value={
                              !commonFunction.isValueEmpty(
                                this.state.paxInfantValue
                              )
                                ? this.state.paxInfantValue
                                : 0
                            }
                            onChangePaxValue={(value, type) =>
                              this.onClickChangePaxNumber("infant", value, type)
                            }
                            maxLimit={this.state.paxAdultValue}
                            minLimit={0}
                            totalPax={this.state.paxInfantValue}
                            styles={this.props.styles}
                          />
                        </div>

                        <div className={this.props.styles.pax_btn}>
                          <button
                            onClick={() => {
                              this.setState({
                                selectPax: false,
                              });
                            }}
                          >
                            {" "}
                            Done
                          </button>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
