import React, { useEffect, useState } from "react";

import FlightAutoComplete from "./FlightAutoComplete";
import MobileCalender from '../../../calendar/flightCalender/theme3/MobileCalender';
import cf from "../../../../lib/utils/common";

export default function MulticityForm({props,index,data}) {
   
 return (
  <div className={props.styles.repeat_comp}>
    <div className={props.styles.flight_search_details_container_multicity}>
    <div className={props.styles.flight_search_details_main_multicity}>
      <div className={props.styles.flight_search_details_row_multicity}> 
        <div
          className={
           props.styles.flight_search_column_multicity +
            " " +
            props.styles.flight_search_depart_multicity
          }
        >
          <FlightAutoComplete
            label="From"
            value={props.state.multicityFlightData[index].org}
            city={props.state.multicityFlightData[index].orgCity}
            state={props.state}
            data={data}
            index={index}
            styles={props.styles}
            onclickFlightAutoComplete={() =>
              props.onclickFlightAutoComplete("departure","isDeparture",index)
            }
            placholder="City"
            onClickSourceAndDestination={(city, airPortCode,airPortName) =>
             props.onClickSetDepartureGoingToValue(
                city,
                airPortCode,
                airPortName,
                "departure",
                index,
              )
            }
            onChangeSourceAndDestination={(value) =>
              props.onChangeSetDepartureGoingToValue(
                value,
                "departure",
                index
              )
            }
            isAutoCompleteExpand={(bool) =>
              props.setState({ expandDepartureBoolean: bool })
            }
            autoCompleteData={
              !cf.isValueEmpty(props.getDepartureSuggestionList)
                ? props.getDepartureSuggestionList
                : []
            }
            expandAutoCompleteBool={
              !cf.isValueEmpty(props.state.expandDepartureBoolean)
                ? props.state.expandDepartureBoolean
                : false
            }
            leftIcon={
              <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                width="30px"
                height="30px"
                viewBox="0 0 30 30"
                enableBackground="new 0 0 30 30"
              >
                <path
                  fill="currentColor"
                  d="M4.108,11.369l-2.693,1.089l5.507,4.903l8.443-2.754l-2.087,6.658l3.571-1.454l3.358-6.778l6.325-2.058l0,0
                                 c0.702-0.193,1.292-0.669,1.631-1.314c0.34-0.645,0.396-1.401,0.157-2.089c-0.239-0.688-0.753-1.246-1.419-1.542
                                 c-0.665-0.295-1.424-0.302-2.094-0.018l-6.174,2.27L10.01,4.621L6.409,6.073l6.778,4.54l-5.628,2.452L4.108,11.369z M16.425,19.358
                                 l-2.179,0.876l1.846-5.839l3.328-1.089L16.425,19.358z M7.709,6.194l2.3-0.938l8.595,3.692l6.415-2.361l0,0
                                 c0.519-0.235,1.112-0.241,1.635-0.017c0.524,0.224,0.929,0.658,1.116,1.195c0.188,0.538,0.141,1.129-0.129,1.63
                                 c-0.271,0.501-0.739,0.866-1.291,1.004L7.075,16.694L2.536,12.67l1.543-0.636l3.45,1.725l6.929-3.025L7.709,6.194z"
                />
                <rect
                  x="9.625"
                  y="23.5"
                  fill="currentColor"
                  width="18.841"
                  height="0.75"
                />
              </svg>
            }
            errorMessage={data?.error?.org}
            isError={data?.error?.org && (data.org === "" ? true : false)}
            listWrapper={props.multicityDepartureWrapper}
            // multicityWrapper={props.multicityDepartureWrapper}
            onKeyDownArrow={(event) => {
              props.onKeyDownArrow(
                event,
                "flightDataArray",
                props.getDepartureSuggestionList,
                "departure"
              );
            }}
            // airPortName={props.state.multicityFlightData[index].departureAirPortName}
          />
        </div>
        {/* <img src="/images/home-fgt.png" class={this.props.styles.flight_swap} alt="flight arrow" onClick={() => { this.onClickSwap() }}></img> */}
        <span
          className={props.styles.flight_swap_multicity}
          tabIndex="2"
          onKeyDown={props.onSwapKeyDownHandler}
          onClick={() => {
            props.onClickSwap();
          }}
        >
       {/* <svg width="45px" height="45px" viewBox="0 0 200 200"><g><path fill="currentColor" d="M100.496,193.102c-50.453,0-91.5-41.047-91.5-91.5s41.047-91.5,91.5-91.5c50.454,0,91.5,41.047,91.5,91.5 S150.949,193.102,100.496,193.102z M100.496,15.102c-47.696,0-86.5,38.804-86.5,86.5s38.804,86.5,86.5,86.5 c47.697,0,86.5-38.804,86.5-86.5S148.192,15.102,100.496,15.102z"></path></g><g><g id="primary"><path fill="currentColor" d="M69.69,101.716l-13.948,13.948c-1.823,1.808-1.834,4.752-0.026,6.574c0.009,0.009,0.017,0.019,0.026,0.027 l13.948,13.946c1.823,1.825,4.778,1.825,6.602,0c1.823-1.821,1.823-4.778,0-6.601l-6.043-5.997h72.48 c2.566,0,4.646-2.083,4.646-4.649c0-2.567-2.08-4.648-4.646-4.648h-72.48l6.043-5.998c1.823-1.808,1.835-4.751,0.027-6.575 c-0.009-0.009-0.018-0.019-0.027-0.027c-1.808-1.822-4.752-1.834-6.575-0.026C69.708,101.699,69.699,101.707,69.69,101.716z"></path></g><g id="primary_1_"><path fill="currentColor" d="M131.301,101.488L145.25,87.54c1.822-1.808,1.834-4.752,0.025-6.574c-0.008-0.009-0.018-0.019-0.025-0.027 l-13.949-13.947c-1.822-1.824-4.777-1.824-6.602,0c-1.822,1.822-1.822,4.779,0,6.602l6.043,5.997H58.264 c-2.567,0-4.648,2.083-4.648,4.649c0,2.568,2.081,4.649,4.648,4.649h72.479l-6.043,5.998c-1.822,1.808-1.834,4.751-0.025,6.575 c0.008,0.009,0.018,0.019,0.025,0.028c1.809,1.822,4.752,1.834,6.574,0.025C131.283,101.504,131.293,101.497,131.301,101.488z"></path></g></g></svg> */}
        </span>
        <div
          className={
            props.styles.flight_search_column_multicity +
            " " +
            props.styles.flight_search_depart_multicity
          }
        >
          <FlightAutoComplete
            label="To"
            value={props.state.multicityFlightData[index].dest}
            city={props.state.multicityFlightData[index].destCity}
            state={props.state}
            data={data}
            index={index}
            styles={props.styles}
            onclickFlightAutoComplete={() =>
              props.onclickFlightAutoComplete("goingTo","isGoingTo",index)
            }
            placholder="City"
            onClickSourceAndDestination={(city, airPortCode,airPortName) =>
              props.onClickSetDepartureGoingToValue(
                city,
                airPortCode,
                airPortName,
                "goingTo",
                index,
              )
            }
            onChangeSourceAndDestination={(value) =>
              props.onChangeSetDepartureGoingToValue(value, "goingTo",index)
            }
            isAutoCompleteExpand={(bool) =>
              props.setState({ expandGoingToBoolean: bool })
            }
            autoCompleteData={
              !cf.isValueEmpty(props.getGoingToSuggestionList)
                ? props.getGoingToSuggestionList
                : []
            }
            expandAutoCompleteBool={
              !cf.isValueEmpty(props.state.expandGoingToBoolean)
                ? props.state.expandGoingToBoolean
                : false
            }
            leftIcon={
              <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                width="30px"
                height="30px"
                viewBox="0 0 30 30"
                enableBackground="new 0 0 30 30"
              >
                <path
                  fill="currentColor"
                  d="M5.727,6.708l-2.85-0.559l1.935,7.115l8.576,2.304l-5.385,4.438l3.785,0.732l6.517-3.843l6.421,1.732l0,0
                                 c0.694,0.221,1.448,0.146,2.084-0.21c0.637-0.355,1.098-0.959,1.272-1.664c0.176-0.708,0.05-1.455-0.348-2.067
                                 c-0.395-0.61-1.026-1.031-1.743-1.159l-6.41-1.471l-5.224-7.778l-3.81-0.75l3.198,7.505l-6.053-1.021L5.727,6.708z M11.68,20.126
                                 l-2.304-0.455l4.735-3.883l3.382,0.905L11.68,20.126z M11.57,4.34l2.44,0.471l5.182,7.788l6.663,1.526l0,0
                                 c0.563,0.086,1.063,0.406,1.378,0.879c0.318,0.474,0.42,1.059,0.283,1.61c-0.137,0.553-0.499,1.022-0.999,1.295
                                 c-0.501,0.272-1.092,0.322-1.63,0.137L5.303,12.788L3.699,6.938l1.641,0.311l1.947,3.33l7.457,1.25L11.57,4.34z"
                />
                <rect
                  x="8.625"
                  y="24.5"
                  fill="currentColor"
                  width="18.841"
                  height="0.75"
                />
              </svg>
            }
            // errorMessage={
            //   props.state.goingToValue !== "" &&
            //   props.state.departureValue === props.state.goingToValue
            //     ? "Origin and destination cannot be same"
            //     : "Enter Destination City"
            // }
            errorMessage={data?.error?.dest}
            isError={data?.error?.dest && ((data.dest === "" || data.dest === data.org) ? true : false)}
            listWrapper={props.multicityReturnWrapper}
            // multicityWrapper={props.multicityReturnWrapper}
            onKeyDownArrow={(event) => {
              props.onKeyDownArrow(
                event,
                "flightDataArray",
                props.getGoingToSuggestionList,
                "goingTo"
              );
            }}
            // airPortName={props.state.multicityFlightData[index].goingToAirPortName}
          />
        </div>
        <div
          className={
            props.styles.flight_search_column_multicity +
            " " +
            props.styles.flight_search_depart_going_date_multicity
          }
        >
          <MobileCalender
            label="Departure"
            styles={props.styles}
            value={
              !cf.isValueEmpty(props.state.multicityFlightData[index].odt)
                ? props.state.multicityFlightData[index].odt
                : ""
            }
            placholder="Departure"
            onChangeDate={(value) => {
              props.onChangeDepartureGoingDateValue(
                value,
                "goindeparturegTo",
                index
              );
            }}
            iconImage="/images/fgt-dt.png"
            startDate={
              !cf.isValueEmpty(props.state.multicityFlightData[index].odt)
                ? props.state.multicityFlightData[index].odt
                : ""
            }
            endDate={
              !cf.isValueEmpty(props.state.goingToDateValue)
                ? props.state.goingToDateValue
                : ""
            }
            startDateLimit={
              !cf.isValueEmpty(props.todaysDate)
                ? props.todaysDate
                : ""
            }
            dateFormat={"DD-MMM-YYYY"}
            changeVisibility={(bool) =>
              props.onchangeVisibility(bool, "departure",index)
            }
            isVisible={props.state.multicityFlightData[index].expandDepartureDateBoolean}
            type={"double"}
            id={index}
            doubleSelection={false}
            totalMonths={12}
          />
        </div>
      </div>
      {index !==0 && index !==1 &&
      <div
        className={props.styles.remove_flight_button }
        id="decrease"
        onClick={() => props.RemoveFlightObject(index) }
        // onFocusCapture={onFocusHandler}
        // tabIndex="2"
        // onKeyDown={keyDownHandler}
      >
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          className={props.styles.minus_pax}
          width="30px"
          height="30px"
          viewBox="0 0 30 30"
          enableBackground="new 0 0 30 30"
        >
          <g>
            <path
              fill="currentColor"
              d="M15.401,29.146c-7.616,0-13.813-6.196-13.813-13.813S7.785,1.521,15.401,1.521
		c7.617,0,13.813,6.196,13.813,13.813S23.018,29.146,15.401,29.146z M15.401,2.521c-7.065,0-12.813,5.747-12.813,12.813
		c0,7.064,5.748,12.813,12.813,12.813s12.813-5.748,12.813-12.813C28.214,8.268,22.466,2.521,15.401,2.521z"
            />
          </g>
          <path
            fill="currentColor"
            d="M24.65,15.333c0,0.368-0.299,0.666-0.667,0.666H6.818c-0.368,0-0.667-0.298-0.667-0.666l0,0
	c0-0.368,0.298-0.667,0.667-0.667h17.166C24.352,14.666,24.65,14.965,24.65,15.333L24.65,15.333z"
          />
        </svg>
      </div>
}
      </div>
      </div>  
      </div>     
 )
  
 
}