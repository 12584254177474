import React, { useEffect, useState } from 'react';

import commonFunction from '../../../../lib/utils/common';

export default function FlightAutoComplete(props) {
    console.log(props)
    const { theme } = props;
    const onClickHandler = (e) => {
        // if (props.expandAutoCompleteBool === true) props.isAutoCompleteExpand(false)
        // else if (props.expandAutoCompleteBool === false) {
        props.onclickFlightAutoComplete()
        props.isAutoCompleteExpand(true)
        // }
    }

    const selectListClickHandler = (element) => {
        props.onClickSourceAndDestination(element.CITY_NAME, element.AIRPORT_CODE,element.AIRPORT_NAME)
        props.isAutoCompleteExpand(false)
    }

    return (
        <div className={props.styles.mob_flight_search_wid_inputfield}>
            <label>{props.label}</label>
            <img src={props.leftIcon} className={props.styles.mob_flight_search_left_icon} alt=""/>
            <input type='text' autoComplete={'off'} id="myTextField"
                placeholder={!commonFunction.isValueEmpty(props.placholder) ? props.placholder : ""}
                className={props.styles.search_widget_input_new}
                value={!commonFunction.isValueEmpty(props.value) && (!props.expandAutoCompleteBool) ? props.value : ""}
                // onChange={(event) => { props.onChangeSourceAndDestination(event.target.value) }}
                onClick={onClickHandler}
                readOnly
                // onFocusCapture={onClickHandler}
            />
            <p>{!commonFunction.isValueEmpty (props.airPortCode) ? props.airPortCode : ""}</p>
            <div className={props.styles.flight_ptext}>
            <p>{!commonFunction.isValueEmpty(props.airPortName) ? props.airPortName : ""}</p>
            </div>
            {!commonFunction.isValueEmpty(props.isError) && props.isError === true ? (
                <div className={props.styles.mob_flight_search_error_message}><span>{!commonFunction.isValueEmpty(props.errorMessage) ? props.errorMessage : 'Invalid field'}</span></div>
            ) : null}
            {props.expandAutoCompleteBool === true ? (
                <div className={props.styles.mob_flight_autocomplete_value}>

                <div className={props.styles.input_search_destination}>
                            <svg width="50px" height="50px" viewBox="0,0,256,256"><g fill="#3479c5" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none"><g transform="scale(5.12,5.12)"><path d="M21,3c-9.39844,0 -17,7.60156 -17,17c0,9.39844 7.60156,17 17,17c3.35547,0 6.46094,-0.98437 9.09375,-2.65625l12.28125,12.28125l4.25,-4.25l-12.125,-12.09375c2.17969,-2.85937 3.5,-6.40234 3.5,-10.28125c0,-9.39844 -7.60156,-17 -17,-17zM21,7c7.19922,0 13,5.80078 13,13c0,7.19922 -5.80078,13 -13,13c-7.19922,0 -13,-5.80078 -13,-13c0,-7.19922 5.80078,-13 13,-13z"></path></g></g></svg>  
                            <input type='text' autoComplete={'off'} id="myTextField"
                                placeholder={!commonFunction.isValueEmpty(props.placholder) ? props.placholder : ""}
                                className={props.styles.search_widget_input_new}
                                value={!commonFunction.isValueEmpty(props.value) ? props.value : ""}
                                onChange={(event) => { props.onChangeSourceAndDestination(event.target.value) }}
                                onClick={onClickHandler}
                                onFocusCapture={onClickHandler}
                                autoFocus
            />
                 </div>

                    {!commonFunction.isValueEmpty(props.autoCompleteData) && props.autoCompleteData.length > 0 &&
                        props.autoCompleteData.map((element, i) => {
                            return (
                                <div key={i + 1} className={props.styles.mob_flight_autocomplete_element} onClick={() => {
                                    props.onClickSourceAndDestination(element.CITY_NAME, element.AIRPORT_CODE,element.AIRPORT_NAME)
                                    props.isAutoCompleteExpand(false)
                                }}>
                                    <div className={props.styles.mob_flight_autocomplete_name}>{element.CITY_NAME}, {element.COUNTRY_NAME}
                                        <span>{element.AIRPORT_NAME}</span>
                                    </div>
                                    <div className={props.styles.mob_flight_autocomplete_tag}>{element.AIRPORT_CODE}</div>
                                </div>
                            )
                        })
                    }
                </div>
            ) : null
            }
        </div>
    )
}