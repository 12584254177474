
import TabHeader5 from "./theme5/index";
import commonFunction from '../../lib/utils/common';

export function TabHeader(props) {
  console.log("props data=====>", props);
  const { theme } = props
  console.log("theme => ", theme);
   
  switch (theme) {
    case 'theme5':
        return !commonFunction.isValueEmpty(props) && Object.keys(props).length > 0 &&
        <TabHeader5 data={props} />
        break;
    default:
      return <TabHeader5 data={props} />
  }

}

export default TabHeader;
