import React, { useEffect, useState } from 'react';
import commonFunction from '../../../../lib/utils/common';

export default function PaxConfig(props) {
    const { theme } = props;
    return (
        <div className={props.styles.mob_flight_search_wid_inputfield}>
            <div 
            // className={props.value === props.minLimit ? props.styles.disable_pax_btn : ""} 
            onClick={() => {
                if (!commonFunction.isValueEmpty(props.value)) {
                    if (props.value > props.minLimit) {
                        props.onChangePaxValue(props.value - 1, "minus");
                    }
                }
            }}>
                <div className={props.styles.mob_flight_search_flex_adjust}>
                    {/* <img src="/theme4/minus-circle.svg" className={props.styles.minus_pax} /> */}
                    {/* <svg className={props.styles.minus_pax} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="minus"><path fill="#FFFFFF" d="M19,11H5a1,1,0,0,0,0,2H19a1,1,0,0,0,0-2Z"></path></svg> */}
                    <svg className={props.styles.minus_pax} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="minus-circle"><path d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm4-9H8a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Z"></path></svg>
                </div>
            </div>
            <span>{props.value}</span>
            <div 
            // className={props.totalPax === props.maxLimit ? props.styles.disable_pax_btn : ""} 
            onClick={() => {
                console.log("theme333333", props.value);
                if (!commonFunction.isValueEmpty(props.value)) {
                    if (
                        props.totalPax >= props.minLimit &&
                        props.totalPax <= props.maxLimit
                    ) {
                        props.onChangePaxValue(props.value + 1, "add");
                    }
                }
            }}>
                {/* <img src="/theme4/plus-circle.svg" className={props.styles.minus_pax} /> */}
                {/* <svg className={props.styles.minus_pax} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="plus"><path fill="#fff" d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"></path></svg> */}
                <svg  className={props.styles.minus_pax} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="plus-circle"><path d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm4-9H13V8a1,1,0,0,0-2,0v3H8a1,1,0,0,0,0,2h3v3a1,1,0,0,0,2,0V13h3a1,1,0,0,0,0-2Z"></path></svg>
            </div>
        </div>
    )
}