import React, { Component } from 'react';
import { format, addDays, differenceInCalendarDays } from 'date-fns';
import FlightAutoComplete from './FlightAutoComplete';
import PaxConfig from './PaxConfig';
import MobileCalender from '../../../calendar/flightCalender/theme1/MobileCalender';
import commonFunction from '../../../../lib/utils/common';

const cabinClass = [
    { id: 1, value: 'Economy', label: 'Economy' },
    { id: 2, value: 'Business', label: 'Business' },
    { id: 3, value: 'First Class', label: 'First Class' },
    { id: 4, value: 'Premium Economy', label: 'Premium Economy' }
];

const todaysDate = format(new Date(), 'dd-LLL-yyyy');
export default class Theme1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOnewaySearch: true,
            departureValue: "",
            goingToValue: "",
            expandDepartureBoolean: false,
            expandGoingToBoolean: false,
            departureDateValue: format(new Date(), 'dd-LLL-yyyy'),
            goingToDateValue: format(addDays(new Date(), 7), 'dd-LLL-yyyy'),
            expandDepartureDateBoolean: false,
            expandGoingToDateBoolean: false,
            paxAdultValue: 1,
            paxChildrenValue: 0,
            paxInfantValue: 0,
            SelectedClassValue: { label: "Economy", id: 1 },
            openCabinClassList: false,
            getDepartureSuggestionList: [],
            getGoingToSuggestionList: [],
            getDepartureAirportCode: "",
            getGoingToAirportCode: "",
            isError: { departure: false, goingTo: false, departureDate: false, goingToDate: false, },
            returnCity: "",
            departureCity: ""
        }
    }
    componentDidMount() {
        this.setState({
            getDepartureSuggestionList: this.props.airports,
            getGoingToSuggestionList: this.props.airports,
            goingToValue: new URLSearchParams(window.location.search).get('to') && new URLSearchParams(window.location.search).get('toCity') ? new URLSearchParams(window.location.search).get('toCity') + " - (" + new URLSearchParams(window.location.search).get('to') + ")" : '',
            getGoingToAirportCode: new URLSearchParams(window.location.search).get('to') ? new URLSearchParams(window.location.search).get('to') : "",
            returnCity: new URLSearchParams(window.location.search).get('toCity') ? new URLSearchParams(window.location.search).get('toCity') : ''
        })
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    handleClickOutside = () => {
        if (this.state.openCabinClassList === true) this.setState({ openCabinClassList: false });
        if (this.state.expandDepartureBoolean === true) this.setState({ expandDepartureBoolean: false });
        if (this.state.expandGoingToBoolean === true) this.setState({ expandGoingToBoolean: false });
    }
    onClickOpenCabinClassList = () => {
        // document.getElementById("modal_container-popup").scrollIntoView({ behavior: 'smooth', block: 'start' })
        if (this.state.openCabinClassList === false) {
            this.setState({ openCabinClassList: true });
        } else if (this.state.openCabinClassList === true) {
            this.setState({ openCabinClassList: false });
        }
    }

    onClickSearchFlight = () => {
        const mapDispatch = {
            departure: commonFunction.isValueEmpty(this.state.departureValue) ? true : false,
            goingTo: commonFunction.isValueEmpty(this.state.goingToValue) ? true : (this.state.departureValue === this.state.goingToValue) ? true : false,
            departureDate: commonFunction.isValueEmpty(this.state.departureDateValue) ? true : false,
            goingToDate: commonFunction.isValueEmpty(this.state.goingToDateValue) ? true : false,
        }
        this.setState({ isError: mapDispatch }, () => {
            let Validate = true;
            for (const [key, value] of Object.entries(this.state.isError)) {
                if (value === true) {
                    Validate = false;
                    break;
                }
            }
            let arrivalDate = ''
            let departureDateArr = !commonFunction.isValueEmpty(this.state.departureDateValue) ? this.state.departureDateValue : '';
            let departureDate = !commonFunction.isValueEmpty(departureDateArr) ? format(new Date(departureDateArr), 'yyyy-MM-dd') : '';
            if (this.state.isOnewaySearch === false) {
                let arrivalDateArr = !commonFunction.isValueEmpty(this.state.goingToDateValue) ? this.state.goingToDateValue : '';
                arrivalDate = !commonFunction.isValueEmpty(arrivalDateArr) ? format(new Date(arrivalDateArr), 'yyyy-MM-dd') : '';
            }
            let urlQuery = {
                from: this.state.getDepartureAirportCode, to: this.state.getGoingToAirportCode, type: 1, class: this.state.SelectedClassValue.id, adults: this.state.paxAdultValue, child: this.state.paxChildrenValue, infants: this.state.paxInfantValue,
                departure: departureDate, arrival: arrivalDate, fromCity: this.state.departureCity, toCity: this.state.returnCity 
            }
            if (Validate === true) commonFunction.redirectTo("flight/search", urlQuery)
        });
    }
    onchangeVisibility = (bool, type) => {
        if (type === "goingTo") {
            this.setState({ expandGoingToDateBoolean: bool, expandDepartureDateBoolean: false }, () => {
                if (this.state.isOnewaySearch === true) {
                    this.setState({ isOnewaySearch: false });
                }
            });
        } else {
            this.setState({ expandGoingToDateBoolean: false, expandDepartureDateBoolean: bool });
        }
    }
    onChangeSetDepartureGoingToValue = (value, type) => {
        if (type === "departure") {
            this.setState({ departureValue: value }, () => {
                if (this.state.isError.departure === true) {
                    this.validateErrorMsg();
                }
                if (value.length === 0) {
                    this.setState({ getDepartureSuggestionList: [] });
                }
            });
        } else {
            this.setState({ goingToValue: value }, () => {
                if (this.state.isError.goingTo === true) {
                    this.validateErrorMsg()
                    if (value.length === 0) this.setState({ getGoingToSuggestionList: [] });
                }
            });
        }
        if (type === "departure") {
            this.props.getAutoSuggestData(value, type)
            this.setState({ expandDepartureBoolean: true });
        } else {
            this.props.getAutoSuggestData(value, type)
            this.setState({ expandGoingToBoolean: true });
        }
    }
    onClickSetDepartureGoingToValue = (city, airPortCode, type) => {
        if (type === "departure") {
            this.setState({
                departureValue: city + ' - (' + airPortCode + ')',
                getDepartureAirportCode: airPortCode, departureCity: city
            }, () => {
                if (this.state.isError.departure === true) {
                    this.validateErrorMsg();
                }
            });
        } else {
            this.setState({
                goingToValue: city + ' - (' + airPortCode + ')',
                getGoingToAirportCode: airPortCode, returnCity: city
            }, () => {
                if (this.state.isError.goingTo === true) {
                    this.validateErrorMsg()
                }
            });
        }
    }
    onclickFlightAutoComplete(type) {
        if (type === "departure") {
            this.setState({ departureValue: "" }, () => {
                this.props.getAutoSuggestData(this.state.departureValue, type, "click")
            })
        } else {
            this.setState({ goingToValue: "" }, () => {
                this.props.getAutoSuggestData(this.state.goingToValue, type, "click")
            })
        }
    }
    onClickChangePaxNumber = (pax, value, type) => {
        if (pax === "adult") {
            console.log("mob_flight_search_wid_container111", type)
            if ((this.state.paxChildrenValue + value) <= 9 && (this.state.paxChildrenValue + value) > 0) {
                this.setState({ paxAdultValue: value }, () => {
                    if (type === "minus") {
                        if (this.state.paxInfantValue > value) {
                            this.setState({ paxInfantValue: --this.state.paxInfantValue });
                        }
                    }
                });
            }
        } else if (pax === "children") {
            if ((value + this.state.paxAdultValue) <= 9 && (value + this.state.paxAdultValue) > 0) {
                this.setState({ paxChildrenValue: value });
            }
        } else if (pax === "infant") {
            console.log("flight_search_wid_main6666", value)
            if (value > this.state.paxAdultValue) {
                this.setState({ paxInfantValue: --value });
            } else {
                this.setState({ paxInfantValue: value });
            }
        }
    }
    validateErrorMsg = () => {
        const mapDispatch = {
            departure: commonFunction.isValueEmpty(this.state.departureValue) ? true : false,
            goingTo: commonFunction.isValueEmpty(this.state.goingToValue) ? true : false,
            departureDate: commonFunction.isValueEmpty(this.state.departureDateValue) ? true : false,
            goingToDate: commonFunction.isValueEmpty(this.state.goingToDateValue) ? true : false,
        }
        this.setState({ isError: mapDispatch });
    }
    onClickSwap = () => {
        if (this.state.departureValue || this.state.goingToValue) {
            let departureValue = this.state.departureValue
            let departureAirportCode = this.state.getDepartureAirportCode
            let departureCity = this.state.departureCity
            this.setState({
                departureValue: this.state.goingToValue, goingToValue: departureValue,
                getDepartureAirportCode: this.state.getGoingToAirportCode, getGoingToAirportCode: departureAirportCode,
                departureCity: this.state.returnCity, returnCity: departureCity
            });
        }
    }
    onChangeDepartureGoingDateValue = (value, type) => {
        console.log(type, value)
        if (type === "goingTo") {
            let startDate = this.state.departureDateValue;
            let endDate = value;
            let dayDifference = differenceInCalendarDays(new Date(endDate), new Date(startDate));
            if (dayDifference < 0) {
                this.setState({ isOnewaySearch: true, goingToDateValue: format(addDays(new Date(startDate), 7), 'dd-LLL-yyyy') });
            } else {
                this.setState({ goingToDateValue: value });
            }
        } else {
            let dayDifference = differenceInCalendarDays(new Date(this.state.goingToDateValue), new Date(value));
            let goingToDateValue = this.state.goingToDateValue
            // console.log("dayDifference", dayDifference)
            if (dayDifference < 0) {
                goingToDateValue = format(addDays(new Date(value), 7), 'dd-LLL-yyyy')
            }
            // console.log("goingToDateValue", goingToDateValue)
            this.setState({ departureDateValue: value, goingToDateValue });
        }
    }
    render() {
        // console.log("styles.mob_flight_search_wid_container", this.state)
        return (
            <div className={this.props.styles.mob_flight_search_wid_container}>
                <div className={this.props.styles.mob_flight_search_wid_main}>
                    <div id={this.props.styles.tab_submenu_content}>
                        <div className={this.props.styles.landing_tab_submenu_new + " " + this.props.styles.sub_tab_new}>
                            <div className={this.props.styles.mobile_visible}>
                                <div className={this.props.styles.mobo_tab_header + " " + this.props.styles.flight_header_mobo}>
                                    {/* <img src="/images/back-arrow.png" alt="" className={this.props.styles.arrow_image} onClick={() => this.props.onClosePopup(false)} /> */}
                                    <span className={this.props.styles.arrow_image} onClick={() => this.props.onClosePopup(false)}>
                                        <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                                        </svg>
                                    </span>
                                    <h3 className={this.props.styles.search_filter_header}>Flights</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={this.props.styles.mob_flight_way_tab}>
                            <div className={this.props.styles.mob_flight_way_btngrp}>
                                <button className={this.state.isOnewaySearch ? this.props.styles.active_way : this.props.styles.button_style} onClick={() => { this.setState({ isOnewaySearch: true, expandGoingToDateBoolean: false, expandDepartureDateBoolean: false }) }}>One Way</button>
                                <button className={!this.state.isOnewaySearch ? this.props.styles.active_way : this.props.styles.button_style} onClick={() => { this.setState({ isOnewaySearch: false }) }}>Round-Trip</button>
                            </div>
                        </div>
                        <div id="modal_container-popup" className={this.props.styles.mob_flight_search_details_container}>
                            <div className={this.props.styles.mob_flight_search_details_main}>
                                <div className={this.props.styles.mob_flight_search_details_row}>
                                    <div className={this.props.styles.mob_flight_search_column + " " + this.props.styles.mob_flight_search_depart}>
                                        <FlightAutoComplete
                                            label="Departure From"
                                            value={this.state.departureValue}
                                            onclickFlightAutoComplete={() => this.onclickFlightAutoComplete("departure")}
                                            styles={this.props.styles}
                                            placholder="City or Airport"
                                            onClickSourceAndDestination={(city, airPortCode) => this.onClickSetDepartureGoingToValue(city, airPortCode, "departure")}
                                            onChangeSourceAndDestination={(value) => this.onChangeSetDepartureGoingToValue(value, "departure")}
                                            isAutoCompleteExpand={(bool) => this.setState({ expandDepartureBoolean: bool })}
                                            autoCompleteData={!commonFunction.isValueEmpty(this.props.getDepartureSuggestionList) ? this.props.getDepartureSuggestionList : []}
                                            expandAutoCompleteBool={!commonFunction.isValueEmpty(this.state.expandDepartureBoolean) ? this.state.expandDepartureBoolean : false}
                                            leftIcon={'/images/flgt.png'}
                                            errorMessage={"Please select your Origin City"}
                                            isError={this.state.isError.departure}
                                        />
                                    </div>
                                    <div className={this.props.styles.mob_flight_swap_main} onClick={() => { this.onClickSwap() }}>
                                        <img src="/images/home-fgt.png" className={this.props.styles.mob_flight_swap} alt="flight arrow" value="Swap"></img>
                                    </div>
                                    <div className={this.props.styles.mob_flight_search_column + " " + this.props.styles.mob_flight_search_depart}>
                                        <FlightAutoComplete
                                            label="Going To"
                                            value={this.state.goingToValue}
                                            onclickFlightAutoComplete={() => this.onclickFlightAutoComplete("goingTo")}
                                            styles={this.props.styles}
                                            placholder="City or Airport"
                                            onClickSourceAndDestination={(city, airPortCode) => this.onClickSetDepartureGoingToValue(city, airPortCode, "goingTo")}
                                            onChangeSourceAndDestination={(value) => this.onChangeSetDepartureGoingToValue(value, "goingTo")}
                                            isAutoCompleteExpand={(bool) => this.setState({ expandGoingToBoolean: bool })}
                                            autoCompleteData={!commonFunction.isValueEmpty(this.props.getGoingToSuggestionList) ? this.props.getGoingToSuggestionList : []}
                                            expandAutoCompleteBool={!commonFunction.isValueEmpty(this.state.expandGoingToBoolean) ? this.state.expandGoingToBoolean : false}
                                            leftIcon={'/images/dwn-flgt.png'}
                                            errorMessage={(this.state.goingToValue && this.state.departureValue === this.state.goingToValue) ? "Origin and destination cannot be same" : "Please select your Destination City"}
                                            isError={this.state.isError.goingTo}
                                        />
                                    </div>
                                    <div className={this.props.styles.mob_flight_search_column + " " + this.props.styles.mob_flight_search_depart_going_date}>
                                        <MobileCalender
                                            label="Departure Date"
                                            styles={this.props.styles}
                                            value={!commonFunction.isValueEmpty(this.state.departureDateValue) ? this.state.departureDateValue : ""}
                                            placholder="Departure Date"
                                            onChangeDate={(value) => { this.onChangeDepartureGoingDateValue(value, "departure") }}
                                            iconImage="/images/fgt-dt.png"
                                            startDate={!commonFunction.isValueEmpty(this.state.departureDateValue) ? this.state.departureDateValue : ""}
                                            endDate={!commonFunction.isValueEmpty(this.state.goingToDateValue) ? this.state.goingToDateValue : ""}
                                            startDateLimit={!commonFunction.isValueEmpty(todaysDate) ? todaysDate : ""}
                                            dateFormat={"DD-MMM-YYYY"}
                                            changeVisibility={(bool) => this.onchangeVisibility(bool, "departure")}
                                            isVisible={this.state.expandDepartureDateBoolean}
                                            type={"double"}
                                            doubleSelection={false}
                                            totalMonths={12}
                                        />
                                    </div>
                                    <div className={this.props.styles.mob_flight_search_column + " " + this.props.styles.mob_flight_search_depart_going_date}>
                                        <MobileCalender
                                            label="Return Date"
                                            styles={this.props.styles}
                                            value={this.state.isOnewaySearch === false ? !commonFunction.isValueEmpty(this.state.goingToDateValue) ? this.state.goingToDateValue : "" : ""}
                                            placholder="Arrival Date"
                                            onChangeDate={(value) => { this.onChangeDepartureGoingDateValue(value, "goingTo") }}
                                            iconImage="/images/fgt-dt.png"
                                            startDate={!commonFunction.isValueEmpty(this.state.departureDateValue) ? this.state.departureDateValue : ""}
                                            endDate={!commonFunction.isValueEmpty(this.state.goingToDateValue) ? this.state.goingToDateValue : ""}
                                            startDateLimit={this.state.departureDateValue ? this.state.departureDateValue : todaysDate ? todaysDate : ""}
                                            dateFormat={"DD-MMM-YYYY"}
                                            changeVisibility={(bool) => this.onchangeVisibility(bool, "goingTo")}
                                            isVisible={this.state.expandGoingToDateBoolean}
                                            type={"double"}
                                            doubleSelection={false}
                                            totalMonths={12}
                                        />
                                    </div>
                                    <div className={this.props.styles.mob_flight_search_column + " " + this.props.styles.mob_flight_search_pax}>
                                        <label>Adult</label><span>(12 Years)</span>
                                        <PaxConfig
                                            value={!commonFunction.isValueEmpty(this.state.paxAdultValue) ? this.state.paxAdultValue : 0}
                                            onChangePaxValue={(value, type) => this.onClickChangePaxNumber("adult", value, type)}
                                            maxLimit={9}
                                            minLimit={1}
                                            totalPax={this.state.paxChildrenValue + this.state.paxAdultValue}
                                            styles={this.props.styles}
                                        />
                                    </div>
                                    <div className={this.props.styles.mob_flight_search_column + " " + this.props.styles.mob_flight_search_pax}>
                                        <label>Children</label><span>(2-11 Years)</span>
                                        <PaxConfig
                                            value={!commonFunction.isValueEmpty(this.state.paxChildrenValue) ? this.state.paxChildrenValue : 0}
                                            onChangePaxValue={(value, type) => this.onClickChangePaxNumber("children", value, type)}
                                            maxLimit={9}
                                            minLimit={0}
                                            totalPax={this.state.paxChildrenValue + this.state.paxAdultValue}
                                            styles={this.props.styles}
                                        />
                                    </div>
                                    <div className={this.props.styles.mob_flight_search_column + " " + this.props.styles.mob_flight_search_pax}>
                                        <label>Infant</label><span>(Below 2 Years)</span>
                                        <PaxConfig
                                            value={!commonFunction.isValueEmpty(this.state.paxInfantValue) ? this.state.paxInfantValue : 0}
                                            onChangePaxValue={(value, type) => this.onClickChangePaxNumber("infant", value, type)}
                                            maxLimit={this.state.paxAdultValue}
                                            minLimit={0}
                                            totalPax={this.state.paxInfantValue}
                                            styles={this.props.styles}
                                        />
                                    </div>
                                    <div id="cabinclassmain" className={this.props.styles.mob_flight_search_column + " " + this.props.styles.mob_flight_search_class}>
                                        <div className={this.props.styles.mob_flight_cabin_class_cont} onClick={() => this.onClickOpenCabinClassList()}>
                                            <img src="/images/eco.png" className={this.props.styles.mob_flight_cabin_class_icon_img} alt=""/>
                                            <div className={this.props.styles.mob_flight_cabin_class_name}>
                                                {!commonFunction.isValueEmpty(this.state.SelectedClassValue) ? this.state.SelectedClassValue.label : "Economy"}
                                            </div>
                                            <div className="mob_flight_cabin_class_arrow">
                                                <img src="/images/down_arrow.jpg" className={this.props.styles.mob_flight_cabin_class_arrow_img} alt=""/>
                                            </div>
                                            {this.state.openCabinClassList === true ? (
                                                <div className={this.props.styles.mob_flight_cabin_class_list}>
                                                    {cabinClass.map((element, index) => {
                                                        return (
                                                            <div className={this.props.styles.mob_flight_cabin_class_ele} onClick={() => { this.setState({ SelectedClassValue: { label: element.label, id: element.id }, openCabinClassList: false }) }}>
                                                                {element.label}
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className={this.props.styles.btn_search}>
                                        <button className={this.props.styles.mob_flight_search_widget_btn}
                                            onClick={() => this.onClickSearchFlight()}>Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
// export default function Theme1(props) {
//     const wrapperRef = useRef(null);
//     const [IsOnewaySearch, setIsOnewaySearch] = useState(true);
//     const [departureValue, setDepartureValue] = useState("");
//     const [goingToValue, setGoingValue] = useState("");
//     const [expandDepartureBoolean, setExpandDepartureValue] = useState(false);
//     const [expandGoingToBoolean, setExpandGoingToValue] = useState(false);
//     const [departureDateValue, setDepartureDateValue] = useState(format(new Date(), 'dd/MM/yyyy'));
//     const [goingToDateValue, setGoingDateValue] = useState(format(addDays(new Date(), 7), 'dd/MM/yyyy'));
//     const [expandDepartureDateBoolean, setExpandDepartureDateBoolean] = useState(false);
//     const [expandGoingToDateBoolean, setExpandGoingToDateBoolean] = useState(false);
//     const [paxAdultValue, setPaxAdultValue] = useState(1);
//     const [paxChildrenValue, setPaxChildrenValue] = useState(0);
//     const [paxInfantValue, setPaxInfantValue] = useState(0);
//     const [SelectedClassValue, setSelectedClassValue] = useState("Economy");
//     const [openCabinClassList, setOpenCabinClassList] = useState(false);
//     const [getDepartureSuggestionList, setDepartureSuggestionList] = useState([]);
//     const [getGoingToSuggestionList, setGoingToSuggestionList] = useState([]);
//     const [isError, setIsError] = useState({ departure: false, goingTo: false, departureDate: false, goingToDate: false, });

//     useEffect(() => {
//         document.addEventListener("click", handleClickOutside, false);
//         return () => {
//             document.removeEventListener("click", handleClickOutside, false);
//         };
//     }, [])
//     const onClickOpenCabinClassList = () => {
//         if (openCabinClassList === false) {
//             setOpenCabinClassList(true);
//         } else if (openCabinClassList === true) {
//             setOpenCabinClassList(false);
//         }
//     }
//     const handleClickOutside = event => {
//         if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
//             if (openCabinClassList === true) setOpenCabinClassList(false);
//             if (expandDepartureBoolean === true) setExpandDepartureValue(false);
//             if (expandGoingToBoolean === true) setExpandGoingToValue(false);
//         }
//     };
//     const onClickSearchFlight = () => {
//         validateErrorMsg();
//         let Validate = true;
//         for (const [key, value] of Object.entries(isError)) {
//             if (value === true) {
//                 Validate = false;
//                 break;
//             }
//         }
//         let cabinNumber = 1;
//         for (let i = 0; i <= cabinClass.length; i++) {
//             if (cabinClass[i].name === SelectedClassValue) cabinNumber =  cabinClass[i].id; 
//         }
//         let urlQuery = {
//             from: getDepartureAirportCode, to: getGoingToAirportCode, type:IsOnewaySearch===true?1:2, 
//             class:cabinNumber, adults:paxAdultValue, child: paxChildrenValue, infants: paxInfantValue, 
//             departure: departureDateValue, arrival:goingToDateValue,
//         }
//         if(Validate === true) commonFunction.redirectTo("/search", urlQuery)
//     }

//     const onChangeGoingDateValue = (value, type) => {
//         if (type === "goingTo") {
//             setGoingDateValue(value);
//         } else {
//             setGoingDateValue(value)
//         }
//     }
//     const onchangeVisibility = (bool, type) => {
//         if (type === "goingTo") {
//             setExpandGoingToDateBoolean(bool);
//             setExpandDepartureDateBoolean(false);
//             if (IsOnewaySearch === true) {
//                 setIsOnewaySearch(false);
//             }
//         } else {
//             setExpandGoingToDateBoolean(false);
//             setExpandDepartureDateBoolean(bool);
//         }
//     }
//     const onChangeSetDepartureGoingToValue = (value, type) => {
//         if (type === "departure") {
//             if(isError.departure === true){
//                 validateErrorMsg();
//             }
//             if(value.length === 0) {
//                 setDepartureSuggestionList([]);
//             } 
//             setDepartureValue(value);
//         } else {
//             setGoingValue(value);
//             if(isError.goingTo === true){
//                 validateErrorMsg()
//             }
//             if(value.length === 0) setGoingToSuggestionList([]); 
//         }
//         autoSuggestHomeWidget({ autoSuggest: value }).then(res => {
//             if (!commonFunction.isValueEmpty(res) && res.status === true &&
//                 !commonFunction.isValueEmpty(res.data)) {
//                 console.log("mob_flight_search_wid_main555", res.data)
//                 if (type === "departure") {
//                     setDepartureSuggestionList([...res.data]);
//                     setExpandDepartureValue(true); 
//                 } else {
//                     setGoingToSuggestionList([...res.data]);
//                     setExpandGoingToValue(true);
//                 }
//             }
//         })
//     }
//     const onClickSetDepartureGoingToValue = (city, airPortCode, type) => {
//         if (type === "departure") {
//             if(isError.departure === true){
//                 validateErrorMsg();
//             }
//             setDepartureValue(city+' - ('+airPortCode +')');
//             setDepartureSuggestionList(airPortCode)
//         } else {
//             if(isError.goingTo === true){
//                 validateErrorMsg()
//             }
//             setGoingValue(city+' - ('+airPortCode +')');
//             setGoingToSuggestionList(airPortCode)
//         }
//     }
//     const onClickChangePaxNumber = (pax, value, type) => {
//         if(pax === "adult"){
//             if((paxChildrenValue+value)<=9 && (paxChildrenValue+value)>0){
//                 setPaxAdultValue(value);
//                 if(type === "minus"){
//                     if(paxInfantValue>value){
//                         setPaxInfantValue(--paxInfantValue);
//                     }
//                 }
//             }
//         }else if(pax === "children"){
//             if((value+paxAdultValue)<=9 && (value+paxAdultValue)>0){
//                 setPaxChildrenValue(value);
//             }
//         }else if(pax === "infant"){
//             console.log("flight_search_wid_main6666", value)
//             if(value>paxAdultValue){
//                 setPaxInfantValue(--value);
//             }else{
//                 setPaxInfantValue(value);
//             }
//         }
//     }
//     const onClickSwap = () => {
//         if (!commonFunction.isValueEmpty(departureValue) && !commonFunction.isValueEmpty(goingToValue)) {
//             setDepartureValue(goingToValue)
//             setGoingValue(departureValue)
//         }
//     }
//     const validateErrorMsg = () =>  {
//         const mapDispatch = {
//             departure: commonFunction.isValueEmpty(departureValue) ? true : false,
//             goingTo: commonFunction.isValueEmpty(goingToValue) ? true : false,
//             departureDate: commonFunction.isValueEmpty(departureDateValue) ? true : false,
//             goingToDate: commonFunction.isValueEmpty(goingToDateValue) ? true : false,
//         }      
//         setIsError(mapDispatch);
//     }
//     return (
//         <div className={styles.mob_flight_search_wid_container} ref={wrapperRef}>
//             <div className={styles.mob_flight_search_wid_main}>
//                 <div id={styles.tab_submenu_content}>
//                     <div className={styles.landing_tab_submenu_new + " " + styles.sub_tab_new}>
//                         <div className={styles.mobile_visible}>
//                             <div className={styles.mobo_tab_header + " " + styles.flight_header_mobo}>
//                                 <img src="/images/back-arrow.png" alt="" className={styles.arrow_image} onClick={() => props.onClosePopup(false)} />
//                                 <h3 className={styles.search_filter_header}>Flights</h3>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className={styles.mob_flight_way_tab}>
//                     <div className={styles.mob_flight_way_btngrp}>
//                         <button className={IsOnewaySearch ? styles.active_way : styles.button_style} onClick={() => { setIsOnewaySearch(true); setExpandGoingToDateBoolean(false); setExpandDepartureDateBoolean(false) }}>One Way</button>
//                         <button className={IsOnewaySearch === false ? styles.active_way : styles.button_style} onClick={() => setIsOnewaySearch(false)}>Round-Trip</button>
//                     </div>
//                 </div>
//                 <div className={styles.mob_flight_search_details_container}>
//                     <div className={styles.mob_flight_search_details_main}>
//                         <div className={styles.mob_flight_search_details_row}>
//                             <div className={styles.mob_flight_search_column + " " + styles.mob_flight_search_depart}>
//                                 <FlightAutoComplete
//                                     label="DEPARTURE FROM"
//                                     value={departureValue}
//                                     placholder="City or Airport"
//                                     onClickSourceAndDestination={(city, airPortCode) => onClickSetDepartureGoingToValue(city, airPortCode, "departure")}
//                                     onChangeSourceAndDestination={(value) => onChangeSetDepartureGoingToValue(value, "departure")}
//                                     isAutoCompleteExpand={(value) => setExpandDepartureValue(value)}
//                                     autoCompleteData={!commonFunction.isValueEmpty(getDepartureSuggestionList) ? getDepartureSuggestionList : []}
//                                     expandAutoCompleteBool={!commonFunction.isValueEmpty(expandDepartureBoolean) ? expandDepartureBoolean : false}
//                                     leftIcon={'/images/flgt.png'}
//                                     errorMessage={"Please select your Origin City"}
//                                     isError={isError.departure}
//                                 />
//                             </div>
//                             <div className={styles.mob_flight_swap_main} onClick={() => { onClickSwap() }}>
//                                 <img src="/images/home-fgt.png" className={styles.mob_flight_swap} alt="flight arrow"  value="Swap"></img>
//                             </div>
//                             <div className={styles.mob_flight_search_column + " " + styles.mob_flight_search_depart}>
//                                 <FlightAutoComplete
//                                     label="GOING TO"
//                                     value={goingToValue}
//                                     placholder="City or Airport"
//                                     onClickSourceAndDestination={(city, airPortCode) => onClickSetDepartureGoingToValue(city, airPortCode, "goingTo")}
//                                     onChangeSourceAndDestination={(value) => onChangeSetDepartureGoingToValue(value, "goingTo")}
//                                     isAutoCompleteExpand={(value) => setExpandGoingToValue(value)}
//                                     autoCompleteData={!commonFunction.isValueEmpty(getGoingToSuggestionList) ? getGoingToSuggestionList : []}
//                                     expandAutoCompleteBool={!commonFunction.isValueEmpty(expandGoingToBoolean) ? expandGoingToBoolean : false}
//                                     leftIcon={'/images/dwn-flgt.png'}
//                                     errorMessage={"Please select your Destination City"}
//                                     isError={isError.goingTo}
//                                 />
//                             </div>
//                             <div className={styles.mob_flight_search_column + " " + styles.mob_flight_search_depart_going_date}>
//                                 <MobileCalender
//                                     label="DEPARTURE DATE"
//                                     value={!commonFunction.isValueEmpty(departureDateValue) ? departureDateValue : ""}
//                                     placholder="Departure Date"
//                                     onChangeDate={(value) => { setDepartureDateValue(value, "departure") }}
//                                     iconImage="/images/fgt-dt.png"
//                                     startDate={!commonFunction.isValueEmpty(departureDateValue) ? departureDateValue : ""}
//                                     endDate={!commonFunction.isValueEmpty(goingToDateValue) ? goingToDateValue : ""}
//                                     dateLimit={!commonFunction.isValueEmpty(departureDateValue) ? departureDateValue : ""}
//                                     dateFormat={"dd/MM/yyyy"}
//                                     changeVisibility={(bool) => onchangeVisibility(bool, "departure")}
//                                     isVisible={expandDepartureDateBoolean}
//                                     type={"double"}
//                                     doubleSelection={false}
//                                 />
//                             </div>
//                             <div className={styles.mob_flight_search_column + " " + styles.mob_flight_search_depart_going_date}>
//                                 <MobileCalender
//                                     label="RETURN DATE"
//                                     value={IsOnewaySearch === false ? !commonFunction.isValueEmpty(goingToDateValue) ? goingToDateValue : "" : ""}
//                                     placholder="Arrival Date"
//                                     onChangeDate={(value) => { onChangeGoingDateValue(value, "goingTo") }}
//                                     iconImage="/images/fgt-dt.png"
//                                     startDate={!commonFunction.isValueEmpty(departureDateValue) ? departureDateValue : ""}
//                                     endDate={!commonFunction.isValueEmpty(goingToDateValue) ? goingToDateValue : ""}
//                                     dateLimit={!commonFunction.isValueEmpty(departureDateValue) ? departureDateValue : ""}
//                                     dateFormat={"dd/MM/yyyy"}
//                                     changeVisibility={(bool) => onchangeVisibility(bool, "goingTo")}
//                                     isVisible={expandGoingToDateBoolean}
//                                     type={"double"}
//                                     doubleSelection={false}
//                                 />
//                             </div>
//                             <div className={styles.mob_flight_search_column + " " + styles.mob_flight_search_pax}>
//                                 <label>Adult</label><span>(12 Years)</span>
//                                 <PaxConfig
//                                     value={!commonFunction.isValueEmpty(paxAdultValue) ? paxAdultValue : 0}
//                                     onChangePaxValue={(value, type) => onClickChangePaxNumber("adult",value, type)}
//                                     maxLimit={9}
//                                     minLimit={1}
//                                     totalPax={paxChildrenValue+paxAdultValue}
//                                 />
//                             </div>
//                             <div className={styles.mob_flight_search_column + " " + styles.mob_flight_search_pax}>
//                                 <label>Children</label><span>(2-11 Years)</span>
//                                 <PaxConfig
//                                     value={!commonFunction.isValueEmpty(paxChildrenValue) ? paxChildrenValue : 0}
//                                     onChangePaxValue={(value, type) => onClickChangePaxNumber("children",value,type)}
//                                     maxLimit={9}
//                                     minLimit={0}
//                                     totalPax={paxChildrenValue+paxAdultValue}
//                                 />
//                             </div>
//                             <div className={styles.mob_flight_search_column + " " + styles.mob_flight_search_pax}>
//                                 <label>Infant</label><span>(Below 2 Years)</span>
//                                 <PaxConfig
//                                     value={!commonFunction.isValueEmpty(paxInfantValue) ? paxInfantValue : 0}
//                                     onChangePaxValue={(value, type) => onClickChangePaxNumber("infant",value,type)}
//                                     maxLimit={paxAdultValue}
//                                     minLimit={0}
//                                     totalPax={paxInfantValue}
//                                 />
//                             </div>
//                             <div className={styles.mob_flight_search_column + " " + styles.mob_flight_search_class}>
//                                 <div className={styles.mob_flight_cabin_class_cont} onClick={onClickOpenCabinClassList}>

//                                         <img src="/images/eco.png" className={styles.mob_flight_cabin_class_icon_img} />

//                                     <div className={styles.mob_flight_cabin_class_name}>
//                                         {!commonFunction.isValueEmpty(SelectedClassValue) ? SelectedClassValue : "Economy"}
//                                     </div>
//                                     <div className="mob_flight_cabin_class_arrow">
//                                         <img src="/images/down_arrow.jpg" className={styles.mob_flight_cabin_class_arrow_img} />
//                                     </div>
//                                     {openCabinClassList === true ? (
//                                         <div className={styles.mob_flight_cabin_class_list}>
//                                             {cabinClass.map((element, index) => {
//                                                 return (
//                                                     <div className={styles.mob_flight_cabin_class_ele} onClick={() => { setSelectedClassValue(element.label); setOpenCabinClassList(false) }}>
//                                                         {element.label}
//                                                     </div>
//                                                 )
//                                             })
//                                             }
//                                         </div>
//                                     ) : null
//                                     }
//                                 </div>
//                             </div>
//                             <div className={styles.btn_search}>
//                                 <button className={styles.mob_flight_search_widget_btn}
//                                     onClick={() => onClickSearchFlight()}>Search Flights</button>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }