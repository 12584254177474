import 'react-multi-carousel/lib/styles.css'
import * as useragent from 'express-useragent'
import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  SearchActivityHomeWidget,
  SearchHotelHomeWidget,
  autoSuggestHomeWidget,
} from '@/lib/searchHomeWidget'
import ActivityMobileSearchWidget from '../../../../../common/components/widgets/activityWidgetMobile'
import ActivitySearchMobileThemeOneCSS from '../../../../../common/components/widgets/activityWidgetMobile/theme2/index.module.css'
import AuthContext from 'store/auth-context'
import DesktopCalenderThemeOneCss from '../../../../../common/components/widgets/flightSearchWidget/theme2/index.module.css'
import ExperienceMobileSearchWidget from '../../../../../common/components/widgets/experienceWidgetMobile'
import FlightMobileSearchWidget from '../../../../../common/components/widgets/flightMobileSearchWidget'
import FlightSearchMobileThemeOneCSS from '../../../../../common/components/widgets/flightMobileSearchWidget/theme2/index.module.css'
import Head from 'next/head'
import HotelMobileSearchWidget from '../../../../../common/components/widgets/hotelMobileSearchWidget'
import HotelSearchMobileThemeOneCSS from '../../../../../common/components/widgets/hotelMobileSearchWidget/theme2/index.module.css'
import Image from 'next/image'
import ModalPopupComponent from '../../../../../common/components/ModalPopupComponent/theme2/ModalPopupComponent'
import cf from '@/lib/utils/common'
import configUrl from '../../../../../config/config.json'
import { getHomePageData } from '../../../../../common/lib/Homepage'
import { getSiteData } from '../../../../../common/lib/auth/authhelpers'
import modalCss from '../../../../../common/components/ModalPopupComponent/theme2/index.module.css'
import parse from 'html-react-parser'
import swPopupCss from '../../../../../common/components/widgets/commonCss/swPopupCssTheme2.module.css'
import styles from './index.module.css'

export default function MobileTheme2(props) {
  console.log('props theme2', props)
  const [isFlightSearchWidgetPopup, setFlightSearchWidgetPopup] = useState(false)
  const [initFlightData, setInitFlightData] = useState([])
  const [getDepartureSuggestionList, setDepartureSuggestionList] = useState([])
  const [getGoingToSuggestionList, setGoingToSuggestionList] = useState([])
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const wrapperRef = useRef(null)
  const [isHotelSearchWidgetPopup, setHotelSearchWidgetPopup] = useState(false)
  const [expandHotelNameBoolean, setExpandHotelName] = useState(false)
  const [expandActivityName, setExpandActivityName] = useState(false)
  const [getHotelAutoSuggestList, setHotelAutoSuggestList] = useState([])
  const [getActivityAutoSuggestList, setActivityAutoSuggestList] = useState([])
  const [menuData, setMenuData] = useState('')
  const [initHotelData, setInitHotelData] = useState([])
  const [isPopup, setPopup] = useState(false)
  const [homeBodyDesktop, setHomeBodyDesktop] = useState([])
  const [homeBodyMobile, setHomeBodyMobile] = useState([])
  const [hotelDefultDays, setHotelDefultDays] = useState(0)
  const [userProfile, setUserProfile] = useState('')
  const ctx = useContext(AuthContext)

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      // slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  }

  useEffect(() => {
    let userToken = cf.getCookieTocken('userToken')
    cf.getUserProfile(userToken, props.site_id).then((res) => {
      if (res.status === true) {
        setUserProfile(ctx?.user)
      } else {
        setUserProfile(res)
      }
    })

    if (props?.home?.data?.data?.body?.desktop) {
      setHomeBodyDesktop(props.home.data.data.body.desktop)

      let hotelIndex = props.home.data.data.body.desktop.findIndex(
        (items) => items.type === 'hotel_search'
      )
      if (hotelIndex > -1) {
        setHotelDefultDays(props.home.data.data.body.desktop[hotelIndex].default_days)
      }
    }
    if (props?.home?.data?.data?.body?.mobile) setHomeBodyMobile(props.home.data.data.body.mobile)
    const style = document.createElement('style')
    if (
      !cf.isValueEmpty(props) &&
      !cf.isValueEmpty(props.home) &&
      !cf.isValueEmpty(props.home.data)
    ) {
      style.innerHTML = props.home.data.data.css
      document.head.appendChild(style)
    }
    setActiveItemIndex(activeItemIndex)
    if (!cf.isValueEmpty(props) && !cf.isValueEmpty(props.home) && props.home.status === true) {
      if (
        !cf.isValueEmpty(props.home.data.data) &&
        !cf.isValueEmpty(props.home.data.data.body) &&
        !cf.isValueEmpty(props.home.data.data.body.desktop) &&
        props.home.data.data.body.desktop.length > 0
      ) {
        let index = props.home.data.data.body.desktop.findIndex(
          (items) => items.type === 'flight_search'
        )
        let hotelIndex = props.home.data.data.body.desktop.findIndex(
          (items) => items.type === 'hotel_search'
        )
        if (index !== -1) {
          if (
            !cf.isValueEmpty(props.home.data.data.body.desktop[index].airports) &&
            props.home.data.data.body.desktop[index].airports.length > 0
          ) {
            // let sortedDefaultAirports = props?.home?.data?.data?.body?.desktop &&
            let sortedDefaultAirports = []
            if (
              props?.home?.data?.data?.body?.desktop &&
              props?.home?.data?.data?.body?.desktop[index] &&
              props?.home?.data?.data?.body?.desktop[index].airports &&
              props?.home?.data?.data?.body?.desktop[index].airports.length
            ) {
              sortedDefaultAirports = props?.home?.data?.data?.body?.desktop[index].airports.sort(
                (a, b) => {
                  return a.ORDER - b.ORDER
                }
              )
            }
            setInitFlightData([...sortedDefaultAirports])
            setDepartureSuggestionList([...sortedDefaultAirports])
            setGoingToSuggestionList([...sortedDefaultAirports])
          }
        }
        if (hotelIndex !== -1) {
          if (
            !cf.isValueEmpty(props.home.data.data.body.desktop[hotelIndex].locations) &&
            props.home.data.data.body.desktop[hotelIndex].locations.length > 0
          ) {
            setInitHotelData([...props.home.data.data.body.desktop[hotelIndex].locations])
            setHotelAutoSuggestList([...props.home.data.data.body.desktop[hotelIndex].locations])
          }
        }
      }
    }
    // showMore()
    // <script>
    //    {showMore()}
    // </script>
    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [])

  // useEffect(() => {
  //    <Script id="show-banner" strategy="lazyOnload">
  //       {alert('readMore')}
  //       {showMore()}

  //       {/* {`document.getElementById('banner').classList.remove('hidden')`} */}
  //    </Script>
  // }, [])

  // function showMore(id) {
  //    if (id) {
  //       alert(890);
  //       document.getElementById(id + 'Overflow').className = '';
  //       document.getElementById(id + 'MoreLink').className = 'hidden';
  //       document.getElementById(id + 'LessLink').className = '';
  //    }
  // }

  // function showLess(id) {
  //    if (id) {

  //       document.getElementById(id + 'Overflow').className = 'hidden';
  //       document.getElementById(id + 'MoreLink').className = '';
  //       document.getElementById(id + 'LessLink').className = 'hidden';
  //    }
  // }

  // var len = 255;
  // var shrinkables = document.getElementsByClassName('shrinkable');
  // if (shrinkables.length > 0) {
  //    for (var i = 0; i < shrinkables.length; i++) {
  //       var fullText = shrinkables[i].innerHTML;
  //       if (fullText.length > len) {
  //          var trunc = fullText.substring(0, len).replace(/\w+$/, '');
  //          var remainder = "";
  //          var id = shrinkables[i].id;
  //          remainder = fullText.substring(len, fullText.length);
  //          shrinkables[i].innerHTML = '<span>' + trunc + '<span class="hidden" id="' + id + 'Overflow">' + remainder + '</span></span>&nbsp;<a id="' + id + 'MoreLink" style="color: var(--primary_color); font-size: 15px; font-weight: bold;cursor: pointer;" onclick="showMore(\'' + id + '\');">...Read More</a><a class="hidden" id="' + id + 'LessLink" style="color: var(--primary_color);font-size: 15px; font-weight: bold;cursor: pointer;" onclick="showLess(\'' + id + '\');">...Read Less</a>';
  //       }
  //    }
  // }

  const getAutoSuggestDataFunc = (value, section, type, event) => {
    switch (section) {
      case 'flight':
        if (event === 'click') {
          if (value.length === 0) {
            if (type === 'departure') {
              setDepartureSuggestionList([...initFlightData])
            } else {
              setGoingToSuggestionList([...initFlightData])
            }
          }
        } else {
          autoSuggestHomeWidget({ autoSuggest: value }).then((res) => {
            if (!cf.isValueEmpty(res) && res.status === true && !cf.isValueEmpty(res.data)) {
              if (type === 'departure') {
                setDepartureSuggestionList([...res.data])
              } else {
                setGoingToSuggestionList([...res.data])
              }
            }
          })
        }
        break
      case 'hotel':
        if (value.length > 0) {
          SearchHotelHomeWidget({ autoSuggest: value }).then((res) => {
            if (!cf.isValueEmpty(res) && res.status === true && !cf.isValueEmpty(res.data)) {
              if (res.data.status === 'success' && !cf.isValueEmpty(res.data.result)) {
                setHotelAutoSuggestList([...res.data.result.records])
                setExpandHotelName(true)
              }
            }
          })
        } else {
          setHotelAutoSuggestList([...initHotelData])
          setExpandHotelName(true)
        }
        break
      case 'activity':
        SearchActivityHomeWidget({ autoSuggest: value }).then((res) => {
          if (!cf.isValueEmpty(res) && !cf.isValueEmpty(res.data) && res.data.status === true) {
            if (
              !cf.isValueEmpty(res.data.searchBycity) &&
              res.data.searchBycity !== 'Not Found' &&
              res.data.searchBycity.status === 'success'
            ) {
              if (
                !cf.isValueEmpty(res.data.searchBycity.result) &&
                res.data.searchBycity.result.length > 0
              ) {
                getActivityAutoSuggestList.cityList = [...res.data.searchBycity.result]
                //     setActivityAutoSuggestList({ ...getActivityAutoSuggestList })
                setActivityAutoSuggestList([...res.data.searchBycity.result])
                if (expandActivityName === false) {
                  setExpandActivityName(true)
                }
              }
              if (
                !cf.isValueEmpty(res.data.searchBycountry) &&
                res.data.searchBycountry !== 'Not Found' &&
                res.data.searchBycountry.status === 'success'
              ) {
                if (
                  !cf.isValueEmpty(res.data.searchBycountry.result) &&
                  res.data.searchBycountry.result.length > 0
                ) {
                  getActivityAutoSuggestList.countryList = [...res.data.searchBycountry.result]
                  //      setActivityAutoSuggestList({ ...getActivityAutoSuggestList })
                  setActivityAutoSuggestList([
                    ...getActivityAutoSuggestList.cityList,
                    ...getActivityAutoSuggestList.countryList,
                  ])

                  if (expandActivityName === false) {
                    setExpandActivityName(true)
                  }
                }
              }
            }
          }
        })
        break
      default:
        break
    }
  }
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      if (expandHotelNameBoolean === true) setExpandHotelName(false)
    }
  }

  const onClickHeaderMenu = (data, i) => {
    if (data.code == 'holidays') {
      window.location.href = '/holiday/search'
      return
    }
    if (data.code == 'others') {
      window.location.href = data.url
      return
    }
    setMenuData(data.code)
    setPopup(true)
  }

  const renderInfo = (menudata) => {
    menudata = menudata == 'holiday' ? 'holidays' : menudata
    switch (menudata) {
      case 'flight':
        return (
          <div className="renderinfo-flight">
            <ModalPopupComponent
              isMobile={true}
              isPopup={isPopup}
              onClosePopup={() => {
                setPopup(false)
              }}
              modalCss={swPopupCss}
            >
              {/* {this.renderLoginPopup(this.state.popupType)} */}
              <FlightMobileSearchWidget
                pageTheme={props?.home?.data?.site?.site_theme}
                isSRP={false}
                onClosePopup={(bool) => {
                  setPopup(bool)
                }}
                homeData={!cf.isValueEmpty(initFlightData) ? initFlightData : []}
                styles={FlightSearchMobileThemeOneCSS}
                calendarStyles={DesktopCalenderThemeOneCss}
                getAutoSuggestData={(value, type, event) =>
                  getAutoSuggestDataFunc(value, 'flight', type, event)
                }
                getGoingToSuggestionList={getGoingToSuggestionList}
                getDepartureSuggestionList={getDepartureSuggestionList}
              />
            </ModalPopupComponent>
          </div>
        )
      case 'hotel':
        return (
          <div className="renderinfo-flight">
            <ModalPopupComponent
              isMobile={true}
              isPopup={isPopup}
              onClosePopup={() => {
                setPopup(false)
              }}
              modalCss={swPopupCss}
              showCrossButton={false}
            >
              <HotelMobileSearchWidget
                pageTheme={props?.home?.data?.site?.site_theme}
                isSRP={false}
                styles={HotelSearchMobileThemeOneCSS}
                getAutoSuggestValue={(value) => getAutoSuggestDataFunc(value, 'hotel')}
                expandHotelNameBoolean={expandHotelNameBoolean}
                getAutoSuggestList={getHotelAutoSuggestList}
                expandHotelNameBooleanfunc={(value) => setExpandHotelName(value)}
                homeData={!cf.isValueEmpty(initHotelData) ? initHotelData : []}
                onClosePopup={() => setPopup(false)}
              />
            </ModalPopupComponent>
          </div>
        )
      case 'holidays':
        return (
          <div className="renderinfo-flight">
            <ModalPopupComponent
              isMobile={true}
              isPopup={isPopup}
              onClosePopup={() => {
                setPopup(false)
              }}
              modalCss={modalCss}
            >
              Holiday
            </ModalPopupComponent>
          </div>
        )
      case 'activity':
        return (
          <div className="renderinfo-flight">
            <ModalPopupComponent
              isMobile={true}
              showCrossButton={false}
              isPopup={isPopup}
              onClosePopup={() => {
                setPopup(false)
              }}
              modalCss={swPopupCss}
            >
              <ActivityMobileSearchWidget
                pageTheme={props?.home?.data?.site?.site_theme}
                isSRP={false}
                cssfileMobile={ActivitySearchMobileThemeOneCSS}
                getAutoSuggestValue={(value) => getAutoSuggestDataFunc(value, 'activity')}
                expandActivityNameBoolean={expandActivityName}
                getAutoSuggestList={getActivityAutoSuggestList}
                expandActivityBooleanfunc={(value) => setExpandActivityName(value)}
                homeData={!cf.isValueEmpty(initHotelData) ? initHotelData : []}
                onClosePopup={() => setPopup(false)}
                dateFormat={props?.home?.data?.site?.date_format || 'DD-MMM-YYYY'}
              />
            </ModalPopupComponent>
          </div>
        )
      case 'experience':
        return (
          <div className="renderinfo-flight">
            <ModalPopupComponent
              isMobile={true}
              showCrossButton={false}
              isPopup={isPopup}
              onClosePopup={() => {
                setPopup(false)
              }}
              modalCss={swPopupCss}
            >
              <ExperienceMobileSearchWidget
                pageTheme={props?.home?.data?.site?.site_theme}
                isSRP={false}
                cssfileMobile={ActivitySearchMobileThemeOneCSS}
                getAutoSuggestValue={(value) => getAutoSuggestDataFunc(value, 'activity')}
                expandActivityNameBoolean={expandActivityName}
                getAutoSuggestList={getActivityAutoSuggestList}
                expandActivityBooleanfunc={(value) => setExpandActivityName(value)}
                homeData={!cf.isValueEmpty(initHotelData) ? initHotelData : []}
                onClosePopup={() => setPopup(false)}
                dateFormat={props?.home?.data?.site?.date_format}
              />
            </ModalPopupComponent>
          </div>
        )
    }
  }
  const imageLoader = ({ src }) => {
    return `${'/shared/api/media/' + props.site_id + '/' + src}`
  }

  return (
    <>
      <div>
        <div className={styles.all_section}>
          {props?.home?.data?.data?.header?.mobile?.menu?.map((link, i) => {
            return (
              <>
                {link.status == 'Active' && (
                  <div key={'headerMenu' + i} className={styles.section_div}>
                    {link?.text?.toLowerCase() == 'explore saudi' ? (
                      <div
                        onClick={() => {
                          window.location.href = link.url
                        }}
                      >
                        <div className={styles.service_icon}>
                          <img
                            src={`${'/shared/api/media/' + props.site_id + '/' + link.icon}`}
                            alt=""
                          />
                        </div>
                        <label> {link.text}</label>
                      </div>
                    ) : (
                      <div onClick={() => onClickHeaderMenu(link, i)}>
                        <div className={styles.service_icon}>
                          <img
                            src={`${'/shared/api/media/' + props.site_id + '/' + link.icon}`}
                            alt=""
                          />
                        </div>
                        <label> {link.text}</label>
                        {/* <Link
                            key={link.text}
                            // href={link.url}
                            onClick={() => { window.location.href = link.url }}
                            className=""
                         >
                            {link.text}
                            <img src={`${ '/shared/api/media/' + "6124e1368ede4f08bf2d9ad4" + '/' + link.icon}`} alt="" />
                         </Link> */}
                      </div>
                    )}
                  </div>
                )}
              </>
            )
          })}
          {menuData && isPopup && renderInfo(menuData)}
        </div>
        <div className="main_body">
          {homeBodyMobile.map((res, i) => {
            return (
              <div key={'homeBodyMbl' + i}>
                {!cf.isValueEmpty(res.type) && res.type == 'image_banner' && (
                  <div className="img_banner_home">
                    <Image loader={imageLoader} src={res.banners} width={500} layout="fill" />
                    {/* <img src={ '/shared/api/media/' + props.site_id + '/' + res.banners} /> */}
                  </div>
                )}
                <div>
                  {!cf.isValueEmpty(res.html)
                    ? parse(res.html)
                    : // <Interweave content={res.html} />
                      ''}
                </div>
                {/* {!cf.isValueEmpty(res.type) && res.type == 'image_carousal' && (
                    <div>
                      <Carousel
                        swipeable={false}
                        draggable={false}
                        showDots={true}
                        responsive={responsive}
                        ssr={false} 
                        infinite={true}
                        autoPlay={props.isMobile ? true : true}
                        autoPlaySpeed={3000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                      >
                        {!cf.isValueEmpty(res) &&
                          !cf.isValueEmpty(res.banners) &&
                          res.banners.length > 0 &&
                          res.banners.map((imagedata, index) => {
                            return (
                              <div key={'banner' + index} className="img_banner_home">
                                <CustomLink href={imagedata.banner_url}>
                                  <Image loader={imageLoader} src={imagedata.r_image} width={500} />
                                </CustomLink>
                              </div>
                            )
                          })}
                      </Carousel>
                    </div>
                  )} */}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
