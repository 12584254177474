import React, { Component } from "react";
import commonFunction from "../../../lib/utils/common";
import Link from "next/link";
import configUrl from "../../../../config/config.json";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      overflowItems: [],
      allMenuItems: [],
      isDropdownVisible: false,
      mainMenu: [],
      show: false,
      header_all_section: null,
      width: window.innerWidth * 0.5,
      selectedService: "flights",
    };
    this.adjustNavbar = this.adjustNavbar.bind(this);
  }

  componentDidMount() {
    this.updateMenuItems();
    // window.addEventListener('resize', this.setState({width: window.innerWidth * 0.5}), this.adjustNavbar);
    // setTimeout(() => {
    //     this.adjustNavbar();
    // }, 0);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.updateMenuItems();
    }
  }

  componentWillUnmount() {
    window.removeEventListener(
      "resize",
      this.setState({ width: window.innerWidth * 0.5 }),
      this.adjustNavbar
    );
  }

  updateMenuItems() {
    const { data } = this.props;
    if (
      !commonFunction.isValueEmpty(data) &&
      !commonFunction.isValueEmpty(data.menu)
    ) {
      const allMenuItems = data.menu.filter(
        (link) => link.status.toLowerCase() === "active"
      );
      this.setState({ allMenuItems }, () => {
        if (!this.state.header_all_section) {
          this.setState(
            {
              header_all_section: document.getElementById("header_all_section"),
              allMenuItems: [],
            },
            () => {
              this.adjustNavbar();
            }
          );
        }
      });
    }
  }

  adjustNavbar() {
    console.log("resize====>");
    console.log("this.state.width", this.state.width);
    const navList =
      document.getElementById("header_all_section") ||
      this.state.header_all_section;
    const moreItem = document.querySelector(".dropdown");

    const navWidth = navList.offsetWidth;
    console.log("navWidth====>", navWidth);
    const moreItemWidth = 30; // moreItem.offsetWidth;
    const availableWidth = navWidth - moreItemWidth;
    let totalWidth = 0;
    let overflowItems = [];
    let mainMenu = [];
    Array.from(navList.children).forEach((item) => {
      const itemWidth = item.getBoundingClientRect().width;
      const isMoreItem = item === moreItem;

      if (!isMoreItem) {
        if (totalWidth + itemWidth <= availableWidth) {
          totalWidth += itemWidth;
          mainMenu.push(item.cloneNode(true));
        } else {
          overflowItems.push(item.cloneNode(true));
        }
      }
    });

    this.setState({ overflowItems: overflowItems, mainMenu: mainMenu }, () => {
      const { overflowItems, mainMenu } = this.state;
      const isDropdownVisible = overflowItems.length > 0;
      this.setState({ isDropdownVisible });
    });
  }

  render() {
    const { allMenuItems, overflowItems, isDropdownVisible, mainMenu } =
      this.state;
    const allItemsDistributed =
      mainMenu.length + overflowItems.length === allMenuItems.length;
    console.log("this.props data=>", this.props);
    return (
      <div
        className={this.props.data.additionalClassName}
        style={{ display: "flex" }}
      >
        {!allItemsDistributed && (
          <div
            className={this.props.data.cssfile.header_all_section}
            id="header_all_section"
          >
            {!commonFunction.isValueEmpty(allMenuItems) && (
              <ul>
                {allMenuItems.map((link, i) => (
                  <div
                    key={"headerMenu" + i}
                    className={`${this.props.data.cssfile.header_sections} ${
                      this.props.data.query === link.text.toLowerCase()
                        ? this.props.data.cssfile.active_hometab
                        : ""
                    }`}
                  >
                    {
                      <Link href={link.url}>
                        <li
                          className={
                            link.url == window.location.pathname
                              ? `${this.props.data.cssfile.activetab}`
                              : `${this.props.data.cssfile.inactive}`
                          }
                        >
                          {this.props.data.showIcon && (
                            <div
                              className={this.props.data.cssfile.service_icon}
                            >
                              {link.code != "others" &&
                              link.url === window.location.pathname ? (
                                <img
                                  height={20}
                                  width={20}
                                  src={`images/${window.location.pathname
                                    .replace("/", "")
                                    .slice(0, -1)}-active.svg`}
                                  alt="hotel"
                                />
                              ) : (
                                <img
                                  src={`${
                                    "/shared/api/media/" +
                                    this.props.data.site_id +
                                    "/" +
                                    link.icon
                                  }`}
                                  alt={link.url}
                                />
                              )}
                            </div>
                          )}
                          {link.text}
                        </li>
                      </Link>
                    }
                  </div>
                ))}
              </ul>
            )}
          </div>
        )}
        {mainMenu.length > 0 && (
          <div
            className="dropdown-content"
            style={{ position: "absolute", background: "white" }}
          >
            <ul
              className="dropdown-menu"
              id="dropdown-menu"
              style={{ display: "flex" }}
            >
              {mainMenu.map((element, index) => {
                return (
                  <li
                    key={index}
                    dangerouslySetInnerHTML={{ __html: element.outerHTML }}
                  ></li>
                );
              })}
              {isDropdownVisible && (
                <div className="dropdown">
                  <span
                    style={{ position: "relative" }}
                    onClick={() => this.setState({ show: !this.state.show })}
                  >
                    More
                  </span>
                  {this.state.show && (
                    <div
                      className="dropdown-content"
                      style={{ position: "absolute", background: "white" }}
                    >
                      <ul className="dropdown-menu" id="dropdown-menu">
                        {overflowItems.map((element, index) => {
                          return (
                            <li
                              key={index}
                              dangerouslySetInnerHTML={{
                                __html: element.outerHTML,
                              }}
                            ></li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default Navbar;
