import React, { useEffect, useState } from 'react';
import commonFunction from '../../../../lib/utils/common';

export default function FlightAutoComplete(props) {
    const { theme } = props;
    const onClickHandler = (e) => {
        // if (props.expandAutoCompleteBool === true) props.isAutoCompleteExpand(false)
        // else if (props.expandAutoCompleteBool === false) {
        props.onclickFlightAutoComplete()
        props.isAutoCompleteExpand(true)
        // }
    }

    const selectListClickHandler = (element) => {
        props.onClickSourceAndDestination(element.CITY_NAME, element.AIRPORT_CODE)
        props.isAutoCompleteExpand(false)
    }

    return (
        <div className={props.styles.mob_flight_search_wid_inputfield}>
            <label>{props.label}</label>
            <img src={props.leftIcon} className={props.styles.mob_flight_search_left_icon} alt=""/>
            <input type='text' autoComplete={'off'} id="myTextField"
                placeholder={!commonFunction.isValueEmpty(props.placholder) ? props.placholder : ""}
                className={props.styles.search_widget_input_new}
                value={!commonFunction.isValueEmpty(props.value) ? props.value : ""}
                onChange={(event) => { props.onChangeSourceAndDestination(event.target.value) }}
                // onClick={() => {
                //     if (props.expandAutoCompleteBool === true) props.isAutoCompleteExpand(false)
                //     else if (props.expandAutoCompleteBool === false) props.isAutoCompleteExpand(true)
                // }
                // }
                onClick={onClickHandler}
                onFocusCapture={onClickHandler}
            />
            {!commonFunction.isValueEmpty(props.isError) && props.isError === true ? (
                <div className={props.styles.mob_flight_search_error_message}><span>{!commonFunction.isValueEmpty(props.errorMessage) ? props.errorMessage : 'Invalid field'}</span></div>
            ) : null}
            {props.expandAutoCompleteBool === true ? (
                <div className={props.styles.mob_flight_autocomplete_value}>
                    {!commonFunction.isValueEmpty(props.autoCompleteData) && props.autoCompleteData.length > 0 &&
                        props.autoCompleteData.map((element, i) => {
                            return (
                                <div key={i + 1} className={props.styles.mob_flight_autocomplete_element} onClick={() => {
                                    props.onClickSourceAndDestination(element.CITY_NAME, element.AIRPORT_CODE)
                                    props.isAutoCompleteExpand(false)
                                }}>
                                    <div className={props.styles.mob_flight_autocomplete_name}>{element.CITY_NAME}, {element.COUNTRY_NAME}
                                        <span>{element.AIRPORT_NAME}</span>
                                    </div>
                                    <div className={props.styles.mob_flight_autocomplete_tag}>{element.AIRPORT_CODE}</div>
                                </div>
                            )
                        })
                    }
                </div>
            ) : null
            }
        </div>
    )
}