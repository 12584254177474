import Theme1 from "./theme1";
import Theme2 from "./theme2"
import Theme3 from "./theme3"
import Theme4 from "./theme4"


export default function MobileSearchWidget(props) {
    // console.log("dsgfghjkhh",props)
    const theme = props.pageTheme;
    switch (theme) {
        case 'theme1':
            return <Theme1 airports={props.homeData} pageTheme={props.pageTheme} styles={props.styles} calendarStyles={props.calendarStyles} isSRP={props.isSRP}
            getAutoSuggestData={(value,type, event) => props.getAutoSuggestData(value, type, event)} getDepartureSuggestionList={props.getDepartureSuggestionList} getGoingToSuggestionList={props.getGoingToSuggestionList}
            onClosePopup={(bool) => { props.onClosePopup(bool) }}/>          
        case 'theme2':
            return <Theme2 airports={props.homeData} pageTheme={props.pageTheme} styles={props.styles} calendarStyles={props.calendarStyles} isSRP={props.isSRP}
            getAutoSuggestData={(value,type, event) => props.getAutoSuggestData(value, type, event)} getDepartureSuggestionList={props.getDepartureSuggestionList} getGoingToSuggestionList={props.getGoingToSuggestionList}
            onClosePopup={(bool) => { props.onClosePopup(bool) }}/>
        case 'theme3':
                return <Theme3 airports={props.homeData} pageTheme={props.pageTheme} styles={props.styles} calendarStyles={props.calendarStyles} isSRP={props.isSRP}
                getAutoSuggestData={(value,type, event) => props.getAutoSuggestData(value, type, event)} getDepartureSuggestionList={props.getDepartureSuggestionList} getGoingToSuggestionList={props.getGoingToSuggestionList}
                onClosePopup={(bool) => { props.onClosePopup(bool) }}/>
        case 'theme4':
                return <Theme4 airports={props.homeData} pageTheme={props.pageTheme} styles={props.styles} calendarStyles={props.calendarStyles} isSRP={props.isSRP}
                getAutoSuggestData={(value,type, event) => props.getAutoSuggestData(value, type, event)} getDepartureSuggestionList={props.getDepartureSuggestionList} getGoingToSuggestionList={props.getGoingToSuggestionList}
                onClosePopup={(bool) => { props.onClosePopup(bool) }}  b2bStyles={props?.b2bStyles} userProfile={props.userProfile}  isOffline={props.isOffline} 
                 {...props}
                />        
        default:
            return <Theme1 airports={props.homeData} pageTheme={props.pageTheme} styles={props.styles} calendarStyles={props.calendarStyles} isSRP={props.isSRP}
            getAutoSuggestData={(value,type, event) => props.getAutoSuggestData(value, type, event)} getDepartureSuggestionList={props.getDepartureSuggestionLi} getGoingToSuggestionList={props.getGoingToSuggestionList}
            onClosePopup={(bool) => { props.onClosePopup(bool) }}/>
    }
}